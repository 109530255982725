import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { WarrantyPlusPackage } from "../../../models/warrantyPlusPackage";
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";
import { InformationObligation } from "../../../models/informationObligation";
import { NavigateFunction } from "react-router-dom";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export class InformationObligationStore {

    private RootStrore:RootStore
    informationObligationItems: Array<InformationObligation>;
    columnDef: Array<ColDef>;
    informationObligationId: number = 0;
    informationObligationItem: InformationObligation = new InformationObligation();
    AdminService:AdminService
    warrantyPlusPackageTypeOptions: Array<any> = [];
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    navigate?: NavigateFunction;
    editorStateInformationDescription = EditorState.createEmpty()
    editorStateInformationObligationMM = EditorState.createEmpty()
    editorStateTodosMM = EditorState.createEmpty()
    showDeleteInformationObligationPopup: boolean = false;
    informationObligationToDeleteId: number = 0;
    constructor(rootStrore:RootStore, navigate?: NavigateFunction,informationObligationId?: number) {
        this.RootStrore= rootStrore;
        this.navigate = navigate
        makeAutoObservable(this, {
            informationObligationItems: observable,
            columnDef: observable,
            informationObligationId: observable,
            informationObligationItem: observable,
            warrantyPlusPackageTypeOptions: observable,
            isSaved: observable,
            showValidationErrorPopup: observable,
            editorStateInformationDescription: observable,
            editorStateInformationObligationMM: observable,
            editorStateTodosMM: observable,
            informationObligationToDeleteId: observable,
            updateInformationObligation: action,
            navigateToInformationObligation: action,
            onEditorStateChangeInformationDescription: action,
            onEditorStateChangeInformationObligationMM: action,
            onEditorStateChangeTodosMM: action,
            SaveInformationObligation:flow,
            GetInformationObligationById:flow,
            deleteInformationObligationItemById: flow,
            getInformationObligationItems: flow,
        })
        this.informationObligationId = informationObligationId;
        
        this.AdminService=new AdminService();
       
        this.columnDef = [            
            {
                field: 'edit', headerName: 'Műveletek', maxWidth: 150,
                cellRenderer: 'btnCellRendererOpenItem',
                cellRendererParams: {
                    clicked: (data: any) => { this.navigateToInformationObligation(data.Id); },
                }
            },
            { field: 'InformationType', headerName: 'Tájékoztatás típusa', filter: 'agSetColumnFilter', sortable: true, maxWidth: 550, minWidth: 550, },
            { field: 'CreatedDateString', headerName: 'Létrehozás dátuma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 200, minWidth: 200, },
            { field: 'CreatedByName', headerName: 'Létrehozó', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250, },
            { field: 'InActive', headerName: 'Státusz', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.booleanFormatter(params), maxWidth: 150, minWidth: 150, },
            { field: 'edit', headerName: 'Eltávolítás', maxWidth: 100, minWidth: 100,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {this.setshowDeleteInformationObligationPopup(true, data.Id);},
                    } 
            },  
        ]
    }

    booleanFormatter = (params: any) => {
        return params != null && params.value != null ? (params.value == 1 ? "Inaktív" : "Aktív") : "";
    }

    navigateToInformationObligation = (informationObligationId: number) => {
        this.navigate('/administration/information-obligation-details/' + informationObligationId);
    }

    updateInformationObligation(key: keyof InformationObligation, value: any) {                
        if (key === 'InActive') {
			if (this.informationObligationItem[key] === 1) {
				this.informationObligationItem[key] = 0
			}	
			else {
				this.informationObligationItem[key] = 1;
			}
		}
		else {
            if (key === 'InformationType' && value.toString().length > 200) {
                this.informationObligationItem.InformationType = value.toString().slice(0, 200)
            }
            else {
                (this.informationObligationItem[key] as any) = value;
            }
		}	
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }
   
    *SaveInformationObligation() {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (!this.hasValidationErrors) {
            if (this.informationObligationItem.Id == 0) {
                this.informationObligationItem.InActive = 0;
            }
            this.RootStrore.addloading('SaveInformationObligation');       
		    yield this.AdminService.SaveInformationObligation(this.informationObligationItem);
            this.RootStrore.removeLoading('SaveInformationObligation');            
            this.navigate('/administration/information-obligation');
        }
        
		
	}
    *GetInformationObligationById(){
        this.RootStrore.addloading('GetInformationObligationById');
        this.informationObligationItem = yield this.AdminService.GetInformationObligationById(this.informationObligationId);       
        this.setInformationType();
        this.setInformationDescription();       
        this.setInformationObligationMM();
        this.RootStrore.removeLoading('GetInformationObligationById');

    }

    *deleteInformationObligationItemById(informationObligationItemId: number) {
        this.RootStrore.addloading('deleteInformationObligationItemById');
        yield this.AdminService.DeleteInformationObligationById(informationObligationItemId)        
        this.RootStrore.removeLoading('deleteInformationObligationItemById');
        this.showDeleteInformationObligationPopup = false;
        this.getInformationObligationItems();
    }

    *getInformationObligationItems() {
        this.RootStrore.addloading('getInformationObligationItems');
        this.informationObligationItems = yield this.AdminService.GetInformationObligations();
		this.RootStrore.removeLoading('getInformationObligationItems');
    } 

    setshowDeleteInformationObligationPopup(value: boolean, id?: number) {
        this.showDeleteInformationObligationPopup = value;
        if (value == true) {
            this.informationObligationToDeleteId = id;
            console.log(this.informationObligationToDeleteId);
        }
    }

    confirmDeleteInformationObligation() {
        this.deleteInformationObligationItemById(this.informationObligationToDeleteId);        
    }

    setInformationType() {
        const blocksFromHtmlInformationDescription = htmlToDraft(this.informationObligationItem.InformationDescription);
        const { contentBlocks, entityMap } = blocksFromHtmlInformationDescription;
        const contentStateInformationDescription = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorStateInformationDescription = EditorState.createWithContent(contentStateInformationDescription);
        this.onEditorStateChangeInformationDescription(editorStateInformationDescription);
    }

    setInformationDescription() {
        const blocksFromHtmlInformationObligationMM = htmlToDraft(this.informationObligationItem.InformationObligationMM);
        const { contentBlocks, entityMap } = blocksFromHtmlInformationObligationMM;
        const contentStateInformationObligationMM = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorStateInformationObligationMM = EditorState.createWithContent(contentStateInformationObligationMM);
        this.onEditorStateChangeInformationObligationMM(editorStateInformationObligationMM);
    }

    setInformationObligationMM() {
        const blocksFromHtmlTodosMM = htmlToDraft(this.informationObligationItem.TodosMM);
        const { contentBlocks, entityMap } = blocksFromHtmlTodosMM;
        const contentStateTodosMM = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorStateTodosMM = EditorState.createWithContent(contentStateTodosMM);
        this.onEditorStateChangeTodosMM(editorStateTodosMM);
    }

    onEditorStateChangeInformationDescription(editorState: any) {
        this.editorStateInformationDescription = editorState;
        let cicc2 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.updateInformationObligation('InformationDescription', cicc2)
    };

    onEditorStateChangeInformationObligationMM(editorState: any) {
        this.editorStateInformationObligationMM = editorState;
        let cicc2 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.updateInformationObligation('InformationObligationMM', cicc2)
    };

    onEditorStateChangeTodosMM(editorState: any) {
        this.editorStateTodosMM = editorState;
        let cicc2 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.updateInformationObligation('TodosMM', cicc2)
    };

    get getValidationsInformationObligation() {
        if (!this.isSaved) {
            return {}
        }

        let validationErrors: Partial<Record<keyof InformationObligation, string>> = {}

        if (!this.informationObligationItem.InformationType) {
            validationErrors["InformationType"] = "Tájékoztatás típusa kitöltése kötelező!"
        }

        if (!this.informationObligationItem.InformationDescription || this.informationObligationItem.InformationDescription === "<p></p>\n") {
            validationErrors["InformationDescription"] = "Tájékoztatás leírása kitöltése kötelező!"
        }

        if (!this.informationObligationItem.InformationObligationMM || this.informationObligationItem.InformationObligationMM === "<p></p>\n") {
            validationErrors["InformationObligationMM"] = "Tájékoztatási kötelezettség MM oldalon kitöltése kötelező!"
        }

        if (!this.informationObligationItem.TodosMM || this.informationObligationItem.TodosMM === "<p></p>\n") {
            validationErrors["TodosMM"] = "Teendők MM oldalon kitöltése kötelező!"
        }        

        // console.log(validationErrors)
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsInformationObligation).length === 0) {
            return false;
        }

        return true;
    }
}