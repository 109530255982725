import { Settlement } from '../models/settlement'
import { SettlementAddress } from '../models/settlementAddress';
import { WarrantyPlusPackage } from '../models/warrantyPlusPackage';
import { TariffPackageScore } from '../models/TariffPackageScore';
import { ManufacturerWarranty } from '../models/manufacturerWarranty';
import { StoreCommission } from '../models/storeCommission';
import { StoreData } from '../models/storeData';
import { TariffPackage } from '../models/tariffPackage';
import { DeviceDiscount } from '../models/deviceDiscount';
import { Device } from '../models/device';
import { VodafoneCommission } from '../models/vodafoneCommission';
import { Store } from '../models/store';
import { RateCommission } from '../models/rateCommission';
import { VodafonePackageFeed } from '../models/vodafonePackageFeed';
import { EmailContainer } from '../models/emailContainer';
import { NumberFormatProps } from 'react-number-format';
import { InformationObligation } from '../models/informationObligation';
import { TrainingModule } from '../models/trainingModel';
import { TrainingModuleDocument } from '../models/trainingModuleDocument';
import { ServiceProviderPermissionManagement } from '../models/serviceProviderPermissionManagement';
import { ModuleAssignment } from '../models/moduleAssignment';
import { RiportFrequence} from '../models/riportFrequence';
import { RiportType} from '../models/riportType';
import { UserRiportFrequence} from '../models/userRiportFrequence';


export class AdminService {

    public async GetStores(): Promise<Array<Store>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetStores', options)
        return response.json();
    }
    public async GetSettlements(): Promise<Settlement[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetSettlements', options)
        return response.json();
    }
    
    public async GetWarrantyPlusPackeges(showInactive:boolean): Promise<WarrantyPlusPackage[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetWarrantyPlusPackeges?showInactive='+showInactive, options)
        return response.json();
    }
    public async GetWarrantyPlusPackegesOffer(showInactive:boolean): Promise<WarrantyPlusPackage[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetWarrantyPlusPackegesOffer?showInactive='+showInactive, options)
        return response.json();
    }
    public async GetWarrantyPlusPackegeById(Id:number): Promise<WarrantyPlusPackage> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetWarrantyPlusPackegeById?Id='+Id, options)
        return response.json();
    }
    
    public async SaveWarrantyPlusPackage(warrantyPackage:WarrantyPlusPackage): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(warrantyPackage)
       
        };

        const response = await fetch('/api/admin/SaveWarrantyPlusPackage', options)
        return response;
    }

    public async GetWarrantyPlusPackageTypes(): Promise<ManufacturerWarranty[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetWarrantyPlusPackageTypes', options)
        return response.json();
    }

    public async GetSettlementAddresses(): Promise<SettlementAddress[]> {
        const options: RequestInit = {
            method: "GET", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetSettlementAddresses', options)
        return response.json();
    }

    public async UploadSettlementTest(formData:any): Promise<void> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:formData
        }
        const requestHeaders: Headers = new Headers();
		//	requestHeaders.append('Content-type', 'multipart/form-data');

		const httpClientOptions: any = {
			headers: requestHeaders,
			credentials: 'include',
			method: 'POST',
			body: formData
		};

        const response = await fetch(`/api/admin/UploadSettlementTest`, httpClientOptions)
        return response.json();
    }

    public async UploadUserGuide(formData:any): Promise<void> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:formData
        }
        const requestHeaders: Headers = new Headers();
		//	requestHeaders.append('Content-type', 'multipart/form-data');

		const httpClientOptions: any = {
			headers: requestHeaders,
			credentials: 'include',
			method: 'POST',
			body: formData
		};

        const response = await fetch(`/api/admin/UploadUserGuide`, httpClientOptions)
        return response.json();
    }

    public async UploadDeviceDiscount(formData:any): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:formData
        }
        const requestHeaders: Headers = new Headers();
		//	requestHeaders.append('Content-type', 'multipart/form-data');

		const httpClientOptions: any = {
			headers: requestHeaders,
			credentials: 'include',
			method: 'POST',
			body: formData
		};

        const response = await fetch(`/api/admin/UploadDeviceDiscount`, httpClientOptions)
        return response.json();
    }

    public async UploadTariffPackageTile(formData:any, id: number): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:formData
        }
        const requestHeaders: Headers = new Headers();
		//	requestHeaders.append('Content-type', 'multipart/form-data');

		const httpClientOptions: any = {
			headers: requestHeaders,
			credentials: 'include',
			method: 'POST',
			body: formData
		};

        const response = await fetch(`/api/admin/UploadTariffPackageTile?id=`+id, httpClientOptions)
        return response.json()
        
    }

    public async UploadBanner(formData:any, type: number): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:formData
        }
        const requestHeaders: Headers = new Headers();
		//	requestHeaders.append('Content-type', 'multipart/form-data');

		const httpClientOptions: any = {
			headers: requestHeaders,
			credentials: 'include',
			method: 'POST',
			body: formData
		};

        const response = await fetch(`/api/admin/UploadBanner?type=`+type, httpClientOptions)
        return response.json()
        
    }

    public async SaveManufacturerWarranty(manufacturerWarranty:ManufacturerWarranty): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(manufacturerWarranty)
       
        };

        const response = await fetch('/api/admin/SaveManufacturerWarranty', options)
        return response;
    }

    public async GetManufacturerWarranties(): Promise<ManufacturerWarranty[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetManufacturerWarranties', options)
        return response.json();
    }

    public async DeleteManufacturerWarrantyItemById(manufacturerWarrantyItemId:number): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(manufacturerWarrantyItemId)
       
        };

        const response = await fetch('/api/admin/DeleteManufacturerWarrantyItemById', options)
        return response;
    }

    public async SaveStoreCommission(storeCommission:StoreCommission): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(storeCommission)
       
        };

        const response = await fetch('/api/admin/SaveStoreCommission', options)
        return response;
    }

    public async GetStoreCommissions(): Promise<StoreCommission[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetStoreCommissions', options)
        return response.json();
    }

    public async GetStoreDatas(): Promise<StoreData[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetStoreDatas', options)
        return response.json();
    }

    public async SaveTariffPackage(tariffPackage:TariffPackage): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(tariffPackage)
       
        };

        const response = await fetch('/api/admin/SaveTariffPackage', options)
        return response;
    }

    public async GetTariffPackages(): Promise<TariffPackage[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetTariffPackages', options)
        return response.json();
    }

    public async GetTariffPackageById(Id:number): Promise<TariffPackage> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetTariffPackageById?Id='+Id, options)
        return response.json();
    }

    public async GetOfferStatuses(): Promise<TariffPackage[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetOfferStatuses', options)
        return response.json();
    }

    public async GetOfferIntervals(): Promise<TariffPackage[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetOfferIntervals', options)
        return response.json();
    }

    public async SaveDeviceDiscount(deviceDiscount:DeviceDiscount): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(deviceDiscount)
       
        };

        const response = await fetch('/api/admin/SaveDeviceDiscount', options)
        return response;
    }

    public async GetDeviceDiscounts(storeCode: string): Promise<DeviceDiscount[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetDeviceDiscounts?storeCode='+storeCode, options)
        return response.json();
    }

    public async GetDeviceDiscountById(Id:number): Promise<DeviceDiscount> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetDeviceDiscountById?Id='+Id, options)
        return response.json();
    }

    public async DeleteDeviceDiscountById(id:number): Promise<boolean> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(id)
       
        };

        const response = await fetch('/api/admin/DeleteDeviceDiscountById', options)
        return response.json();
    }

    public async GetDeviceByArticleNumber(ArticleNumber:number,storeCode:string): Promise<Device> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetDeviceByArticleNumber?ArticleNumber='+ArticleNumber+"&sapCode="+storeCode, options)
        return response.json();
    }

    public async GetVodafoneCommissions(): Promise<VodafoneCommission[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetVodafoneCommissions', options)
        return response.json();
    }

    public async SaveVodefoneCommission(vodafoneCommission:VodafoneCommission): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(vodafoneCommission)
       
        };

        const response = await fetch('/api/admin/SaveVodefoneCommission', options)
        return response;
    }

    public async GetDeviceDiscountForOfferPackageDevice(tariffPackageId:number, deviceId:number): Promise<TariffPackage> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetDeviceDiscountForOfferPackageDevice?tariffPackageId='+tariffPackageId+"&deviceId="+deviceId, options)
        return response.json();
    }
    
    public async GetPercentegaDiscount(tariffPackageId:number, deviceId:number): Promise<DeviceDiscount> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetPercentegaDiscount?tariffPackageId='+tariffPackageId+"&deviceId="+deviceId, options)
        return response.json();
    }
    public async GetPackageDiscount(tariffPackageId:number, deviceId:number): Promise<DeviceDiscount> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetPackageDiscount?tariffPackageId='+tariffPackageId+"&deviceId="+deviceId, options)
        return response.json();
    }
    public async GetHighestDeviceDiscount(tariffPackageId:number, deviceId:number): Promise<TariffPackage> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetHighestDeviceDiscount?tariffPackageId='+tariffPackageId+"&deviceId="+deviceId, options)
        return response.json();
    }

    public async SaveRateCommission(rateCommission:RateCommission): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(rateCommission)
       
        };

        const response = await fetch('/api/admin/SaveRateCommission', options)
        return response;
    }

    public async GetLastRateCommission(): Promise<RateCommission> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetLastRateCommission', options)
        return response.json();
    }

    public async GetVodafonePackagesByArticleNumber(queryText:string, sapCode: string): Promise<VodafonePackageFeed> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetVodafonePackagesByArticleNumber?queryText='+queryText+"&sapCode="+sapCode, options)
        return response.json();
    }

    public async GenerateRateCommission() {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
        };

        window.location.assign(`/api/report/GenerateRateCommission`)        
    }

    public async GenerateDeviceDiscountPartial(storeCode: string) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
        };

        window.location.assign(`/api/report/GenerateDeviceDiscountPartial?storeCode=`+storeCode)        
    }

    public async GenerateDeviceDiscountComplete(storeCode: string) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
        };

        window.location.assign(`/api/report/GenerateDeviceDiscountComplete?storeCode=`+storeCode)        
    }

    public async GetBannerByContent(type: number): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetBannerByContent?type='+type, options)
        return response.json();
    }

    public async GetOverrideEmailNotifications(): Promise<EmailContainer[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetOverrideEmailNotifications', options)
        return response.json();
    }

    public async SaveOverrideEmailNotifications(emailContainer:EmailContainer): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(emailContainer)
       
        };

        const response = await fetch('/api/admin/SaveOverrideEmailNotifications', options)
        return response;
    }

    public async RemoveOverrideEmailNotifications(id:number): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(id)
       
        };

        const response = await fetch('/api/admin/RemoveOverrideEmailNotifications?id='+id, options)
        return response;
    }

    public async GetTariffPackageScores(): Promise<TariffPackageScore[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetTariffPackageScores', options)
        return response.json();
    }
    public async GetTariffPackageScoreById(Id:number): Promise<TariffPackageScore> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetTariffPackageScoreById?Id='+Id, options)
        return response.json();
    }
    
    public async SaveTariffPackageScore(tariffPackage:TariffPackageScore): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(tariffPackage)
       
        };

        const response = await fetch('/api/admin/SaveTariffPackageScore', options)
        return response;
    }

    public async GetInformationObligations(onlyActive?: boolean): Promise<InformationObligation[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetInformationObligations?onlyActive='+onlyActive, options)
        return response.json();
    }
    public async GetInformationObligationById(Id:number): Promise<InformationObligation> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetInformationObligationById?Id='+Id, options)
        return response.json();
    }
    
    public async SaveInformationObligation(informationObligation: InformationObligation): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(informationObligation)
       
        };

        const response = await fetch('/api/admin/SaveInformationObligation', options)
        return response;
    }

    public async DeleteInformationObligationById(id:number): Promise<boolean> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(id)
       
        };

        const response = await fetch('/api/admin/DeleteInformationObligationById', options)
        return response.json();
    }

    public async GetTrainingModules(onlyActive: boolean = false): Promise<TrainingModule[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetTrainingModules?onlyActive='+onlyActive, options)
        return response.json();
    }
    public async GetTrainingModuleById(Id:number): Promise<TrainingModule> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetTrainingModuleById?Id='+Id, options)
        return response.json();
    }
    
    public async SaveTrainingModule(trainingModule: TrainingModule): Promise<TrainingModule> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(trainingModule)
       
        };

        const response = await fetch('/api/admin/SaveTrainingModule', options)
        return response.json();
    }

    public async UploadTrainingModuleDocument(formData:any, trainingModuleId: number): Promise<void> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:formData
        }
        const requestHeaders: Headers = new Headers();
		//	requestHeaders.append('Content-type', 'multipart/form-data');

		const httpClientOptions: any = {
			headers: requestHeaders,
			credentials: 'include',
			method: 'POST',
			body: formData
		};

        const response = await fetch(`/api/document/UploadTrainingModuleDocument?trainingModuleId=${trainingModuleId}`, httpClientOptions)
        return response.json();
    }

    public async GetDocumentOfTrainingModule(id: number): Promise<TrainingModuleDocument> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/document/GetDocumentOfTrainingModule?id=` + id, options)
        return response.json();
    }

    public async GetTrainingModuleDocumentByObjectId(id: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        window.location.assign(`/api/document/GetTrainingModuleDocumentByObjectId?id=` + id)
        // window.location.assign(response.body as any);
    }

    public async GetServiceProviderPermissionManagements(): Promise<ServiceProviderPermissionManagement[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetServiceProviderPermissionManagements', options)
        return response.json();
    }

    public async SaveModuleAssignment(moduleAssignment: ModuleAssignment): Promise<number[]> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(moduleAssignment)
       
        };

        const response = await fetch('/api/admin/SaveModuleAssignment', options)
        return response.json();
    }

    public async GetUserRuleModuleAssignment(userId: number, roleId: number): Promise<ModuleAssignment[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetUserRuleModuleAssignment?userId='+userId+'&roleId='+roleId, options)
        return response.json();
    }

    public async GetUserRuleModuleAssignmentById(id: number): Promise<ModuleAssignment[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetUserRuleModuleAssignmentById?id='+id, options)
        return response.json();
    }

    public async UpdateUserRuleModuleAssignmentDueDate(moduleAssignment: ModuleAssignment): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(moduleAssignment)
       
        };

        const response = await fetch('/api/admin/UpdateUserRuleModuleAssignmentDueDate', options)
        return response;
    }

    public async GetUserAllNotCompletedModuleAssignment(userId: number): Promise<ModuleAssignment[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetUserAllNotCompletedModuleAssignment?userId='+userId, options)
        return response.json();
    }

    public async GetUserAllNotCompletedModuleAssignmentWithExpiredDueDate(userId: number): Promise<ModuleAssignment[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetUserAllNotCompletedModuleAssignmentWithExpiredDueDate?userId='+userId, options)
        return response.json();
    }

    public async SetModuleAssignmentCompleted(id: number): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(id)
       
        };

        const response = await fetch('/api/admin/SetModuleAssignmentCompleted', options)
        return response;
    }

    public async CheckUserShouldAddToConsultantRoleAfterCompletedAllModule(): Promise<boolean> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"       
        };

        const response = await fetch('/api/admin/CheckUserShouldAddToConsultantRoleAfterCompletedAllModule', options)
        return response.json();
    }

    public async GetRiportFrequencies(): Promise<RiportFrequence[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetRiportFrequencies', options)
        return response.json();
    }

    public async GetRiportTypes(): Promise<RiportType[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetRiportTypes', options)
        return response.json();
    }

    public async GetUserRiportFrequences(): Promise<RiportType[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/admin/GetUserRiportFrequences', options)
        return response.json();
    }

    public async InsertOrUpdateUserRiportFrequences(UserRiportFrequence: UserRiportFrequence[]): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(UserRiportFrequence)
       
        };

        const response = await fetch('/api/admin/InsertOrUpdateUserRiportFrequences', options)
        return response;
    }

    public async DeleteModuleAssigmentById(id: number): Promise<boolean> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(id)

        };

        const response = await fetch('/api/admin/DeleteModuleAssigmentById', options)
        return response.json();
    }
}