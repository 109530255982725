import { action, makeObservable, observable } from "mobx";

export class CustomerDataStore {
    isHRSZChecked: boolean = false;    

    constructor() {
        makeObservable(this, {
            isHRSZChecked: observable,
			handleIsHRSZ: action
        })
    }

    handleIsHRSZ = () => {
		this.isHRSZChecked = !this.isHRSZChecked;        
	}
}