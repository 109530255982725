import React from 'react';
import { useLocation } from 'react-router-dom';

interface IWithProps {}

const withLocation =
	<P extends object>(Component: React.ComponentType<P>): React.FC<P & IWithProps> =>
	(props) => {
		let location = useLocation();

		return <Component location={location} {...(props as P)} />;
	};

export default withLocation;
