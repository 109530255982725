import { makeAutoObservable } from "mobx";

export class EmailContainer {
    Id: number;
    RoleId: number;
    RoleName: string;
    StoreCode: string;
    StoreName: string;
    Email: string;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.RoleId = 0;
        this.RoleName = null;
        this.StoreCode = null;
        this.StoreName = null;
        this.Email = null;
    }
}