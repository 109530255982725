import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { WarrantyPlusPackage } from "../../../models/warrantyPlusPackage";
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";
import { TariffPackage } from "../../../models/tariffPackage";
import { TariffPackageScore } from "../../../models/TariffPackageScore";
import { NavigateFunction } from "react-router-dom";
import { RiportService } from "../../../services/riport.service";

export class TariffPackageScoreStore {

    private RootStrore:RootStore
    tariffPackageScoreItems: Array<TariffPackageScore>;
    columnDef: Array<ColDef>;
    tariffPackageScoreItemId: number = 0;
    tariffPackageScoreItem: TariffPackageScore = new TariffPackageScore();
    AdminService:AdminService
    warrantyPlusPackageTypeOptions: Array<any> = [];
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    savedActualScore: number = null;
    navigate?: NavigateFunction;
    RiportService: RiportService;
    containsInActive: number = 0;
    constructor(rootStrore:RootStore, navigate?: NavigateFunction,tariffPackageScoreItemId?: number) {
        this.RootStrore= rootStrore;
        this.navigate = navigate
        makeAutoObservable(this, {
            tariffPackageScoreItems: observable,
            columnDef: observable,
            tariffPackageScoreItemId: observable,
            tariffPackageScoreItem: observable,
            warrantyPlusPackageTypeOptions: observable,
            isSaved: observable,
            showValidationErrorPopup: observable,
            savedActualScore: observable,
            containsInActive: observable,
            updateTariffPackageItem: action,
            SaveTariffPackageScore:flow,
            GetTariffPackageScoreById:flow,
            generateTariffPackageScoreExport: flow,
        })
        this.tariffPackageScoreItemId = tariffPackageScoreItemId;
        
        this.AdminService=new AdminService();
        this.RiportService = new RiportService();
       
        this.columnDef = [
            {
                field: 'TariffPackageName', headerName: 'Tarifacsomag neve', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350,
                cellRenderer: (params) => {
                    var link = document.createElement('a');
                    link.href = '#/administration/tariffpackagescoredetails/' + params.data.Id;
                    link.innerText = params.value;
                    link.addEventListener('click', (e) => {
                        // e.preventDefault();
                        console.log(params.data.id);
                    });
                    return link;
                }
            },
            { field: 'ActualScore', headerName: 'Tarifacsomag pontértéke', valueFormatter: params => this.decimalPointToComma(params), filter: 'agSetColumnFilter',  sortable: true, maxWidth: 330, minWidth: 330, },
            { field: 'ModifiedByName', headerName: 'Utoljára módosította', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
            { field: 'ModifiedDateString', headerName: 'Utoljára módosítva', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
        ]
    }

    decimalPointToComma(params: any) {
        return params != null && params.value != null ? params.value.toString().replace('.', ',') : null;
    }

    updateTariffPackageItem(key: keyof TariffPackageScore, value: any) {      
        if (value?.toString()?.length > 1 && value?.toString()?.charAt(0) == "0") {            
            return;
        }
        if (value.toString() != "00") {
            console.log('valuevaluevaleuvalue', value);
            if (value == null || value == "") {
                this.tariffPackageScoreItem.ActualScore = null;
            }    
            else {
                this.tariffPackageScoreItem.ActualScore = Number(value); 
            }                       
            console.log("this.tariffPackageScoreItem.ActualScore", this.tariffPackageScoreItem.ActualScore)  
        }                                        	
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }

    setContainsInActive = () => {
        if (this.containsInActive == 0) {
            this.containsInActive = 1;
        }
        else {
            this.containsInActive = 0;
        }        
        console.log('this.containsInActivethis.containsInActivethis.containsInActive', this.containsInActive)
    }
   
    *SaveTariffPackageScore() {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (!this.hasValidationErrors) {
            this.RootStrore.addloading('SaveTariffPackageScore');   
            console.log(this.tariffPackageScoreItem);    
		    yield this.AdminService.SaveTariffPackageScore(this.tariffPackageScoreItem);
            this.RootStrore.removeLoading('SaveTariffPackageScore');  
            this.navigate('/administration/tariffpackagescore')      
        }        		
	}

    *GetTariffPackageScoreById(){
        this.RootStrore.addloading('GetTariffPackageById');
        this.tariffPackageScoreItem = yield this.AdminService.GetTariffPackageScoreById(this.tariffPackageScoreItemId);
        this.savedActualScore = this.tariffPackageScoreItem.ActualScore;
        this.RootStrore.removeLoading('GetTariffPackageById');
    }

    *generateTariffPackageScoreExport() {
        let isContainsInActive: boolean = this.containsInActive == 1;
        console.log(isContainsInActive);
        yield this.RiportService.GenerateTariffPackageScoreExport(isContainsInActive);
    }

    get getValidationsTariffPackageScore() {        
        let validationErrors: Partial<Record<any, string>> = {}

        if (this.tariffPackageScoreItem.ActualScore === null || this.tariffPackageScoreItem.ActualScore?.toString() == "") {
            validationErrors["ActualScoreSameAsPreviousScore"] = "Az aktuális pontérték nem lehet üres!"
        }    

        // if (this.tariffPackageScoreItem.ActualScore == this.tariffPackageScoreItem.PreviousScore) {
        //     validationErrors["ActualScoreSameAsPreviousScore"] = "Az aktuális pontérték nem lehet egyenlő az előző pontértékkel!"            
        // }        

        if (this.tariffPackageScoreItem.ActualScore == this.savedActualScore) {
            validationErrors["ActualScoreSameAsPreviousScore"] = "Az aktuális pontérték megegyezik az eredetileg aktuális pontértékkel!"
        }        

        if (this.tariffPackageScoreItem.ActualScore < 0 || this.tariffPackageScoreItem.ActualScore > 100) {
            validationErrors["ActualScoreRange"] = "Aktuális pontértéknek 0 és 100 közötti érték adható meg!"
        }        

        console.log('this.tariffPackageScoreItem.PreviousScore', this.tariffPackageScoreItem.PreviousScore);
        console.log('this.tariffPackageScoreItem.ActualScore', this.tariffPackageScoreItem.ActualScore);
        console.log(validationErrors)
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsTariffPackageScore).length === 0) {
            return false;
        }

        return true;
    }
}