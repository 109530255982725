import { makeAutoObservable } from "mobx";

export class WarrantyPlusPackage {
    Id: number;
    PackageNumber: string;
    Type: number;
    Name: string;
    ValueFrom: number;
    ValueTo: number;
    Price: number;
    ExtendedWarrantyStartsAfterManufacturersWarranty: boolean;
    ExtendedWarrantyLengthMonth: number;
    ValidFrom: Date;
    ValidTo: Date;
    IsActive: number;
    IsSelected: boolean;
    TypeString: string;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.PackageNumber = null;
        this.Type = 1;
        this.Name = null;
        this.ValueFrom = null;
        this.ValueTo = null;
        this.Price = null;
        this.ExtendedWarrantyStartsAfterManufacturersWarranty = false;
        this.ExtendedWarrantyLengthMonth = null;
        this.ValidFrom = null;
        this.ValidTo = null;
        this.IsActive = 1;
        this.IsSelected = false;
        this.TypeString = null;
    }
}