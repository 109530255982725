import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { NavigateFunction } from "react-router-dom";
import { OfferService } from "../../services/offer.service";
import { RootStore } from "../../stores/RootStore"
import { AdminService } from "../../services/admin.service";
import { DropdownProps } from "semantic-ui-react";
import { ModuleAssignment } from "../../models/moduleAssignment";

export class ModuleAssignmentStore {

	private RootStore: RootStore
    ajanlatbongeszItems: Array<any>;    
    offerStatusOptions: Array<any>;
    offerIntervalOptions: Array<any>;
    columnDef: Array<ColDef>;
    columnDefTasks: Array<ColDef>;
    
    navigate?: NavigateFunction;
    OfferService: OfferService;
    AdminService: AdminService;
    offerStatusId: number = 1;
    offerIntervalId: number = 1;
    moduleAssignments:Array<ModuleAssignment> = [];    
    moduleAssignmentExpiredDueDate:Array<ModuleAssignment> = [];    
    selectedModuleId: number = 0;
    showTrainingModuleStatement: boolean = false;
    selectedModuleAssignment: ModuleAssignment = new ModuleAssignment();
    constructor(navigate: NavigateFunction,RootStore?: RootStore) {
        this.RootStore = RootStore;
        this.navigate = navigate
        makeAutoObservable(this, {
            ajanlatbongeszItems: observable,            
            moduleAssignments:observable,
            offerStatusOptions: observable,
            offerIntervalOptions: observable,
            columnDef: observable,
            columnDefTasks:observable,
            offerStatusId: observable,
            offerIntervalId: observable,
            selectedModuleId: observable,
            selectedModuleAssignment: observable,
            moduleAssignmentExpiredDueDate: observable,
            GetUserAllNotCompletedModuleAssignment: flow,
            GetTrainingModuleDocumentByObjectId: flow,
            SetModuleAssignmentCompleted: flow,
        })

        this.OfferService = new OfferService();    
        this.AdminService = new AdminService();                            
    }
    
    navigateOffer(id:number){
        this.navigate('/offer/'+id);
    }       

    openModuleStatementPopup(itemId: number) {
        this.showTrainingModuleStatement = true;
        this.selectedModuleAssignment = this.moduleAssignments.find(x => x.Id == itemId);
        this.selectedModuleId = itemId;
    }

    *GetUserAllNotCompletedModuleAssignment() {
        this.RootStore?.addloading('GetUserAllNotCompletedModuleAssignment')
        this.moduleAssignments = yield this.AdminService.GetUserAllNotCompletedModuleAssignment(this.RootStore.currentUser.ExtendedInfoId);
        this.moduleAssignmentExpiredDueDate = yield this.AdminService.GetUserAllNotCompletedModuleAssignmentWithExpiredDueDate(this.RootStore.currentUser.ExtendedInfoId);
        this.RootStore?.removeLoading('GetUserAllNotCompletedModuleAssignment')
        if (this.moduleAssignments.length == 0) {
            if (this.moduleAssignmentExpiredDueDate.length == 0) {
                this.RootStore.currentUser.IsAnyNotCompletedModuleInProgress = false;            
                this.navigate('/home');
            }
            else {
                this.navigate('/home');
            }
        }
    }    

    *GetTrainingModuleDocumentByObjectId(id: number, index: number) {
        this.RootStore?.addloading('GetTrainingModuleDocumentByObjectId')
		this.AdminService.GetTrainingModuleDocumentByObjectId(id);
        this.RootStore?.removeLoading('GetTrainingModuleDocumentByObjectId')
        this.moduleAssignments[index].IsRead = true;
	}

    *SetModuleAssignmentCompleted() {
        this.RootStore?.addloading('SetModuleAssignmentCompleted')
		this.AdminService.SetModuleAssignmentCompleted(this.selectedModuleId);
        this.RootStore?.removeLoading('SetModuleAssignmentCompleted')
        this.moduleAssignments = this.moduleAssignments.filter(module => module.Id !== this.selectedModuleId);
        this.moduleAssignmentExpiredDueDate = yield this.AdminService.GetUserAllNotCompletedModuleAssignmentWithExpiredDueDate(this.RootStore.currentUser.ExtendedInfoId);
        if (this.moduleAssignments.length == 0) {
            const result: boolean = yield this.AdminService.CheckUserShouldAddToConsultantRoleAfterCompletedAllModule();   
            if (result == true) {
                this.RootStore.currentUser.IsNewOfferDisabledForSeniorOrConsultant = false;
                this.RootStore.currentUser.IsConsultant = true;
            }         
            if (this.RootStore.currentUser.StoreAdmin && this.RootStore.currentUser.IsVodafoneSupervisorApproved == 1) {
                this.RootStore.currentUser.IsNewOfferDisabledForSeniorOrConsultant = false;
            }
            if (this.moduleAssignmentExpiredDueDate.length == 0) {
                this.RootStore.currentUser.IsAnyNotCompletedModuleInProgress = false;
                this.navigate('/home')
            }
            if (this.moduleAssignmentExpiredDueDate.length != 0) {                
                this.navigate('/home')
            }            
        }
        this.showTrainingModuleStatement = false;
        this.selectedModuleId = 0;
        this.selectedModuleAssignment = new ModuleAssignment();
	}
}