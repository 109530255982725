import { action, flow, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { Settlement } from "../../../models/settlement";
import { SettlementAddress } from "../../../models/settlementAddress";
import { AdminService } from "../../../services/admin.service";

export class AddressLoaderStore {
    
    settlementItems: Array<Settlement>;
    settlementAddressItems: Array<SettlementAddress>;
    isUploadConfirmOpen: boolean = false;
    uploadIdString: string = "uploadId";
    settlementColumnDef: Array<ColDef>;
    settlementAddressColumnDef: Array<ColDef>;
    file: any;
    AdminService: AdminService

    constructor(){
        makeObservable(this, {
            settlementItems: observable,
            settlementAddressItems: observable,
            isUploadConfirmOpen: observable,
            uploadIdString: observable,
            settlementColumnDef: observable,
            settlementAddressColumnDef: observable,
            cancelUpload: action,
            setIsUploadConfirmOpen: action,
            confirmUpload: flow,
        })

        this.settlementColumnDef = [
            { field: 'Name', headerName: 'Település neve', filter: 'agSetColumnFilter', sortable: true, minWidth: 700, maxWidth: 700},
            { field: 'ZipCode', headerName: 'Település irányítószáma', filter: 'agSetColumnFilter', sortable: true, minWidth: 700, maxWidth: 700},            
        ]

        this.settlementAddressColumnDef = [
            { field: 'SettlementName', headerName: 'Település neve', filter: 'agSetColumnFilter', sortable: true, minWidth: 480, maxWidth: 480},     
            { field: 'StreetName', headerName: 'Közterület neve', filter: 'agSetColumnFilter', sortable: true, minWidth: 460, maxWidth: 460},                         
            { field: 'Suffix', headerName: 'Közterület jellege', filter: 'agSetColumnFilter', sortable: true, minWidth: 460, maxWidth: 460}                             
        ]

        this.AdminService = new AdminService()
    }        

    cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isUploadConfirmOpen = false;        
    }

    setIsUploadConfirmOpen = () => {
        this.isUploadConfirmOpen = true;        
    }    

    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
        console.log('Kiválasztott file:', this.file)
    }

    *confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {        
        this.isUploadConfirmOpen = false;
        let formData: FormData = new FormData();
        this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        yield this.AdminService.UploadSettlementTest(formData); 
        console.log(this.file)
    }
}