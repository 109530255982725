import { RootStore } from "../../../stores/RootStore";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { action, computed, flow, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { DeviceDiscount } from "../../../models/deviceDiscount";
import { UploadResult } from "../../../models/uploadresult";

import { Device } from "../../../models/device";
import { OfferService } from "../../../services/offer.service";
import { TariffPackage } from "../../../models/tariffPackage";
import { AdminService } from "../../../services/admin.service";
import { NavigateFunction } from "react-router-dom";
import { RiportService } from "../../../services/riport.service";

export class RateDeviceDiscountStore {


    private RootStore: RootStore;
    deviceDiscountItems: Array<any>;
    columnDef: Array<ColDef>;
    rateColumnDef: Array<ColDef>;
    deviceDiscountItem: DeviceDiscount = new DeviceDiscount();
    deviceDiscountItemId: number = 0;
    selectedDeviceItem: Device = new Device();
    selectedTariffPackageItem: TariffPackage = new TariffPackage();
    queryText: string = "";
    tariffPackageQueryText: string = "";
    OfferService: OfferService
    AdminService: AdminService
    devicesByQueryText: Array<Device> = [];
    tariffPackagesByQueryText: Array<TariffPackage> = [];
    yesNoDatasource: Array<any>;
    tariffPackageItems: Array<any> = [];
    navigate?: NavigateFunction;
    showTariffPackageSearch: boolean = true;
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    isUploadConfirmOpen: boolean = false;
    uploadIdString: string = "uploadId";
    file: any;
    RiportService: RiportService;
    isQueryPopupOpen: boolean = false;
    generateId: number = 1;
    generateOptions: Array<any> = [];
    showDeleteRateDeviceDiscountPopup: boolean = false;
    rateDeviceDiscountToDeleteId: number = 0;


    constructor(rootStore: RootStore, navigate?: NavigateFunction, deviceDiscountItemId?: number) {
        this.RootStore = rootStore
        this.navigate = navigate
        this.deviceDiscountItemId = deviceDiscountItemId
        makeAutoObservable(this, {
            deviceDiscountItems: observable,
            columnDef: observable,
            deviceDiscountItem: observable,
            deviceDiscountItemId: observable,
            selectedDeviceItem: observable,
            selectedTariffPackageItem: observable,
            queryText: observable,
            tariffPackageQueryText: observable,
            devicesByQueryText: observable,
            tariffPackagesByQueryText: observable,
            tariffPackageItems: observable,
            showTariffPackageSearch: observable,
            showDeleteRateDeviceDiscountPopup: observable,
            rateDeviceDiscountToDeleteId: observable,
            updateDeviceDiscountItem: action,
            deleteTariffPackageItemById: action,
            navigateToTome: action,
            updateDevice: action,
            updateTariffPackage: action,
            onChangeQueryText: action,
            onTariffPackageChangeQueryText: action,
            navigateToDeviceDiscount: action,
            setShowDeleteRateDeviceDiscountPopup: action,
            addSelectedTarifaPackageItem: action,
            confirmDeleteRateDeviceDiscount: action,
            getDeviceByQueryText: flow,
            SaveDeviceDiscount: flow,
            GetDeviceDiscountById: flow,
            GetDeviceByArticleNumber: flow,
            GetDeviceDiscounts: flow,
            GetTariffPackageByQueryText: flow,
            generateDeviceDiscountPartial: flow,
            generateDeviceDiscountComplete: flow,
            DeleteDeviceDiscountById: flow,            
            deviceByQueryTextDDL: computed,
            tariffPackageByQueryTextDDL: computed
        })

        this.deviceDiscountItemId = deviceDiscountItemId;
        this.OfferService = new OfferService();
        this.AdminService = new AdminService();
        this.RiportService = new RiportService();

        this.columnDef = [
            {
                field: 'edit', headerName: 'Műveletek', maxWidth: 120,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => { this.navigateToDeviceDiscount(data.Id); },
                }
            },
            { field: 'DeviceName', headerName: 'Készülék', filter: 'agSetColumnFilter', sortable: true, width: 180, resizable: true},
            { field: 'DeviceId', headerName: 'Cikkszám', filter: 'agSetColumnFilter', sortable: true, width: 120, resizable: true, },
            { field: 'DiscountStartDateString', headerName: 'Kedvezmény kezdete', filter: 'agSetColumnFilter', sortable: true, width: 180, resizable: true, },
            { field: 'DiscountEndDateString', headerName: 'Kedvezmény vége', filter: 'agSetColumnFilter', sortable: true, width: 180, resizable: true, },
            { field: 'DiscountAmount', headerName: 'Kedvezmény összege', filter: 'agSetColumnFilter', valueFormatter: params => this.numberFormatter(params), sortable: true, width: 120, resizable: true, },
            { field: 'DiscountPackagePrice', headerName: 'Kedvezményes csomag ár', filter: 'agSetColumnFilter', valueFormatter: params => this.numberFormatter(params), sortable: true, width: 200, resizable: true, },
            { field: 'PercentageDiscount', headerName: 'Százalékos kedvezmény', filter: 'agSetColumnFilter', valueFormatter: params => this.numberPercentageFormatter(params), sortable: true, width: 200, resizable: true, },
            { field: 'edit', headerName: 'Eltávolítás', maxWidth: 100, minWidth: 100,
                cellRenderer: 'btnDeleteCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {this.setShowDeleteRateDeviceDiscountPopup(true, data.Id);},
                    } 
            },  
        ]

        this.rateColumnDef = [
            { field: 'PackageCode', headerName: 'Tarifacsomag cikkszáma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
            { field: 'Name', headerName: 'Tarifacsomag neve', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
            {
                field: 'edit', headerName: 'Eltávolítás', maxWidth: 150, minWidth: 150,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => { this.deleteTariffPackageItemById(data.Id); },
                }
            },
        ] 

        this.yesNoDatasource = [
            { value: false, text: 'Nem' },
            { value: true, text: 'Igen' },
        ]

        this.generateOptions = [
            { value: 1, text: 'Nem teljeskörű' },
            { value: 2, text: 'Teljeskörű' },
        ]
    }

    numberPercentageFormatter = (params: any) => {
        return params.value != null ? Number(params.value * 100).toString() + ' %' : "";
    }

    numberFormatter = (params: any) => {
        return params.value != null ? params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft' : "";
    }

    navigateToDeviceDiscount = (deviceDiscountId: number) => {
        this.navigate('/administration/rate-device-discount/' + deviceDiscountId);
    }

    updateDeviceDiscountItem(key: keyof DeviceDiscount, value: any) {
        (this.deviceDiscountItem[key] as any) = value;
        // if (key === 'DiscountStartDate' || key === 'DiscountEndDate') {
        //     var valueDate = new Date(value);
        //     (this.deviceDiscountItem[key] as any) = new Date(valueDate.getFullYear(), valueDate.getMonth(), valueDate.getDate(), 5) ;
        //     console.log('date:', this.deviceDiscountItem[key])
        // }
    }

    deleteTariffPackageItemById(tariffPackageItemId: number) {
        this.deviceDiscountItem.TariffPackages = this.deviceDiscountItem.TariffPackages.filter(x => x.Id !== tariffPackageItemId);
        console.log('this.rateItems', toJS(this.deviceDiscountItem.TariffPackages))
        console.log(tariffPackageItemId)
    }

    navigateToTome = () => {
        window.open('/#/home', '_blank')
    }

    updateDevice(value: any) {
        this.deviceDiscountItem.DeviceId = value.ArticleNumber;
        this.selectedDeviceItem.Name = value.Name;
        this.selectedDeviceItem.Manufacturer = value.Manufacturer // type

        this.queryText = null
    }

    updateTariffPackage(value: any) {
        this.selectedTariffPackageItem = new TariffPackage();
        this.selectedTariffPackageItem.Id = value.Id;
        this.selectedTariffPackageItem.Name = value.Name;
        this.selectedTariffPackageItem.PackageCode = value.PackageCode;
        this.selectedTariffPackageItem.CustomNamePackageCodeForAutoComplete = `${value.PackageCode} - ${value.Name}`

        this.tariffPackageQueryText = null
    }

    updateGenerateId(value: any) {
        this.generateId = value;
    }

    onChangeQueryText = (event: any, data: any) => {
        this.queryText = data.value
        console.log(this.onChangeQueryText)
        this.getDeviceByQueryText();
    }

    onTariffPackageChangeQueryText = (event: any, data: any) => {
        this.tariffPackageQueryText = data.value
        console.log(this.onTariffPackageChangeQueryText)
        console.log('this.tariffPackageQueryTextthis.tariffPackageQueryText',this.tariffPackageQueryText)
        if (this.tariffPackageQueryText == null || this.tariffPackageQueryText == "") {
            this.selectedTariffPackageItem = null;
        }
        this.GetTariffPackageByQueryText();
    }

    addSelectedTarifaPackageItem = () => {
        this.deviceDiscountItem.TariffPackages.push(this.selectedTariffPackageItem)
        this.selectedTariffPackageItem = null;
        this.tariffPackageQueryText = null
        this.showTariffPackageSearch = false;
        setTimeout(() => { this.showTariffPackageSearch = true }, 50)

        console.log(toJS(this.deviceDiscountItem.TariffPackages));
        console.log(this.selectedTariffPackageItem);

    }

    generate() {
        if (this.generateId === 1) {
            this.generateDeviceDiscountPartial();
        }
        if (this.generateId === 2) {
            this.generateDeviceDiscountComplete();
        }
    }

    openIsQueryPopupOpen = () => {
        this.isQueryPopupOpen = true;
    }

    closeIsQueryPopupOpen = () => {
        this.isQueryPopupOpen = false;
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }

    cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isUploadConfirmOpen = false;
    }

    setIsUploadConfirmOpen = () => {
        this.isUploadConfirmOpen = true;
    }

    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
        console.log('Kiválasztott file:', this.file)
    }

    setShowDeleteRateDeviceDiscountPopup(value: boolean, id?: number) {
        this.showDeleteRateDeviceDiscountPopup = value;
        if (value == true) {
            this.rateDeviceDiscountToDeleteId = id;
            console.log(this.rateDeviceDiscountToDeleteId);
        }
    }

    confirmDeleteRateDeviceDiscount() {        
        this.DeleteDeviceDiscountById(this.rateDeviceDiscountToDeleteId);        
    }

    *getDeviceByQueryText() {
        this.devicesByQueryText = yield this.OfferService.GetDeviceByQueryTextRate(this.queryText, this.RootStore.selectedStore)
    }

    *GetTariffPackageByQueryText() {
        this.tariffPackagesByQueryText = yield this.OfferService.GetTariffPackagesByQueryText(this.tariffPackageQueryText)
    }

    *SaveDeviceDiscount() {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (!this.hasValidationErrors) {
            this.RootStore.addloading('SaveDeviceDiscount');
            yield this.AdminService.SaveDeviceDiscount(this.deviceDiscountItem);
            this.RootStore.removeLoading('SaveDeviceDiscount');
            this.navigate('/administration/rate-device-discount')
        }
    }

    *GetDeviceDiscounts() {
        this.RootStore.addloading('GetDeviceDiscounts')
        this.deviceDiscountItems = yield this.AdminService.GetDeviceDiscounts(this.RootStore.selectedStore)
        this.RootStore.removeLoading('GetDeviceDiscounts')
    }

    *GetDeviceDiscountById() {
        this.RootStore.addloading('GetDeviceDiscountById');
        this.deviceDiscountItem = yield this.AdminService.GetDeviceDiscountById(this.deviceDiscountItemId);
        this.RootStore.removeLoading('GetDeviceDiscountById');
        this.GetDeviceByArticleNumber();
    }

    *GetDeviceByArticleNumber() {
        this.RootStore.addloading('GetDeviceByArticleNumber');
        this.selectedDeviceItem = yield this.AdminService.GetDeviceByArticleNumber(this.deviceDiscountItem.DeviceId, this.RootStore.selectedStore)
        this.RootStore.removeLoading('GetDeviceByArticleNumber');
    }

    *DeleteDeviceDiscountById(deviceDiscountId: number) {
        this.RootStore.addloading('DeleteDeviceDiscountById');
        yield this.AdminService.DeleteDeviceDiscountById(deviceDiscountId)                
        this.RootStore.removeLoading('DeleteDeviceDiscountById');
        this.showDeleteRateDeviceDiscountPopup = false;
        this.GetDeviceDiscounts();
    }

    *generateDeviceDiscountPartial() {
        yield this.RiportService.GenerateDeviceDiscountPartial(this.RootStore.selectedStore)
    }

    *generateDeviceDiscountComplete() {
        yield this.RiportService.GenerateDeviceDiscountComplete(this.RootStore.selectedStore)
    }

    *confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        this.isUploadConfirmOpen = false;
        let formData: FormData = new FormData();
        this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        let retVAl: UploadResult=null;
        retVAl = yield this.AdminService.UploadDeviceDiscount(formData);
        if(retVAl.result=="error"){
            alert("A betöltés sikertelen volt, mert volt benne legalább egy olyan sor, ahol a Kedvezmény összege, a Kedvezményes csomag ár és a Százalékos kedvezmény közül kettő is ki volt töltve.")
        }
        console.log(this.file)
        this.GetDeviceDiscounts();
    }

    get deviceByQueryTextDDL() {
        return this.devicesByQueryText.map(device => {
            return { title: device.ArticleNumber, item: device };
        })

    }

    get tariffPackageByQueryTextDDL() {
        return this.tariffPackagesByQueryText.filter(tp => !this.deviceDiscountItem.TariffPackages.some(x => x.Id == tp.Id)).map(tariffPackage => {
            return { title: `${tariffPackage.PackageCode} - ${tariffPackage.Name}`, item: tariffPackage };
        })

    }

    get getValidationsDeviceDiscount() {
        if (!this.isSaved) {
            return {}
        }

        let validationErrors: Partial<Record<keyof any, string>> = {}

        if (!this.deviceDiscountItem.DeviceId) {
            validationErrors["DeviceId"] = "Készülék cikkszáma kitöltése kötelező!"
        }

        if (!this.deviceDiscountItem.DiscountStartDate) {
            validationErrors["DiscountStartDate"] = "Kedvezmény kezdete kitöltése kötelező!"
        }

        if (!this.deviceDiscountItem.DiscountEndDate) {
            validationErrors["DiscountEndDate"] = "Kedvezmény vége kitöltése kötelező!"
        }

        if (this.deviceDiscountItem.DiscountEndDate < this.deviceDiscountItem.DiscountStartDate) {
            validationErrors["EndDateEarlierThanStartDaet"] = "Kedvezmény vége nem lehet korábbi, mint a kedvezmény kezdete!"
        }

        if (!this.deviceDiscountItem.DiscountAmount && !this.deviceDiscountItem.DiscountPackagePrice && !this.deviceDiscountItem.PercentageDiscount) {
            validationErrors["DiscountAmount"] = "Kedvezmény összege kitöltése kötelező!"
        }
        if (!this.deviceDiscountItem.DiscountAmount && !this.deviceDiscountItem.DiscountPackagePrice && !this.deviceDiscountItem.PercentageDiscount) {
            validationErrors["DiscountPackagePrice"] = "Kedvezményes csomag ár kitöltése kötelező!"
        }
        if (!this.deviceDiscountItem.PercentageDiscount && !this.deviceDiscountItem.DiscountAmount && !this.deviceDiscountItem.DiscountPackagePrice) {
            validationErrors["PercentageDiscount"] = "Százalékos kedvezmény kitöltése kötelező!"
        }
        if (this.deviceDiscountItem.DiscountAmount < 0 && !this.deviceDiscountItem.DiscountPackagePrice) {
            validationErrors["DiscountAmountLessThanZero"] = "Kedvezmény összege nem lehet kisebb, mint 0!"
        }
        if (this.deviceDiscountItem.DiscountPackagePrice < 0 && !this.deviceDiscountItem.DiscountAmount) {
            validationErrors["DiscountPackagePriceLessThanZero"] = "Kedvezményes csomag ár nem lehet kisebb, mint 0!"
        }
        if (this.deviceDiscountItem.PercentageDiscount < 0 && !this.deviceDiscountItem.DiscountPackagePrice && !this.deviceDiscountItem.DiscountAmount) {
            validationErrors["PercentageDiscountLessThanZero"] = "Százalékos kedvezmény nem lehet kisebb, mint 0!"
        }
        if (this.deviceDiscountItem.TariffPackages.length === 0) {
            validationErrors["TariffPackagesArrayLength"] = "Legalább egy tarifacsomag hozzáadása kötelező!"
        }
        if ((this.deviceDiscountItem.PercentageDiscount && this.deviceDiscountItem.PercentageDiscount<1 )&& !this.deviceDiscountItem.DiscountPackagePrice && !this.deviceDiscountItem.DiscountAmount) {
            validationErrors["PercentageDiscountLessThanOne"] = "Százalékos kedvezmény nem lehet kisebb, mint 1!"
        }

        if ((this.deviceDiscountItem.PercentageDiscount && this.deviceDiscountItem.PercentageDiscount>99 )&& !this.deviceDiscountItem.DiscountPackagePrice && !this.deviceDiscountItem.DiscountAmount) {
            validationErrors["PercentageDiscountGreaterThan99"] = "Százalékos kedvezmény nem lehet nagyobb, mint 99!"
        }
        


        // console.log(validationErrors)
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsDeviceDiscount).length === 0) {
            return false;
        }

        return true;
    }
}