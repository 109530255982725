import { Role } from "../models/role";
import { User } from "../models/user";
import { UserInRole } from "../models/userInRole";
import { ChangePassword } from "../models/changePassword";

export class UserService {
    

    public async GetCurrentUser(isLogin: boolean = false): Promise<User> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",            
        };

        const response = await fetch(`/api/user/GetCurrentUser?isLogin=`+isLogin, options)
        
            return response.json();
        
    }

    public async Login(UserName: string, Password: string): Promise<string> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({ UserName, Password })
        };
        const response = await fetch(`/api/user/Login`, options)
        return response.json();

    }
    
    public async GetRolesForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`/api/user/GetRolesForDDL`, options)
        return response.json();
    }
    public async GetRoles(): Promise<Array<Role>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`/api/user/GetRoles`, options)
        return response.json();
    }

    public async GetUserInRoles(): Promise<Array<UserInRole>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`/api/user/GetUserInRoles`, options)
        return response.json();
    }
    public async GetUserByQueryTextUserManagement(queryText: string,selectedRole:number,selectedStore:string): Promise<Array<User>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            
        }; 

        const response = await fetch(`/api/user/GetUserByQueryTextUserManagement?queryText=`+queryText+'&selectedRole='+selectedRole+'&selectedStore='+selectedStore, options)
        return response.json();
    }
    public async GetUserByQueryText(queryText: string): Promise<Array<User>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            
        }; 

        const response = await fetch(`/api/user/GetUserByQueryText?queryText=`+queryText, options)
        return response.json();
    }

    public async AddUserToRole(UserInRole: UserInRole): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(UserInRole)
        };
        const response = await fetch(`/api/user/AddUserToRole`, options)
        return response

    }

    public async RemoveuserFormRole(UserInRole: UserInRole): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(UserInRole)
        };
        const response = await fetch(`/api/user/RemoveuserFormRole`, options)
        return response

       

    }
    public async logOut(): Promise<boolean> {
        const options: RequestInit = {
            method: "Post",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:null
        };
        const response = await fetch(`/api/user/logOut`, options)
        return response.json();

    }

    public async GetAccountStatesForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`/api/user/GetAccountStatesForDDL`, options)
        return response.json();
    }

    public async GetSupervisorApprovedsForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        }; 

        const response = await fetch(`/api/user/GetSupervisorApprovedsForDDL`, options)
        return response.json();
    }

    public async SaveUser(userVM:User): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(userVM)
       
        };

        const response = await fetch('/api/user/SaveUser', options)
        return response;
    }
    public async GenerateNewPassword(userVM:User): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(userVM)
       
        };

        const response = await fetch('/api/user/GenerateNewPassword', options)
        return response;
    }

    public async ChangePassword(vm:ChangePassword): Promise<any> {
        const options: RequestInit = {
            method: "POST", 
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body:JSON.stringify(vm)
       
        };

        const response = await fetch('/api/user/ChangePassword', options)
        return response.json();
    }
    
    public async GetUsers(): Promise<User[]> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/user/GetUsers', options)
        return response.json();
    }

    public async GetUserById(Id:number): Promise<User> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/user/GetUserById?Id='+Id, options)
        return response.json();
    }

    public async CheckUserNameIsNotUnique(userNameQuery: string): Promise<boolean> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
        };
        const response = await fetch(`/api/user/CheckUserNameIsNotUnique?userNameQuery=`+userNameQuery, options)
        return response.json();

    }

    public async GetUserByQueryTextForModuleAssignment(queryText: string,selectedRoleId:number): Promise<Array<User>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            
        }; 

        const response = await fetch(`/api/user/GetUserByQueryTextForModuleAssignment?queryText=`+queryText+'&selectedRoleId='+selectedRoleId, options)
        return response.json();
    }    

    public async GetUsersByRoleIdForModuleAssignment(selectedRoleId:number, selectedStoreId?: number): Promise<Array<User>> {
        const options: RequestInit = {
            method: "GET",
            headers: {Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            
        }; 

        const response = await fetch(`/api/user/GetUsersByRoleIdForModuleAssignment?selectedRoleId=`+selectedRoleId+'&selectedStoreId='+selectedStoreId, options)
        return response.json();
    }    
}