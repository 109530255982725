import { GDPRStore } from "./GdprStore";
import { NavigateFunction } from "react-router-dom";
import { RootStore } from "../../../stores/RootStore";
import React from "react";
import { inject, observer, Provider } from "mobx-react";
import withNavigation from "../../../hocs/withNavigation";
// import gdprFile from '../../../assets/Adatkezelesi_tajekoztato_VF_MM_AEGON.pdf'
import withParams from '../../../hocs/withParams';
import '../../../index.css';
// import gdprFileNumber1 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_v1.1.pdf'
// import gdprFileNumber2 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_MKB_v1.1.pdf'
// import gdprFileNumber3 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_AE_v1.1.pdf'
// import gdprFileNumber4 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_MKB_AE_v1.1.pdf'
// import gdprFileNumber1 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_v1.3.pdf'
// import gdprFileNumber2 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_MBH_v1.3.pdf'
// import gdprFileNumber3 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_AE_v1.3.pdf'
// import gdprFileNumber4 from '../../../assets/Adatkezelesi_tajekoztato_MM_VF_MBH_AE_v1.3.pdf'

import gdprFileNumber1 from '../../../assets/Adatkezelési tájékoztató_MM_VF_v1.4_2024.02.14.pdf'
import gdprFileNumber2 from '../../../assets/Adatkezelési tájékoztató_MM_VF_MBH_v1.4_2024.02.14.pdf'

interface IStores {
    GDPRStore: GDPRStore
}

interface IGDPRPageProps {
    navigate?: NavigateFunction;
    RootStore?:RootStore;
    params?: any;
}

class GDPRPage extends React.Component<IGDPRPageProps> {
    private stores: IStores    

    constructor(props: any) {
        super(props);

        this.stores = { GDPRStore: new GDPRStore(this.props.RootStore, this.props.params.id)}
    }

    componentDidMount(): void {
        console.log('this.stores.GDPRStore.gdprNumber',this.stores.GDPRStore.gdprNumber)
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                {/* <iframe src={`${gdprFile}`} title="testPdf" height="900px" width="100%" /> */}
                {this.stores.GDPRStore.gdprNumber == 1 && (
                    <iframe src={`${gdprFileNumber1}`} title="testPdf" height="900px" width="100%" />
                )}
                {this.stores.GDPRStore.gdprNumber == 2 && (
                    <iframe src={`${gdprFileNumber2}`} title="testPdf" height="900px" width="100%" />
                )}
                {this.stores.GDPRStore.gdprNumber == 3 && (
                    <iframe src={`${gdprFileNumber1}`} title="testPdf" height="900px" width="100%" />
                )}
                {this.stores.GDPRStore.gdprNumber == 4 && (
                    <iframe src={`${gdprFileNumber2}`} title="testPdf" height="900px" width="100%" />
                )}
            </Provider>
        </div>
    }
}

export default withParams(withNavigation(inject('RootStore')(observer(GDPRPage))));