import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { NavigateFunction } from "react-router-dom";
import { OfferService } from "../../services/offer.service";
import { RootStore } from "../../stores/RootStore"
import { AdminService } from "../../services/admin.service";
import { DropdownProps } from "semantic-ui-react";

export class HomeStore {

	private RootStore: RootStore
    ajanlatbongeszItems: Array<any>;    
    offerStatusOptions: Array<any>;
    offerIntervalOptions: Array<any>;
    columnDef: Array<ColDef>;
    columnDefTasks: Array<ColDef>;
    
    navigate?: NavigateFunction;
    OfferService: OfferService;
    AdminService: AdminService;
    offerStatusId: number = 1;
    offerIntervalId: number = 1;
    tasks:Array<any>;
    selectedStoreCodeTask:string;
    selectedStoreCodeOffer:string;
    
    constructor(navigate: NavigateFunction,RootStore?: RootStore) {
        this.RootStore = RootStore;
        this.navigate = navigate
        makeAutoObservable(this, {
            ajanlatbongeszItems: observable,            
            tasks:observable,
            offerStatusOptions: observable,
            offerIntervalOptions: observable,
            columnDef: observable,
            columnDefTasks:observable,
            offerStatusId: observable,
            offerIntervalId: observable,
            handleOfferStatusIdChange: action,
            handleOfferIntervalIdChange: action,
            onchangeTaskStore:action,
            onchangeOfferStore:action,
            getOffers: flow,
            getOfferStatuses: flow,
            getOfferIntervals: flow,
            getOffersWithinDays: flow,
            getTasks:flow,
            selectedStoreCodeTask:observable,
            selectedStoreCodeOffer:observable
        })

        this.OfferService = new OfferService();    
        this.AdminService = new AdminService();                    

        this.columnDef = [
            { field: 'edit', headerName: 'Műveletek',  maxWidth:120,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {this.navigateOffer(data.Id);},
                    }
                },
            { field: 'OfferNumber', headerName: 'Ajánlat száma', filter: 'agTextColumnFilter', sortable: true, maxWidth: 160},            
            { field: 'CreatedStringHomePage', headerName: 'Rögzítés kezdete', filter: 'agTextColumnFilter', sortable: true, maxWidth: 180},            
            { field: 'StoreName', headerName: 'Áruház', filter: 'agTextColumnFilter', sortable: true, maxWidth: 140},            
            { field: 'MainStatusString', headerName: 'Ajánlat státusza', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'CustomerName', headerName: 'Ügyfél neve', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'VodafoneStatusString', headerName: 'Vodafone státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'BPBankStatusString', headerName: 'Bank státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'AEGONStatusString', headerName: 'G+ státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true},
        ]
        this.columnDefTasks = [
            { field: 'edit', headerName: 'Műveletek',  maxWidth:120,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {this.navigateOffer(data.Id);},
                    }
                },
            { field: 'OfferNumber', headerName: 'Ajánlat száma', filter: 'agTextColumnFilter', sortable: true, maxWidth: 160},      
            { field: 'CreatedStringHomePage', headerName: 'Rögzítés kezdete', filter: 'agTextColumnFilter', sortable: true, maxWidth: 180},         
            { field: 'StoreName', headerName: 'Áruház', filter: 'agTextColumnFilter', sortable: true, maxWidth: 140},            
            { field: 'MainStatusString', headerName: 'Ajánlat státusza', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'CustomerName', headerName: 'Ügyfél neve', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'VodafoneStatusString', headerName: 'Vodafone státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'BPBankStatusString', headerName: 'Bank státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true},
            { field: 'AEGONStatusString', headerName: 'G+ státusz', filter: 'agSetColumnFilter', resizable: true, sortable: true},
        ]
    }
    onchangeTaskStore = (event: any, data: any) => {
		this.selectedStoreCodeOffer = data;
        this.setCookie("SelectedStore", data, 30);
        this.getTasks();
        this.getOfferAfterChange();

	}
    onchangeOfferStore = (event: any, data: any) => {
		this.selectedStoreCodeOffer = data;
        this.setCookie("SelectedStore", data, 30);
        this.getTasks();
        this.getOfferAfterChange();
	}
    navigateOffer(id:number){
        this.navigate('/offer/'+id);
    }

    handleOfferStatusIdChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		this.offerStatusId = Number(data.value);
		console.log("offerStatusId:", data.value)	  
        
        this.getOfferAfterChange();
	}

    handleOfferIntervalIdChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
		this.offerIntervalId = Number(data.value);
		console.log("offerIntervalId:", data.value)	
        
        this.getOfferAfterChange();
        
	}

    getOfferAfterChange = () => {
        if (this.offerIntervalId === 1) {
            this.getOffersWithinDays(7);
        }
        if (this.offerIntervalId === 2) {
            this.getOffersWithinDays(30);
        }
        if (this.offerIntervalId === 3) {
            this.getOffersWithinDays(90);
        }
        if (this.offerIntervalId === 4) {
            this.getOffersWithinDays(365);
        }
        if (this.offerIntervalId === 5) {
            this.getOffersWithinDays(10000);
        }
    }

    *getOffers() {
        this.RootStore?.addloading('homePageGetOfferLoading')
        this.ajanlatbongeszItems = yield this.OfferService.GetOffers(this.RootStore.selectedStore);
        this.RootStore?.removeLoading('homePageGetOfferLoading')
    }
    *getTasks() {
        this.RootStore?.addloading('getTasks');
        let storeCode= this.RootStore.currentUser.IsGlobal?this.selectedStoreCodeOffer:this.RootStore.selectedStore;

        this.tasks = yield this.OfferService.GetTasks(storeCode);
        this.RootStore?.removeLoading('getTasks')
    }

    *getOfferStatuses() {
        this.offerStatusOptions = yield this.AdminService.GetOfferStatuses();
    }

    *getOfferIntervals() {
        this.offerIntervalOptions = yield this.AdminService.GetOfferIntervals();
    }

    *getOffersWithinDays(days: number) {
        this.RootStore?.addloading('getOffersWithinDays')
        let storeCode= this.RootStore.currentUser.IsGlobal?this.selectedStoreCodeOffer:this.RootStore.selectedStore;
        this.ajanlatbongeszItems = yield this.OfferService.GetOffersWithinDays(days,storeCode,this.offerStatusId);
        this.RootStore?.removeLoading('getOffersWithinDays')
    }
    setCookie(cname:any, cvalue:any, exdays:any) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
      }
      readCookie(name:any) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      }
}