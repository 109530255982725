import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import withNavigation from "../../../hocs/withNavigation";
import { DocumentsStore } from "./DocumentsStore";

interface IStores {
    DocumentsStore: DocumentsStore
}

interface IDocumentsPageProps {
    navigate?: NavigateFunction;
}

class DocumentsPage extends React.Component<IDocumentsPageProps> {
    private stores: IStores

    constructor(props: any){
        super(props);

        this.stores = { DocumentsStore: new DocumentsStore()}
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div>
                DocumentsStore Page
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(DocumentsPage)));