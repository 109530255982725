import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Grid, Icon, Input } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { UsermanagementStore } from "./UsermanagementStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import DeleteButton from '../../../components/DeleteButton'
import { RootStore } from "../../../stores/RootStore";
import OpenItem from "../../../components/OpenItem";

interface IStores {
    UsermanagementStore: UsermanagementStore
}

interface IUsermanagementPageProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore
}

class UsermanagementPage extends React.Component<IUsermanagementPageProps> {
    private stores: IStores

    constructor(props: any) {
        super(props);

        this.stores = { UsermanagementStore: new UsermanagementStore(this.props.RootStore, this.props.navigate) }
    }

    componentDidMount(): void {
        this.stores.UsermanagementStore.getUsers();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div style={{ margin: 20, width: '95%' }}>
                    <Grid>
                        <Grid.Row centered>
                            <Grid.Column width={12}>
                                <div style={{ marginBottom: 20 }}>
                                    <CustomHeader>Felhasználók kezelése</CustomHeader>
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    {
                                        (this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.StoreAdmin || this.props.RootStore.currentUser.VodafoneSupervisor) &&

                                        <Button color="red" onClick={() => { this.props.navigate('/administration/usermanagement-details/0') }}>Új felhasználó rögzítése</Button>
                                    }
                                </div>
                                <Grid>
                                    <Grid.Row centered>
                                        <Grid.Column width={16}>
                                            <div id="grid" className="ag-theme-alpine" style={{ width: '1400px', height: '519px' }}>
                                                <AgGridReact
                                                    rowData={this.stores.UsermanagementStore.usermanagementItems}
                                                    columnDefs={this.stores.UsermanagementStore.columnDef}
                                                    localeText={AgGridText}
                                                    frameworkComponents={{ btnCellRenderer: OpenItem }}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>
                                                </AgGridReact>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(UsermanagementPage)));