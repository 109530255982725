import { makeAutoObservable } from "mobx";

export class TrainingModule {
    Id: number;
    CreatedById: number;
    CreatedByName: string;
    CreatedDate: Date;
    CreatedDateString: string;
    ModifiedById: number;
    ModifiedByName: string;
    ModifiedDate: Date;
    ModifiedDateString: string;
    Name: string;
    Code: string;
    Statement: string;
    IsActualSave: boolean;
    InActive: number;
    Content: any;
    CodeByUser: string;
    CodeNumber: number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.CreatedById = 0;
        this.CreatedByName = null;
        this.CreatedDate = null;
        this.CreatedDateString = null;
        this.ModifiedById = 0;
        this.ModifiedByName = null;
        this.ModifiedDate = null;
        this.ModifiedDateString = null;
        this.Name = "";
        this.Code = "";
        this.Statement = null;
        this.IsActualSave = null;
        this.InActive = null;
        this.Content = null;
        this.CodeByUser = "";
        this.CodeNumber = 0;
    }
}