import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Dropdown, Grid } from "semantic-ui-react";
import withNavigation from "../../hocs/withNavigation";
import { OnlinePickupStore } from "./OnlinePickupStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../core/agGridText';

interface IStores {
    OnlinePickupStore: OnlinePickupStore
}

interface IOnlinePickupPageProps {
    navigate?: NavigateFunction;
}

class OnlinePickupPage extends React.Component<IOnlinePickupPageProps> {

    stores: IStores

    constructor(props: any) {
        super(props);

        this.stores = { OnlinePickupStore: new OnlinePickupStore()}
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div>
                <Grid style={{paddingTop: 50}}>
							<Grid.Row centered>
								<Grid.Column width={12}>																	
                                    <div style={{background: 'rgb(248, 248, 248)', width: '1400px', height: '40px', textAlign: 'center', paddingTop: 10, 
												border: '1px solid rgb(186, 191, 199)', borderBottom: 'none', display: 'flex'}}>
										<div style={{fontWeight: 'bold', fontSize: '16px', marginLeft: '606px', marginRight: 'auto'}}>Ajánlatböngésző (Online pickup)</div>
										<div>
											<Dropdown value={0}
												options={this.stores.OnlinePickupStore.ajanlatallapotaItems}>
											</Dropdown>
										</div>
										<div style={{marginLeft: '80px', marginRight: '20px'}}>
											<Dropdown value={0}
												options={this.stores.OnlinePickupStore.rogzitesidoszakaItems}>
											</Dropdown>
										</div>
									</div>
									<div id="grid" className="ag-theme-alpine" style={{width: '1400px', height: '519px'}}>
										<AgGridReact											
											rowData={this.stores.OnlinePickupStore.ajanlatbongeszItems}											
											columnDefs={this.stores.OnlinePickupStore.columnDef}
											localeText={AgGridText}
											pagination={true}
											paginationPageSize={10}
											gridOptions={{
												paginationNumberFormatter: (params:any) => {
												return  params.value.toLocaleString() ;
											  },
											}}>																				
										</AgGridReact>
									</div>   
								</Grid.Column>
							</Grid.Row>							
						</Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(OnlinePickupPage)));