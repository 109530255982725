import { makeAutoObservable } from "mobx";
import { TariffPackage } from "./tariffPackage";

export class DeviceDiscount {
    Id: number;
    DeviceId: number;
    DiscountStartDate: Date;
    DiscountEndDate: Date;
    DiscountAmount: number;
    IsMergeable: boolean;
    TariffPackages: Array<TariffPackage>;
    DiscountStartDateString: string;
    DiscountEndDateString: string;
    DiscountPackagePrice: number;
    PercentageDiscount:number;
    
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.DeviceId = null;
        this.DiscountStartDate = null;
        this.DiscountEndDate = null;
        this.DiscountAmount = null;
        this.IsMergeable = true;
        this.TariffPackages = [];
        this.DiscountStartDateString = null;
        this.DiscountEndDateString = null;
        this.DiscountPackagePrice=null;
        this.PercentageDiscount=null;
    }
}