import { makeAutoObservable } from "mobx";
import { VodafoneSupervisorApproval } from "./vodafoneSupervisorApproval";

export class User {
    Id: string;
    AspNetUserId: string;
    ExtendedInfoId: number;
    StoreCode: string;
    Name: string;
    UserCode: string;
    Disabled: number;
    UserCode2: number;
    UserGuid: string; // guid
    IsDeleted: boolean;
    FirstName: string;
    LastName: string;
    Email: string;
    AegonGSales: boolean;
    AegonCode: string;
    CreditIntermediaryCode: string;
    StoreId: number;
    Consultant: number;
    UserName: string;
    CurrentPassword: string;
    NewPassword: string;
    HoldingAdmin: boolean;
    HoldingGlobalViewer: boolean;
    StoreAdmin: boolean;
    CreditManager: boolean;
    OnlinePickup: boolean;
    VodafoneSupervisor: boolean;
    VodafoneBO: boolean;
    WawiCode: number;
    WawiName: string;
    AccountState: number;
    IsVodafoneSupervisorApproved: number;
    IsGlobal: boolean;
    IsConsultant:boolean;
    IsSeniorConsultant: boolean;
    StoreName: string;
    LastLoginDate: Date;
    VodafoneSupervisorApprovals: Array<VodafoneSupervisorApproval>
    IsAnyNotCompletedModuleInProgress: boolean;
    IsNewOfferDisabledForSeniorOrConsultant: boolean;
    IsSelected: boolean;
    IsAnyNotCompletedExpiredModule: boolean;
    constructor() {
        makeAutoObservable(this);
        this.Id = null;
        this.AspNetUserId = null;
        this.ExtendedInfoId = 0;
        this.StoreCode = null;
        this.Name = null;
        this.UserCode = null;
        this.Disabled = 0;
        this.UserCode2 = 0;
        this.UserGuid = null;
        this.IsDeleted = null;
        this.FirstName = null;
        this.LastName = null;
        this.Email = null;
        this.AegonGSales = null;
        this.AegonCode = null;
        this.CreditIntermediaryCode = null;
        this.StoreId = 0;
        this.Consultant = 0;
        this.UserName = null;
        this.CurrentPassword = null;
        this.NewPassword = null;
        this.HoldingAdmin = null;
        this.HoldingGlobalViewer = null;
        this.StoreAdmin = null;
        this.CreditManager = null;
        this.OnlinePickup = null;
        this.VodafoneSupervisor = null;
        this.VodafoneBO = null;
        this.WawiCode = null;
        this.WawiName = null;
        this.AccountState = 0;
        this.IsVodafoneSupervisorApproved = 0;
        this.IsGlobal = null;
        this.IsConsultant=null;
        this.IsSeniorConsultant=null;
        this.StoreName = null;
        this.LastLoginDate = null;
        this.VodafoneSupervisorApprovals = [];
        this.IsAnyNotCompletedModuleInProgress = null;
        this.IsNewOfferDisabledForSeniorOrConsultant = null;
        this.IsSelected = false;
        this.IsAnyNotCompletedExpiredModule = null;
    }
}