import { action, computed, flow, makeObservable, observable, toJS } from "mobx";


export class InfoVideoStore {
    
    contentItems: Array<any> = [];
    currentNumber: number = 1;

    constructor() {
        makeObservable(this, {
            contentItems: observable,
            currentNumber: observable,
            setContentItemActive: action,
        })

        this.contentItems = [
            { key:1, title: "1. Infovideó, mint digitális segítség", checked: true, isActive: true,},
            { key:2, title: "2. Választott Vodafone előfizetés", checked: true, isActive: false,},
            { key:3, title: "3-4. Szerződés magánszemélyként", checked: true, isActive: false,},
            { key:5, title: "5. Roaming", checked: true, isActive: false,},
            { key:6, title: "6. Szerződésmásolat", checked: false, isActive: false,},
            { key:7, title: "7. Elektronikus értesítések", checked: false, isActive: false,},
            { key:8, title: "8. Felmondási jog", checked: false, isActive: false,},
            { key:9, title: "9. Adatvédelmi tájékoztató", checked: false, isActive: false,},
            { key:10, title: "10. Hívásrészletező, személyes adatok", checked: false, isActive: false,},
            { key:11, title: "11. Egyedi értékhatár", checked: false, isActive: false,},
            { key:12, title: "12. Nyilatkozat teljeskörű tájékoztatásról", checked: false, isActive: false,},
            { key:13, title: "13. Szerződés módosítás", checked: false, isActive: false,},
            { key:14, title: "14. Szerződés időtartamok", checked: false, isActive: false,},
            { key:15, title: "15. Hívásrészletező és tudakozó", checked: false, isActive: false,},
            { key:16, title: "16. Direkt marketing hozzájárulás", checked: false, isActive: false,},
            { key:17, title: "17. Helymeghatározási adatok", checked: false, isActive: false,},
            { key:18, title: "18. GDPR tájékoztató", checked: false, isActive: false,},
            { key:19, title: "19. Vodafone Szerződés", checked: false, isActive: false,},
            { key:20, title: "Összegzés", checked: false, isActive: false,},
        ]
    }

    setContentItemActive = (index: number) => {
        for (let i = 0; i < this.contentItems.length; i++) {
            this.contentItems[i].isActive = false;    
        }
        this.contentItems[index].isActive = true;
        this.currentNumber = this.contentItems[index].key
    }    
}