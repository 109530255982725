import { makeAutoObservable } from "mobx";

export class InformationObligation {
    Id: number;
    CreatedById: number;
    CreatedByName: string;
    CreatedDate: Date;
    CreatedDateString: string;
    ModifiedById: number;
    ModifiedByName: string;
    ModifiedDate: Date;
    InformationType: string;
    InformationDescription: string;
    InformationObligationMM: string;
    TodosMM: string;
    InActive: number;
    IsDeleted: boolean;
    constructor(){
        makeAutoObservable(this);
        this.Id = 0;
        this.CreatedById = null;
        this.CreatedByName = null;
        this.CreatedDateString = null;
        this.ModifiedById = null;
        this.ModifiedByName = null;
        this.InformationType = null;
        this.InformationDescription = null;
        this.InformationObligationMM = null;
        this.TodosMM = null;
        this.InActive = 0;
        this.IsDeleted = null;
    }
}