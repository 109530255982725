import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Grid, Input, Image, Label } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { BannerStore } from "./BannerStore";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AdminService } from '../../../services/admin.service'

interface IStores {
    BannerStore: BannerStore
}

interface IBannerPageProps {
    navigate?: NavigateFunction;
}

class BannerPage extends React.Component<IBannerPageProps> {
    private stores: IStores
    AdminService: AdminService;

    constructor(props: any){
        super(props);

        this.stores = { BannerStore: new BannerStore()}
        this.AdminService = new AdminService();
    }

    componentDidMount(): void {
        this.stores.BannerStore.getBanners();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <Confirm open={this.stores.BannerStore.isUploadConfirmOpen1}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    onConfirm={(event) => {this.stores.BannerStore.confirmUpload(event, 1);}}
                    onCancel={(event) => {this.stores.BannerStore.cancelUpload(event, 1);}}
                    cancelButton="Mégse"
                    confirmButton="Feltöltés"
                    content={
                        <div>
                            <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                                <Input hidden id={this.stores.BannerStore.uploadIdString} onChange={(event) => {this.stores.BannerStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                            </Label>
                        </div>
                    }>                    
                </Confirm>
                <Confirm open={this.stores.BannerStore.isUploadConfirmOpen2}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    onConfirm={(event) => {this.stores.BannerStore.confirmUpload(event, 2);}}
                    onCancel={(event) => {this.stores.BannerStore.cancelUpload(event, 2);}}
                    cancelButton="Mégse"
                    confirmButton="Feltöltés"
                    content={
                        <div>
                            <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                                <Input hidden id={this.stores.BannerStore.uploadIdString} onChange={(event) => {this.stores.BannerStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                            </Label>
                        </div>
                    }>                    
                </Confirm>
                <Confirm open={this.stores.BannerStore.isUploadConfirmOpen3}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    onConfirm={(event) => {this.stores.BannerStore.confirmUpload(event, 3);}}
                    onCancel={(event) => {this.stores.BannerStore.cancelUpload(event, 3);}}
                    cancelButton="Mégse"
                    confirmButton="Feltöltés"
                    content={
                        <div>
                            <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                                <Input hidden id={this.stores.BannerStore.uploadIdString} onChange={(event) => {this.stores.BannerStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                            </Label>
                        </div>
                    }>                    
                </Confirm>
                <Confirm open={this.stores.BannerStore.isUploadConfirmOpen4}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    onConfirm={(event) => {this.stores.BannerStore.confirmUpload(event, 4);}}
                    onCancel={(event) => {this.stores.BannerStore.cancelUpload(event, 4);}}
                    cancelButton="Mégse"
                    confirmButton="Feltöltés"
                    content={
                        <div>
                            <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                                <Input hidden id={this.stores.BannerStore.uploadIdString} onChange={(event) => {this.stores.BannerStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                            </Label>
                        </div>
                    }>                    
                </Confirm>
                {/* <Confirm open={this.stores.BannerStore.isUploadConfirmOpen5}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    onConfirm={(event) => {this.stores.BannerStore.confirmUpload(event, 5);}}
                    onCancel={(event) => {this.stores.BannerStore.cancelUpload(event, 5);}}
                    cancelButton="Mégse"
                    confirmButton="Feltöltés"
                    content={
                        <div>
                            <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                                <Input hidden id={this.stores.BannerStore.uploadIdString} onChange={(event) => {this.stores.BannerStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                            </Label>
                        </div>
                    }>                    
                </Confirm> */}
                <div style={{ margin: 20, width: '95%' }}>
                    <Grid>
                        <Grid.Row centered>
                            <Grid.Column width={15}>
                                <div style={{marginBottom: 20}}>
                                    <CustomHeader>Checklist banner</CustomHeader>
                                </div>		
                                <div style={{ display: 'flex', marginBottom: 35}}>
                                    <div style={{paddingTop: 8, marginRight: 30, marginTop: 'auto', marginBottom: 'auto'}}>
                                        <label>Ajánlat összeállításához kapcsolódó kép:</label>
                                    </div>
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                        <Button color="green" onClick={() => {this.stores.BannerStore.setIsUploadConfirmOpen(1)}}>Feltöltés</Button>
                                    </div>                                    
                                    <div style={{ marginLeft: '40px', maxWidth: '500px', marginBottom: 'auto', marginTop: 'auto'}}>
                                        {this.stores.BannerStore.bannerByteContent1 && (
                                            <Image src={`data:image/jpg;base64,${this.stores.BannerStore.bannerByteContent1}`}/>
                                        )}              
                                        {!this.stores.BannerStore.bannerByteContent1 && (
                                            <div>Nem került kép feltöltésre.</div>
                                        )}                           
                                    </div>                                    
                                </div>
                                <div style={{ display: 'flex', marginBottom: 35}}>
                                    <div style={{paddingTop: 8, marginRight: 30, marginTop: 'auto', marginBottom: 'auto'}}>
                                        <label>Vodafone folyamatokhoz kapcsolódó kép:</label>
                                    </div>
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                        <Button color="green" onClick={() => {this.stores.BannerStore.setIsUploadConfirmOpen(2)}}>Feltöltés</Button>
                                    </div>
                                    <div style={{ marginLeft: '40px', maxWidth: '500px', marginBottom: 'auto', marginTop: 'auto'}}>
                                        {this.stores.BannerStore.bannerByteContent2 && (
                                            <Image src={`data:image/jpg;base64,${this.stores.BannerStore.bannerByteContent2}`}/>
                                        )}      
                                        {!this.stores.BannerStore.bannerByteContent2 && (
                                            <div>Nem került kép feltöltésre.</div>
                                        )}                                   
                                    </div>
                                </div>
                                {/* <div style={{ display: 'flex', marginBottom: 35}}>
                                    <div style={{paddingTop: 8, marginRight: 30, marginTop: 'auto', marginBottom: 'auto'}}>
                                        <label>Aláírt dokumentumok átvételéhez kapcsolódó kép:</label>
                                    </div>
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                        <Button color="green" onClick={() => {this.stores.BannerStore.setIsUploadConfirmOpen(3)}}>Feltöltés</Button>
                                    </div>
                                    <div style={{ marginLeft: '40px', maxWidth: '500px', marginBottom: 'auto', marginTop: 'auto'}}>
                                        {this.stores.BannerStore.bannerByteContent3 && (
                                            <Image src={`data:image/jpg;base64,${this.stores.BannerStore.bannerByteContent3}`}/>
                                        )}                 
                                        {!this.stores.BannerStore.bannerByteContent3 && (
                                            <div>Nem került kép feltöltésre.</div>
                                        )}                        
                                    </div>
                                </div> */}
                                <div style={{ display: 'flex', marginBottom: 35}}>
                                    <div style={{paddingTop: 8, marginRight: 30, marginTop: 'auto', marginBottom: 'auto'}}>
                                        <label>Hitelbírálathoz kapcsolódó kép:</label>
                                    </div>                                    
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                        <Button color="green" onClick={() => {this.stores.BannerStore.setIsUploadConfirmOpen(3)}}>Feltöltés</Button>
                                    </div>
                                    <div style={{ marginLeft: '40px', maxWidth: '500px', marginBottom: 'auto', marginTop: 'auto'}}>
                                        {this.stores.BannerStore.bannerByteContent3 && (
                                            <Image src={`data:image/jpg;base64,${this.stores.BannerStore.bannerByteContent3}`}/>
                                        )}        
                                        {!this.stores.BannerStore.bannerByteContent3 && (
                                            <div>Nem került kép feltöltésre.</div>
                                        )}                                 
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 35}}>
                                    <div style={{paddingTop: 8, marginRight: 30, marginTop: 'auto', marginBottom: 'auto'}}>
                                        <label>Ajánlat véglegesítéséhez kapcsolódó kép:</label>
                                    </div>
                                    <div style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                        <Button color="green" onClick={() => {this.stores.BannerStore.setIsUploadConfirmOpen(4)}}>Feltöltés</Button>
                                    </div>
                                    <div style={{ marginLeft: '40px', maxWidth: '500px', marginBottom: 'auto', marginTop: 'auto'}}>
                                        {this.stores.BannerStore.bannerByteContent4 && (
                                            <Image src={`data:image/jpg;base64,${this.stores.BannerStore.bannerByteContent4}`}/>
                                        )} 
                                        {!this.stores.BannerStore.bannerByteContent4 && (
                                            <div>Nem került kép feltöltésre.</div>
                                        )}                                        
                                    </div>
                                </div>
                            </Grid.Column>                                
                        </Grid.Row>
                    </Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(BannerPage)));