import { action, computed, flow, makeObservable, observable } from "mobx";
import { EmailContainer } from "../../../models/emailContainer";
import { User } from "../../../models/user";
import { UserInRole } from "../../../models/userInRole";
import { UserService } from "../../../services/user.service";
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";
import { Store } from "../../../models/store";

export class EmailManagementStore {
    private RootStrore: RootStore    
    emailsColumnDef: Array<any>;
    roles: Array<any>;
    queryText: string = "";
    usersByQueryText: Array<User> = [];
    UserService: UserService;
    AdminService: AdminService;
    SelectedUser: User;
    selectedRoleId: any;
    selectedStore: any;
    showSearch:boolean=true;
    emails: Array<EmailContainer>;
    selectedEmail: string = "";
    alreadyExistEmailContainer: boolean = false;
    saving:boolean=false;

    constructor(rootStore?: RootStore) {
        this.RootStrore = rootStore;
        makeObservable(this, {            
            emails: observable,            
            emailsColumnDef: observable,
            roles: observable,
            queryText: observable,
            usersByQueryText: observable,
            alreadyExistEmailContainer: observable,
            onChangeQueryText: action,
            getUserByQueryText: flow,
            SelectedUser: observable,
            selectedEmail: observable,
            selectedStore:observable,
            userByQueryTextDDL: computed,
            getAddButtonDisabled: computed,
            update: action,
            addEmailToContainer: flow,
            selectedRoleId: observable,
            deleteEmailFromContainer: flow,
            showSearch:observable,
            checkRoleAndStoreAlreadyExist: action,
            saving:observable
        })

        this.emailsColumnDef = [            
            { field: 'RoleName', headerName: 'Szerepkör', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' } },
            { field: 'StoreName', headerName: 'Áruház', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' } },
            { field: 'Email', headerName: 'Gyűjtő email-cím', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' } },
            {
                field: 'edit', headerName: 'Műveletek', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' },
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {
                        if (window.confirm('Biztosan törli az eamilt?')) {
                            this.deleteEmailFromContainer(data.Id);
                        }
                    },
                },
            },

        ]

        this.UserService = new UserService();
        this.AdminService = new AdminService();

        this.selectedRoleId = 1
    }

    onChangeQueryText = (event: any, data: any) => {
        this.queryText = data.value
        console.log(this.onChangeQueryText)
        this.getUserByQueryText();
    }

    *getUserByQueryText() {
        this.usersByQueryText = yield this.UserService.GetUserByQueryTextUserManagement(this.queryText,this.selectedRoleId,this.selectedStore)
    }
    update(key: keyof this, value: any) {
        (this[key] as any) = value;
        if (key == 'selectedRole') {
            if (value < 3 ||value>6 && value!=9) {
                this.selectedStore = "";
            }
            if(value>2 && value <7 || value ==9){
                this.showSearch=false;
                this.SelectedUser=null;
                setTimeout(()=>{this.showSearch=true}, 100);                
            }
        }
        console.log(value);
        
    }
    checkRoleAndStoreAlreadyExist() {
        var existingEmail = this.emails.find(x => x.RoleId == this.selectedRoleId && x.StoreCode == this.selectedStore)
        if (existingEmail){
            this.alreadyExistEmailContainer = true;
        }
        else {
            this.alreadyExistEmailContainer = false;
        }
    }
    *deleteEmailFromContainer(id: number) {
        this.RootStrore.addloading('deleteEmailFromContainer');        
        yield this.AdminService.RemoveOverrideEmailNotifications(id);
        this.emails = yield this.AdminService.GetOverrideEmailNotifications();
        this.RootStrore.removeLoading('deleteEmailFromContainer');
    }
    *addEmailToContainer() {
        this.checkRoleAndStoreAlreadyExist();
        if (this.alreadyExistEmailContainer) {
            if (window.confirm('A kiválasztott szerepkör és áruház pároshoz már tartozik gyűjtő email-cím!')) {
                
            }
        }
        if((this.selectedRoleId==5 || this.selectedRoleId==6) && !this.selectedStore ) {
            alert("Áruház választása kötelező!");
        }
        else {
            this.saving=true;
            this.RootStrore.addloading('addEmailToContainer');
            let emailToSave = new EmailContainer();
            emailToSave.RoleId = this.selectedRoleId;
            emailToSave.StoreCode = emailToSave.RoleId == 8 ? null : this.selectedStore;
            emailToSave.Email = this.selectedEmail;
            console.log("emailToSave: ",emailToSave);
            yield this.AdminService.SaveOverrideEmailNotifications(emailToSave);
            this.emails = yield this.AdminService.GetOverrideEmailNotifications();
            this.selectedRoleId=null;
            this.selectedEmail="";
            this.selectedStore=null;
            this.saving=false;
            this.RootStrore.removeLoading('addEmailToContainer');
        }        
        
    }
    get userByQueryTextDDL() {
        return this.usersByQueryText.map(user => {
            return { title: user.Name + " (" + user.UserName + ")", item: user };
        })
    }

    get getAddButtonDisabled() {        
        if (this.selectedEmail == null || this.selectedEmail == "" || this.selectedEmail.trim().length == 0 || this.selectedEmail == undefined) {
            return true;
        }
        return false;
    }
}