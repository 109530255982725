import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { RiportService } from "../../services/riport.service";
import { RootStore } from "../../stores/RootStore";
import { AdminService } from "../../services/admin.service";
import { DataSource } from "../../models/datasource";
import { RiportFrequence } from "../../models/riportFrequence";
import { RiportType } from "../../models/riportType";
import { UserRiportFrequence } from "../../models/userRiportFrequence";

export class RiportStore {
    
    private rootStore: RootStore;
    startDate: Date = null;
    endDate: Date = null;
    isOnlyTwelveMonth: number = 0;
    RiportService: RiportService;
    AdminService: AdminService;
    riportFrequences: Array<RiportFrequence> = [];
    riportFrequencesOptions: Array<DataSource> = [];
    riportTypesOptions: Array<DataSource> = [];
    selectedRiportFrequenceId: number = 0;
    selectedRiportTypeId: number = 0;
    riportTypes: Array<RiportType> = [];
    userRiportFrequences: Array<UserRiportFrequence> = [];
    userRiportFrequencesToSave: Array<UserRiportFrequence> = [];
    isModified: boolean = false;
    constructor(rootStore?: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            startDate: observable,
            endDate: observable,
            isOnlyTwelveMonth: observable,     
            riportFrequencesOptions: observable,     
            riportFrequences: observable,     
            selectedRiportFrequenceId: observable,     
            riportTypes: observable,     
            userRiportFrequences: observable,     
            userRiportFrequencesToSave: observable,     
            riportTypesOptions: observable,     
            selectedRiportTypeId: observable,     
            isModified: observable,     
            handleGenerate: action,   
            generateReport: flow,
            generateReportForStoreAdmin: flow,
            GetRiportFrequencies: flow,
            GetRiportTypes: flow,
            InsertOrUpdateUserRiportFrequences: flow,
            GenerateRiportBySelectedRiportType: flow,
        });

        this.RiportService = new RiportService();
        this.AdminService = new AdminService();
    }

    updateStartDate = (value: any) => {
        this.startDate = value;
    }

    updateEndDate = (value: any) => {
        this.endDate = value;
    }

    updateIsOnlyTwelveMOnth = () => {
        if (this.isOnlyTwelveMonth === 0) {
            this.isOnlyTwelveMonth = 1
        }
        else {
            this.isOnlyTwelveMonth = 0;
        }
    }

    handleGenerate = () => {
        if (this.selectedRiportTypeId == 11) {
            if (!this.rootStore.currentUser.HoldingAdmin && this.rootStore.currentUser.StoreAdmin ) {
                this.generateReportForStoreAdmin();            
            }
            if (this.rootStore.currentUser.HoldingAdmin || this.rootStore.currentUser.HoldingGlobalViewer) {
                this.generateReport();            
            }
        }    
        else {
            this.GenerateRiportBySelectedRiportType();
        }            
    }

    *generateReport() {
        this.rootStore.addloading("GenerateReport");
        yield this.RiportService.GenerateReport(this.rootStore.selectedStore, this.startDate, this.endDate, this.isOnlyTwelveMonth);
        this.rootStore.removeLoading("GenerateReport");
    }

    *generateReportForStoreAdmin() {
        this.rootStore.addloading("GenerateReportForStoreAdmin");
        yield this.RiportService.GenerateReportForStoreAdmin(this.rootStore.selectedStore, this.startDate, this.endDate, this.isOnlyTwelveMonth);
        this.rootStore.removeLoading("GenerateReportForStoreAdmin");    
    }

    *GenerateRiportBySelectedRiportType() {
        this.rootStore.addloading("GenerateRiportBySelectedRiportType");
        yield this.RiportService.GenerateRiportBySelectedRiportType(this.rootStore.selectedStore, this.startDate, this.endDate, this.isOnlyTwelveMonth, this.selectedRiportTypeId);
        this.rootStore.removeLoading("GenerateRiportBySelectedRiportType");
    }

    *GetRiportFrequencies() {
        this.riportFrequences = yield this.AdminService.GetRiportFrequencies();
        this.riportFrequences.map((frequence) => {
            this.riportFrequencesOptions.push(
                {
                    value: frequence.Id,
                    text: frequence.Name,
                }
            )
        })
    }

    *GetRiportTypes() {
        this.riportTypes = yield this.AdminService.GetRiportTypes(); 
        // if (this.rootStore.currentUser.HoldingAdmin || this.rootStore.currentUser.HoldingGlobalViewer) {
            //nem kell filterezni, marad az eredeti, mivel ugyis mindent lathatnak
        // }
        if (this.rootStore.currentUser.StoreAdmin && !this.rootStore.currentUser.HoldingAdmin && !this.rootStore.currentUser.HoldingGlobalViewer) {
            this.riportTypes = this.riportTypes.filter(x => x.Id == 1 || x.Id == 3 || x.Id == 11);            
        }
        if (this.rootStore.currentUser.VodafoneSupervisor && !this.rootStore.currentUser.HoldingAdmin && !this.rootStore.currentUser.HoldingGlobalViewer) {
            this.riportTypes = this.riportTypes.filter(x => x.Id == 3 || x.Id == 8 || x.Id == 9);            
        }
        if ((this.rootStore.currentUser.IsConsultant || this.rootStore.currentUser.IsSeniorConsultant) && !this.rootStore.currentUser.HoldingAdmin && !this.rootStore.currentUser.HoldingGlobalViewer && !this.rootStore.currentUser.StoreAdmin ) {
            this.riportTypes = this.riportTypes.filter(x => x.Id == 3);            
        }

        this.riportTypesOptions = this.riportTypes.map((type ) => {
            return { value: type.Id, text: type.Name}
        })
        
        this.GetUserRiportFrequences();       
    }

    *GetUserRiportFrequences() {
        this.userRiportFrequencesToSave = [];
        this.userRiportFrequences = yield this.AdminService.GetUserRiportFrequences();        
        this.riportTypes.map((riportType) => {
            this.userRiportFrequencesToSave.push( {
                Id: 0,
                UserId: 0,
                RiportTypeId: riportType.Id,
                RiportTypeName: riportType.Name,
                FrequencyId: 0,
                IsRequested: 0,
            })
        });
        console.log('this.userRiportFrequencesthis.userRiportFrequencesthis.userRiportFrequences', this.userRiportFrequences);
        console.log('this.userRiportFrequencesToSavethis.userRiportFrequencesToSavethis.userRiportFrequencesToSave', this.userRiportFrequencesToSave);
        this.userRiportFrequences.map((userRiportFrequence) => {
            this.userRiportFrequencesToSave.map((saveItem, index) => {
                if (index == 0) {
                    this.selectedRiportFrequenceId = userRiportFrequence.FrequencyId;                    
                }                
                if (userRiportFrequence.RiportTypeId == saveItem.RiportTypeId) {
                    saveItem.IsRequested = userRiportFrequence.IsRequested                    
                }
            });
        });        
    }

    changeFrequence(index: number) {
        if (this.userRiportFrequencesToSave[index].IsRequested == 1) {
            this.userRiportFrequencesToSave[index].IsRequested = 0;
        } else {
            this.userRiportFrequencesToSave[index].IsRequested = 1;
        }
        this.isModified = true;
    }

    changeSelectedRiportFrequenceId(value: any) {
        this.selectedRiportFrequenceId = value;
        this.userRiportFrequencesToSave.map((toSaveItem) => {
            toSaveItem.FrequencyId = this.selectedRiportFrequenceId;
        })
        this.isModified = true;
    }

    changeSelectedRiportTypeId(value: any) {
        this.selectedRiportTypeId = value;        
        console.log(this.selectedRiportTypeId);
    }

    *InsertOrUpdateUserRiportFrequences() {
        this.userRiportFrequencesToSave[0].FrequencyId = this.selectedRiportFrequenceId;
        yield this.AdminService.InsertOrUpdateUserRiportFrequences(this.userRiportFrequencesToSave);     
        this.GetUserRiportFrequences();   
        this.isModified = false;
    }

    get getDisabled() {
        if (this.selectedRiportTypeId == 0 || !this.startDate || !this.endDate || (this.endDate < this.startDate) || (this.endDate.toLocaleDateString() === this.startDate.toLocaleDateString())) {
            return true
        }                
        return false;
    }

    get getMaxDate() {
        return new Date();
    }

    get getMaxDateForEndDate() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1);
    }

    get getMinDateForEndDate() {
        return this.startDate ? new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()+1) : null;
    }

}