import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Checkbox, Grid } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { RootStore } from "../../../stores/RootStore";
import { RateCommissionStore } from "./RateCommissionStore";

interface IStores {
    RateCommissionStore: RateCommissionStore
}

interface IRateCommissionPageProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore;
}

class RateCommissionPage extends React.Component<IRateCommissionPageProps> {
    private stores: IStores

    constructor(props: any){
        super(props);

        this.stores = { RateCommissionStore: new RateCommissionStore(this.props.RootStore)}
    }

    componentDidMount(): void {
        this.stores.RateCommissionStore.getLastRateCommission();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div style={{padding: 50}}>
                    <Grid>
                        <div style={{marginBottom: 50}}>
                            <div style={{display: 'flex'}}>
                                <div>
                                    <CustomHeader>Tarifacsomagok jutalékosztályának beállítása</CustomHeader>
                                </div>                            
                                <div style={{marginLeft: '200px'}}>
                                    <Button primary onClick={() => {this.stores.RateCommissionStore.generateRateCommission()}}>
                                        Adatok exportálása
                                    </Button>
                                </div>
                            </div>                            
                        </div>
                        <Grid.Row centered style={{marginBottom: 30}}>
                            <Grid.Column width={1}>
                                <Checkbox radio label="L1" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 1} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(1)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L2" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 2} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(2)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L3" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 3} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(3)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L4" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 4} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(4)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L5" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 5} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(5)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L6" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 6} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(6)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L7" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 7} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(7)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L8" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 8} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(8)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L9" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 9} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(9)}}></Checkbox>
                            </Grid.Column>
                            <Grid.Column width={1}>                                
                                <Checkbox radio label="L10" checked={this.stores.RateCommissionStore.rateCommissionItem.Lnumber === 10} onChange={() => {this.stores.RateCommissionStore.setRateCommissionItemLnumber(10)}}></Checkbox>
                            </Grid.Column>                            
                        </Grid.Row>                        
                        <Grid.Row centered style={{marginBottom: 50}}>
                            <Grid.Column width={3}>
                                <div style={{marginLeft: 10}}>
                                    <label>
                                        Utoljára módosította: {this.stores.RateCommissionStore.rateCommissionItem.ModifiedByName}
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <div style={{marginLeft: 10}}>
                                    <label>
                                        Utoljára módosítva: {this.stores.RateCommissionStore.rateCommissionItem.ModifiedDateString}
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <div style={{marginLeft: 10}}>
                                    <label>
                                        Utoljára beállított érték: L{this.stores.RateCommissionStore.previousLnumber}
                                    </label>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={3}>
                                <div style={{marginLeft: 10}}>
                                    <label>
                                        Előző érték: L{this.stores.RateCommissionStore.rateCommissionItem.PrevLnumber}
                                    </label>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row centered>
                            <Grid.Column width={3}>
                                <Button color='green' onClick={() => {this.stores.RateCommissionStore.saveRateCommission()}}>Mentés</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(RateCommissionPage)));