import { InfoVideoStore } from "./InfoVideoStore";
import { RootStore } from '../../stores/RootStore';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Grid, Icon, Image } from 'semantic-ui-react';
import withNavigation from '../../hocs/withNavigation';
import InfoVideoDetailsPage from './InfoVideoDetailsPage'
import logo from '../../assets/logo.png';

interface IStores {
    InfoVideoStore: InfoVideoStore;
}

interface IInfoVideoPageProps {
    navigate?: NavigateFunction;
	RootStore?: RootStore;
}

class InfoVideoPage extends React.Component<IInfoVideoPageProps> {
    private stores: IStores;

    constructor(props: any) {
        super(props);

        this.stores = { InfoVideoStore: new InfoVideoStore()}
    }

    public render() {
        return (
            <div>
                <Provider {...this.stores}>
                    <div>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                <div style={{alignItems: 'center'}}>
									<Image src={logo} size="medium" style={{marginBottom: '20px'}}/>
								</div>
                                    {this.stores.InfoVideoStore.contentItems.map((item, index) => {
                                        return (
                                            <div key={item.key} style={{fontSize: '19px', fontWeight: item.isActive? 'bold' : '400', marginBottom: 20, cursor: 'pointer'}} onClick={() => {this.stores.InfoVideoStore.setContentItemActive(index)}}>                                                
                                                {item.title}
                                                {item.checked && (
                                                    <Icon color="green" name="check circle outline" style={{marginLeft: 10}}></Icon>
                                                )}                                                
                                            </div>
                                        )                                         
                                    })}
                                </Grid.Column>
                                <Grid.Column width={12}>
                                    {this.stores.InfoVideoStore.currentNumber === 1 && (
                                        <InfoVideoDetailsPage titleString={"1. Infovideó, mint digitális segítség"}
                                        description={"Tisztelt Ügyfelünk! Köszönjük, hogy figyelemmel kísérted Kollégánk tájékoztatóját! Az előző oldalon összegeztük számodra az elhangzottakat. A következő oldalakon szeretnénk tájékoztatni a szerződés részletes feltételeiről. Célunk, hogy minden szükséges feltételt a legegyszerűbb módon ismerhess meg, ehhez rövid videókkal nyújtunk segítséget. Minden videó egy nyilatkozat megismerését teszi lehetővé, így az egyes videóanyagok megtekintése után minden esetben nyilatkozhatsz döntésedről. Lesznek olyan helyzetek, ahol dönthetsz úgy, hogy igénybe kívánod venni az adott lehetőséget, azonban a jelöléssel ellátott videók esetében 'ELFOGADOM' válasz megadása nélkül a szerződés nem köthető meg. Ha a videók megtekintését követően további kérdésed merülne, ismét megtekintheted azokat. Ha a nyilatkozat elolvasását követően további kérdéseid lennének, fordulj bátran kollégáinkhoz!"}></InfoVideoDetailsPage>
                                    )}                                                                       
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Provider>
            </div>
        )
    }
}

export default withNavigation(inject('RootStore')(observer(InfoVideoPage)));
