import { makeAutoObservable } from "mobx";

export class UserInRole {
    Id: number;
    RoleId: number;
    UserId: string; // guid
    RoleName: string;
    UserName: string;
    StoreCode: string;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.RoleId = 0;
        this.UserId = null;
        this.RoleName = null;
        this.UserName = null;
        this.StoreCode=null;
    }
}