import { inject, observer } from 'mobx-react';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RootStore } from '../stores/RootStore';
import HomePage from '../pages/home/HomePage';
import OfferPage from '../pages/offer/OfferPage';
import WarrantyPlusPage from '../pages/administration/warrantyplus/WarrantyPlusPage';
import AddressLoaderPage from '../pages/administration/addressloader/AddressLoaderPage';
import ManufacturerWarrantyPage from '../pages/administration/manufacturerwarranty/ManufacturerWarrantyPage';
import UsermanagementPage from '../pages/administration/usermanagement/UsermanagementPage';
import StoreDataPage from '../pages/administration/storedata/StoreDataPage';
import RateCommissionPage from '../pages/administration/ratecommission/RateCommissionPage';
import RatePortfolioPage from '../pages/administration/rateportfolio/RatePortfolioPage';
import RateDeviceDiscountPage from '../pages/administration/ratedevicediscount/RateDeviceDiscountPage';
import RateDeviceDiscountDetailsPage from '../pages/administration/ratedevicediscount/RateDeviceDiscountDetailsPage';
import VodafoneCommissionPage from '../pages/administration/vodafonecommission/VodafoneCommissionPage';
import DocumentsPage from '../pages/administration/documents/DocumentsPage';
import RatePortfolioDetailsPage from '../pages/administration/rateportfolio/RatePortfolioDetailsPage';
import VodafoneBOPage from '../pages/vodafonebo/VodafoneBOPage';
import CreditManagerPage from '../pages/creditmanager/CreditManagerPage';
import OnlinePickupPage from '../pages/onlinepickup/OnlinePickupPage';
import WarrantyPlusDetailsPage from '../pages/administration/warrantyplus/WarrantyPlusDetailsPage';
import StoreCommissionPage from '../pages/administration/manufacturerwarranty/StoreCommissionPage';
import LoginPage from '../pages/login/LoginPage';
import UsermanagementDetailsPage from '../pages/administration/usermanagement/UsermanagementDetailsPage';
import RolePage from '../pages/administration/roles/RolePage';
import RiportPage from '../pages/riport/RiportPage';
import GDPRPage from '../pages/administration/gdpr/GdprPage';
import SaveLandingPage from '../pages/savelandingpage/SaveLandingPage';
import UserGuidePage from '../pages/administration/userguide/UserGuidePage';
import BannerPage from '../pages/administration/banner/BannerPage';
import PrintablePackagePage from '../pages/printablepackage/PrintablePackagePage';
import ChangePasswordPage from '../pages/changepassword/ChangePasswordPage';
import EmailManagementPage from '../pages/administration/emailmanagement/EmailManagementPage';
import TariffPackageScorePage from '../pages/administration/tariffpackagescore/TariffPackageScorePage';
import TariffPackageScoreDetailsPage from '../pages/administration/tariffpackagescore/TariffPackageScoreDetailsPage';
import InformationObligationPage from '../pages/administration/informationobligation/InformationObligationPage';
import InformationObligationDetailsPage from '../pages/administration/informationobligation/InformationObligationDetailsPage';
import ClientRiportPage from '../pages/administration/clientreport/ClientRiportPage';
import TrainingModulePage from '../pages/administration/trainingmodule/TrainingModulePage';
import TrainingModuleDetailsPage from '../pages/administration/trainingmodule/TrainingModuleDetailsPage';
import ServiceProviderPermissionManagementPage from '../pages/administration/serviceproviderpermissionmanagement/ServiceProviderPermissionManagementPage';
import ModuleAssignmentPage from '../pages/moduleassignment/ModuleAssignmentPage';


interface IProps {
	RootStore?: RootStore;
}

class Layout extends React.Component<IProps> {
	componentDidMount(): void {
		console.log('Layout.componentDidMount');
	}

	public render() {
		const { RootStore } = this.props;

		return (
			<div>
				<Routes>
					<Route path="/" element={<Navigate to="/home" />} />
					<Route path="/home" element={<HomePage />} />
					<Route path="/offer/:id" element={<OfferPage />} />
					<Route path="/vodafonebo" element={<VodafoneBOPage />} />
					<Route path="/creditmanager" element={<CreditManagerPage />} />
					<Route path="/onlinepickup" element={<OnlinePickupPage />} />
					<Route path="administration/warranty-plus" element={<WarrantyPlusPage />} />
					<Route path="administration/warranty-plus-details/:id" element={<WarrantyPlusDetailsPage />} />
					<Route path="administration/manufacturer-warranty" element={<ManufacturerWarrantyPage />} />
					<Route path="administration/usermanagement" element={<UsermanagementPage />} />
					<Route path="administration/usermanagement-details/:id" element={<UsermanagementDetailsPage />} />
					<Route path="administration/store-data" element={<StoreDataPage />} />
					<Route path="administration/rate-commission" element={<RateCommissionPage />} />
					<Route path="administration/rate-portfolio" element={<RatePortfolioPage />} />
					<Route path="administration/rate-portfolio-details/:id" element={<RatePortfolioDetailsPage />} />
					<Route path="administration/rate-device-discount" element={<RateDeviceDiscountPage />} />
					<Route path="administration/rate-device-discount/:id" element={<RateDeviceDiscountDetailsPage />} />
					<Route path="administration/vodafone-commission" element={<VodafoneCommissionPage />} />
					<Route path="administration/documents" element={<DocumentsPage />} />
					<Route path="administration/address-loader" element={<AddressLoaderPage />} />
					<Route path="administration/store-commission" element={<StoreCommissionPage />} />
					<Route path="administration/role" element={<RolePage />} />
					<Route path="administration/user-guide" element={<UserGuidePage />} />
					<Route path="administration/banner" element={<BannerPage />} />
					<Route path="administration/emailmanagement" element={<EmailManagementPage />} />
					<Route path="administration/tariffpackagescore" element={<TariffPackageScorePage />} />
					<Route path="administration/tariffpackagescoredetails/:id" element={<TariffPackageScoreDetailsPage />} />
					<Route path="administration/information-obligation" element={<InformationObligationPage />} />
					<Route path="administration/information-obligation-details/:id" element={<InformationObligationDetailsPage />} />
					<Route path="administration/clientreport" element={<ClientRiportPage />} />
					<Route path="administration/training-module" element={<TrainingModulePage />} />
					<Route path="administration/training-module-details/:id" element={<TrainingModuleDetailsPage />} />
					<Route path="administration/serviceprovider-permissionmanagement" element={<ServiceProviderPermissionManagementPage />} />
					<Route path="login" element={<LoginPage />} />
					<Route path="riport" element={<RiportPage />} />
					<Route path="/gdpr/:id" element={<GDPRPage />} />
					<Route path="savelandingpage/:id" element={<SaveLandingPage />} />
					<Route path="/print-package/:id" element={<PrintablePackagePage />} />
					<Route path="/change-password" element={<ChangePasswordPage />} />
					<Route path="module-assignment" element={<ModuleAssignmentPage />} />
				</Routes>
			</div>
		);
	}
}

export default inject('RootStore')(observer(Layout));
