import { SaveLandingPageStore } from "./SaveLandingPageStore";
import { NavigateFunction } from "react-router-dom";
import React from "react";
import { inject, observer, Provider } from "mobx-react";
import withNavigation from "../../hocs/withNavigation";
import { Button, Grid } from "semantic-ui-react";
import { RootStore } from '../../stores/RootStore';
import withParams from "../../hocs/withParams";

interface IStores {
    SaveLandingPageStore: SaveLandingPageStore
}

interface ISaveLandingPageProps {
    navigate?: NavigateFunction
	RootStore?: RootStore;
	params?: any;
}

class SaveLandingPage extends React.Component<ISaveLandingPageProps> {

    stores: IStores

    constructor(props: any) {
        super(props);

        this.stores = { SaveLandingPageStore: new SaveLandingPageStore(this.props.navigate, this.props.RootStore, this.props.params.id)}
    }

    public render() {
        return <div>
                <Provider {...this.stores}>
                    <div style={{marginTop: '130px'}}>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column width={3}>
                                    <div style={{fontSize: '20px', fontWeight: 'bold'}}>Az ajánlat sikeresen elmentve!</div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered style={{marginTop: '30px'}}>
                                <Grid.Column width={2}>
                                    <Button compact color='green' onClick={() => {this.stores.SaveLandingPageStore.navigateBackToOffer()}}>Vissza az ajánlathoz</Button>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <Button compact color='grey' onClick={() => {this.stores.SaveLandingPageStore.navigateToHomePage()}}>Ugrás a Főoldalra</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Provider>
        </div>
    }
}

export default withParams(withNavigation(inject('RootStore')(observer(SaveLandingPage))));