import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { RiportService } from "../../../services/riport.service";
import { RootStore } from "../../../stores/RootStore";

export class ClientReportStore {
    
    private rootStore: RootStore;
    startDate: Date = null;
    endDate: Date = null;
    isOnlyTwelveMonth: number = 0;
    RiportService: RiportService;
    constructor(rootStore?: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, {
            startDate: observable,
            endDate: observable,
            isOnlyTwelveMonth: observable,     
            handleGenerate: action,   
            generateReport: flow,
        });

        this.RiportService = new RiportService();
    }

    updateStartDate = (value: any) => {
        this.startDate = value;
    }

    updateEndDate = (value: any) => {
        this.endDate = value;
    }

    updateIsOnlyTwelveMOnth = () => {
        if (this.isOnlyTwelveMonth === 0) {
            this.isOnlyTwelveMonth = 1
        }
        else {
            this.isOnlyTwelveMonth = 0;
        }
    }

    handleGenerate = () => {
       
            this.generateReport();            
               
    }

    *generateReport() {
          yield this.RiportService.GenerateClientReport(this.rootStore.selectedStore, this.startDate, this.endDate, this.isOnlyTwelveMonth)        
    }



    get getDisabled() {
        if (!this.startDate || !this.endDate || (this.endDate < this.startDate) || (this.endDate.toLocaleDateString() === this.startDate.toLocaleDateString())) {
            return true
        }                
        return false;
    }

    get getMaxDate() {
        return new Date();
    }

    get getMaxDateForEndDate() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1);
    }

    get getMinDateForEndDate() {
        return this.startDate ? new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate()+1) : null;
    }

}