import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Icon, Image, Table } from 'semantic-ui-react';
import telefon from '../assets/Telefon.jpg';
import { OfferPackage } from '../models/offerPackage';
import { OfferStore } from '../pages/offer/OfferStore';
import { CustomTableHeader } from './CustomTableHeader';
import { CustomTableHeader2 } from './CustomTableHeader2';

interface IProps {
	OfferStore?: OfferStore
	packageItem?: OfferPackage
}

class PhoneTable extends React.Component<IProps> {

	public render() {
		const { OfferStore, packageItem } = this.props

		return <div style={{ width: OfferStore.allPackage ? '350px' : '450px' }}>
			{packageItem?.PackageId !== 0 && (
				<Table basic color="red" celled={true} compact>
					<Table.Header fullwidth="true">
						<Table.Row verticalalign="top">
							
							<Table.HeaderCell collapsing></Table.HeaderCell>
							<Table.HeaderCell style={{ textAlign: 'center' }}>Teljes ár</Table.HeaderCell>
							{!OfferStore.allPackage  && <Table.HeaderCell style={{ textAlign: 'center', verticalalign: 'middle' }}>Havi összeg</Table.HeaderCell>}
						</Table.Row>
					</Table.Header>
					{/** ------ Tarifacsomag ------ */}
					{packageItem?.PackageId !== 0 && (
						<React.Fragment>
							<CustomTableHeader title="Tarifacsomag" />

							<Table.Body>
								<Table.Row verticalalign="top">
									<Table.Cell>
										{OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.Name}
										<div className="cikkszam">Hűségidő: {packageItem?.DurationId === 1 ? '12' : '24'} hónap</div>

									</Table.Cell>
									{!OfferStore.allPackage && <Table.Cell textAlign="right"> <b>havidíj</b></Table.Cell>}
									<Table.Cell textAlign="right">
										{OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.PackagePrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó
										<br />
										{/* <div className="cikkszam">Inflációkövetés nélkül</div> */}
									</Table.Cell>
								</Table.Row>
								<Table.Row warning textAlign="right">
									<Table.Cell>
										<div style={{ width: 170 }}>
											<span>
												<Icon name="tag" />
											</span>
											Havidíjkedvezmény
											<br />
											Időtartam: {packageItem?.DurationId === 1 ? OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.MonthlyDiscountDurationTwelve.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.MonthlyDiscountDuration.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} hó
										</div>
									</Table.Cell>
									<Table.Cell textAlign="center">-{packageItem?.DurationId === 1 ? OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.MonthlyDiscountTwelve.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.MonthlyDiscount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>
									{!OfferStore.allPackage && <Table.Cell className="no-border-left" />}
								</Table.Row>
							</Table.Body>
						</React.Fragment>
					)}

					{/** ------ Egyéb díjak ------ */}
					{packageItem?.PackageId !== 0 && packageItem?.DurationId != 1 && (OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) &&
						<React.Fragment>
							<CustomTableHeader2 background='#204470' title="Egyéb díjak" />

							<Table.Body>
								<Table.Row verticalalign="top">
									<Table.Cell>
										{(OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.CreditManager) ? packageItem?.PackageAdministrativeName : "Adminisztrációs díjak"}
										<div className="cikkszam">Cikkszám: {packageItem?.PackageArticleNumber}</div>
									</Table.Cell>
									
									<Table.Cell textAlign="right">{OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
									{!OfferStore.allPackage && <Table.Cell textAlign="right" className='bold'>22 x {Math.round(OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>}
								</Table.Row>
							</Table.Body>
						</React.Fragment>
					}
					{/** ------ Készülék ------ */}
					{packageItem?.DeviceName && packageItem?.DurationId !== 1 &&
						<React.Fragment>
							<CustomTableHeader2 title="Készülék" />
							<Table.Body verticalalign="top">
								<Table.Row>
									<Table.Cell colSpan="3">
										<Grid columns={2}>
											<Grid.Row>
												<Grid.Column width={2}>
													<Image /* label={{ as: "a", color: "yellow", corner: "left", icon: "tag" }} */ src={telefon} verticalalign="bottom" size="mini" rounded spaced />
												</Grid.Column>
												<Grid.Column textAlign="left" verticalalign="bottom" width={10}>
													<div style={{ paddingBottom: 10 }}>
														{packageItem?.DeviceName}
														<br />
														<span className="cikkszam">Cikkszám: {packageItem?.DeviceArticleNumber}</span>
													</div>
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Table.Cell>
								</Table.Row>
								{(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
									<React.Fragment>
										<Table.Row textAlign="right">
											<Table.Cell>Eredeti ár</Table.Cell>
											<Table.Cell>{packageItem?.Price ? packageItem?.Price?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} Ft</Table.Cell>
											{!OfferStore.allPackage && <Table.Cell className="no-border-left" />}
										</Table.Row>
										<Table.Row warning textAlign="right">
											<Table.Cell>
												<div style={{ width: 170 }}>
													<span>
														<Icon name="tag" />
													</span>
													Készülékkedvezmény
												</div>
											</Table.Cell>
											{/* <Table.Cell>{(packageItem?.Price - packageItem?.DiscountPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell> */}
											<Table.Cell>


												-{

													packageItem.PercentageDiscountPrice ?
														(OfferStore.naiveRound(packageItem?.Price - packageItem.PercentageDiscountPrice, 2))?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :


														packageItem.DevicePackageDiscount ? (packageItem?.Price - packageItem.DevicePackageDiscount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :
															packageItem?.DeviceDiscount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
												} Ft</Table.Cell>
											{!OfferStore.allPackage && <Table.Cell className="no-border-left" />}
										</Table.Row>
										<Table.Row warning textAlign="right">
											<Table.Cell>
												<div style={{ width: 170 }}>
													<span>
														<Icon name="tag" />
													</span>
													{(OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.CreditManager) ? `${packageItem?.PackageAdministrativeName} kedvezmény` : "Adminisztrációs díjak kedvezmény"}
												</div>
											</Table.Cell>
											<Table.Cell>-{OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
											{!OfferStore.allPackage && <Table.Cell className="no-border-left" />}
										</Table.Row>
									</React.Fragment>
								)}
							</Table.Body>
							{(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
								<Table.Body className="c-table-footer" fullwidth="true">
									<Table.Row textAlign="right">
										 <Table.HeaderCell className="bold">Fizetendő Vodafone csomagajánlatban</Table.HeaderCell>
										
										<Table.HeaderCell className="bold">
											{
												packageItem.PercentageDiscountPrice ?
													(packageItem.PercentageDiscountPrice - OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :
													packageItem.DevicePackageDiscount ? (packageItem.DevicePackageDiscount - OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :
														(packageItem?.Price - packageItem?.DeviceDiscount - OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
											} Ft</Table.HeaderCell>
										{!OfferStore.allPackage && (
											<Table.HeaderCell className="bold">
												{
													packageItem.PercentageDiscountPrice ?
														<div>

															22 × {Math.round((packageItem.PercentageDiscountPrice - OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó<samp></samp>
														</div>
														:

														packageItem.DevicePackageDiscount ?
															<div>

																22 × {Math.round((packageItem.DevicePackageDiscount - OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó<samp></samp>
															</div>

															:
															<div>
																22 × {Math.round((packageItem?.Price - packageItem?.DeviceDiscount - OfferStore?.tariffPackageItems.find(x => x.Id === packageItem?.PackageId)?.AdminsitrationFee) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó<samp></samp>
															</div>
												}
											</Table.HeaderCell>
										)}
									</Table.Row>
								</Table.Body>
							)}
						</React.Fragment>
					}

					{(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
						<React.Fragment>

							{/** ------ Kiegészítők ------ */}
							{(packageItem?.Accessories.length > 0 || packageItem?.ScreenProtectorId !== 0) &&
								<React.Fragment>
									<CustomTableHeader2 title="Kiegészítők" />
									{packageItem?.Accessories.map((item) => {
										return <Table.Body verticalalign="top">
											<Table.Row>
												<Table.Cell>
													{item.AccessoryName}
													<br />
													<span className="cikkszam">Cikkszám: {item.AccessoryArticleNumber}</span>
												</Table.Cell>
												<Table.Cell textAlign="right">{item.AccessoryPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
												{!OfferStore.allPackage && <Table.Cell textAlign="right">{Math.round(Number(item.AccessoryPrice) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>}
											</Table.Row>
										</Table.Body>

									})}
									{packageItem?.ScreenProtectorId !== 0 &&
										<Table.Body>
											<Table.Row>
												<Table.Cell>
													{packageItem?.ScreenProtectorName}
													<br />
													<span className="cikkszam">Cikkszám: {packageItem?.ScreenProtectorArticleNumber}</span>
												</Table.Cell>
												<Table.Cell textAlign="right"></Table.Cell>
												{!OfferStore.allPackage && <Table.Cell textAlign="right">
												</Table.Cell>}
											</Table.Row>
										</Table.Body>
									}
									<Table.Body className="c-table-footer" fullwidth="true">
										<Table.Row textAlign="right">
											<Table.HeaderCell className="bold">Összesen</Table.HeaderCell>
											
											<Table.HeaderCell className="bold">{packageItem?.Accessories.reduce((sum, current) => sum + Number(current.AccessoryPrice), 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.HeaderCell>
											{!OfferStore.allPackage && <Table.HeaderCell className="bold">22 × {Math.round((packageItem?.Accessories.reduce((sum, current) => sum + Number(current.AccessoryPrice), 0)) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.HeaderCell>}
										</Table.Row>
									</Table.Body>
								</React.Fragment>
							}
							{/** ------ Garancia kiterjesztés ------ */}
							{((packageItem?.WarrantyPackageId !== 0) || (packageItem?.ScreenProtectorId !== 0)) && packageItem?.DurationId !== 1 &&
								<React.Fragment>
									<CustomTableHeader title="Garanciakiterjesztés" />
									<Table.Body verticalalign="top">
										{packageItem?.WarrantyPackageId > 0 &&
											<Table.Row>
												<Table.Cell>
													{OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.Name}
													<br />
													<span className="cikkszam">Cikkszám: {OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.PackageNumber}</span>
												</Table.Cell>
												<Table.Cell textAlign="right" className='bold'>havidíj</Table.Cell>
												{!OfferStore.allPackage && <Table.Cell textAlign="right">{OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.Price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>}
											</Table.Row>
										}
										{/* {packageItem?.ScreenProtectorId !== 0 &&
											<Table.Row>
												<Table.Cell>
													{packageItem?.ScreenProtectorName}
													<br />
													<span className="cikkszam">Cikkszám: {packageItem?.ScreenProtectorArticleNumber}</span>
												</Table.Cell>
												<Table.Cell textAlign="right">{packageItem?.ScreenProtectorPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.Cell>
												{!OfferStore.allPackage && <Table.Cell textAlign="right">{Math.round(packageItem?.ScreenProtectorPrice / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.Cell>}
											</Table.Row>} */}
									</Table.Body>
									{/* <Table.Body className="c-table-footer" fullwidth="true">
										<Table.Row textAlign="right">
											<Table.HeaderCell className="bold">Összesen</Table.HeaderCell>
											<Table.HeaderCell className="bold">{((OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.Price ? OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.Price : 0) + packageItem?.ScreenProtectorPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft</Table.HeaderCell>
											{!OfferStore.allPackage && <Table.HeaderCell className="bold">22 × {Math.round(((OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.Price ? OfferStore?.warrantyPlusItems.find(x => x.Id === packageItem?.WarrantyPackageId)?.Price : 0) + packageItem?.ScreenProtectorPrice) / 22).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ft/hó</Table.HeaderCell>}
										</Table.Row>
									</Table.Body> */}
								</React.Fragment>
							}
						</React.Fragment>
					)}
				</Table>
			)}
		</div>
	}
}

export default inject("OfferStore")(observer(PhoneTable))