import { makeAutoObservable } from "mobx";

export class ChangePassword {
    UserGuid: string;
    OldPassword: string;
    NewPassword: string;
    constructor() {
        makeAutoObservable(this);
        this.UserGuid = null;
        this.OldPassword = null;
        this.NewPassword = null;
    }
}