import { InfoVideoStore } from "./InfoVideoStore";
import { RootStore } from '../../stores/RootStore';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { Grid } from 'semantic-ui-react';
import withNavigation from '../../hocs/withNavigation';

interface IStores {
    InfoVideoStore: InfoVideoStore;
}

interface IInfoVideoDetailsPageProps {
    navigate?: NavigateFunction;
	RootStore?: RootStore;
    titleString: string;
    videoUrl?: string;
    description?: string;
}

class InfoVideoDetailsPage extends React.Component<IInfoVideoDetailsPageProps> {
    private stores: IStores;

    constructor(props: any) {
        super(props);

        this.stores = { InfoVideoStore: new InfoVideoStore()}
    }

    public render() {

        const { titleString, videoUrl, description } = this.props
        return (
            <div>
                <Provider {...this.stores}>
                    <div>
                        <Grid>
                            <Grid.Row centered>                                
                                <Grid.Column centered width={16}>
                                    <div style={{fontSize: '35px', fontWeight: 'bold', color: 'rgb(104, 110, 169)', textAlign: 'center', marginTop: 100}}>
                                        {titleString}
                                    </div>
                                    {videoUrl && (
                                        <div>
                                            {videoUrl}
                                        </div>                                            
                                    )}
                                    {description && (
                                        <div style={{marginTop: '20px', color: 'rgb(167, 169, 192)', fontSize: '19px', textAlign: 'justify'}}>
                                            {description}
                                        </div>    
                                    )}
                                    
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </Provider>
            </div>
        )
    }
}

export default withNavigation(inject('RootStore')(observer(InfoVideoDetailsPage)));