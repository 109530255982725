import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { InputOnChangeData } from "semantic-ui-react";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { AdminService } from "../../../services/admin.service";
import { VodafoneCommission } from "../../../models/vodafoneCommission";
import { TariffPackage } from "../../../models/tariffPackage";
import { RootStore } from "../../../stores/RootStore";

export class VodafoneCommissionStore {        
    vodafoneCommissionItems: Array<VodafoneCommission> = [];
    columnDef: Array<ColDef>;
    isItemOpen: boolean = false;
    selectedTarifaItem: VodafoneCommission = new VodafoneCommission();
    AdminService: AdminService;
    temporaryDiscount0_5Number: number = null;
    temporaryDiscount5_25Number: number = null;
    temporaryDiscount25_50Number: number = null;    
    temporaryDiscount50_InfNumber: number = null;
    private RootStore:RootStore;
    constructor(rootStore:RootStore){
        this.RootStore= rootStore;
        makeAutoObservable(this, {
            vodafoneCommissionItems: observable,
            columnDef: observable,
            isItemOpen: observable,
            temporaryDiscount0_5Number: observable,
            temporaryDiscount5_25Number: observable,
            temporaryDiscount25_50Number: observable,
            temporaryDiscount50_InfNumber: observable,
            selectedTarifaItem: observable,
            setItemOpenTrue: action,
            setItemOpenFalse: action,
            openSelectedItem: action,            
            updateVodafoneCommissionItem: action,
            saveSelectedVodafoneCommissionItem: flow,
            getVodafoneCommissions: flow,
        })

        this.AdminService = new AdminService();

        this.columnDef = [
            { field: 'edit', headerName: 'Műveletek',  maxWidth:120, minWidth:120,
                cellRenderer: 'btnCellRendererOpenItem',
                cellRendererParams: {
                    clicked: (data: any) => {this.openSelectedItem(data.Id);},
                    }
                },
            { field: 'TariffPackageName', headerName: 'Tarifacsomag', filter: 'agSetColumnFilter', sortable: true, maxWidth: 270, minWidth: 270 },
            { field: 'Discount0_5', headerName: '[0-5eFt]', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.numberFormatter(params), maxWidth: 150, minWidth: 150, cellStyle: {textAlign: 'right'}},
            { field: 'Discount5_25', headerName: '[5-25eFt]', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.numberFormatter(params), maxWidth: 150, minWidth: 150, cellStyle: {textAlign: 'right'}},
            { field: 'Discount25_50', headerName: '[25-50eFt]', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.numberFormatter(params), maxWidth: 150, minWidth: 150, cellStyle: {textAlign: 'right'}},
            { field: 'Discount50_Inf', headerName: '[50eFt-]', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.numberFormatter(params), maxWidth: 150, minWidth: 150, cellStyle: {textAlign: 'right'}},            
        ]
    }

    numberFormatter = (params: any) => {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
    }

    updateVodafoneCommissionItem = (data: InputOnChangeData, columnNumber: number) => {                
            if (data.value === null || data.value === undefined || data.value === '') {
                if (columnNumber === 1) {
                    this.temporaryDiscount0_5Number = null;
                }                
                if (columnNumber === 2) {
                    this.temporaryDiscount5_25Number = null;
                }                
                if (columnNumber === 3) {
                    this.temporaryDiscount25_50Number = null;
                }                
                if (columnNumber === 4) {
                    this.temporaryDiscount50_InfNumber = null;
                }                
            }
            else {
                if (columnNumber === 1) {
                    this.temporaryDiscount0_5Number = Number(data.value);
                }                
                if (columnNumber === 2) {
                    this.temporaryDiscount5_25Number = Number(data.value);
                }                
                if (columnNumber === 3) {
                    this.temporaryDiscount25_50Number = Number(data.value);
                }                
                if (columnNumber === 4) {
                    this.temporaryDiscount50_InfNumber = Number(data.value);
                }      
            }            
        
    }

    setItemOpenTrue = () => {
        this.isItemOpen = true;
    }

    setItemOpenFalse = () => {
        this.isItemOpen = false;
    }

    openSelectedItem = (itemId: number) => {
        this.isItemOpen = true;
        var item = this.vodafoneCommissionItems.find(x => x.Id == itemId)
        this.selectedTarifaItem = item;
        this.temporaryDiscount0_5Number = this.selectedTarifaItem.Discount0_5;
        this.temporaryDiscount5_25Number = this.selectedTarifaItem.Discount5_25;
        this.temporaryDiscount25_50Number = this.selectedTarifaItem.Discount25_50;
        this.temporaryDiscount50_InfNumber = this.selectedTarifaItem.Discount50_Inf;
    }

    *saveSelectedVodafoneCommissionItem() {
        this.setItemOpenFalse();
        this.selectedTarifaItem.Discount0_5 = this.temporaryDiscount0_5Number;
        this.selectedTarifaItem.Discount5_25 = this.temporaryDiscount5_25Number;
        this.selectedTarifaItem.Discount25_50 = this.temporaryDiscount25_50Number;
        this.selectedTarifaItem.Discount50_Inf = this.temporaryDiscount50_InfNumber;
        this.RootStore.addloading('getVodafoneCommissions');
        yield this.AdminService.SaveVodefoneCommission(this.selectedTarifaItem);
        this.RootStore.removeLoading('getVodafoneCommissions');
        this.getVodafoneCommissions();
    }

    *getVodafoneCommissions() {
        this.RootStore.addloading('getVodafoneCommissions');
        this.vodafoneCommissionItems = yield this.AdminService.GetVodafoneCommissions();

        this.RootStore.removeLoading('getVodafoneCommissions');
    }

    get getVodafoneCommissionItemCanBeSaved() {
        if (this.temporaryDiscount0_5Number === null || this.temporaryDiscount5_25Number === null || this.temporaryDiscount25_50Number === null || this.temporaryDiscount50_InfNumber === null ||
            this.temporaryDiscount0_5Number < 0 || this.temporaryDiscount5_25Number < 0 || this.temporaryDiscount25_50Number < 0 || this.temporaryDiscount50_InfNumber < 0) {
                return false;
            }

        return true;
    }


}