import { action, flow, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { InputOnChangeData } from "semantic-ui-react";
import { ColDef, GridApi } from '@ag-grid-enterprise/all-modules';
import { AdminService } from "../../../services/admin.service";
import { ManufacturerWarranty } from "../../../models/manufacturerWarranty";
import { RootStore } from "../../../stores/RootStore";
import { StoreCommission } from "../../../models/storeCommission";

export class ManufacturerWarrantyStore {
    private RootStore:RootStore
    manufacturerWarrantyItems: Array<any> = [];
    warrantyPeriodMonth: any = '';
    maxPrice: any = '';
    isEditMode: boolean = false;
    storeCommissionItems: Array<any>;
    columnDef: Array<ColDef>;
    storeCommissionColumnDef: Array<ColDef>;
    AdminService:AdminService;
    manufacturerWarrantyItem: ManufacturerWarranty = new ManufacturerWarranty();
    storeCommissionItem: StoreCommission = new StoreCommission(); 
    isStoreCommissionPopupOpen: boolean = false;   
    temporaryStoreCommissionNumber: number = null;
    temporaryWarrantyPeriodMonthNumber: number = null;
    temporaryMaxPriceNumber: number = null;
    constructor(rootStore:RootStore) {
        this.RootStore= rootStore;
        makeAutoObservable(this, {
            manufacturerWarrantyItems: observable,
            warrantyPeriodMonth: observable,
            maxPrice: observable,
            isEditMode: observable,
            storeCommissionItems: observable,
            columnDef: observable,
            storeCommissionColumnDef: observable,
            manufacturerWarrantyItem: observable,
            isStoreCommissionPopupOpen: observable,           
            temporaryStoreCommissionNumber: observable,           
            temporaryWarrantyPeriodMonthNumber: observable,           
            temporaryMaxPriceNumber: observable,           
            setWarrantyPeriodMonth: action,
            setMaxPrice: action,
            setEditModeFalse: action,
            openManufacturerItemById:action,
            openStoreCommissionItemById: action,
            setIsStoreCommissionPopupOpenFalse: action,
            setStoreCommissionItemWarrantyCommissionPercentage: action,
            getManufacturerWarranties: flow,
            saveManufacturerItem: flow,
            getStoreCommissionsItems: flow,
            saveStoreCommissionItem: flow,
            deleteManufacturerWarrantyItemById: flow,
        })
        this.AdminService=new AdminService();

        this.columnDef = [
            { field: 'edit', headerName: 'Műveletek',  maxWidth:180, minWidth:180,
                cellRenderer: 'btnCellRendererOpenItem',
                cellRendererParams: {
                    clicked: (data: any) => {this.openManufacturerItemById(data.Id);},
                    }
                },
            { field: 'WarrantyPeriod', headerName: 'Garancia időtartama (hónap)', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250},
            { field: 'DevicePrice', headerName: 'Maximális készülékár', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.numberFormatter(params), maxWidth: 250, minWidth: 250},
            { field: 'edit', headerName: 'Eltávolítás', maxWidth: 150, minWidth: 150,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {this.deleteManufacturerWarrantyItemById(data.Id);},
                    } 
            },            
        ]

        this.storeCommissionColumnDef = [
            { field: 'edit', headerName: 'Műveletek',  maxWidth:150, minWidth:150,
                cellRenderer: 'btnCellRendererOpenItem',
                cellRendererParams: {
                    clicked: (data: any) => {this.openStoreCommissionItemById(data.Id);},
                    }
                },
            { field: 'StoreName', headerName: 'Áruház', filter: 'agSetColumnFilter', sortable: true, maxWidth: 230, minWidth: 230},
            { field: 'WarrantyCommissionPercentage', headerName: 'G+ jutalék százalék', filter: 'agSetColumnFilter', sortable: true, maxWidth: 200, minWidth: 200, cellStyle: {textAlign: 'right'}},            
        ]

    }        

    numberFormatter = (params: any) => {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
    }

    setWarrantyPeriodMonth = (data: InputOnChangeData) => {        
        if (this.isEditMode) {            
            if (data.value === null || data.value === undefined || data.value === '') {
                this.temporaryWarrantyPeriodMonthNumber = null;
            }
            else {
                this.temporaryWarrantyPeriodMonthNumber = Number(data.value)
            }  
        }
        else {
            if (data.value === null || data.value === undefined || data.value === '') {
                this.warrantyPeriodMonth = null;
            }
            else {
                this.warrantyPeriodMonth = Number(data.value)
            }                        
        }
    }

    setMaxPrice = (data: InputOnChangeData) => {        
        if (this.isEditMode) {
            if (data.value === null || data.value === undefined || data.value === '') {
                this.temporaryMaxPriceNumber = null;
            }
            else {
                this.temporaryMaxPriceNumber = Number(data.value)
            }            
        }
        else {
            if (data.value === null || data.value === undefined || data.value === '') {
                this.maxPrice = null;
            }
            else {
                this.maxPrice = Number(data.value)
            }            
        }
    }

    setEditModeFalse = () => {
        this.isEditMode = false;
    }

    setIsStoreCommissionPopupOpenFalse = () => {
        this.isStoreCommissionPopupOpen = false
    }

    setStoreCommissionItemWarrantyCommissionPercentage = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {   
        if (data.value === null || data.value === undefined || data.value === '') {
            this.temporaryStoreCommissionNumber = null    
        }     
        else {
            this.temporaryStoreCommissionNumber = Number(data.value)
        }        
        console.log(data.value)
        
    }    

    openManufacturerItemById = (itemId: number) => {
        this.isEditMode = true;
        let item = this.manufacturerWarrantyItems.find(x => x.Id == itemId) 
        if (item) {
            this.manufacturerWarrantyItem = item
            this.temporaryWarrantyPeriodMonthNumber = this.manufacturerWarrantyItem.WarrantyPeriod
            this.temporaryMaxPriceNumber = this.manufacturerWarrantyItem.DevicePrice
        }
    }

    openStoreCommissionItemById = (itemId: number) => {        
        this.isStoreCommissionPopupOpen = true;
        let item = this.storeCommissionItems.find(x => x.Id == itemId) 
        if (item) {
            this.storeCommissionItem = item
            this.temporaryStoreCommissionNumber = this.storeCommissionItem.WarrantyCommissionPercentage
        }
        console.log('this.storeCommissionItem',this.storeCommissionItem)
    }

    *saveManufacturerItem() {
        if (!this.isEditMode) {
            this.manufacturerWarrantyItem = new ManufacturerWarranty();
            this.manufacturerWarrantyItem.WarrantyPeriod = this.warrantyPeriodMonth
            this.manufacturerWarrantyItem.DevicePrice = this.maxPrice
        }        
        if (this.isEditMode) {
            this.manufacturerWarrantyItem.WarrantyPeriod = this.temporaryWarrantyPeriodMonthNumber;
            this.manufacturerWarrantyItem.DevicePrice = this.temporaryMaxPriceNumber;
        }
        this.setEditModeFalse()
        this.warrantyPeriodMonth = ''
        this.maxPrice = ''
        this.RootStore.addloading('saveManufacturerItem');
        yield this.AdminService.SaveManufacturerWarranty(this.manufacturerWarrantyItem);
        this.RootStore.removeLoading('saveManufacturerItem');
        this.manufacturerWarrantyItem = null;        
        this.getManufacturerWarranties()
        
    }

    *getManufacturerWarranties() {
        this.RootStore.addloading('getManufacturerWarranties');
        this.manufacturerWarrantyItems = yield this.AdminService.GetManufacturerWarranties();
        console.log('this.manufacturerWarrantyItems',toJS(this.manufacturerWarrantyItems))
        this.RootStore.removeLoading('getManufacturerWarranties');           
    }

    *deleteManufacturerWarrantyItemById(manufacturerWarrantyItemId: number) {
        this.RootStore.addloading('deleteManufacturerWarrantyItemById');
        yield this.AdminService.DeleteManufacturerWarrantyItemById(manufacturerWarrantyItemId)        
        this.RootStore.removeLoading('deleteManufacturerWarrantyItemById');
        this.getManufacturerWarranties();
    }

    *saveStoreCommissionItem() {
        this.setIsStoreCommissionPopupOpenFalse();
        this.RootStore.addloading('saveStoreCommissionItem');
        this.storeCommissionItem.WarrantyCommissionPercentage = this.temporaryStoreCommissionNumber        
        yield this.AdminService.SaveStoreCommission(this.storeCommissionItem)
        console.log('this.storeCommissionItem',this.storeCommissionItem)
        this.RootStore.removeLoading('saveStoreCommissionItem');        
        this.getStoreCommissionsItems();
    }

    *getStoreCommissionsItems() {
        this.RootStore.addloading('getStoreCommissionsItems');
        this.storeCommissionItems = yield this.AdminService.GetStoreCommissions();
        this.RootStore.removeLoading('getStoreCommissionsItems');
    }

    get getItemAddable() {
        if (this.warrantyPeriodMonth === '' || this.maxPrice === '' || this.warrantyPeriodMonth === null || this.maxPrice === null ||
        this.warrantyPeriodMonth === 0 || this.maxPrice === 0 || this.warrantyPeriodMonth < 0 || this.maxPrice < 0) {
            return false;
        }
        
        return true;
    }

    get getSelectedItemAddable() {
        if (this.temporaryWarrantyPeriodMonthNumber === 0 || this.temporaryMaxPriceNumber === 0 || this.temporaryWarrantyPeriodMonthNumber === null || this.temporaryMaxPriceNumber === null ||
            this.temporaryWarrantyPeriodMonthNumber < 0 || this.temporaryMaxPriceNumber < 0) {
            return false;
        }
        
        return true;
    }
    
    get getStoreCommissionItemAddable() {
        if ( this.temporaryStoreCommissionNumber > 100 || this.temporaryStoreCommissionNumber < 0 || this.temporaryStoreCommissionNumber === null) {
            return false;
        }

        return true;
    }
}