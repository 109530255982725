import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import withNavigation from '../../../hocs/withNavigation';
import { Button, Grid } from 'semantic-ui-react';
import { CustomHeader } from '../../../components/CustomHeader';
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { flow, makeObservable, observable } from 'mobx';
import { AdminService } from '../../../services/admin.service';
import { RootStore } from '../../../stores/RootStore';
import { TrainingModuleStore } from './TrainingModuleStore';
import OpenItem from "../../../components/OpenItem";

interface IStores {
	TrainingModuleStore: TrainingModuleStore;
}

interface ITrainingModulePageProps {
	navigate?: NavigateFunction;
	RootStore?:RootStore
}

class TrainingModulePage extends React.Component<ITrainingModulePageProps> {
	private stores: IStores;
    AdminService: AdminService;
	showInactive:boolean=false;
	 

	constructor(props: any) {
		super(props);
        this.AdminService = new AdminService();

		this.stores = { TrainingModuleStore: new TrainingModuleStore(this.props.RootStore, this.props.navigate) };
		makeObservable(this, {            
            GetTrainingModules: flow,
			showInactive:observable
        })
	}
	componentDidMount(): void {
        
        this.GetTrainingModules();
    }
	*GetTrainingModules() {
        this.props.RootStore.addloading('GetTrainingModules');
        this.stores.TrainingModuleStore.trainingModuleItems = yield this.AdminService.GetTrainingModules();
		this.props.RootStore.removeLoading('GetTrainingModules');
    } 
	public render() {
		return (
			<div>
				<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Továbbképzési modulok</CustomHeader>
                                    </div>			
                                    {(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor) && (
                                        <div style={{marginBottom: 20}}>
                                            <Button color="red" onClick={() => {this.props.navigate('/administration/training-module-details/0')}}>Új modul rögzítése</Button>								
                                        </div>					
                                    )}									
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>                                            
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1402px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.TrainingModuleStore.trainingModuleItems}											
                                                    columnDefs={this.stores.TrainingModuleStore.columnDef}
                                                    frameworkComponents={{ btnCellRendererOpenItem: OpenItem}}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
													gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(TrainingModulePage)));
