import { inject, observer } from 'mobx-react';
import React, { KeyboardEventHandler } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Grid, Image, Input } from 'semantic-ui-react';
import logo from '../../assets/logo.png';
import withNavigation from '../../hocs/withNavigation';
import { RootStore } from '../../stores/RootStore';

interface ILoginPageProps {
	navigate?: NavigateFunction;
	RootStore?: RootStore;
}

class LoginPage extends React.Component<ILoginPageProps> {
	constructor(props: any) {
		super(props);
	}	

	public render() {
		return (
			<div>
				<div  >
					<Grid>
						<Grid.Row centered style={{ marginTop: 100 }}>
							<Grid.Column width={4}>
								<div>
									<Image src={logo} size="medium" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row centered>
							<Grid>
								<Grid.Row centered>
									<Grid.Column width={12} style={{ textAlign: 'center' }}>
										<div>
											<label>Felhasználónév</label>
										</div>
										<Input onKeyDown={(event: KeyboardEvent) => this.props.RootStore.handleKeyDown(event)} value={this.props.RootStore.UserName} onChange={(event, data) => { this.props.RootStore.onChangeUserName(event, data) }}></Input>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Grid.Column width={12} style={{ textAlign: 'center' }}>
										<div>
											<label>Jelszó</label>
										</div>
										<Input onKeyDown={(event: KeyboardEvent) => this.props.RootStore.handleKeyDown(event)} value={this.props.RootStore.Password} onChange={(event, data) => { this.props.RootStore.onChangePassword(event, data) }} type='password'></Input>
										
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Grid.Column width={12} style={{ textAlign: 'center' }}>

										{this.props.RootStore.loginFailure &&
											<div style={{ color: 'rgb(255,40,0)' }}>
												{this.props.RootStore.loginFailMessage}
											</div>
										}
										
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Grid.Column width={12} style={{ textAlign: 'center' }}>
										<Button											
											disabled={(this.props.RootStore.UserName == null || this.props.RootStore.UserName == "") || (this.props.RootStore.Password == null || this.props.RootStore.Password == "")}
											color="green"
											onClick={() => {
												this.props.RootStore.login();
											}}
										>
											Belépés
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Row>
					</Grid>
				</div>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(LoginPage)));
