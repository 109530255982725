import { makeAutoObservable } from "mobx";

export class Device {
    ArticleNumber: number;
    Name: string;
    Manufacturer: string;
    Price: number;
    StockQuantity: number;
    PictureUrl: string;
    Description: string;
    constructor(){
        makeAutoObservable(this);
        this.ArticleNumber = null;
        this.Name = null;
        this.Manufacturer = null;
        this.Price = 0;
        this.StockQuantity = 0;
        this.PictureUrl = null;
        this.Description = null;
    }
}