import { RootStore } from "../../stores/RootStore";
import { NavigateFunction } from "react-router-dom";
import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";

export class SaveLandingPageStore {

    private RootStore: RootStore
    navigate?: NavigateFunction;
    offerIdToNavigate: number = 0;
    constructor(navigate: NavigateFunction,RootStore?: RootStore,offerId?: number) {
        this.RootStore = RootStore;
        this.navigate = navigate
        this.offerIdToNavigate = offerId;
        makeAutoObservable(this, {
            navigateToHomePage: action,
            navigateBackToOffer: action,
        });
    }

    componentDidMount(): void {
		// this.getLogin();		
		// this.getCurrentUser();
		// console.log('App componentDidMount')
        this.RootStore.removeAllLoading();

    };
    navigateToHomePage() {
        this.navigate('/home');
    }

    navigateBackToOffer() {
        this.navigate(`/offer/${this.offerIdToNavigate}`)
    }
}