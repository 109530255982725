import { makeAutoObservable } from "mobx";

export class AccessoriesOfPackage {
    Id: number;
    PackageId: number;
    AccessoryId: number;
    AccessoryName: string;
    AccessoryArticleNumber: string;
    AccessoryPrice: string;
    AccessoryDescription: string;
    AccessoryPictureUrl: string;    
    AccessoryManufacturer: string;
    IsActive: boolean;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.PackageId = 0;
        this.AccessoryId = 0;
        this.AccessoryName = null;
        this.AccessoryArticleNumber = null;
        this.AccessoryPrice = null;
        this.AccessoryDescription = null;
        this.AccessoryPictureUrl = null;
        this.AccessoryManufacturer = null;
        this.IsActive = false;
    }
}