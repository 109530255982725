import { action, flow, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { User } from "../../../models/user";
import { RootStore } from "../../../stores/RootStore";
import { NavigateFunction } from "react-router-dom";
import { UserService } from "../../../services/user.service";

export class UsermanagementStore {

    usermanagementItems: Array<any> = [];
    columnDef: Array<ColDef>;
    usermanagementItem: User = new User();
    usermanagementItemId: number = 0;
    aegonGSalesOptions: Array<any> = [];
    accountStatesOptions: Array<any> = [];
    supervisorApprovedsOptions: Array<any> = [];
    isGenerateNewPasswordConfirmOpen: boolean = false;
    isFirstSave: boolean = false;
    isUserNameNotUnique: boolean = false;
    isUserNameNotUniqueOpen: boolean = false;
    RootStore: RootStore;
    navigate?: NavigateFunction;
    UserService: UserService;
    isVodafone:boolean=false;
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    usermanagementStoreValue: string = "";
    constructor(rootStore: RootStore, navigate?: NavigateFunction, usermanagementItemId?: number) {
        this.RootStore = rootStore;
        this.navigate = navigate;
        this.usermanagementItemId = usermanagementItemId;        
        makeAutoObservable(this, {
            usermanagementItems: observable,
            columnDef: observable,            
            usermanagementItem: observable,
            usermanagementItemId: observable,
            aegonGSalesOptions: observable,
            accountStatesOptions: observable,
            supervisorApprovedsOptions: observable,
            isGenerateNewPasswordConfirmOpen: observable,
            isFirstSave: observable,
            isUserNameNotUnique: observable,
            isUserNameNotUniqueOpen: observable,
            updateUsermanagementItem: action,
            navigateToUserItem: action,
            setNewInstance: action,
            confirmGenerateNewPassword: action,
            cancelGenerateNewPassword: action,
            setIsGenerateNewPasswordConfirmOpenTrue: action,
            cancelIsUserNameNotUniqueOpen: action,
            saveUserItem: flow,
            getUsers: flow,
            getUserById: flow,
            isVodafone:observable
        })
		this.isVodafone=!this.RootStore.currentUser.HoldingAdmin && !this.RootStore.currentUser.StoreAdmin && this.RootStore.currentUser.VodafoneSupervisor;

        this.UserService = new UserService();

        this.columnDef = [
            {
                field: 'edit', headerName: 'Műveletek', maxWidth: 180,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => { this.navigateToUserItem(data.Id); },
                }
            },
            { field: 'Name', headerName: 'Név', filter: 'agSetColumnFilter', sortable: true, minWidth: 320, maxWidth: 320, },
            // { field: 'UserCode', headerName: 'Azonosító', filter: 'agSetColumnFilter', sortable: true, maxWidth: 300, minWidth: 300 },
            { field: 'StoreName', headerName: 'Áruház neve', filter: 'agSetColumnFilter', sortable: true, maxWidth: 300, minWidth: 300 },
            { field: 'UserCode', headerName: 'WAWI-kód', filter: 'agSetColumnFilter', sortable: true, minWidth: 320, maxWidth: 320, },
            { field: 'Disabled', headerName: 'Fiók állapota (WAWI)', filter: 'agSetColumnFilter', sortable: true, minWidth: 278, maxWidth: 278, 
            valueFormatter: params => this.booleanFormatter(params), filterValueGetter: params => this.booleanFilterFormatter(params)},
            { field: 'AccountState', headerName: 'Fiók állapota (MMSConnect)', filter: 'agSetColumnFilter', sortable: true, minWidth: 278, maxWidth: 278, 
            valueFormatter: params => this.accountStateFormatter(params), filterValueGetter: params => this.accountStateFilterFormatter(params)},
        ]

        this.aegonGSalesOptions = [
            { value: true, text: 'Igen' },
            { value: false, text: 'Nem' },
        ]
    }    

    booleanFilterFormatter = (params: any) => {        
        return params?.data?.Disabled != null ? (params.data.Disabled == 0 ? "Aktív" : "Inaktív") : "";
    }

    accountStateFilterFormatter = (params: any) => {        
        return params?.data?.AccountState != null ? (params.data.AccountState == 1 ? "Aktív" : (params.data.AccountState == 2 ? "Ideiglenesen inaktív" : "Tartósan inaktív")) : "";
    }

    booleanFormatter = (params: any) => {
        return params.value != null ? (params.value == 0 ? "Aktív" : "Inaktív") : "";
    }

    accountStateFormatter = (params: any) => {
        return params.value != null ? (params.value == 1 ? "Aktív" : (params.value == 2 ? "Ideiglenesen inaktív" : "Tartósan inaktív")) : "";
    }

    navigateToUserItem = (userItemId: number) => {
        this.RootStore.setFromModuleAssignment(false);
        this.navigate('/administration/usermanagement-details/' + userItemId);
    }

    updateUsermanagementItem(key: keyof User, value: any) {
        if(key== 'IsGlobal' && value==true){
            this.usermanagementItem.StoreCode=null;
            this.usermanagementItem.StoreId=null;

        }
        (this.usermanagementItem[key] as any) = value;
        // if (key === 'StoreId') {
        //     this.usermanagementItem.StoreId = this.RootStore.stores.find(x => x.value === value).Id;
        //     this.usermanagementStoreValue = this.RootStore.stores.find(x => x.value === value).value;
        //     console.log(this.usermanagementItem.StoreId)
        // }
    }

    setNewInstance() {
        this.usermanagementItem = new User();        
        this.usermanagementItem.StoreCode =  this.RootStore.selectedStore; 
        this.usermanagementItem.StoreId = this.RootStore.stores.find(x => x.value === this.RootStore.selectedStore).Id; 
        this.usermanagementItem.AccountState = 1;
        this.usermanagementItem.IsVodafoneSupervisorApproved = 2;
        this.usermanagementItem.AegonGSales = false;
        if (this.RootStore.currentUser.VodafoneSupervisor && !this.RootStore.currentUser.HoldingAdmin && !this.RootStore.currentUser.StoreAdmin) {
            this.usermanagementItem.IsGlobal = true;
        }
        else {
            this.usermanagementItem.IsGlobal = false;
        }        
        if (!this.RootStore.currentUser.HoldingAdmin) {
            if(!this.isVodafone){
                this.usermanagementItem.IsGlobal = false;
               // this.usermanagementItem.StoreCode = this.RootStore.selectedStore.Id;                
            }
            
        }
        console.log('setDefaultValues lefutott', this.RootStore.selectedStore, this.usermanagementItem.AccountState, this.usermanagementItem.IsVodafoneSupervisorApproved)
        this.isFirstSave = true;
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }

    setIsGenerateNewPasswordConfirmOpenTrue = () => {
        this.isGenerateNewPasswordConfirmOpen = true;
    }

    confirmGenerateNewPassword = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isGenerateNewPasswordConfirmOpen = false;
        this.UserService.GenerateNewPassword(this.usermanagementItem)
    }

    cancelGenerateNewPassword = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isGenerateNewPasswordConfirmOpen = false;
    }

    cancelIsUserNameNotUniqueOpen = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isUserNameNotUniqueOpen = false;
    }    

    *saveUserItem() {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (this.isFirstSave) {
            this.isUserNameNotUnique = yield this.UserService.CheckUserNameIsNotUnique(this.usermanagementItem.UserName)
            var debug = 0;
        }
        if (this.isUserNameNotUnique) {
            this.isUserNameNotUniqueOpen = true;
        }
        if (!this.isUserNameNotUnique && !this.hasValidationErrors) {
            this.RootStore.addloading('saveUserItem')
            yield this.UserService.SaveUser(this.usermanagementItem)
            this.RootStore.removeLoading('saveUserItem')
            if (this.RootStore.fromUserModuleAssignment) {
                this.navigate('/administration/serviceprovider-permissionmanagement');
            }
            else {
                this.navigate('/administration/usermanagement');
            }
            this.isFirstSave = false;
        }
    }

    *getUsers() {
        this.RootStore.addloading('getUsers')
        this.usermanagementItems = yield this.UserService.GetUsers();
        this.RootStore.removeLoading('getUsers')
    }

    *getUserById() {
        this.RootStore.addloading('getUserById');
        this.usermanagementItem = yield this.UserService.GetUserById(this.usermanagementItemId)
        this.RootStore.removeLoading('getUserById');
    }

    get getValidationsUsermanagement() {
        if (!this.isSaved) {
            return {}
        }

        let validationErrors: Partial<Record<keyof User, string>> = {}

        if (!this.usermanagementItem.LastName) {
            validationErrors["LastName"] = "Vezetéknév kitöltése kötelező!"
        }

        if (!this.usermanagementItem.FirstName) {
            validationErrors["FirstName"] = "Keresztnév kitöltése kötelező!"
        }

        if (!this.usermanagementItem.UserName) {
            validationErrors["UserName"] = "Bejelentkezési név (loginnév) kitöltése kötelező!"
        }

        if (!this.usermanagementItem.Email) {
            validationErrors["Email"] = "Vállalati e-mail kitöltése kötelező!"
        }

        // if ((this.usermanagementItem.StoreId === 0 || this.usermanagementItem.StoreId === null) && !this.RootStore.currentUser.HoldingAdmin) {
        //     validationErrors["StoreId"] = "Áruház kiválasztása kötelező!"
        // }
        if ((this.usermanagementItem.StoreCode == "" || this.usermanagementItem.StoreCode === null) && !this.RootStore.currentUser.HoldingAdmin) {
            validationErrors["StoreId"] = "Áruház kiválasztása kötelező!"
        }
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsUsermanagement).length === 0) {
            return false;
        }

        return true;
    }
}