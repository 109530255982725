import { makeAutoObservable } from "mobx";

export class TariffPackageScore {
    Id: number;
    TariffPackageId: number;
    ModifiedById: number;
    ModifiedDate: Date;
    ModifiedByName: string;
    ModifiedDateString: string;
    TariffPackageName: string;
    ActualScore: number;
    PreviousScore: number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.TariffPackageId = 0;
        this.ModifiedById = 0;
        this.ModifiedDate = null;
        this.ModifiedByName = null;
        this.ModifiedDateString = null;
        this.TariffPackageName = null;
        this.ActualScore = null;
        this.PreviousScore = null;
    }
}