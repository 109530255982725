import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { RatePortfolioStore } from "./RatePortfolioStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { RootStore } from "../../../stores/RootStore";
import OpenItem from "../../../components/OpenItem";

interface IStores {
    RatePortfolioStore: RatePortfolioStore
}

interface IRatePortfolioPageProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore
}

class RatePortfolioPage extends React.Component<IRatePortfolioPageProps> {
    private stores: IStores

    constructor(props: any){
        super(props);

        this.stores = { RatePortfolioStore: new RatePortfolioStore(this.props.RootStore, this.props.navigate)}
    }

    componentDidMount(): void {
        this.stores.RatePortfolioStore.getTariffPackages()
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
            <div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Tarifacsomag-portfólió</CustomHeader>
                                    </div>				
                                    <div style={{marginBottom: 20}}>
										<Button color="red" onClick={() => {this.props.navigate('/administration/rate-portfolio-details/0')}}>Új tarifacsomag rögzítése</Button>								
									</div>				
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1390px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.RatePortfolioStore.tariffPackageItems}											
                                                    columnDefs={this.stores.RatePortfolioStore.columnDef}
                                                    localeText={AgGridText}
                                                    frameworkComponents={{ btnCellRenderer: OpenItem }}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>     
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(RatePortfolioPage)));