import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Checkbox, Confirm, Dropdown, Grid, Input, Label, Message } from 'semantic-ui-react';
import withNavigation from '../../../hocs/withNavigation';
import { CustomHeader } from '../../../components/CustomHeader';
import withParams from '../../../hocs/withParams';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { RootStore } from '../../../stores/RootStore';
import { WarrantyPlusPackage } from '../../../models/warrantyPlusPackage';
import { AdminService } from '../../../services/admin.service';
import NumberFormat from 'react-number-format';
import { TrainingModuleStore } from './TrainingModuleStore';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../../index.css'
import { TrainingModule } from '../../../models/trainingModel';

interface IStores {
	TrainingModuleStore: TrainingModuleStore
}

interface ITrainingModuleDetailsPageProps {
	navigate?: NavigateFunction,
	params?: any
	RootStore?: RootStore
}

class TrainingModuleDetailsPage extends React.Component<ITrainingModuleDetailsPageProps> {

	stores: IStores
	AdminService: AdminService

	constructor(props: any) {
		super(props);

		this.stores = { TrainingModuleStore: new TrainingModuleStore(this.props.RootStore, this.props.navigate, this.props.params.id) }

		this.AdminService = new AdminService()
	}	

	public render() {
		return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Grid>
						<Grid.Row centered>
						<Confirm open={this.stores.TrainingModuleStore.isUploadConfirmOpen}
							closeOnEscape={false}
							closeOnDimmerClick={false}
							onConfirm={(event) => { this.stores.TrainingModuleStore.confirmUpload(event); }}
							onCancel={(event) => { this.stores.TrainingModuleStore.cancelUpload(event); }}
							cancelButton="Mégse"
							confirmButton={this.stores.TrainingModuleStore.isFilePdf == true ? "Feltöltés" : null}
							content={
							<div style={{ padding: '20px 16px' }}>								
								<Label as="label" basic htmlFor="upload" style={{ padding: '20px 10px' }}>
								<Input hidden id={this.stores.TrainingModuleStore.uploadIdString} onChange={(event) => { this.stores.TrainingModuleStore.setFile(event) }} type="file" style={{ width: '684px' }} />
								</Label>
								{this.stores.TrainingModuleStore.isFilePdf == false && (
									<div style={{ color: 'red', fontWeight: 'bold', paddingTop: 15, paddingLeft: 5 }}>A kiválasztott fájl formátumának PDF-nek kell lennie!</div>
								)}
							</div>

							}>
						</Confirm>
							<Grid.Column width={10}>								
								<Grid>
									<Grid.Row>
                                        <Grid.Column width={8}>
											<div>
												<label>Modul megnevezése</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input fluid value={this.stores.TrainingModuleStore.trainingModuleItem?.Name}
												onChange={(event, data) => { this.stores.TrainingModuleStore.updateTrainingModule('Name', data.value) }}
												error={!!this.stores.TrainingModuleStore.getValidationsTrainingModule?.Name} />
										</Grid.Column>
										<Grid.Column width={8}>
											<div>
												<label>Modul kódja</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<div style={{display: 'flex'}}>												
												<Input value={this.stores.TrainingModuleStore.trainingModuleItem?.CodeByUser}  style={{width: 60}}
													onChange={(event, data) => { this.stores.TrainingModuleStore.updateTrainingModule('CodeByUser', data.value) }}
													error={!!this.stores.TrainingModuleStore.getValidationsTrainingModule?.CodeByUser || !!this.stores.TrainingModuleStore.getValidationsTrainingModule?.CodeByUserLength} />		
												<span style={{fontSize: '14px', paddingTop: '9px'}}>
													{this.stores.TrainingModuleStore.trainingModuleItem.CodeNumber?.toString()?.padStart(3, '0')}
												</span>																																	
											</div>											
										</Grid.Column>										
									</Grid.Row>																		
									<Grid.Row>
										<Grid.Column width={8}>
											<div>
												<label>Modulhoz kapcsolódó nyilatkozat</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>											
											<div className={!!this.stores.TrainingModuleStore.getValidationsTrainingModule?.Statement ? "divError" : null}>
											<Editor												
												editorState={this.stores.TrainingModuleStore.editorStateStatement}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={(editorState: any) => {
													this.stores.TrainingModuleStore.onEditorStateChangeStatement(editorState);
												}}
											/>
											</div>											
										</Grid.Column>
                                        <Grid.Column width={8}>
											<div>
												<label>Modul anyaga</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											{this.stores.TrainingModuleStore.document && (
												<a style={{ cursor: 'pointer' }} onClick={() => this.stores.TrainingModuleStore.GetTrainingModuleDocumentByObjectId(this.stores.TrainingModuleStore.document.Id)} download>{this.stores.TrainingModuleStore.document.FileName}</a>
											)}
											<div style={{ marginBottom: 20, marginLeft: 'auto' }}>
												<Button color="green" onClick={() => { this.stores.TrainingModuleStore.setIsUploadConfirmOpen() }}>Feltöltés</Button>
											</div>
										</Grid.Column>	                                        																				
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Inaktív?</label>
											</div>
											<Checkbox checked={this.stores.TrainingModuleStore.trainingModuleItem?.InActive === 1} value={this.stores.TrainingModuleStore.trainingModuleItem?.InActive === 0 ? 1 : 0}
                                  					onChange={(event, data) => {this.stores.TrainingModuleStore.updateTrainingModule('InActive', data.value)}}/>											
										</Grid.Column>
									</Grid.Row>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>				
				<Confirm open={this.stores.TrainingModuleStore.showValidationErrorPopup && this.stores.TrainingModuleStore.hasValidationErrors}	
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={() => {this.stores.TrainingModuleStore.cancelShowValidationErrorPopup();}}	
					cancelButton="Bezár"
					confirmButton={false}								
					content={
						<div style={{ padding: 40 }}>							
								<Message style={{maxHeight: 300, overflow: 'auto'}} negative>
									<Message.Header>Hiba a mentés közben!</Message.Header>
									<span>Validációs hibák:
										<ul style={{marginLeft: 18}}>{Object.values(this.stores.TrainingModuleStore.getValidationsTrainingModule).map((error) => {
											return <li key={error}>{error}</li>
											})}
										</ul>										
									</span>
								</Message>							
						</div>
					}
				></Confirm>	
				<Confirm open={this.stores.TrainingModuleStore.showInActivateForbiddenPopUp}	
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={() => {this.stores.TrainingModuleStore.cancelInActivateForbiddenPopUp();}}	
					cancelButton="Bezár"
					confirmButton={false}								
					content={
						<div style={{ padding: 40 }}>							
								<Message style={{maxHeight: 300, overflow: 'auto'}} negative>
									<Message.Header>Hiba a mentés közben!</Message.Header>									
									<span>
										Jövőbeni határidővel rendelkező modul nem inaktiválható!
									</span>
								</Message>							
						</div>
					}
				></Confirm>				
				<div style={{ marginTop: 20 }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={5} style={{ textAlign: 'left' }}>
								<Button onClick={() => { this.props.navigate('/administration/training-module') }}>Mégse</Button>
							</Grid.Column>
							{(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor) && (
								<Grid.Column width={5} style={{ textAlign: 'right' }}>								
									<Button color="green" onClick={() => { this.save() }}>Mentés</Button>
								</Grid.Column>
							)}							
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>
	}
	save() {
		this.stores.TrainingModuleStore.SaveTrainingModule(true);
	}
	componentDidMount(): void {
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.TrainingModuleStore.GetTrainingModuleById();
		} else {
			this.stores.TrainingModuleStore.trainingModuleItem = new TrainingModule();
			this.stores.TrainingModuleStore.setNewItem();
		}		
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(TrainingModuleDetailsPage))));


