import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Checkbox, Confirm, Dropdown, Grid, Input, Message } from 'semantic-ui-react';
import withNavigation from '../../../hocs/withNavigation';
import { WarrantyPlusStore } from './WarrantyPlusStore';
import { CustomHeader } from '../../../components/CustomHeader';
import withParams from '../../../hocs/withParams';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { RootStore } from '../../../stores/RootStore';
import { WarrantyPlusPackage } from '../../../models/warrantyPlusPackage';
import { AdminService } from '../../../services/admin.service';
import NumberFormat from 'react-number-format';

interface IStores {
	WarrantyPlusStore: WarrantyPlusStore
}

interface IWarrantyPlusDetailsPageProps {
	navigate?: NavigateFunction,
	params?: any
	RootStore?: RootStore
}

class WarrantyPlusDetailsPage extends React.Component<IWarrantyPlusDetailsPageProps> {

	stores: IStores
	AdminService: AdminService

	constructor(props: any) {
		super(props);

		this.stores = { WarrantyPlusStore: new WarrantyPlusStore(this.props.RootStore, this.props.params.id) }
		// if (this.props.params.id) {
		// 	this.stores.WarrantyPlusStore.GetWarrantyPlusPackegeById();
		// }

		this.AdminService = new AdminService()

	}	

	public render() {
		return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={10}>
								<div style={{ marginBottom: 20 }}>
									<CustomHeader>Garancia+ csomagajánlatok - {this.stores.WarrantyPlusStore.warrantyPlusItem.Name ? this.stores.WarrantyPlusStore.warrantyPlusItem.Name : 'Új csomag rögzítése'}</CustomHeader>
								</div>
								<Grid>
									<Grid.Row>
										<Grid.Column width={5}>
											<div>
												<label>Csomag cikkszáma</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input fluid type="number" value={this.stores.WarrantyPlusStore.warrantyPlusItem.PackageNumber} 
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('PackageNumber', data.value) }}
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.PackageNumber}
											/>
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Csomag típusa</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Dropdown
												disabled={true}
												selection
												fluid
												value={this.stores.WarrantyPlusStore.warrantyPlusItem.Type}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('Type', data.value) }}
												options={this.stores.WarrantyPlusStore.warrantyPlusPackageTypeOptions}></Dropdown>
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Csomag neve</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input fluid value={this.stores.WarrantyPlusStore.warrantyPlusItem.Name}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('Name', data.value) }}
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.Name} />
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5}>
											<div>
												<label>Támogatott készülékérték-tól</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.WarrantyPlusStore.warrantyPlusItem.ValueFrom}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('ValueFrom', data.value) }} 
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.ValueFrom}/> */}
											<NumberFormat
                                                value={this.stores.WarrantyPlusStore.warrantyPlusItem.ValueFrom}                                                
                                                fluid
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.WarrantyPlusStore.updateWarrantyItem('ValueFrom', data.value)}}                                                
                                                customInput={Input}
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.ValueFrom}/>												
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Támogatott készülékérték-ig</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.WarrantyPlusStore.warrantyPlusItem.ValueTo}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('ValueTo', data.value) }} 
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.ValueTo}/> */}
											<NumberFormat
                                                value={this.stores.WarrantyPlusStore.warrantyPlusItem.ValueTo}   
												fluid                                             
                                                thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.WarrantyPlusStore.updateWarrantyItem('ValueTo', data.value)}}                                                
                                                customInput={Input}
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.ValueTo}/>
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Csomag ára</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											{/* <Input fluid type="number" value={this.stores.WarrantyPlusStore.warrantyPlusItem.Price}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('Price', data.value) }} 
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.Price}/> */}
											<NumberFormat
                                                value={this.stores.WarrantyPlusStore.warrantyPlusItem.Price}                                                
                                                fluid
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.WarrantyPlusStore.updateWarrantyItem('Price', data.value)}}                                                
                                                customInput={Input}
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.Price}/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5}>
											<div>
												<label>Csomag kockázatviselési ideje (hónap)</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input fluid type="number" value={this.stores.WarrantyPlusStore.warrantyPlusItem.ExtendedWarrantyLengthMonth}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('ExtendedWarrantyLengthMonth', data.value) }} 
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.ExtendedWarrantyLengthMonth}/>
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Csomag hatályba lépése érvényességének kezdete</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<SemanticDatepicker firstDayOfWeek={1} value={this.stores.WarrantyPlusStore.warrantyPlusItem.ValidFrom ? new Date(this.stores.WarrantyPlusStore.warrantyPlusItem.ValidFrom) : null}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('ValidFrom', data.value) }} 
												error={!!this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage.ValidFrom}/>
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Csomag hatályba lépése érvényességének vége</label>
											</div>											
											<SemanticDatepicker firstDayOfWeek={1} value={this.stores.WarrantyPlusStore.warrantyPlusItem.ValidTo ? new Date(this.stores.WarrantyPlusStore.warrantyPlusItem.ValidTo) : null}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('ValidTo', data.value) }} />											
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={6}>
											<div>
												<label>A csomag kockázatviselési kezdete gyártói garanciát követően kezdődik?</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Dropdown style={{ width: '312px' }}
												selection
												value={this.stores.WarrantyPlusStore.warrantyPlusItem.ExtendedWarrantyStartsAfterManufacturersWarranty}
												onChange={(event, data) => { this.stores.WarrantyPlusStore.updateWarrantyItem('ExtendedWarrantyStartsAfterManufacturersWarranty', data.value) }}
												options={[
													{ value: true, text: 'Igen' },
													{ value: false, text: 'Nem' },
												]}></Dropdown>											
										</Grid.Column>
										<Grid.Column width={6}>
											<div>
												<label>Inaktív?</label>
											</div>
											<Checkbox checked={this.stores.WarrantyPlusStore.warrantyPlusItem.IsActive === 0} value={this.stores.WarrantyPlusStore.warrantyPlusItem.IsActive === 1 ? 0 : 1}
                                  					onChange={(event, data) => {this.stores.WarrantyPlusStore.updateWarrantyItem('IsActive', data.value)}}/>											
										</Grid.Column>										
									</Grid.Row>
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>				
				<Confirm open={this.stores.WarrantyPlusStore.showValidationErrorPopup && this.stores.WarrantyPlusStore.hasValidationErrors}	
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={() => {this.stores.WarrantyPlusStore.cancelShowValidationErrorPopup();}}	
					cancelButton="Bezár"
					confirmButton={false}								
					content={
						<div style={{ padding: 40 }}>							
								<Message style={{maxHeight: 300, overflow: 'auto'}} negative>
									<Message.Header>Hiba a mentés közben!</Message.Header>
									<span>Validációs hibák:
										<ul style={{marginLeft: 18}}>{Object.values(this.stores.WarrantyPlusStore.getValidationsWarrantyPlusPackage).map((error) => {
											return <li key={error}>{error}</li>
											})}
										</ul>										
									</span>
								</Message>							
						</div>
					}
				></Confirm>				
				<div style={{ marginTop: 20 }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={5} style={{ textAlign: 'left' }}>

								<Button onClick={() => { this.props.navigate('/administration/warranty-plus') }}>Mégse</Button>
							</Grid.Column>
							<Grid.Column width={5} style={{ textAlign: 'right' }}>

								<Button color="green" onClick={() => { this.save() }}>Mentés</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>
	}
	save() {
		this.stores.WarrantyPlusStore.SaveWarrantyPlusPackage();
		// this.props.navigate('/administration/warranty-plus')
	}
	componentDidMount(): void {
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.WarrantyPlusStore.GetWarrantyPlusPackegeById();
		} else {
			this.stores.WarrantyPlusStore.warrantyPlusItem = new WarrantyPlusPackage();
			this.stores.WarrantyPlusStore.warrantyPlusItem.Type=3;
		}
		this.stores.WarrantyPlusStore.getWarrantyPlusPackageTypes();
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(WarrantyPlusDetailsPage))));


