import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Grid, Input } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { ManufacturerWarrantyStore } from "./ManufacturerWarrantyStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import DeleteButton from "../../../components/DeleteButton";
import { AdminService } from "../../../services/admin.service";
import { flow, makeObservable, toJS } from "mobx";
import OpenItem from "../../../components/OpenItem";
import { RootStore } from "../../../stores/RootStore";
import NumberFormat from 'react-number-format';

interface IStores {
    ManufacturerWarrantyStore: ManufacturerWarrantyStore
}

interface IManufacturerWarrantyPageProps {
    navigate?: NavigateFunction;
    RootStore?:RootStore
}

class ManufacturerWarrantyPage extends React.Component<IManufacturerWarrantyPageProps> {
    private stores: IStores
    AdminService: AdminService;

    constructor(props: any){
        super(props);
        this.AdminService = new AdminService();

        this.stores = { ManufacturerWarrantyStore: new ManufacturerWarrantyStore(this.props.RootStore)}        
    }

    componentDidMount(): void {
        this.stores.ManufacturerWarrantyStore.getManufacturerWarranties()
    }

    

    public render() {
        return <div>
            <Provider {...this.stores}>
            <div style={{ margin: 20, width: '95%' }}>
                        <Confirm open={this.stores.ManufacturerWarrantyStore.isEditMode}	
                            closeOnEscape={false} //disables closing the window by pressing ESC	
                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                            
                            onCancel={() => {this.stores.ManufacturerWarrantyStore.setEditModeFalse();}}	
                            cancelButton="Mégse"
                            confirmButton={false}								
                            content={
                                <div style={{ padding: 30 }}>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                                        <div style={{marginRight: '70px'}}>
                                            <div>
                                                <label>Garancia időtartama (hónap)</label>
                                            </div>
                                            {/* <Input value={this.stores.ManufacturerWarrantyStore.temporaryWarrantyPeriodMonthNumber} 
                                                type="number" onChange={(event, data) => {this.stores.ManufacturerWarrantyStore.setWarrantyPeriodMonth(event, data)}}/> */}
                                            <NumberFormat
                                                value={this.stores.ManufacturerWarrantyStore.temporaryWarrantyPeriodMonthNumber}                                                                                                
												thousandSeparator=" "                                                                                                
                                                onValueChange={(data) => {this.stores.ManufacturerWarrantyStore.setWarrantyPeriodMonth(data)}}                                                
                                                customInput={Input}/>
                                        </div>
                                        <div>
                                            <div>
                                                <label>Maximális készülékár</label>
                                            </div>
                                            {/* <Input value={this.stores.ManufacturerWarrantyStore.temporaryMaxPriceNumber}
                                            type="number" onChange={(event, data) => {this.stores.ManufacturerWarrantyStore.setMaxPrice(event, data)}}/> */}
                                            <NumberFormat
                                                value={this.stores.ManufacturerWarrantyStore.temporaryMaxPriceNumber}                                                                                                
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {this.stores.ManufacturerWarrantyStore.setMaxPrice(data)}}                                                
                                                customInput={Input}/>
                                        </div>                                                                        
                                    </div>                                    
                                    <div style={{ textAlign: 'left' }}>
                                        <Button color="red" disabled={!this.stores.ManufacturerWarrantyStore.getSelectedItemAddable} onClick={() => {this.stores.ManufacturerWarrantyStore.saveManufacturerItem()}}>Mentés</Button>
                                    </div>
                                </div>
                            }
                        />
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Gyári garancia paraméterezése</CustomHeader>
                                    </div>								
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={10} style={{alignItems: 'center'}}>
                                                <Grid.Row centered>
                                                    <div id="grid" className="ag-theme-alpine" style={{width: '832px', height: '519px'}}>
                                                        <AgGridReact											
                                                            rowData={toJS(this.stores.ManufacturerWarrantyStore.manufacturerWarrantyItems)}											
                                                            columnDefs={this.stores.ManufacturerWarrantyStore.columnDef}
                                                            localeText={AgGridText}
                                                            frameworkComponents={{ btnCellRenderer: DeleteButton, 
                                                                                   btnCellRendererOpenItem: OpenItem }}
                                                            pagination={true}
                                                            paginationPageSize={10}
                                                            gridOptions={{
                                                                paginationNumberFormatter: (params:any) => {
                                                                return  params.value.toLocaleString() ;
                                                              },
                                                            }}>																				
                                                        </AgGridReact>
                                                    </div>     
                                                </Grid.Row>                                                
                                            </Grid.Column>                                        
                                        </Grid.Row>  
                                        {this.stores.ManufacturerWarrantyStore.isEditMode &&
                                        <Grid.Row style={{paddingBottom: 0, marginTop: 20}}>
                                        <Grid.Column width={10}>
                                            <Grid>
                                                <Grid.Row centered>
                                                    <div style={{marginLeft: '426px'}}>
                                                        <label>
                                                            Garancia időtartama (hónap)
                                                        </label>
                                                    </div>
                                                    <div style={{marginLeft: '54px'}}>
                                                        <label>
                                                            Maximális készülékár
                                                        </label>
                                                    </div>                                                        
                                                </Grid.Row>
                                            </Grid>                                                                                                
                                        </Grid.Column>
                                    </Grid.Row>}                                                                                
                                    <Grid.Row centered style={{paddingTop: 0}}>
                                        <Grid.Column width={10}>
                                            <Grid>
                                                <Grid.Row centered>
                                                    <div style={{paddingTop: 8}}>
                                                        <label>
                                                            Új garancia felvétele:
                                                        </label>
                                                    </div>
                                                    <Grid.Column style={{marginRight: 20}} width={4}>
                                                        {/* <Input fluid type="number" value={this.stores.ManufacturerWarrantyStore.warrantyPeriodMonth} 
                                                            onChange={(event, data) => {this.stores.ManufacturerWarrantyStore.setWarrantyPeriodMonth(event, data)}}>                                                                                                                            
                                                        </Input> */}
                                                        <NumberFormat
                                                            value={this.stores.ManufacturerWarrantyStore.warrantyPeriodMonth}                                                                                                
                                                            thousandSeparator=" "                                                                                                            
                                                            onValueChange={(data) => {this.stores.ManufacturerWarrantyStore.setWarrantyPeriodMonth(data)}}                                                
                                                            customInput={Input}/>
                                                    </Grid.Column>
                                                    <Grid.Column width={4}>
                                                        {/* <Input fluid type="number" value={this.stores.ManufacturerWarrantyStore.maxPrice}
                                                            onChange={(event, data) => {this.stores.ManufacturerWarrantyStore.setMaxPrice(event, data)}}>                                                                
                                                        </Input> */}
                                                        <NumberFormat
                                                            value={this.stores.ManufacturerWarrantyStore.maxPrice}                                                                                                
                                                            thousandSeparator=" "                                                
                                                            suffix=" Ft" 
                                                            onValueChange={(data) => {this.stores.ManufacturerWarrantyStore.setMaxPrice(data)}}                                                
                                                            customInput={Input}/>
                                                    </Grid.Column>
                                                    <Grid.Column width={3}>
                                                        <Button color="red" onClick={() => {this.stores.ManufacturerWarrantyStore.saveManufacturerItem()}}
                                                        disabled={!this.stores.ManufacturerWarrantyStore.getItemAddable}>Hozzáadás</Button>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>                                                                                                
                                        </Grid.Column>
                                    </Grid.Row>                                                           
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(ManufacturerWarrantyPage)));