import { RateCommission } from "../../../models/rateCommission";
import { AdminService } from "../../../services/admin.service"
import { RootStore } from "../../../stores/RootStore"
import { action, flow, makeObservable, observable } from "mobx";
import { RiportService } from "../../../services/riport.service";

export class RateCommissionStore {
    private RootStore:RootStore;
    AdminService: AdminService;
    RiportService: RiportService;
    rateCommissionItem: RateCommission = new RateCommission();
    previousLnumber: number = 1;

    constructor(rootStore:RootStore) {
        this.RootStore= rootStore;
        makeObservable(this, {
            rateCommissionItem: observable,
            previousLnumber: observable,
            setRateCommissionItemLnumber: action,
            saveRateCommission: flow,
            getLastRateCommission: flow,
            generateRateCommission: flow,
        })

        this.AdminService = new AdminService();
        this.RiportService = new RiportService();
    }

    setRateCommissionItemLnumber = (number: number) => {
        this.rateCommissionItem.Lnumber = number
    }

    *saveRateCommission() {
        this.RootStore.addloading('getLastRateCommission')
        yield this.AdminService.SaveRateCommission(this.rateCommissionItem)        
        this.RootStore.removeLoading('getLastRateCommission')
        console.log('this.rateCommissionItem', this.rateCommissionItem);
        this.getLastRateCommission();
    }

    *getLastRateCommission() {
        this.RootStore.addloading('getLastRateCommission')
        this.rateCommissionItem = yield this.AdminService.GetLastRateCommission()
        this.previousLnumber = this.rateCommissionItem.Lnumber;
        this.RootStore.removeLoading('getLastRateCommission')
    }

    *generateRateCommission() {
        yield this.RiportService.GenerateRateCommission();
    }

}