import { OfferStore } from '../offer/OfferStore';
import { RootStore } from '../../stores/RootStore';
import { NavigateFunction } from 'react-router-dom';
import React, { Suspense } from 'react';
import { flow, makeAutoObservable, makeObservable } from 'mobx';
import withNavigation from '../../hocs/withNavigation';
import withParams from '../../hocs/withParams';
import { inject, observer, Provider } from 'mobx-react';
import { OfferService } from '../../services/offer.service';
import PhoneTable from '../../components/PhoneTable';
import { Grid, Table } from 'semantic-ui-react';
import { CustomTableHeader } from '../../components/CustomTableHeader';
import PhoneTableSummarizer from '../../components/PhoneTableSummarizer';

interface IStores {
	OfferStore: OfferStore;
}

interface IProps {
	RootStore?: RootStore;
	OfferStore?: OfferStore;
	navigate?: NavigateFunction;
	params?: any;
}

class PrintablePackagePage extends React.Component<IProps> {
    private stores: IStores;
	OfferService: OfferService
	
    constructor(props: any) {
        super(props);
		this.OfferService = new OfferService();

        this.stores = { OfferStore: new OfferStore(this.props.navigate, this.props.RootStore, this.props.params.id) };

        makeObservable(this, {
            getOfferById: flow
        })        
    }

    componentDidMount(): void {
        this.getOfferById();
        this.stores.OfferStore.getTariffPackages();
		this.stores.OfferStore.getWarrantyPlusPackeges();                
    }

    *getOfferById() {
        this.stores.OfferStore.offer = yield this.OfferService.GetOfferById(this.props.params.id);        
        setTimeout(() => {window.print()}, 1000)
    }

    public render() {
        return <div style={{marginLeft: 15}}>
            <Provider {...this.stores}>                               
                <div style={{display: 'flex', minHeight: 1370}}>
                    <div style={{marginRight: 20}}>
                        {this.stores.OfferStore.offer.Packages[0] && (
                            <PhoneTable packageItem={this.stores.OfferStore.offer.Packages[0]} />
                        )}
                    </div>
                    <div>
                        {this.stores.OfferStore.offer.Packages[1] && (
                            <PhoneTable packageItem={this.stores.OfferStore.offer.Packages[1]} />
                        )}
                        {this.stores.OfferStore.offer.Packages[0] && !this.stores.OfferStore.offer.Packages[1] && this.stores.OfferStore.offer.IsLoanDemand && (
                            <PhoneTableSummarizer OfferStore={this.stores.OfferStore}></PhoneTableSummarizer>	
                        )}
                    </div>
                </div>
                {/* {this.stores.OfferStore.offer.Packages.length > 2 || (this.stores.OfferStore.offer.Packages.length === 2 && this.stores.OfferStore.offer.IsLoanDemand) && ( */}
                    <div style={{display: this.stores.OfferStore.offer.Packages.length > 2 || (this.stores.OfferStore.offer.Packages.length === 2 && this.stores.OfferStore.offer.IsLoanDemand) ? 'flex' : 'none', minHeight: 1370}}>
                        <div style={{marginRight: 20}}>
                            {this.stores.OfferStore.offer.Packages[2] && (
                                <PhoneTable packageItem={this.stores.OfferStore.offer.Packages[2]} />
                            )}
                            {this.stores.OfferStore.offer.Packages[1] && !this.stores.OfferStore.offer.Packages[2] && this.stores.OfferStore.offer.IsLoanDemand && (
                                <PhoneTableSummarizer OfferStore={this.stores.OfferStore}></PhoneTableSummarizer>	
                            )}
                        </div>
                        <div>
                            {this.stores.OfferStore.offer.Packages[3] && (
                                <PhoneTable packageItem={this.stores.OfferStore.offer.Packages[3]} />
                            )}
                            {this.stores.OfferStore.offer.Packages[2] && !this.stores.OfferStore.offer.Packages[3] && this.stores.OfferStore.offer.IsLoanDemand && (
                                <PhoneTableSummarizer OfferStore={this.stores.OfferStore}></PhoneTableSummarizer>	
                            )}
                        </div>
                    </div>
                {/* )} */}
                {/* {this.stores.OfferStore.offer.Packages.length > 4 || (this.stores.OfferStore.offer.Packages.length === 4 && this.stores.OfferStore.offer.IsLoanDemand) && ( */}
                    <div style={{display: this.stores.OfferStore.offer.Packages.length > 4 || (this.stores.OfferStore.offer.Packages.length === 4 && this.stores.OfferStore.offer.IsLoanDemand) ?'flex' : 'none', minHeight: 1370}}>
                        <div style={{marginRight: 20}}>
                            {this.stores.OfferStore.offer.Packages[4] && (
                                <PhoneTable packageItem={this.stores.OfferStore.offer.Packages[4]} />
                            )}
                            {this.stores.OfferStore.offer.Packages[3] && !this.stores.OfferStore.offer.Packages[4] && this.stores.OfferStore.offer.IsLoanDemand && (
                                <PhoneTableSummarizer OfferStore={this.stores.OfferStore}></PhoneTableSummarizer>	
                            )}
                        </div>
                        <div>
                            {this.stores.OfferStore.offer.Packages[5] && (
                                <PhoneTable packageItem={this.stores.OfferStore.offer.Packages[5]} />
                            )}
                            {this.stores.OfferStore.offer.Packages[1] && !this.stores.OfferStore.offer.Packages[5] && this.stores.OfferStore.offer.IsLoanDemand && (
                                <PhoneTableSummarizer OfferStore={this.stores.OfferStore}></PhoneTableSummarizer>	
                            )}
                        </div>
                    </div>  
                {/* )} */}
                {/* {this.stores.OfferStore.offer.Packages.length === 6 && this.stores.OfferStore.offer.IsLoanDemand && ( */}
                    <div>
                        {this.stores.OfferStore.offer.Packages[5] && this.stores.OfferStore.offer.IsLoanDemand && (
                            <PhoneTableSummarizer OfferStore={this.stores.OfferStore}></PhoneTableSummarizer>	
                        )}
                    </div>              
                {/* )}                 */}
            </Provider>            
        </div>
    }
}

export default withParams(withNavigation(inject('RootStore')(observer(PrintablePackagePage))));