import { inject, observer } from "mobx-react";
import React from "react";
import { Step, Image, Icon } from "semantic-ui-react";
import { OfferStore } from "../pages/offer/OfferStore";

interface IProps {
  OfferStore?: OfferStore;
}

class LayoutStep extends React.Component<IProps> {
  public render() {
    const { OfferStore } = this.props;

    return (
      <React.Fragment>
        {this.props.OfferStore.isBanner && (
          <div>
            <div style={{ display: 'flex'}}>
              {this.props.OfferStore.showBanner == true && (    
                <div>
                  {this.props.OfferStore?.bannerByteContent1 && (
                    <div style={{ maxWidth: '1360px',}}>
                      <Image src={`data:image/jpg;base64,${this.props.OfferStore?.bannerByteContent1}`}/>
                    </div>
                  )}
                  {this.props.OfferStore?.bannerByteContent2 && (
                    <div style={{ maxWidth: '1360px',}}>
                      <Image src={`data:image/jpg;base64,${this.props.OfferStore?.bannerByteContent2}`}/>
                    </div>
                  )}
                  {this.props.OfferStore?.bannerByteContent3 && (
                    <div style={{ maxWidth: '1360px',}}>
                      <Image src={`data:image/jpg;base64,${this.props.OfferStore?.bannerByteContent3}`}/>
                    </div>
                  )}
                  {this.props.OfferStore?.bannerByteContent4 && (
                    <div style={{ maxWidth: '1360px',}}>
                      <Image src={`data:image/jpg;base64,${this.props.OfferStore?.bannerByteContent4}`}/>
                    </div>
                  )}
                </div>                            
              )}                            
              {this.props.OfferStore.showBanner == true && (
                <div style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', cursor: 'pointer'}}>
                  <Icon name="chevron circle up" size="large" color="green" onClick={() => {this.props.OfferStore?.setShowBanner()}}></Icon>
                </div>
              )}
              {!this.props.OfferStore.showBanner && (
                <div style={{marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', cursor: 'pointer'}}>
                  <Icon name="chevron circle down" size="large" color="green" onClick={() => {this.props.OfferStore?.setShowBanner()}}></Icon>
                </div>
              )}              
            </div>	
          </div>
        )}        
        <Step.Group widths={3} ordered size="mini" className="custom-step">
          <Step
            completed
            active={OfferStore.activeTab === "keszulek"}
            onClick={() => {
              OfferStore.handleActiveTab("keszulek");
            }}
          >
            <Step.Content>
              <Step.Title>Csomagösszeállítás</Step.Title>
              {/*<Step.Description>Choose your shipping options</Step.Description>*/}
            </Step.Content>
          </Step>

          <Step
            active={OfferStore.activeTab === "ugyfel"}
            disabled={(OfferStore.RootStore.currentUser.VodafoneBO || OfferStore.RootStore.currentUser.VodafoneSupervisor) && OfferStore.offer.VodafoneStatus<2200 }
            onClick={() => {
              OfferStore.handleActiveTab("ugyfel");
            }}
          >
            <Step.Content>
              <Step.Title>Ügyfél adatai</Step.Title>
              {/*<Step.Description>Enter billing information</Step.Description>*/}
            </Step.Content>
          </Step>
        </Step.Group>
      </React.Fragment>      
    );
  }
}

export default inject("OfferStore")(observer(LayoutStep));
