import { action, flow, makeAutoObservable, computed, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";
import { TrainingModule } from "../../../models/trainingModel";
import { TrainingModuleDocument} from "../../../models/trainingModuleDocument";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { NavigateFunction } from "react-router-dom";

export class TrainingModuleStore {

    private RootStrore:RootStore
    trainingModuleItems: Array<TrainingModule>;
    columnDef: Array<ColDef>;
    trainingModuleId: number = 0;
    trainingModuleItem: TrainingModule = new TrainingModule();
    AdminService:AdminService
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    editorStateStatement = EditorState.createEmpty();
    isUploadConfirmOpen: boolean = false;
    uploadIdString: string = "uploadId";
	file: any;
    isFilePdf: boolean;
    document: TrainingModuleDocument = new TrainingModuleDocument();
    navigate?: NavigateFunction;
    showInActivateForbiddenPopUp: boolean = false;
    constructor(rootStrore:RootStore, navigate?: NavigateFunction,trainingModuleId?: number) {
        this.RootStrore= rootStrore;
        this.navigate = navigate
        makeAutoObservable(this, {
            trainingModuleItems: observable,
            columnDef: observable,
            trainingModuleId: observable,
            trainingModuleItem: observable,
            isSaved: observable,
            showValidationErrorPopup: observable,
            editorStateStatement: observable,
            isUploadConfirmOpen: observable,
            uploadIdString: observable,
            file: observable,
            isFilePdf: observable,
            document: observable,
            showInActivateForbiddenPopUp: observable,
            updateTrainingModule: action,
            onEditorStateChangeStatement: action,
            setIsUploadConfirmOpen: action,
            cancelUpload: action,
            navigateToTrainingModule: action,
            setNewItem: flow,
            SaveTrainingModule:flow,
            GetTrainingModuleById:flow,
            confirmUpload: flow,
            GetDocumentsOfObject: flow,
            GetTrainingModuleDocumentByObjectId: flow,
            getValidationsTrainingModule: computed
        })
        this.trainingModuleId = trainingModuleId;
        
        this.AdminService=new AdminService();
       
        this.columnDef = [
            {
                field: 'edit', headerName: 'Műveletek', maxWidth: 150,
                cellRenderer: 'btnCellRendererOpenItem',
                cellRendererParams: {
                    clicked: (data: any) => { this.navigateToTrainingModule(data.Id); },
                }
            },
            { field: 'Name', headerName: 'Modul megnevezése', filter: 'agSetColumnFilter', sortable: true, maxWidth: 550, minWidth: 550, },
            { field: 'Code', headerName: 'Modul kódja', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
            { field: 'CreatedDateString', headerName: 'Létrehozás dátuma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },            
        ]
    }

    numberFormatter = (params: any) => {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
    }

    navigateToTrainingModule = (trainingModuleId: number) => {
        this.navigate('/administration/training-module-details/' + trainingModuleId);
    }

    updateTrainingModule(key: keyof TrainingModule, value: any) {                
        if (key === 'InActive') {
			if (this.trainingModuleItem[key] === 1) {
				this.trainingModuleItem[key] = 0
			}	
			else {
				this.trainingModuleItem[key] = 1;
			}
		}
		else {
			if (key === 'Name' && value.toString().length > 200) {
                this.trainingModuleItem.Name = value.toString().slice(0, 200)                
            }
            else if (key === 'CodeByUser') {
                if (this.trainingModuleItem[key] == null) {
                    this.trainingModuleItem[key] = "";
                }
                var regexp = new RegExp('^[A-Z\/]*$'); ////regexp
                var regExpRes = regexp.test(value);
                if (regExpRes) {
                    this.trainingModuleItem[key] = value;
                    if (value.toString().length > 2) {
                        this.trainingModuleItem[key] = value.toString().slice(0, 2)
                    }
                }
            }
            else {
                (this.trainingModuleItem[key] as any) = value;
            }
		}	
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }

    cancelInActivateForbiddenPopUp = () => {
        this.showInActivateForbiddenPopUp = false;
    }

    onEditorStateChangeStatement(editorState: any) {
        this.editorStateStatement = editorState;
        let cicc2 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.updateTrainingModule('Statement', cicc2)
    };

    setIsUploadConfirmOpen = () => {
		this.isUploadConfirmOpen = true;
	}

    cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		this.isUploadConfirmOpen = false;
	}

    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
		console.log('Kiválasztott file:', this.file)
		if (this.file.name.endsWith(".pdf")) {
			console.log("pdf file")
			this.isFilePdf = true;
		}
		if (!this.file.name.endsWith(".pdf")) {
			console.log("not pdf file")
			this.isFilePdf = false;
		}
	}

    *setNewItem() {
        this.trainingModuleItem.IsActualSave = false;
        this.trainingModuleItem = yield this.AdminService.SaveTrainingModule(this.trainingModuleItem);
        console.log('this.trainingModuleItemthis.trainingModuleItemthis.trainingModuleItem', this.trainingModuleItem);
    }
   
    *SaveTrainingModule(isActualSave: boolean) {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (!this.hasValidationErrors) {
            this.RootStrore.addloading('SaveTrainingModule');   
            if (isActualSave) {
                this.trainingModuleItem.IsActualSave = true;                
            }   
            else {
                this.trainingModuleItem.IsActualSave = false;
            } 
		    this.trainingModuleItem = yield this.AdminService.SaveTrainingModule(this.trainingModuleItem);
            if (this.trainingModuleItem.Id == -1) {
                this.showInActivateForbiddenPopUp = true;
            }
            else {
                this.trainingModuleId = this.trainingModuleItem.Id;                  
                this.navigate('/administration/training-module');
            }            
            this.RootStrore.removeLoading('SaveTrainingModule');     
        }        		
	}
    *GetTrainingModuleById(){
        this.RootStrore.addloading('GetTrainingModuleById');
        this.trainingModuleItem = yield this.AdminService.GetTrainingModuleById(this.trainingModuleId);

        const blocksFromHtml = htmlToDraft(this.trainingModuleItem.Statement);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.onEditorStateChangeStatement(editorState);
        this.GetDocumentsOfObject(this.trainingModuleId);
        this.RootStrore.removeLoading('GetTrainingModuleById');
    }

    *confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        this.isUploadConfirmOpen = false;
        let formData: FormData = new FormData();
        this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        yield this.AdminService.UploadTrainingModuleDocument(formData, this.trainingModuleItem.Id);        
        this.GetDocumentsOfObject(this.trainingModuleItem.Id);
        console.log(this.file)
    }

    *GetDocumentsOfObject(id: number) {
		this.document = yield this.AdminService.GetDocumentOfTrainingModule(id);		
        console.log('this.documentthis.documentthis.documentthis.document', this.document)
	}

    *GetTrainingModuleDocumentByObjectId(id: number) {
		this.AdminService.GetTrainingModuleDocumentByObjectId(id);
	}

    get getValidationsTrainingModule() {
        if (!this.isSaved) {
            return {}
        }

        let validationErrors: Partial<Record<keyof any, string>> = {}

        if (!this.trainingModuleItem?.Name) {
            validationErrors["Name"] = "Modul megnevezés kitöltése kötelező!"
        }

        if (!this.trainingModuleItem?.CodeByUser) {
            validationErrors["CodeByUser"] = "Modul kódja kitöltése kötelező!"
        }

        if (this.trainingModuleItem?.CodeByUser && this.trainingModuleItem?.CodeByUser.length == 1) {
            validationErrors["CodeByUserLength"] = "Modul kódja pontosan két karakter kell legyen!"
        }

        if (!this.trainingModuleItem.Statement || this.trainingModuleItem.Statement === "<p></p>\n") {
            validationErrors["Statement"] = "Modulhoz kapcsolódó nyilatkozat kitöltése kötelező!"
        }

        if (!this.document || (this.document && this.document.Content == null)) {
            validationErrors["Document"] = "Modul anyagának feltöltése kötelező!"
        }       

        // console.log(validationErrors)
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsTrainingModule).length === 0) {
            return false;
        }

        return true;
    }
}