import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Checkbox, Confirm, Dropdown, Grid, Input, Message } from 'semantic-ui-react';
import withNavigation from '../../../hocs/withNavigation';
import { CustomHeader } from '../../../components/CustomHeader';
import withParams from '../../../hocs/withParams';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { RootStore } from '../../../stores/RootStore';
import { WarrantyPlusPackage } from '../../../models/warrantyPlusPackage';
import { AdminService } from '../../../services/admin.service';
import NumberFormat from 'react-number-format';
import { InformationObligationStore } from './InformationObligationStore';
import { InformationObligation } from '../../../models/informationObligation';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../../../index.css'

interface IStores {
	InformationObligationStore: InformationObligationStore
}

interface IInformationObligationDetailsPageProps {
	navigate?: NavigateFunction,
	params?: any
	RootStore?: RootStore
}

class InformationObligationDetailsPage extends React.Component<IInformationObligationDetailsPageProps> {

	stores: IStores
	AdminService: AdminService

	constructor(props: any) {
		super(props);

		this.stores = { InformationObligationStore: new InformationObligationStore(this.props.RootStore, this.props.navigate, this.props.params.id) }
		// if (this.props.params.id) {
		// 	this.stores.WarrantyPlusStore.GetWarrantyPlusPackegeById();
		// }

		this.AdminService = new AdminService()

	}	

	public render() {
		return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={10}>
								{/* <div style={{ marginBottom: 20 }}>
									<CustomHeader>Tájékoztatási kötelezettség - {this.stores.InformationObligationStore.informationObligationItem.infor ? this.stores.InformationObligationStore.informationObligationItem.Name : 'Új csomag rögzítése'}</CustomHeader>
								</div> */}
								<Grid>
									<Grid.Row>
                                        <Grid.Column width={8}>
											<div>
												<label>Tájékoztatás típusa</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Input fluid value={this.stores.InformationObligationStore.informationObligationItem.InformationType}
												onChange={(event, data) => { this.stores.InformationObligationStore.updateInformationObligation('InformationType', data.value) }}
												error={!!this.stores.InformationObligationStore.getValidationsInformationObligation.InformationType} />
										</Grid.Column>
										<Grid.Column width={8}>
											<div>
												<label>Tájékoztatás leírása</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>											
											<div className={!!this.stores.InformationObligationStore.getValidationsInformationObligation.InformationDescription ? "divError" : null}>
											<Editor												
												editorState={this.stores.InformationObligationStore.editorStateInformationDescription}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={(editorState: any) => {
													this.stores.InformationObligationStore.onEditorStateChangeInformationDescription(editorState);
												}}
											/>

											</div>											
										</Grid.Column>
									</Grid.Row>			
                                    <Grid.Row>
                                    <Grid.Column width={8}>
											<div>
												<label>Tájékoztatási kötelezettség MM oldalon</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>											
											<div className={!!this.stores.InformationObligationStore.getValidationsInformationObligation.InformationObligationMM ? "divError" : null}>
											<Editor												
												editorState={this.stores.InformationObligationStore.editorStateInformationObligationMM}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={(editorState: any) => {
													this.stores.InformationObligationStore.onEditorStateChangeInformationObligationMM(editorState);
												}}
											/>

											</div>											
										</Grid.Column>
										<Grid.Column width={8}>
											<div>
												<label>Teendők MM oldalon</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>											
											<div className={!!this.stores.InformationObligationStore.getValidationsInformationObligation.TodosMM ? "divError" : null}>
											<Editor												
												editorState={this.stores.InformationObligationStore.editorStateTodosMM}
												toolbarClassName="toolbarClassName"
												wrapperClassName="wrapperClassName"
												editorClassName="editorClassName"
												onEditorStateChange={(editorState: any) => {
													this.stores.InformationObligationStore.onEditorStateChangeTodosMM(editorState);
												}}
											/>

											</div>											
										</Grid.Column>
									</Grid.Row>	
                                    {this.stores.InformationObligationStore.informationObligationItem.Id != 0 && (
                                        <Grid.Row>										
                                            <Grid.Column width={6}>
                                                <div>
                                                    <label>Inaktív?</label>
                                                </div>
                                                <Checkbox checked={this.stores.InformationObligationStore.informationObligationItem.InActive === 1} value={this.stores.InformationObligationStore.informationObligationItem.InActive === 0 ? 0 : 1}
                                                        onChange={(event, data) => {this.stores.InformationObligationStore.updateInformationObligation('InActive', data.value)}}/>											
                                            </Grid.Column>										
                                        </Grid.Row>
                                    )}																										
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>				
				<Confirm open={this.stores.InformationObligationStore.showValidationErrorPopup && this.stores.InformationObligationStore.hasValidationErrors}	
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={() => {this.stores.InformationObligationStore.cancelShowValidationErrorPopup();}}	
					cancelButton="Bezár"
					confirmButton={false}								
					content={
						<div style={{ padding: 40 }}>							
								<Message style={{maxHeight: 300, overflow: 'auto'}} negative>
									<Message.Header>Hiba a mentés közben!</Message.Header>
									<span>Validációs hibák:
										<ul style={{marginLeft: 18}}>{Object.values(this.stores.InformationObligationStore.getValidationsInformationObligation).map((error) => {
											return <li key={error}>{error}</li>
											})}
										</ul>										
									</span>
								</Message>							
						</div>
					}
				></Confirm>				
				<div style={{ marginTop: 20 }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={5} style={{ textAlign: 'left' }}>

								<Button onClick={() => { this.props.navigate('/administration/information-obligation') }}>Mégse</Button>
							</Grid.Column>
							<Grid.Column width={5} style={{ textAlign: 'right' }}>

								<Button color="green" onClick={() => { this.save() }}>Mentés</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>
	}
	save() {
		this.stores.InformationObligationStore.SaveInformationObligation();
		// this.props.navigate('/administration/warranty-plus')
	}
	componentDidMount(): void {
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.InformationObligationStore.GetInformationObligationById();
		} else {
			this.stores.InformationObligationStore.informationObligationItem = new InformationObligation();
		}
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(InformationObligationDetailsPage))));


