import { Label, Table } from 'semantic-ui-react';

export const CustomTableHeader2 = (props: any) => (
	<Table.Header fullwidth="true">
		<Table.Row active>
			<Table.HeaderCell className="c-table-header" colSpan="3">
				{props.title ? (
					<Label color="blue" ribbon>
						<div style={{ width: 170 }}>{props.title}</div>
					</Label>
				) : (
					<div style={{ height: 25.969 }}></div>
				)}
			</Table.HeaderCell>
		</Table.Row>
	</Table.Header>
);
