import { inject, observer } from 'mobx-react';
import React from 'react';
import { Grid, Icon, Image, Table } from 'semantic-ui-react';
import { OfferPackage } from '../models/offerPackage';
import { OfferStore } from '../pages/offer/OfferStore';
import { CustomTableHeader } from './CustomTableHeader';
import { CustomTableHeader2 } from './CustomTableHeader2';

interface IProps {
    OfferStore?: OfferStore
    packageItem?: OfferPackage
}

class PhoneTableSummarizer extends React.Component<IProps> {

    public render() {
        const { OfferStore } = this.props

        return <div style={{ marginLeft: 24 }}>
            <div style={{ width: '350px' }}>
                <Table basic color="red" celled={true} compact>
                    <Table.Header fullwidth="true">
                        <Table.Row verticalalign="top">
                            <Table.HeaderCell colSpan="3" textAlign='center'>Részletfizetés havi összeg - önerő nélkül</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    {/** ------ Tarifacsomag ------ */}
                    <React.Fragment>
                        <CustomTableHeader title="Tarifacsomag" />
                        <Table.Body>
                            <Table.Row textAlign="right" style={{ height: "54px" }}>
                                <Table.HeaderCell className="bold" width={8}>
                                    <div style={{ textAlign: "left", paddingTop: "10px" }}>
                                        {/* Tarifacsomag havidíja
                                <br />
                                Az első 12 hónapban:<br />13. hónaptól kezdve:	 */}
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell className="bold">
                                    <div style={{ verticalAlign: "middle", paddingTop: "10px" }}>
                                        {/* {OfferStore?.allPackageTariffpackagePrice} */}
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row warning style={{ height: "55px" }}>
                                <Table.HeaderCell className="bold" width={8}>
                                    <div style={{ textAlign: "left", paddingTop: "10px" }}>
                                        {/* Tarifacsomag havidíja
                                <br />
                                Az első 12 hónapban:<br />13. hónaptól kezdve:	 */}
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell className="bold" width={8}>
                                    <div style={{ textAlign: "left", paddingTop: "10px" }}>
                                        {/* Tarifacsomag havidíja
                                <br />
                                Az első 12 hónapban:<br />13. hónaptól kezdve:	 */}
                                    </div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    </React.Fragment>
                    {/** ------ Egyéb díjak ------ */}
                    <React.Fragment>
                        <CustomTableHeader2 title="Egyéb díjak" />
                        <Table.Body className="c-table-footer" fullwidth="true">
                            <Table.Row verticalalign="top" style={{ height: "54px" }}>
                                <Table.HeaderCell className="bold" width={9}>
                                    <div style={{ textAlign: "left", paddingTop: "10px" }}>
                                        Adminisztrációs díj havi részlet:
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell className="bold" textAlign="right">
                                    <div style={{ verticalAlign: "middle", paddingTop: "10px" }}> 22 × {OfferStore.allPackageAdministrationFeeSum}/hó</div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    </React.Fragment>
                    {/** ------ Készülék ------ */}
                    <React.Fragment>
                        <CustomTableHeader2 title="Készülék" />
                        <Table.Body verticalalign="top">
                            <Table.Row style={{ height: "79px" }}>
                                <Table.Cell colSpan="3">
                                    <Grid columns={2}>
                                        <Grid.Row>
                                            <Grid.Column width={2}>
                                                <div ></div>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row textAlign="right">
                                <Table.Cell className="no-border-left" colSpan="3">
                                    <div style={{ height: 19.984 }}>
                                        {OfferStore.allPackageDevicePriceSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft'}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row warning textAlign="right">
                                <Table.Cell className="no-border-left" colSpan="3">
                                    <div style={{ height: 19.984 }}>
                                        -  {OfferStore.allPackageDeviceDiscountSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft'}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row warning textAlign="right"  >
                                <Table.Cell className="no-border-left" colSpan="3">
                                    <div style={{ height: 35.984 }}>
                                        - {OfferStore.allPackageAdministrationFeeForDeviceSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft'}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        <Table.Body className="c-table-footer" fullwidth="true">
                            <Table.Row textAlign="right">
                                <Table.HeaderCell className="bold" width={9}>
                                    <div style={{ textAlign: "left" }}>
                                        Készülék havi részlet:
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell className="bold">
                                    <div style={{ height: 39.969, verticalAlign: "middle", paddingTop: "10px" }}> 22 × {OfferStore.deviceInstallment}/hó</div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    </React.Fragment>

                    {/** ------ Kiegészítők ------ */}
                    {OfferStore.accessoriesSum > 0 && <React.Fragment>
                        <CustomTableHeader2 title="Kiegészítők" />
                        <Table.Body verticalalign="top">
                            <Table.Row>
                                <Table.Cell textAlign="right" colSpan="3">
                                    <div style={{ height: 60.953 }}>{OfferStore.accessoriesSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft'}</div>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        <Table.Body className="c-table-footer" fullwidth="true">
                            <Table.Row textAlign="right" >
                                <Table.HeaderCell className="bold" width={9}>
                                    <div style={{ textAlign: "left" }}>
                                        Kieg. havi részlet
                                    </div>
                                </Table.HeaderCell>
                                <Table.HeaderCell className="bold">
                                    <div style={{ verticalAlign: "middle" }}> 22 × {OfferStore.accessoriesSumMonthly}/hó</div>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Body>
                    </React.Fragment>
                    }
                    {/** ------ Garancia kiterjesztés ------ */}
                    <React.Fragment>
                        <CustomTableHeader title="Garanciakiterjesztés" />
                        <Table.Body>
                            {/* <Table.Row textAlign="right" style={{ height: "54px" }}>
                            <Table.HeaderCell className="bold" width={8}>
                                <div style={{ textAlign: "left", paddingTop: "10px" }}> */}
                            {/* Tarifacsomag havidíja
                                <br />
                                Az első 12 hónapban:<br />13. hónaptól kezdve:	 */}
                            {/* </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className="bold">
                                <div style={{ verticalAlign: "middle", paddingTop: "10px" }}> */}
                            {/* {OfferStore?.allPackageTariffpackagePrice} */}
                            {/* </div>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row warning style={{ height: "55px" }}>
                            <Table.HeaderCell className="bold" width={8}>
                                <div style={{ textAlign: "left", paddingTop: "10px" }}> */}
                            {/* Tarifacsomag havidíja
                                <br />
                                Az első 12 hónapban:<br />13. hónaptól kezdve:	 */}
                            {/* </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell className="bold" width={8}>
                                <div style={{ textAlign: "left", paddingTop: "10px" }}> */}
                            {/* Tarifacsomag havidíja
                                <br />
                                Az első 12 hónapban:<br />13. hónaptól kezdve:	 */}
                            {/* </div>
                            </Table.HeaderCell>
                        </Table.Row> */}
                        </Table.Body>
                        {/* <Table.Body verticalalign="top"> */}
                        {/* <Table.Row>
                            <Table.Cell textAlign="right" colSpan="3"> */}
                        {/* <div style={{ height: 60.953 }}>{OfferStore.allPackageWarrantyAndScreenProtectorSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft'}</div> */}
                        {/* </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell textAlign="right" colSpan="3"> */}
                        {/* <div style={{ height: 40.969 }}>635 Ft/hó</div> */}
                        {/* </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                    <Table.Body className="c-table-footer" fullwidth="true">
                        <Table.Row textAlign="right">
                            <Table.HeaderCell className="bold" width={9}> */}
                        {/* <div style={{ textAlign: "left" }}>
                                    Garancia havidíja összesen
                                </div> */}
                        {/* </Table.HeaderCell>
                            <Table.HeaderCell className="bold"> */}
                        {/* <div style={{ verticalAlign: "middle" }}> 22 × {OfferStore.allPackageWarrantyAndScreenProtectorSumMonthly}</div> */}
                        {/* </Table.HeaderCell>
                        </Table.Row> */}
                        {/* </Table.Body> */}
                    </React.Fragment>
                </Table>
            </div>
        </div>
    }
}

export default inject("OfferStore")(observer(PhoneTableSummarizer))