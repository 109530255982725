import { flow, makeObservable } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Dropdown, Grid, Icon, Input, Message, Popup } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { UserService } from "../../../services/user.service";
import { RootStore } from "../../../stores/RootStore";
import { UsermanagementStore } from "./UsermanagementStore";
import withParams from '../../../hocs/withParams';
import { User } from "../../../models/user";

interface IStores {
	UsermanagementStore: UsermanagementStore
}

interface IUsermanagementDetailsPageProps {
	navigate?: NavigateFunction;
	RootStore?: RootStore,
	params?: any
}

class UsermanagementDetailsPage extends React.Component<IUsermanagementDetailsPageProps> {
	private stores: IStores
	UserService: UserService
	isVodafone: boolean = false;

	constructor(props: any) {
		super(props);
		makeObservable(this, {
			getDatasourceItems: flow
		})

		this.UserService = new UserService();

		this.stores = { UsermanagementStore: new UsermanagementStore(this.props.RootStore, this.props.navigate, this.props.params.id) }
	}

	componentDidMount(): void {
		this.getDatasourceItems();
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.UsermanagementStore.getUserById();
			console.log('usermanagement id:', this.props.params.id)
		} else {
			this.stores.UsermanagementStore.setNewInstance();
		}
	}

	*getDatasourceItems() {
		this.stores.UsermanagementStore.accountStatesOptions = yield this.UserService.GetAccountStatesForDDL();
		this.stores.UsermanagementStore.supervisorApprovedsOptions = yield this.UserService.GetSupervisorApprovedsForDDL();

		if (this.stores.UsermanagementStore.RootStore.currentUser.StoreAdmin && !this.stores.UsermanagementStore.RootStore.currentUser.HoldingAdmin) {
			this.stores.UsermanagementStore.supervisorApprovedsOptions = this.stores.UsermanagementStore.supervisorApprovedsOptions.filter(x => x.value !== 1);
		}
	}

	public render() {
		return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Confirm open={this.stores.UsermanagementStore.isGenerateNewPasswordConfirmOpen}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
						onConfirm={(event) => { this.stores.UsermanagementStore.confirmGenerateNewPassword(event); }}
						onCancel={(event) => { this.stores.UsermanagementStore.cancelGenerateNewPassword(event); }}
						cancelButton="Mégse"
						confirmButton="Igen"
						content={
							<div style={{ padding: 30 }}>
								<p>
									Biztosan új jelszót generál?
								</p>
							</div>
						}
					></Confirm>
					<Confirm open={this.stores.UsermanagementStore.isUserNameNotUniqueOpen}
						closeOnEscape={false} //disables closing the window by pressing ESC	
						closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
						onCancel={(event) => { this.stores.UsermanagementStore.cancelIsUserNameNotUniqueOpen(event); }}
						cancelButton="OK"
						confirmButton={false}
						content={
							<div style={{ padding: 30 }}>
								<p>
									A bejelentkezési név (loginnév) már foglalt!
								</p>
							</div>
						}
					></Confirm>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={12}>
								<div style={{ marginBottom: 20 }}>
									<CustomHeader>Felhasználók kezelése</CustomHeader>
								</div>
								<Grid>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Globális felhasználó?</label>
											</div>
											<Dropdown
											
												selection
												fluid
												value={this.stores.UsermanagementStore.usermanagementItem.IsGlobal}
												options={this.stores.UsermanagementStore.aegonGSalesOptions}
												disabled={!this.props.RootStore.currentUser.HoldingAdmin || this.props.params.id != 0}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('IsGlobal', data.value) }}>
											</Dropdown>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Vezetéknév</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.LastName}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('LastName', data.value) }}
												error={!!this.stores.UsermanagementStore.getValidationsUsermanagement.LastName} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Keresztnév</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.FirstName}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('FirstName', data.value) }}
												error={!!this.stores.UsermanagementStore.getValidationsUsermanagement.FirstName} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Bejelentkezési név (loginnév)</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.UserName}
												disabled={!this.stores.UsermanagementStore.isFirstSave}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('UserName', data.value) }}
												error={!!this.stores.UsermanagementStore.getValidationsUsermanagement.UserName} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Jelszó</label>
											</div>
											<Input fluid disabled type="password" value={'1234567891'} />
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>Vállalati e-mail</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.Email}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('Email', data.value) }}
												error={!!this.stores.UsermanagementStore.getValidationsUsermanagement.Email} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>WAWI-kód</label>
											</div>
											<Input fluid type="number" value={this.stores.UsermanagementStore.usermanagementItem.WawiCode}
												disabled={this.stores.UsermanagementStore.isVodafone}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('WawiCode', data.value) }} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>WAWI-név</label>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.WawiName}
												disabled={this.stores.UsermanagementStore.isVodafone}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('WawiName', data.value) }} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label> G+ értékesítő?</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Dropdown
												selection
												fluid
												value={this.stores.UsermanagementStore.usermanagementItem.AegonGSales}
												disabled={this.stores.UsermanagementStore.isVodafone}
												options={this.stores.UsermanagementStore.aegonGSalesOptions}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('AegonGSales', data.value) }}></Dropdown>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={4}>
											<div>
												<label>G+ ügynökkód</label>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.AegonCode}
												disabled={this.stores.UsermanagementStore.isVodafone}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('AegonCode', data.value) }} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Hitelközvetítő ügynökkód</label>
											</div>
											<Input fluid value={this.stores.UsermanagementStore.usermanagementItem.CreditIntermediaryCode}
												disabled={this.stores.UsermanagementStore.isVodafone}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('CreditIntermediaryCode', data.value) }} />
										</Grid.Column>
										<Grid.Column width={4}>
											<div>
												<label>Áruház</label>
												{!this.stores.UsermanagementStore.RootStore.currentUser.HoldingAdmin && (
													<span style={{ color: 'red', marginLeft: 2 }}>*</span>
												)}
											</div>
											{/* disabled={!(this.stores.UsermanagementStore.isFirstSave) || !this.stores.UsermanagementStore.RootStore.currentUser.HoldingAdmin} */}
											<Dropdown
												selection
												fluid
												value={this.stores.UsermanagementStore.usermanagementItem.StoreCode}
												options={this.props.RootStore.stores}
												disabled={this.stores.UsermanagementStore.usermanagementItem.IsGlobal || !(this.stores.UsermanagementStore.isFirstSave) || !this.stores.UsermanagementStore.RootStore.currentUser.HoldingAdmin}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('StoreCode', data.value) }}
												error={!!this.stores.UsermanagementStore.getValidationsUsermanagement.StoreCode} ></Dropdown>
										</Grid.Column>
										{/* this.props.RootStore.currentUser.HoldingAdmin ||  */}
										<Grid.Column width={4}>
											<div>
												<label>Fiók állapota</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
											</div>
											<Dropdown
												selection
												fluid
												value={this.stores.UsermanagementStore.usermanagementItem.AccountState}
												options={this.stores.UsermanagementStore.accountStatesOptions}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('AccountState', data.value) }}></Dropdown>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5}>
											<div>
												<label>Vodafone Supervisor által szaktanácsadónak jóváhagyva</label>
												<span style={{ color: 'red', marginLeft: 2 }}>*</span>
												{this.stores.UsermanagementStore.usermanagementItem.VodafoneSupervisorApprovals && (
													<span style={{cursor: 'pointer', marginLeft: 3}}>
														<Popup trigger={<Button icon='info' color='green' size='tiny'/>} wide='very'>
															{this.stores.UsermanagementStore.usermanagementItem.VodafoneSupervisorApprovals.map((item) => {
																return <span>{item.ApprovalDateString} - {item.ApproverUserLoginName} - {item.StatusName}<br/></span>
															})}
														</Popup>
													</span>  
												)}
											</div>
											<Dropdown style={{ width: '312px' }}
												selection
												value={this.stores.UsermanagementStore.usermanagementItem.IsVodafoneSupervisorApproved}
												options={this.stores.UsermanagementStore.supervisorApprovedsOptions}
												onChange={(event, data) => { this.stores.UsermanagementStore.updateUsermanagementItem('IsVodafoneSupervisorApproved', data.value) }}></Dropdown>
										</Grid.Column>
										{ this.stores.UsermanagementStore.RootStore.currentUser.StoreAdmin && (
											<Grid.Column width={5}>
												<div style={{paddingTop: '18px', textAlign: 'center'}}>												
													<span style={{ color: 'red'}}>Vodafone Supervisor által szaktanácsadónak jóváhagyva mező esetén ha üres a mező, akkor IGEN értéket tartalmaz! </span>
												</div>											
											</Grid.Column>
										)}										
									</Grid.Row>
								</Grid>
							</Grid.Column>
						</Grid.Row>
						<Confirm open={this.stores.UsermanagementStore.showValidationErrorPopup && this.stores.UsermanagementStore.hasValidationErrors}
							closeOnEscape={false} //disables closing the window by pressing ESC	
							closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
							onCancel={() => { this.stores.UsermanagementStore.cancelShowValidationErrorPopup(); }}
							cancelButton="Bezár"
							confirmButton={false}
							content={
								<div style={{ padding: 40 }}>
									<Message style={{ maxHeight: 300, overflow: 'auto' }} negative>
										<Message.Header>Hiba a mentés közben!</Message.Header>
										<span>Validációs hibák:
											<ul style={{ marginLeft: 18 }}>{Object.values(this.stores.UsermanagementStore.getValidationsUsermanagement).map((error) => {
												return <li key={error}>{error}</li>
											})}
											</ul>
										</span>
									</Message>
								</div>
							}
						></Confirm>
						<Grid.Row centered width={12}>
							<div style={{ display: 'flex', width: '100%' }}>
								<div style={{ marginLeft: 238 }}>
									<Button color="green" onClick={() => { this.stores.UsermanagementStore.saveUserItem() }}>Mentés</Button>
								</div>
								<div>
									<Button onClick={() => { 
										if (this.props.RootStore.fromUserModuleAssignment)
										{
											this.props.navigate('/administration/serviceprovider-permissionmanagement');
										}
										else {											
											this.props.navigate('/administration/usermanagement');
											}
										}}>Mégse</Button>
								</div>
								<div style={{ marginLeft: '1070px' }}>
									<Button color="blue" onClick={() => { this.stores.UsermanagementStore.setIsGenerateNewPasswordConfirmOpenTrue() }}>Új jelszó generálása</Button>
								</div>
							</div>
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(UsermanagementDetailsPage))));