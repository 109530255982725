import { makeAutoObservable } from "mobx";

export class ManufacturerWarranty {
    Id: number;
    WarrantyPeriod: number;
    DevicePrice: number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.WarrantyPeriod = 0;
        this.DevicePrice = 0;
    }
}