import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Confirm, Dropdown, Grid, Input, Message, Search } from 'semantic-ui-react';
import withNavigation from '../../../hocs/withNavigation';
import { CustomHeader } from '../../../components/CustomHeader';
import withParams from '../../../hocs/withParams';
import { RootStore } from '../../../stores/RootStore';
import { RateDeviceDiscountStore } from './RateDeviceDiscountStore';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { inject, observer, Provider } from 'mobx-react';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { AgGridText } from '../../../core/agGridText';
import DeleteButton from '../../../components/DeleteButton';
import { DeviceDiscount } from '../../../models/deviceDiscount';
import { toJS } from "mobx";
import '../../../index.css'
import NumberFormat from 'react-number-format';

interface IStores {
    RateDeviceDiscountStore: RateDeviceDiscountStore
}

interface IRateDeviceDiscountDetailsPageProps {
    navigate?: NavigateFunction,
    params?: any,
    RootStore?: RootStore
}

class RateDeviceDiscountDetailsPage extends React.Component<IRateDeviceDiscountDetailsPageProps> {
    stores: IStores

    constructor(props: any) {
        super(props);

        this.stores = { RateDeviceDiscountStore: new RateDeviceDiscountStore(this.props.RootStore, this.props.navigate, this.props.params.id)}
    }

	componentDidMount(): void {
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.RateDeviceDiscountStore.GetDeviceDiscountById();
			console.log('GetDeviceDiscountById id:', this.props.params.id)
		} else {
			this.stores.RateDeviceDiscountStore.deviceDiscountItem = new DeviceDiscount();
		}
	}


    public render() {
		
		const {RateDeviceDiscountStore} = this.stores

        return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={10}>
								<div style={{ marginBottom: 20 }}>
									<CustomHeader>Készülék jogán járó kedvezmény</CustomHeader>
								</div>
								{/* <div>
									<Button onClick={() => {RateDeviceDiscountStore.navigateToTome()}}>navigate to home in new tab</Button>
								</div> */}
								<Grid>
									<Grid.Row>
										<Grid.Column width={5}>
											<div>
												<label>Készülék cikkszáma</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Search placeholder='Keresés...' fluid className={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DeviceId ? "searchError" : "search"}
													onResultSelect={(e, data) => {RateDeviceDiscountStore.updateDevice(data.result?.item)}}
													onSearchChange={(event, data) => {RateDeviceDiscountStore.onChangeQueryText(event, data)}}
													results={RateDeviceDiscountStore.deviceByQueryTextDDL}
													value={RateDeviceDiscountStore.queryText || RateDeviceDiscountStore.selectedDeviceItem?.ArticleNumber?.toString()}													
											/>
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Készülék gyártója</label>
											</div>
                                            {RateDeviceDiscountStore.selectedDeviceItem?.Manufacturer}											
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Készülék típusa</label>
											</div>
                                            {RateDeviceDiscountStore.selectedDeviceItem?.Name}									
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width={5}>
											<div>
												<label>Kedvezmény kezdete</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
                                            <SemanticDatepicker firstDayOfWeek={1} value={RateDeviceDiscountStore.deviceDiscountItem.DiscountStartDate ? new Date(RateDeviceDiscountStore.deviceDiscountItem.DiscountStartDate) : null}
												onChange={(event, data) => { RateDeviceDiscountStore.updateDeviceDiscountItem('DiscountStartDate', data.value) }}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountStartDate}></SemanticDatepicker>											
										</Grid.Column>
										<Grid.Column width={5}>
											<div>
												<label>Kedvezmény vége</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
                                            <SemanticDatepicker firstDayOfWeek={1} value={RateDeviceDiscountStore.deviceDiscountItem.DiscountEndDate ? new Date(RateDeviceDiscountStore.deviceDiscountItem.DiscountEndDate) : null}
												onChange={(event, data) => { RateDeviceDiscountStore.updateDeviceDiscountItem('DiscountEndDate', data.value) }}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountEndDate || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.EndDateEarlierThanStartDaet}></SemanticDatepicker>											
										</Grid.Column>                                        
										<Grid.Column width={5}>
											<div>
												<label>Összevonható?</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											<Dropdown
												selection
												fluid
												value={RateDeviceDiscountStore.deviceDiscountItem.IsMergeable}
												onChange={(event, data) => { RateDeviceDiscountStore.updateDeviceDiscountItem('IsMergeable', data.value) }}
												options={RateDeviceDiscountStore.yesNoDatasource}></Dropdown>
										</Grid.Column>									
									</Grid.Row>
                                    <Grid.Row>
									<Grid.Column width={5}>
											<div>
												<label>Kedvezmény összege</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
                                            {/* <Input fluid type="number" value={RateDeviceDiscountStore.deviceDiscountItem.DiscountAmount}
												onChange={(event, data) => { RateDeviceDiscountStore.updateDeviceDiscountItem('DiscountAmount', data.value) }}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountAmount || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountAmountLessThanZero} /> */}
											<NumberFormat
                                                value={RateDeviceDiscountStore.deviceDiscountItem.DiscountAmount }      
												fluid     
												  disabled={RateDeviceDiscountStore.deviceDiscountItem.DiscountPackagePrice ||  RateDeviceDiscountStore.deviceDiscountItem.PercentageDiscount}          	                                                                         
												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {RateDeviceDiscountStore.updateDeviceDiscountItem('DiscountAmount', data.value)}}                                                
                                                customInput={Input}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountAmount || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountAmountLessThanZero}/>
										</Grid.Column>											
										<Grid.Column width={5}>
											<div>
												<label>Kedvezményes csomag ár</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
                                            {/* <Input fluid type="number" value={RateDeviceDiscountStore.deviceDiscountItem.DiscountAmount}
												onChange={(event, data) => { RateDeviceDiscountStore.updateDeviceDiscountItem('DiscountAmount', data.value) }}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountAmount || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountAmountLessThanZero} /> */}
											<NumberFormat
                                                value={RateDeviceDiscountStore.deviceDiscountItem.DiscountPackagePrice}      
												fluid                   
												disabled={RateDeviceDiscountStore.deviceDiscountItem.DiscountAmount ||  RateDeviceDiscountStore.deviceDiscountItem.PercentageDiscount}          	                                                                         

												thousandSeparator=" "                                                
                                                suffix=" Ft" 
                                                onValueChange={(data) => {RateDeviceDiscountStore.updateDeviceDiscountItem('DiscountPackagePrice', data.value)}}                                                
                                                customInput={Input}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountPackagePrice || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.DiscountPackagePriceLessThanZero}/>
										</Grid.Column>	
										<Grid.Column width={5}>
											<div>
												<label>Százalékos kedvezmény</label>
												<span style={{color: 'red', marginLeft: 2}}>*</span>

											</div>
											<NumberFormat
                                                value={RateDeviceDiscountStore.deviceDiscountItem.PercentageDiscount}      
												fluid                   
												disabled={RateDeviceDiscountStore.deviceDiscountItem.DiscountAmount || RateDeviceDiscountStore.deviceDiscountItem.DiscountPackagePrice} 
												                                                            
												  decimalScale={2}
												thousandSeparator=" "                                                
                                                
                                                onValueChange={(data) => {RateDeviceDiscountStore.updateDeviceDiscountItem('PercentageDiscount', data.value)}}                                                
                                                customInput={Input}
												error={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.PercentageDiscount || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.PercentageDiscountLessThanZero || !!RateDeviceDiscountStore.getValidationsDeviceDiscount.PercentageDiscountLessThanOne|| !!RateDeviceDiscountStore.getValidationsDeviceDiscount.PercentageDiscountGreaterThan99 }/>                                         
										</Grid.Column>     																	
									</Grid.Row>	
									<Grid.Row>                                        										                                   
										<Grid.Column width={5}>
											<div>
												<label>Kapcsolt tarifacsomagok kódja és megnevezése</label>
												{/* legalabb 1 */}
												<span style={{color: 'red', marginLeft: 2}}>*</span>
											</div>
											{RateDeviceDiscountStore.showTariffPackageSearch && (
												<Search placeholder='Keresés...' fluid className={!!RateDeviceDiscountStore.getValidationsDeviceDiscount.TariffPackagesArrayLength ? "searchError" : "search"}
												onResultSelect={(e, data) => {RateDeviceDiscountStore.updateTariffPackage(data.result?.item)}}
												onSearchChange={(event, data) => {RateDeviceDiscountStore.onTariffPackageChangeQueryText(event, data)}}
												results={RateDeviceDiscountStore.tariffPackageByQueryTextDDL}
												value={(RateDeviceDiscountStore.tariffPackageQueryText || RateDeviceDiscountStore.selectedTariffPackageItem?.CustomNamePackageCodeForAutoComplete) || ""}												
											/> 
											)}                                            
										</Grid.Column>     
                                        <Grid.Column width={2} style={{paddingTop: 19}}>
											<div>
                                                <Button color='green' disabled={!(RateDeviceDiscountStore.selectedTariffPackageItem?.Id && RateDeviceDiscountStore.selectedTariffPackageItem?.Name)} onClick={() => {RateDeviceDiscountStore.addSelectedTarifaPackageItem()}}>Hozzáadás</Button>												
											</div>                                            
										</Grid.Column>										
									</Grid.Row>	     									                          
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>	
                <div style={{marginTop: 30, fontSize: 20, color: 'red', marginLeft: 20}}>
                    Kapcsolt tarifacsomagok
                </div>			
                <div>
                    <div id="grid" className="ag-theme-alpine" style={{width: '850px', height: '519px', marginTop: 10, marginLeft: 20}}>
                        <AgGridReact											
                            rowData={toJS(RateDeviceDiscountStore.deviceDiscountItem.TariffPackages)}											
                            columnDefs={RateDeviceDiscountStore.rateColumnDef}
                            localeText={AgGridText}
                            frameworkComponents={{ btnCellRenderer: DeleteButton }}
                            pagination={true}
                            paginationPageSize={10}
							gridOptions={{
								paginationNumberFormatter: (params:any) => {
								return  params.value.toLocaleString() ;
							  },
							}}>																				
                        </AgGridReact>
                    </div>  
                </div>
				<Confirm open={RateDeviceDiscountStore.showValidationErrorPopup && RateDeviceDiscountStore.hasValidationErrors}	
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={() => {RateDeviceDiscountStore.cancelShowValidationErrorPopup();}}	
					cancelButton="Bezár"
					confirmButton={false}								
					content={
						<div style={{ padding: 40 }}>							
								<Message style={{maxHeight: 300, overflow: 'auto'}} negative>
									<Message.Header>Hiba a mentés közben!</Message.Header>
									<span>Validációs hibák:
										<ul style={{marginLeft: 18}}>{Object.values(RateDeviceDiscountStore.getValidationsDeviceDiscount).map((error) => {
											return <li key={error}>{error}</li>
											})}
										</ul>										
									</span>
								</Message>							
						</div>
					}
				></Confirm>
                <div style={{ marginTop: 20, marginLeft: 20}}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={5} style={{ textAlign: 'left' }}>

								<Button onClick={() => { this.props.navigate('/administration/rate-device-discount') }}>Mégse</Button>
							</Grid.Column>
							<Grid.Column width={5} style={{ textAlign: 'right' }}>

								<Button color="green" onClick={() => {RateDeviceDiscountStore.SaveDeviceDiscount()}}>Mentés</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>	
    }
}

export default withParams(withNavigation(inject('RootStore')(observer(RateDeviceDiscountDetailsPage))));
