import { makeObservable, observable } from "mobx";
import { RootStore } from "../../../stores/RootStore";

export class GDPRStore {

    private RootStore:RootStore
    gdprNumber: number = 1;

    constructor(rootStore:RootStore, gdprNumber?: number) {
        this.RootStore= rootStore;        
        makeObservable(this, {
            gdprNumber: observable,
        })
        this.gdprNumber = gdprNumber;
    }
}