import React from 'react';
import { useParams } from 'react-router-dom';

interface IWithProps {}

const withParams =
	<P extends object>(Component: React.ComponentType<P>): React.FC<P & IWithProps> =>
	(props) => {
		const params = useParams();

		return <Component params={params} {...(props as P)} />;
	};

export default withParams;
