import { flow, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";

export class StoreDataStore {
    
    storeDataItems: Array<any>;
    columnDef: Array<ColDef>;
    AdminService: AdminService
    private RootStore:RootStore
    constructor(rootStore:RootStore) {
        this.RootStore= rootStore;
        makeObservable(this, {
            storeDataItems: observable,
            columnDef: observable,
            getStoreDatas: flow,
        })

        this.AdminService = new AdminService();        

        this.columnDef = [
            { field: 'StoreName', headerName: 'Áruház neve', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250},
            { field: 'SAPCode', headerName: 'Áruház SAP-kódja', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250},
            { field: 'LinistryCode', headerName: 'Áruház Linistry-kódja', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250},
            { field: 'OutletId', headerName: 'MCC Outlet Id', filter: 'agSetColumnFilter', sortable: true, maxWidth: 250, minWidth: 250},            
        ]
    }

    *getStoreDatas() {
        this.RootStore.addloading('getStoreDatas');
        this.storeDataItems = yield this.AdminService.GetStoreDatas()
        this.RootStore.removeLoading('getStoreDatas');        
    }
}