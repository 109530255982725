import { EmailManagementStore } from "./EmailManagementStore";
import { NavigateFunction } from "react-router-dom";
import React from "react";
import { inject, observer, Provider } from "mobx-react";
import withNavigation from "../../../hocs/withNavigation";
import { Button, Dropdown, Grid, Input, Search } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { AgGridText } from "../../../core/agGridText";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { UserService } from "../../../services/user.service";
import { flow, makeObservable } from "mobx";
import { RootStore } from "../../../stores/RootStore";
import DeleteButton from "../../../components/DeleteButton";
import { GridReadyEvent } from "@ag-grid-enterprise/all-modules";
import { AdminService } from "../../../services/admin.service";
import { toJS } from 'mobx';

interface IStores {
    EmailManagementStore: EmailManagementStore
}

interface IRolePageProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore
}

class EmailManagementPage extends React.Component<IRolePageProps> {
    private stores: IStores
    UserService: UserService;
    AdminService: AdminService;

    constructor(props: any) {
        super(props);

        this.stores = { EmailManagementStore: new EmailManagementStore(this.props.RootStore) }
        this.UserService = new UserService();
        this.AdminService = new AdminService();

        makeObservable(this, {            
            getRoles: flow,
            getEmails: flow,            
        })
    }

    componentDidMount(): void {
        if(!this.props.RootStore.currentUser.HoldingAdmin){
            this.stores.EmailManagementStore.selectedStore=this.props.RootStore.currentUser.StoreCode;
            console.log("this.stores.EmailManagementStore.selectedStore", this.stores.EmailManagementStore.selectedStore);
        }        
        console.log("this.stores.EmailManagementStore.selectedStore", this.stores.EmailManagementStore.selectedStore);
        this.getRoles();
        this.getEmails();
    }

    *getRoles() {
        this.stores.EmailManagementStore.roles = yield this.UserService.GetRolesForDDL()
        
        if (this.props.RootStore.currentUser.HoldingAdmin) {
            this.stores.EmailManagementStore.roles = this.stores.EmailManagementStore.roles.filter(x => x.value == 5 || x.value == 6 || x.value == 8);
            console.log(toJS(this.stores.EmailManagementStore.roles));            
        }
        else {
            this.stores.EmailManagementStore.roles = this.stores.EmailManagementStore.roles.filter(x => x.value == 5 || x.value == 6);
        }             
        this.stores.EmailManagementStore.selectedRoleId = this.stores.EmailManagementStore.roles[0].value;
    }

    *getEmails() {
        this.stores.EmailManagementStore.emails = yield this.AdminService.GetOverrideEmailNotifications()
    }

    handleGridReady(event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div style={{ margin: 20, width: '95%' }}>
                    <Grid width={12}>
                        <Grid.Row centered>
                            <Grid.Column width={14}>
                                <div style={{ marginBottom: 20 }}>
                                    <CustomHeader>E-mail értesítések kezelése</CustomHeader>
                                </div>
                                <Grid>
                                    <Grid.Row>
                                       
                                        <Grid.Column width={3}>
                                            <div>
                                                <label>Szerepkör</label>
                                            </div>
                                            <Dropdown
                                                fluid
                                                selection
                                                value={this.stores.EmailManagementStore.selectedRoleId}
                                                onChange={(e, data) => { this.stores.EmailManagementStore.update('selectedRoleId', data?.value) }}
                                                options={this.stores.EmailManagementStore.roles}>
                                            </Dropdown>
                                        </Grid.Column>
                                        {(this.stores.EmailManagementStore.selectedRoleId != 8) && !this.stores.EmailManagementStore.saving && (
                                            <Grid.Column width={3}>
                                                <div>
                                                    <label>Áruház</label>
                                                </div>
                                                <Dropdown
                                                    selection
                                                    fluid
                                                    value={this.stores.EmailManagementStore.selectedStore}
                                                    options={this.props.RootStore.stores}
                                                    disabled={!this.props.RootStore.currentUser.HoldingAdmin}
                                                    onChange={(e, data) => { this.stores.EmailManagementStore.update('selectedStore', data?.value) }}
                                                />
                                            </Grid.Column>
                                        )}                                        
                                        <Grid.Column width={4}>
                                            <div>
                                                <label>Gyűjtő email-cím</label>
                                            </div>                                            
                                            <Input fluid value={this.stores.EmailManagementStore.selectedEmail}
													onChange={(event, data) => {this.stores.EmailManagementStore.update('selectedEmail', data.value)}}/>
                                           
                                        </Grid.Column>
                                        <Grid.Column width={2}>
                                            <Button color="green" style={{ height: '38px', marginTop: '19px' }} onClick={() => this.stores.EmailManagementStore.addEmailToContainer()}
                                            disabled={this.stores.EmailManagementStore.getAddButtonDisabled}>Hozzáadás</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16} style={{ alignItems: 'center' }}>
                                            <Grid.Row centered>
                                                <div id="grid" className="ag-theme-alpine" style={{ width: '1400px', height: '519px' }}>
                                                    <AgGridReact
                                                        rowData={this.stores.EmailManagementStore.emails}
                                                        columnDefs={this.stores.EmailManagementStore.emailsColumnDef}
                                                        localeText={AgGridText}
                                                        pagination={true}
                                                        paginationPageSize={10}
                                                        frameworkComponents={{ btnCellRenderer: DeleteButton }}
                                                        onGridReady={this.handleGridReady}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </Grid.Row>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(EmailManagementPage)))