import { action, computed, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { TariffPackage } from "../../../models/tariffPackage";
import { RootStore } from "../../../stores/RootStore";
import { NavigateFunction } from "react-router-dom";
import { AdminService } from "../../../services/admin.service";
import { VodafonePackageFeed } from "../../../models/vodafonePackageFeed";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
export class RatePortfolioStore {

    tariffPackageItems: Array<any>;
    columnDef: Array<ColDef>;
    tariffPackageItemId: number = 0;
    tariffPackageItem: TariffPackage = new TariffPackage();
    private RootStore: RootStore
    navigate?: NavigateFunction;
    queryTextL1: string = "";
    queryTextL2: string = "";
    queryTextL3: string = "";
    queryTextL4: string = "";
    queryTextL5: string = "";
    queryTextL6: string = "";
    queryTextL7: string = "";
    queryTextL8: string = "";
    queryTextL9: string = "";
    queryTextL10: string = "";
    vodafonePackageFeedsByQueryTextL1: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL2: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL3: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL4: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL5: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL6: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL7: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL8: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL9: Array<VodafonePackageFeed> = [];
    vodafonePackageFeedsByQueryTextL10: Array<VodafonePackageFeed> = [];
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    isUploadConfirmOpen: boolean = false;
    uploadIdString: string = "uploadId";
    file: any;
    byteContent: { content: string };
    AdminService: AdminService;
    editorState = EditorState.createEmpty()
    constructor(rootStore: RootStore, navigate?: NavigateFunction, tariffPackageItemId?: number) {
        this.RootStore = rootStore;
        this.navigate = navigate
        this.tariffPackageItemId = tariffPackageItemId
        makeAutoObservable(this, {
            tariffPackageItems: observable,
            columnDef: observable,
            tariffPackageItemId: observable,
            tariffPackageItem: observable,
            queryTextL1: observable,
            queryTextL2: observable,
            queryTextL3: observable,
            queryTextL4: observable,
            queryTextL5: observable,
            queryTextL6: observable,
            queryTextL7: observable,
            queryTextL8: observable,
            queryTextL9: observable,
            queryTextL10: observable,
            vodafonePackageFeedsByQueryTextL1: observable,
            vodafonePackageFeedsByQueryTextL2: observable,
            vodafonePackageFeedsByQueryTextL3: observable,
            vodafonePackageFeedsByQueryTextL4: observable,
            vodafonePackageFeedsByQueryTextL5: observable,
            vodafonePackageFeedsByQueryTextL6: observable,
            vodafonePackageFeedsByQueryTextL7: observable,
            vodafonePackageFeedsByQueryTextL8: observable,
            vodafonePackageFeedsByQueryTextL9: observable,
            vodafonePackageFeedsByQueryTextL10: observable,
            editorState: observable,
            updateTariffPackage: action,
            navigateToTariffPackage: action,
            onChangeQueryTextL1: action,
            onChangeQueryTextL2: action,
            onChangeQueryTextL3: action,
            onChangeQueryTextL4: action,
            onChangeQueryTextL5: action,
            onChangeQueryTextL6: action,
            onChangeQueryTextL7: action,
            onChangeQueryTextL8: action,
            onChangeQueryTextL9: action,
            onChangeQueryTextL10: action,
            onEditorStateChange: action,
            updateTariffPackageArticleNumber: action,
            saveTariffPackageItem: flow,
            getTariffPackages: flow,
            getWarrantyPlusPackegeById: flow,
            getVodafonePackageFeedByQueryTextL1: flow,
            getVodafonePackageFeedByQueryTextL2: flow,
            getVodafonePackageFeedByQueryTextL3: flow,
            getVodafonePackageFeedByQueryTextL4: flow,
            getVodafonePackageFeedByQueryTextL5: flow,
            getVodafonePackageFeedByQueryTextL6: flow,
            getVodafonePackageFeedByQueryTextL7: flow,
            getVodafonePackageFeedByQueryTextL8: flow,
            getVodafonePackageFeedByQueryTextL9: flow,
            getVodafonePackageFeedByQueryTextL10: flow,
            vodafonePackageFeedByQueryTextDDLL1: computed,
            vodafonePackageFeedByQueryTextDDLL2: computed,
            vodafonePackageFeedByQueryTextDDLL3: computed,
            vodafonePackageFeedByQueryTextDDLL4: computed,
            vodafonePackageFeedByQueryTextDDLL5: computed,
            vodafonePackageFeedByQueryTextDDLL6: computed,
            vodafonePackageFeedByQueryTextDDLL7: computed,
            vodafonePackageFeedByQueryTextDDLL8: computed,
            vodafonePackageFeedByQueryTextDDLL9: computed,
            vodafonePackageFeedByQueryTextDDLL10: computed,
        })

        this.tariffPackageItemId = tariffPackageItemId;
        this.AdminService = new AdminService();
        this.columnDef = [
            {
                field: 'edit', headerName: 'Műveletek', maxWidth: 180,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => { this.navigateToTariffPackage(data.Id); },
                }
            },
            { field: 'Name', headerName: 'Tarifacsomag megnevezése', filter: 'agSetColumnFilter', sortable: true, maxWidth: 403, minWidth: 403 },
            { field: 'PackageCode', headerName: 'Tarifacsomag kódja', filter: 'agSetColumnFilter', sortable: true, maxWidth: 403, minWidth: 403 },
            { field: 'PackagePrice', headerName: 'Tarifacsomag ára', filter: 'agSetColumnFilter', valueFormatter: params => this.numberFormatter(params), sortable: true, maxWidth: 402, minWidth: 402 },
        ]
    }
    onEditorStateChange(editorState: any) {
        this.editorState = editorState;


        let cicc2 = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        this.updateTariffPackage('Description', cicc2)
    };
    numberFormatter = (params: any) => {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
    }

    navigateToTariffPackage = (tariffPackageId: number) => {
        this.navigate('/administration/rate-portfolio-details/' + tariffPackageId);
    }

    updateTariffPackage(key: keyof TariffPackage, value: any) {
        if (key === 'IsActive') {
            if (this.tariffPackageItem[key] === 1) {
                this.tariffPackageItem[key] = 0
            }
            else {
                this.tariffPackageItem[key] = 1;
            }
        }
        else {
            (this.tariffPackageItem[key] as any) = value;
        }
    }

    updateTariffPackageArticleNumber(value: any, number: number) {
        if (number === 1) {
            this.tariffPackageItem.ArticleNumberL1 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL1 = value.ArticleName;
        }
        if (number === 2) {
            this.tariffPackageItem.ArticleNumberL2 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL2 = value.ArticleName;
        }
        if (number === 3) {
            this.tariffPackageItem.ArticleNumberL3 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL3 = value.ArticleName;
        }
        if (number === 4) {
            this.tariffPackageItem.ArticleNumberL4 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL4 = value.ArticleName;
        }
        if (number === 5) {
            this.tariffPackageItem.ArticleNumberL5 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL5 = value.ArticleName;
        }
        if (number === 6) {
            this.tariffPackageItem.ArticleNumberL6 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL6 = value.ArticleName;
        }
        if (number === 7) {
            this.tariffPackageItem.ArticleNumberL7 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL7 = value.ArticleName;
        }
        if (number === 8) {
            this.tariffPackageItem.ArticleNumberL8 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL8 = value.ArticleName;
        }
        if (number === 9) {
            this.tariffPackageItem.ArticleNumberL9 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL9 = value.ArticleName;
        }
        if (number === 10) {
            this.tariffPackageItem.ArticleNumberL10 = value.ArticleNumber;
            this.tariffPackageItem.AdministrativeNameL10 = value.ArticleName;
        }
    }

    onChangeQueryTextL1 = (event: any, data: any) => {
        this.queryTextL1 = data.value
        console.log(this.onChangeQueryTextL1)
        this.tariffPackageItem.ArticleNumberL1 = null;
        this.tariffPackageItem.AdministrativeNameL1 = null;
        this.getVodafonePackageFeedByQueryTextL1();
    }

    onChangeQueryTextL2 = (event: any, data: any) => {
        this.queryTextL2 = data.value
        console.log(this.onChangeQueryTextL2)
        this.tariffPackageItem.ArticleNumberL2 = null;
        this.tariffPackageItem.AdministrativeNameL2 = null;
        this.getVodafonePackageFeedByQueryTextL2();
    }

    onChangeQueryTextL3 = (event: any, data: any) => {
        this.queryTextL3 = data.value
        console.log(this.onChangeQueryTextL3)
        this.tariffPackageItem.ArticleNumberL3 = null;
        this.tariffPackageItem.AdministrativeNameL3 = null;
        this.getVodafonePackageFeedByQueryTextL3();
    }

    onChangeQueryTextL4 = (event: any, data: any) => {
        this.queryTextL4 = data.value
        console.log(this.onChangeQueryTextL4)
        this.tariffPackageItem.ArticleNumberL4 = null;
        this.tariffPackageItem.AdministrativeNameL4 = null;
        this.getVodafonePackageFeedByQueryTextL4();
    }

    onChangeQueryTextL5 = (event: any, data: any) => {
        this.queryTextL5 = data.value
        console.log(this.onChangeQueryTextL5)
        this.tariffPackageItem.ArticleNumberL5 = null;
        this.tariffPackageItem.AdministrativeNameL5 = null;
        this.getVodafonePackageFeedByQueryTextL5();
    }

    onChangeQueryTextL6 = (event: any, data: any) => {
        this.queryTextL6 = data.value
        console.log(this.onChangeQueryTextL6)
        this.tariffPackageItem.ArticleNumberL6 = null;
        this.tariffPackageItem.AdministrativeNameL6 = null;
        this.getVodafonePackageFeedByQueryTextL6();
    }

    onChangeQueryTextL7 = (event: any, data: any) => {
        this.queryTextL7 = data.value
        console.log(this.onChangeQueryTextL7)
        this.tariffPackageItem.ArticleNumberL7 = null;
        this.tariffPackageItem.AdministrativeNameL7 = null;
        this.getVodafonePackageFeedByQueryTextL7();
    }

    onChangeQueryTextL8 = (event: any, data: any) => {
        this.queryTextL8 = data.value
        console.log(this.onChangeQueryTextL8)
        this.tariffPackageItem.ArticleNumberL8 = null;
        this.tariffPackageItem.AdministrativeNameL8 = null;
        this.getVodafonePackageFeedByQueryTextL8();
    }

    onChangeQueryTextL9 = (event: any, data: any) => {
        this.queryTextL9 = data.value
        console.log(this.onChangeQueryTextL9)
        this.tariffPackageItem.ArticleNumberL9 = null;
        this.tariffPackageItem.AdministrativeNameL9 = null;
        this.getVodafonePackageFeedByQueryTextL9();
    }

    onChangeQueryTextL10 = (event: any, data: any) => {
        this.queryTextL10 = data.value
        console.log(this.onChangeQueryTextL10)
        this.tariffPackageItem.ArticleNumberL10 = null;
        this.tariffPackageItem.AdministrativeNameL10 = null;
        this.getVodafonePackageFeedByQueryTextL10();
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }

    cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isUploadConfirmOpen = false;
    }

    setIsUploadConfirmOpen = () => {
        this.isUploadConfirmOpen = true;
    }

    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
        console.log('Kiválasztott file:', this.file)
    }

    *getVodafonePackageFeedByQueryTextL1() {
        this.vodafonePackageFeedsByQueryTextL1 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL1, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL2() {
        this.vodafonePackageFeedsByQueryTextL2 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL2, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL3() {
        this.vodafonePackageFeedsByQueryTextL3 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL3, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL4() {
        this.vodafonePackageFeedsByQueryTextL4 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL4, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL5() {
        this.vodafonePackageFeedsByQueryTextL5 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL5, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL6() {
        this.vodafonePackageFeedsByQueryTextL6 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL6, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL7() {
        this.vodafonePackageFeedsByQueryTextL7 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL7, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL8() {
        this.vodafonePackageFeedsByQueryTextL8 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL8, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL9() {
        this.vodafonePackageFeedsByQueryTextL9 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL9, this.RootStore.selectedStore)
    }

    *getVodafonePackageFeedByQueryTextL10() {
        this.vodafonePackageFeedsByQueryTextL10 = yield this.AdminService.GetVodafonePackagesByArticleNumber(this.queryTextL10, this.RootStore.selectedStore)
    }

    *saveTariffPackageItem() {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (!this.hasValidationErrors) {
            this.RootStore.addloading('saveTariffPackageItem')
            yield this.AdminService.SaveTariffPackage(this.tariffPackageItem)
            this.RootStore.removeLoading('saveTariffPackageItem')
            this.navigate('/administration/rate-portfolio')
        }
    }

    *getTariffPackages() {
        this.RootStore.addloading('getTariffPackages')
        this.tariffPackageItems = yield this.AdminService.GetTariffPackages()
        this.RootStore.removeLoading('getTariffPackages')
    }

    *getWarrantyPlusPackegeById() {
        this.RootStore.addloading('getWarrantyPlusPackegeById');
        this.tariffPackageItem = yield this.AdminService.GetTariffPackageById(this.tariffPackageItemId);

        const blocksFromHtml = htmlToDraft(this.tariffPackageItem.Description);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        this.onEditorStateChange(editorState);
        console.log(this.tariffPackageItem)
        this.RootStore.removeLoading('getWarrantyPlusPackegeById');

    }

    *confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        this.isUploadConfirmOpen = false;
        let formData: FormData = new FormData();
        this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        this.byteContent = yield this.AdminService.UploadTariffPackageTile(formData, this.tariffPackageItem.Id);
        this.tariffPackageItem.Logo = this.byteContent.content
        // console.log('this.tariffPackageItem.Logo:', this.tariffPackageItem.Logo)
        console.log(this.file)
    }

    get vodafonePackageFeedByQueryTextDDLL1() {
        return this.vodafonePackageFeedsByQueryTextL1.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL2() {
        return this.vodafonePackageFeedsByQueryTextL2.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL3() {
        return this.vodafonePackageFeedsByQueryTextL3.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL4() {
        return this.vodafonePackageFeedsByQueryTextL4.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL5() {
        return this.vodafonePackageFeedsByQueryTextL5.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL6() {
        return this.vodafonePackageFeedsByQueryTextL6.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL7() {
        return this.vodafonePackageFeedsByQueryTextL7.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL8() {
        return this.vodafonePackageFeedsByQueryTextL8.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL9() {
        return this.vodafonePackageFeedsByQueryTextL9.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get vodafonePackageFeedByQueryTextDDLL10() {
        return this.vodafonePackageFeedsByQueryTextL10.map(vodafonePackageFeed => {
            return { title: vodafonePackageFeed.ArticleName + " (" + vodafonePackageFeed.ArticleNumber + ")", item: vodafonePackageFeed };
        })
    }

    get getValidationsTariffPackage() {
        if (!this.isSaved) {
            return {}
        }

        let validationErrors: Partial<Record<keyof TariffPackage, string>> = {}

        if (!this.tariffPackageItem.Name) {
            validationErrors["Name"] = "Tarifacsomag megnevezése kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.PackageCode) {
            validationErrors["PackageCode"] = "Tarifacsomag kódja kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL1) {
            validationErrors["ArticleNumberL1"] = "Tarifacsomag cikkszáma L1 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL2) {
            validationErrors["ArticleNumberL2"] = "Tarifacsomag cikkszáma L2 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL3) {
            validationErrors["ArticleNumberL3"] = "Tarifacsomag cikkszáma L3 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL4) {
            validationErrors["ArticleNumberL4"] = "Tarifacsomag cikkszáma L4 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL5) {
            validationErrors["ArticleNumberL5"] = "Tarifacsomag cikkszáma L5 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL6) {
            validationErrors["ArticleNumberL6"] = "Tarifacsomag cikkszáma L6 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL7) {
            validationErrors["ArticleNumberL7"] = "Tarifacsomag cikkszáma L7 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL8) {
            validationErrors["ArticleNumberL8"] = "Tarifacsomag cikkszáma L8 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL9) {
            validationErrors["ArticleNumberL9"] = "Tarifacsomag cikkszáma L9 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.ArticleNumberL10) {
            validationErrors["ArticleNumberL10"] = "Tarifacsomag cikkszáma L10 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL1) {
            validationErrors["AdministrativeNameL1"] = "Tarifacsomag adminisztratív megnevezése L1 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL2) {
            validationErrors["AdministrativeNameL2"] = "Tarifacsomag adminisztratív megnevezése L2 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL3) {
            validationErrors["AdministrativeNameL3"] = "Tarifacsomag adminisztratív megnevezése L3 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL4) {
            validationErrors["AdministrativeNameL4"] = "Tarifacsomag adminisztratív megnevezése L4 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL5) {
            validationErrors["AdministrativeNameL5"] = "Tarifacsomag adminisztratív megnevezése L5 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL6) {
            validationErrors["AdministrativeNameL6"] = "Tarifacsomag adminisztratív megnevezése L6 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL7) {
            validationErrors["AdministrativeNameL7"] = "Tarifacsomag adminisztratív megnevezése L7 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL8) {
            validationErrors["AdministrativeNameL8"] = "Tarifacsomag adminisztratív megnevezése L8 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL9) {
            validationErrors["AdministrativeNameL9"] = "Tarifacsomag adminisztratív megnevezése L9 kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdministrativeNameL10) {
            validationErrors["AdministrativeNameL10"] = "Tarifacsomag adminisztratív megnevezése L10 kitöltése kötelező!"
        }

        if (this.tariffPackageItem.Id > 0 && !this.tariffPackageItem.Logo) {
            validationErrors["Logo"] = "Tarifacsomag csempe feltöltése kötelező!"
        }

        if (!this.tariffPackageItem.PackagePrice || this.tariffPackageItem.PackagePrice < 0) {
            validationErrors["PackagePrice"] = "Tarifacsomag ára kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.AdminsitrationFee || this.tariffPackageItem.AdminsitrationFee < 0) {
            validationErrors["AdminsitrationFee"] = "Adminisztrációs díj fogyasztói ára kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.DeviceDiscount || this.tariffPackageItem.DeviceDiscount < 0) {
            validationErrors["DeviceDiscount"] = "Készülékkedvezmény kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.MonthlyDiscount || this.tariffPackageItem.MonthlyDiscount < 0) {
            validationErrors["MonthlyDiscount"] = "Havidíjkedvezmény 24 havi hűség esetén kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.MonthlyDiscountDuration || this.tariffPackageItem.MonthlyDiscountDuration < 0 || this.tariffPackageItem.MonthlyDiscountDuration > 24) {
            validationErrors["MonthlyDiscountDuration"] = "Havidíjkedvezmény időtartama 24 havi hűség esetén (hó) kitöltése kötelező! Maximum érték: 24"
        }

        if (!this.tariffPackageItem.MonthlyDiscountTwelve || this.tariffPackageItem.MonthlyDiscountTwelve < 0) {
            validationErrors["MonthlyDiscountTwelve"] = "Havidíjkedvezmény 12 havi hűség esetén kitöltése kötelező!"
        }

        if (!this.tariffPackageItem.MonthlyDiscountDurationTwelve || this.tariffPackageItem.MonthlyDiscountDurationTwelve < 0 || this.tariffPackageItem.MonthlyDiscountDurationTwelve > 12) {
            validationErrors["MonthlyDiscountDurationTwelve"] = "Havidíjkedvezmény időtartama 12 havi hűség esetén (hó) kitöltése kötelező! Maximum érték: 12"
        }

        if (!this.tariffPackageItem.Description || this.tariffPackageItem.Description === "<p></p>\n") {
            validationErrors["Description"] = "Tarifacsomag rövid leírása kitöltése kötelező!"
        }

        // console.log(validationErrors)
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsTariffPackage).length === 0) {
            return false;
        }

        return true;
    }


}