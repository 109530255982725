import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import withNavigation from '../../../hocs/withNavigation';
import { Button, Confirm, Grid } from 'semantic-ui-react';
import { CustomHeader } from '../../../components/CustomHeader';
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { flow, makeObservable, observable } from 'mobx';
import { AdminService } from '../../../services/admin.service';
import { RootStore } from '../../../stores/RootStore';
import { InformationObligationStore } from './InformationObligationStore';
import OpenItem from "../../../components/OpenItem";
import DeleteButton from "../../../components/DeleteButton";

interface IStores {
	InformationObligationStore: InformationObligationStore;
}

interface IInformationObligationPageProps {
	navigate?: NavigateFunction;
	RootStore?:RootStore
}

class InformationObligationPage extends React.Component<IInformationObligationPageProps> {
	private stores: IStores;
    AdminService: AdminService;
	showInactive:boolean=false;
	 
	constructor(props: any) {
		super(props);
        this.AdminService = new AdminService();

		this.stores = { InformationObligationStore: new InformationObligationStore(this.props.RootStore, this.props.navigate) };
		makeObservable(this, {            
			showInactive:observable
        })
	}
	componentDidMount(): void {        
        this.stores.InformationObligationStore.getInformationObligationItems();
    }
	
	public render() {
		return (
			<div>
				<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
                        <Confirm open={this.stores.InformationObligationStore.showDeleteInformationObligationPopup}
                            closeOnEscape={false} //disables closing the window by pressing ESC	
                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
                            onConfirm={(event) => { this.stores.InformationObligationStore.confirmDeleteInformationObligation(); }}
                            onCancel={(event) => { this.stores.InformationObligationStore.setshowDeleteInformationObligationPopup(false); }}
                            cancelButton="Mégse"
                            confirmButton="Tovább"
                            content={
                                <div style={{ padding: 30 }}>
                                    <p>
                                        Biztosan véglegesen törlöd a tájékoztatási kötelezettséget? 
                                    </p>                                                                        
                                </div>
                            }
                        ></Confirm>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Tájékoztatási kötelezettségek</CustomHeader>
                                    </div>			
									<div style={{marginBottom: 20}}>
										<Button color="red" onClick={() => {this.props.navigate('/administration/information-obligation-details/0')}}>Tájékoztatási kötelezettség rögzítése</Button>								
									</div>					
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>                                            
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1402px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.InformationObligationStore.informationObligationItems}											
                                                    columnDefs={this.stores.InformationObligationStore.columnDef}
                                                    localeText={AgGridText}
                                                    frameworkComponents={{ btnCellRendererOpenItem: OpenItem,
                                                        btnCellRenderer: DeleteButton }}
                                                    pagination={true}
                                                    paginationPageSize={10}
													gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(InformationObligationPage)));
