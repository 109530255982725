import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IWithProps {}

const withNavigation =
	<P extends object>(Component: React.ComponentType<P>): React.FC<P & IWithProps> =>
	(props) => {
		const navigate  = useNavigate();

		return <Component navigate ={navigate } {...(props as P)} />;
	};

export default withNavigation;
