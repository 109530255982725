import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { AdminService } from "../../../services/admin.service";

export class UserGuideStore {
    
    isUploadConfirmOpen: boolean = false;
    uploadIdString: string = "uploadId";    
    file: any;
    AdminService: AdminService
    isSuccedeed: boolean = false;

    constructor(){
        makeAutoObservable(this, {            
            isUploadConfirmOpen: observable,
            uploadIdString: observable,            
            isSuccedeed: observable,            
            cancelUpload: action,
            setIsUploadConfirmOpen: action,
            confirmUpload: flow,
        })
        
        this.AdminService = new AdminService()
    }        

    cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        this.isUploadConfirmOpen = false;        
    }

    setIsUploadConfirmOpen = () => {
        this.isUploadConfirmOpen = true;        
    }    

    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
        console.log('Kiválasztott file:', this.file)
    }

    *confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {        
        this.isUploadConfirmOpen = false;
        let formData: FormData = new FormData();
        this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        yield this.AdminService.UploadUserGuide(formData); 
        console.log(this.file)
        this.isSuccedeed = true;
    }
}