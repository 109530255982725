import { observer, Provider } from 'mobx-react';
import React, { createRef, Suspense } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Ref } from 'semantic-ui-react';
import './App.css';
import LayoutMenu from './components/LayoutMenu';
import Layout from './components/Layout';
import { Loading } from './components/Loading';
import withNavigation from './hocs/withNavigation';
import LoginPage from './pages/login/LoginPage';
import InfoVideoLoginPage from './pages/infovideo/InfoVideoLoginPage';
import InfoVideoPage from './pages/infovideo/InfoVideoPage';
import { RootStore } from './stores/RootStore';
import { UserService } from './services/user.service'
import { User } from './models/user';
import { flow, makeObservable } from 'mobx';
import logo from './assets/logo.png'
import { Dropdown, Icon, Image, Menu } from 'semantic-ui-react';

interface IAppProps {
	navigate?: NavigateFunction
}

class App extends React.Component<IAppProps> {
	pageRef = createRef();
	RootStore: RootStore;
	UserSevice: UserService;

	lockTimeStr = "";
	maxLockTime = 60 * 10;
	lockTime: any;

	constructor(props: any) {
		super(props);

		this.RootStore = new RootStore(this.props.navigate);
		// this.UserSevice = new UserService();

		makeObservable(this, {
			// getLogin: flow,

		})
	}
	// async componentWillUnmount() {
	// 	let cicc=1;
	// 	await this.RootStore.logOut();
	// 	cicc=2;
	// }
	componentDidMount(): void {
		// this.getLogin();		
		// this.getCurrentUser();
		// console.log('App componentDidMount')
		this.lockTime = this.maxLockTime;

		document.onmousemove = () => {
			this.lockTime = this.maxLockTime;
		};
		const addEventListener = window.addEventListener as any;

		// window.onbeforeunload = async (event) => {
		// 	var message = 'Important: Please click on \'Save\' button to leave this page.';
		// 	if (typeof event == 'undefined') {
		// 	  event = window.event;
		// 	}
		// 	if (event) {
		// 	  event.returnValue = message;
		// 	}
		// 	let debug = await this.UserSevice.logOut();
		// 	return message;
		//   };
		// addEventListener('beforeunload', async (e: any) => {
		// 	// console.log('brdformstore beforeunload');
		// 	//this.UserSevice.logOut();
		// 	if (performance..navigation.type == 1) {

		// 	} else {
		// 		e.preventDefault();
		// 		e.returnValue = 'Chrome';
		// 		await this.RootStore.logOut();
		// 		// navigator.sendBeacon(`/api/user/logOut`);
		// 		return 'Chrome';

		// 	}


		// });
		

		setInterval(
			() => {
				this.lockTime--;

				if (this.lockTime == 0) {
					this.RootStore.logOut();
				}
			}, 1000);
	}




	public render() {
		if (!this.RootStore.isIsInited) {
			return null;
		}

		if (!this.RootStore.currentUser && !this.RootStore.isInfoVideoLogin) {
			return (
				<Provider RootStore={this.RootStore}>
					<div style={{ width: '98%', margin: 'auto', marginTop: 15 }}>
						<LoginPage></LoginPage>
					</div>
				</Provider>
			)
		}

		if (this.RootStore.currentUser && !this.RootStore.isInfoVideoLogin && !this.RootStore.isInfoVideo) {
			return (
				<Provider RootStore={this.RootStore}>
					<div style={{ width: '98%', margin: 'auto', marginTop: 15 }}>
						<Suspense fallback={<Loading />}>
							<Ref innerRef={this.pageRef as any}>
								<React.Fragment>
									{/* && (window.location.href.indexOf('/change-password') < 1)  */}
									{(window.location.href.indexOf('/print-package') < 1) && (
										<LayoutMenu />
									)}
									{this.RootStore.currentUser.IsAnyNotCompletedModuleInProgress && (
										<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', paddingTop: 3, background: '#ffcc00', border: '#ffcc00', borderRadius: '1px solid #ffcc00', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', height: 30}}>Teljesítendő továbbképzésed van!</div>
									)}
									{this.RootStore.currentUser.IsAnyNotCompletedExpiredModule && (
										<div style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '20px', paddingTop: 3, background: '#ffcc00', border: '#ffcc00', borderRadius: '10px', marginTop: 5, height: 30}}>Lejárt határidejű továbbképzésed van, jelezd az Áruházi adminisztrátornak!</div>
									)}
									<Layout />
								</React.Fragment>
							</Ref>
						</Suspense>
						{this.RootStore.loadingCount > 0 &&
							<div >
								<div className="blur"></div>
								<div className="progress" style={{ padding: '5px' }}>
									<div style={{ background: 'white' }}>
										<Image src={logo} style={{ width: '289px' }} />
									</div>

								</div>
							</div>
						}
					</div>
				</Provider >
			);
		}

		if (this.RootStore.isInfoVideoLogin) {
			return (
				<Provider RootStore={this.RootStore}>
					<div style={{ width: '98%', margin: 'auto', marginTop: 15 }}>
						<InfoVideoLoginPage></InfoVideoLoginPage>
					</div>
				</Provider>
			)
		}

		if (this.RootStore.isInfoVideo) {
			return (
				<Provider RootStore={this.RootStore}>
					<div style={{ width: '98%', margin: 'auto', marginTop: 15 }}>
						<InfoVideoPage></InfoVideoPage>
					</div>
				</Provider>
			)
		}

	}
}

export default withNavigation(observer(App));
