import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Dropdown, Grid, Input, Label } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import OpenItem from "../../../components/OpenItem";
import { AgGridText } from "../../../core/agGridText";
import withNavigation from "../../../hocs/withNavigation";
import { RootStore } from "../../../stores/RootStore";
import { RateDeviceDiscountStore } from "./RateDeviceDiscountStore";
import DeleteButton from "../../../components/DeleteButton";

interface IStores {
    RateDeviceDiscountStore: RateDeviceDiscountStore
}

interface IRateDeviceDiscountPageProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore
}

class RateDeviceDiscountPage extends React.Component<IRateDeviceDiscountPageProps> {
    private stores: IStores

    constructor(props: any){
        super(props);

        this.stores = { RateDeviceDiscountStore: new RateDeviceDiscountStore(this.props.RootStore,this.props.navigate)}
    }

    componentDidMount(): void {
        this.stores.RateDeviceDiscountStore.GetDeviceDiscounts()
    }

    public render() {
        return <div>
                <Provider {...this.stores}>
                <Confirm open={this.stores.RateDeviceDiscountStore.isUploadConfirmOpen}
                    closeOnEscape={false}
                    closeOnDimmerClick={false}
                    onConfirm={(event) => {this.stores.RateDeviceDiscountStore.confirmUpload(event);}}
                    onCancel={(event) => {this.stores.RateDeviceDiscountStore.cancelUpload(event);}}
                    cancelButton="Mégse"
                    confirmButton={ this.stores.RateDeviceDiscountStore.file ? "Feltöltés" : null}
                    content={
                        <div>
                            <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                                
                                <Input hidden id={this.stores.RateDeviceDiscountStore.uploadIdString} onChange={(event) => {this.stores.RateDeviceDiscountStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                            </Label>
                        </div>
                    }>                    
                </Confirm>
                <Confirm open={this.stores.RateDeviceDiscountStore.showDeleteRateDeviceDiscountPopup}
                    closeOnEscape={false} //disables closing the window by pressing ESC	
                    closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
                    onConfirm={(event) => { this.stores.RateDeviceDiscountStore.confirmDeleteRateDeviceDiscount(); }}
                    onCancel={(event) => { this.stores.RateDeviceDiscountStore.setShowDeleteRateDeviceDiscountPopup(false); }}
                    cancelButton="Mégse"
                    confirmButton="Tovább"
                    content={
                        <div style={{ padding: 30 }}>
                            <p>
                                Biztosan véglegesen törlöd a készülék jogán járó kedvezményt? 
                            </p>                                                                        
                        </div>
                    }
                ></Confirm>
                <div style={{ margin: 20, width: '95%' }}>
                        <Grid>
                            <Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Készülék jogán járó kedvezmény</CustomHeader>
                                    </div>			
                                    <div style={{marginBottom: 20, display: 'flex'}}>
                                        <Button color="red" onClick={() => {this.props.navigate('/administration/rate-device-discount/0')}}>Új kedvezmény rögzítése</Button>								
                                        <Button primary onClick={() => {this.stores.RateDeviceDiscountStore.openIsQueryPopupOpen()}}>Lekérés</Button>
                                        <Button color='orange' onClick={() => {this.stores.RateDeviceDiscountStore.setIsUploadConfirmOpen()}}>Tömeges betöltés</Button>
                                    </div>	
                                    <Confirm open={this.stores.RateDeviceDiscountStore.isQueryPopupOpen}	
                                        closeOnEscape={false} //disables closing the window by pressing ESC	
                                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                                        onCancel={(event) => {this.stores.RateDeviceDiscountStore.closeIsQueryPopupOpen();}}	
                                        onConfirm={(event) => {this.stores.RateDeviceDiscountStore.generate();}}
                                        cancelButton="Mégse"
                                        confirmButton="Lekérés"								
                                        content={
                                            <div style={{ padding: 30 }}>
                                                <Dropdown
                                                    selection																						                                                    
                                                    options={this.stores.RateDeviceDiscountStore.generateOptions}				
                                                    value={this.stores.RateDeviceDiscountStore.generateId}
                                                    onChange={(event, data) => {this.stores.RateDeviceDiscountStore.updateGenerateId(data.value)}}
                                                >
                                                </Dropdown>
                                            </div>
                                        }
                                    ></Confirm>				
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1400px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.RateDeviceDiscountStore.deviceDiscountItems}											
                                                    columnDefs={this.stores.RateDeviceDiscountStore.columnDef}
                                                    localeText={AgGridText}                                                    
                                                    pagination={true}
                                                    frameworkComponents={{ btnCellRenderer: OpenItem,
                                                        btnDeleteCellRenderer: DeleteButton }}
                                                    paginationPageSize={10}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
                            </Grid.Row>
                        </Grid>
                    </div>
                </Provider>
            </div>
    }
}

export default withNavigation(inject('RootStore')(observer(RateDeviceDiscountPage)));