import React from 'react';
import ReactDOM from 'react-dom';

export interface IPortalProps {
	id?: string;
}

export class Portal extends React.PureComponent<IPortalProps> {
	private el: HTMLDivElement;

	constructor(props: IPortalProps) {
		super(props);

		this.el = document.createElement('div');
	}

	componentDidMount() {
		let target = document.body;

		if (this.props.id) {
			target = document.getElementById(this.props.id);
		}

		target?.appendChild(this.el);
	}

	componentWillUnmount() {
		let target = document.body;

		if (this.props.id) {
			target = document.getElementById(this.props.id);
		}

		target?.removeChild(this.el);
	}

	render() {
		return ReactDOM.createPortal(this.props.children, this.el);
	}
}
