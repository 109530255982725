import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Dropdown, Grid } from 'semantic-ui-react';
import withNavigation from '../../hocs/withNavigation';
import { HomeStore } from './HomeStore';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../core/agGridText';
import OpenItem from "../../components/OpenItem";
import { GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { RootStore } from '../../stores/RootStore';


interface IStores {
	HomeStore: HomeStore;
}

interface IHomePageProps {
	navigate?: NavigateFunction;
	RootStore?: RootStore;
}

class HomePage extends React.Component<IHomePageProps> {
	private stores: IStores;

	constructor(props: any) {
		super(props);

		this.stores = { HomeStore: new HomeStore(this.props.navigate, this.props.RootStore) };

	}

	handleGridReady(event: GridReadyEvent) {
		event.api.sizeColumnsToFit();
	}

	componentDidMount(): void {
		// autorun(() => {
		// 	console.log(this.props.RootStore.selectedStore);
		// 	if (this.props.RootStore.selectedStore)

		// });
		let storeCookie=	this.stores.HomeStore.readCookie('SelectedStore')

		if(storeCookie){
			this.stores.HomeStore.selectedStoreCodeTask = storeCookie;
			this.stores.HomeStore.selectedStoreCodeOffer = storeCookie;
		}else{
			this.stores.HomeStore.selectedStoreCodeTask = 'all';
			this.stores.HomeStore.selectedStoreCodeOffer = 'all';
		}
	
		this.stores.HomeStore.getOffersWithinDays(7)
		this.stores.HomeStore.getTasks();
		this.stores.HomeStore.getOfferStatuses()
		this.stores.HomeStore.getOfferIntervals()

	}


	public render() {
		return (
			<div>
				<Provider {...this.stores}>
					<div>
						<Grid style={{ paddingTop: 50 }}>
							<Grid.Row centered>
								<Grid.Column width={12}>
									<div style={{
										background: '#df0000', width: '1400px', height: '57px', textAlign: 'center', paddingTop: 10,
										border: '1px solid #df0000', borderBottom: 'none', display: 'flex', color: 'white'
									}}>
										<div style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '606px', marginRight: 'auto', paddingTop: 8 }}>Ajánlatböngésző</div>
										{this.props.RootStore.currentUser.IsGlobal && <div>
										<Dropdown

											selection
											value={this.stores.HomeStore.selectedStoreCodeOffer}
											options={this.props.RootStore.storesOfferTasks}
											onChange={(event, data) => this.stores.HomeStore.onchangeOfferStore('IsLoanDemand', data.value)}
										/>
									</div>}
										<div style={{ marginLeft: '10px' }}>
											<Dropdown value={this.stores.HomeStore.offerStatusId}
												selection
												options={this.stores.HomeStore.offerStatusOptions}
												onChange={(event, value) => {
													this.stores.HomeStore.handleOfferStatusIdChange(event, value)
												}}>
											</Dropdown>
										</div>
										<div style={{ marginLeft: '10px', marginRight: '10px' }}>
											<Dropdown value={this.stores.HomeStore.offerIntervalId}
												selection
												options={this.stores.HomeStore.offerIntervalOptions}
												onChange={(event, value) => {
													this.stores.HomeStore.handleOfferIntervalIdChange(event, value)
												}}>
											</Dropdown>
										</div>
										
									</div>
									<div id="grid" className="ag-theme-alpine" style={{ width: '1400px', height: '519px' }}>
										<AgGridReact
											rowData={this.stores.HomeStore.ajanlatbongeszItems}
											columnDefs={this.stores.HomeStore.columnDef}
											localeText={AgGridText}
											pagination={true}
											frameworkComponents={{ btnCellRenderer: OpenItem }}
											paginationPageSize={10}
											onGridReady={this.handleGridReady}
											gridOptions={{
												paginationNumberFormatter: (params: any) => {
													return params.value.toLocaleString();
												},
											}}
										>
										</AgGridReact>
									</div>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row centered>
								<Grid.Column width={12}>
								<div style={{
										background: '#df0000', width: '1400px', height: '57px', textAlign: 'center', paddingTop: 10,
										border: '1px solid #df0000', borderBottom: 'none', display: 'flex', color: 'white'
									}}>
										<div style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: '606px', marginRight: 'auto', paddingTop: 8 }}>Aktív feladataim</div>
									
									{this.props.RootStore.currentUser.IsGlobal && <div style={{  marginRight: '10px' }}>
										<Dropdown

											selection
											value={this.stores.HomeStore.selectedStoreCodeOffer}
											options={this.props.RootStore.storesOfferTasks}
											onChange={(event, data) => this.stores.HomeStore.onchangeTaskStore('IsLoanDemand', data.value)}
										/>
									</div>}

									
									
									</div>
									
									<div id="grid" className="ag-theme-alpine" style={{ width: '1400px', height: '519px' }}>
										<AgGridReact
											rowData={this.stores.HomeStore.tasks}
											columnDefs={this.stores.HomeStore.columnDefTasks}
											localeText={AgGridText}
											pagination={true}
											frameworkComponents={{ btnCellRenderer: OpenItem }}
											onGridReady={this.handleGridReady}
											paginationPageSize={10}
											gridOptions={{
												paginationNumberFormatter: (params: any) => {
													return params.value.toLocaleString();
												},
											}}>
										</AgGridReact>
									</div>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(HomePage)));