export const AgGridText = {
    filters: 'Szűrők',
    noRowsToShow: 'Nincs egyetlen elem sem a listában.',
    selectAll: 'Összes',
    searchOoo: 'Keresés...',
    blanks: 'Üres',
    equals: 'Egyenlő',
    notEqual: 'Nem egyenlő',
    lessThan: 'Kisebb, mint',
    greaterThan: 'Nagyobb, mint',
    lessThanOrEqual: 'Kisebb vagy egyenlő',
    greaterThanOrEqual: 'Nagyobb vagy egyenlő',
    inRange: 'Között',
    inRangeStart: '-ig',
    inRangeEnd: '-tól',
    andCondition: 'és',
    orCondition: 'vagy',
    noMatches: 'Nincs találat.',
    filterOoo: 'Szűrés...',
    contains: 'Tartalmazza',
    notContains: 'Nem tartalmazza',
    startsWith: 'Kezdete',
    endsWith: 'Vége',
    to: '-',
    of: '/',
    page: 'Oldal:',
    loadingOoo: 'Betöltés...',
    thousandSeparator: '.'
}