import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Grid, Input, Label } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { UserGuideStore } from "./UserGuideStore";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AdminService } from '../../../services/admin.service'

interface IStores {
    UserGuideStore: UserGuideStore
}

interface IUserGuidePageProps {
    navigate?: NavigateFunction;
}

class UserGuidePage extends React.Component<IUserGuidePageProps> {
    private stores: IStores
    AdminService: AdminService;

    constructor(props: any){
        super(props);

        this.stores = { UserGuideStore: new UserGuideStore()}
        this.AdminService = new AdminService();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
            <Confirm open={this.stores.UserGuideStore.isUploadConfirmOpen}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                onConfirm={(event) => {this.stores.UserGuideStore.confirmUpload(event);}}
                onCancel={(event) => {this.stores.UserGuideStore.cancelUpload(event);}}
                cancelButton="Mégse"
                confirmButton="Feltöltés"
                content={
                    <div>
                        <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                            <Input hidden id={this.stores.UserGuideStore.uploadIdString} onChange={(event) => {this.stores.UserGuideStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                        </Label>
                    </div>
                }>                    
            </Confirm>
            <div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Felhasználói kézikönyv (Súgó)</CustomHeader>
                                    </div>		
                                    <div style={{marginBottom: 20}}>
                                        <Button color="green" onClick={() => {this.stores.UserGuideStore.setIsUploadConfirmOpen()}}>Feltöltés</Button>
                                    </div>   
                                    {this.stores.UserGuideStore.isSuccedeed && (
                                        <div>Sikeres feltöltés!</div>
                                    )}                                 
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(UserGuidePage)));