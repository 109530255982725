import { action, computed, flow, makeAutoObservable, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';
import { User } from '../models/user';
import { AdminService } from '../services/admin.service';
import { UserService } from '../services/user.service';
import { Store } from '../models/store';
import { OfferService } from '../services/offer.service';

export class RootStore {
	showFullMenu: boolean = false;
	isUserAuthed: boolean = false;
	navigate: NavigateFunction;
	UserName: string = null;
	Password: string = null;
	UserService: UserService;
	loadingCount: number = 0;
	loadings: string[] = [];
	currentUser: User = null;
	isIsInited: boolean = false;
	loginFailure: boolean = false;
	loginFailMessage: string = "";
	isInfoVideoLogin: boolean = false;
	isInfoVideo: boolean = false;
	selectedStore: any;
	stores: Array<any> = [];
	storesOfferTasks: Array<any> = [];
	userGuide: any;
	isDownloadUserGuide: boolean = false;
	offerStoreId: number;
	storeName: string = "";
    fromUserModuleAssignment: boolean = false;

	AdminService: AdminService
	OfferService: OfferService;
	packagePriceError:boolean=false;	

	constructor(navigate: NavigateFunction) {
		makeAutoObservable(this, {
			showFullMenu: observable,
			isUserAuthed: observable,
			UserName: observable,
			Password: observable,
			loadingCount: observable,
			loadings: observable,
			currentUser: observable,
			isIsInited: observable,
			loginFailure: observable,
			loginFailMessage: observable,
			isInfoVideoLogin: observable,
			isInfoVideo: observable,
			userGuide: observable,
			isDownloadUserGuide: observable,
			setFullMenu: action,
			setIsUserAuthed: action,
			onChangeUserName: action,
			onChangePassword: action,
			onchangeSelectedStore: action,
			setIsInfoVideoLogin: action,
			infovideoLogin: action,
			logOut: flow,
			login: flow,
			getCurrentUser: flow,
			getStores: flow,
			getUserGuide: flow,
			getStoreName: flow,
			isAuthed: computed,
			selectedStore: observable,
			stores: observable,
			storesOfferTasks: observable,
			packagePriceError:observable
		});

		this.navigate = navigate
		this.UserService = new UserService();
		this.AdminService = new AdminService();
		this.OfferService = new OfferService();
		this.getStores();			
				
	}
	*getStores() {
		const response: Array<Store> = yield this.AdminService.GetStores();
		this.stores = response;
		this.storesOfferTasks = response;
		let all = {
			Id: 0,
			Name: "Mind",
			SAPCode: "H006",
			text: "Mind",
			value: "all",
		}
		this.storesOfferTasks.unshift(all)
		this.getCurrentUser();
	}
	*getCurrentUser() {

		const response: User = yield this.UserService.GetCurrentUser();
		if (response.AspNetUserId) {
			this.currentUser = response;

		}
		if (this.currentUser && this.currentUser.StoreCode) {
			this.selectedStore = this.currentUser.StoreCode;
		}
		else {
			this.selectedStore = this.stores[0].value;
		}
		this.isIsInited = true;
		console.log('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW',this.selectedStore)
	}

	*getUserGuide() {
		this.userGuide = this.OfferService.GetSystemDocumentByType(1);
	}

	handleKeyDown(e: KeyboardEvent) {
		if (e.code === "Enter") {
			this.login();
		}				
	}
	setIsDownloadUserGuide = () => {
		this.isDownloadUserGuide = true;
		setTimeout(()=>{this.isDownloadUserGuide = false}, 1000)
		console.log(this.userGuide)
	}

	setFullMenu(state: boolean) {
		this.showFullMenu = state;
	}
	
	setIsUserAuthed = () => {
		this.isUserAuthed = true;
		this.navigate('/home')
		console.log('User authorized:', this.isUserAuthed)
	}
	*getStoreName() {
		let index = this.stores.findIndex(a => a.value == this.selectedStore);

		if (this.currentUser.HoldingAdmin) {
			let id = Number(window.location.href.substring(window.location.href.indexOf('offer/')+6))

			this.offerStoreId = yield this.OfferService.GetOfferStoreId(id);

			index = this.stores.findIndex(a => a.Id == this.offerStoreId);
		}

		console.log('this.selectedStorethis.selectedStorethis.selectedStorethis.selectedStorethis.selectedStorethis.selectedStorethis.selectedStore:', this.selectedStore, this.stores)

		this.storeName = this.stores[index].Name;
	}
	getDisabledStoreDDL() {
		return !(this.currentUser && this.currentUser.HoldingAdmin && window.location.href.indexOf('/home') > -1)
	}
	onchangeSelectedStore = (event: any, data: any) => {
		this.selectedStore = data;
		let index = this.stores.findIndex(a => a.value == this.selectedStore);
		this.storeName = this.stores[index].Name;

	}

	onChangeUserName = (event: any, data: any) => {
		this.UserName = data.value		
	}

	onChangePassword = (event: any, data: any) => {
		this.Password = data.value		
	}
	addloading = (loading: string) => {
		console.log("ADD: " + loading);
		this.loadings.push(loading);
		this.loadingCount++;
	}
	removeLoading = (loading: string) => {
		console.log("Remove: " + loading);
		this.removeFromArray(this.loadings, loading);
		this.loadingCount--;
	}
	removeFromArray = (array: any[], element: any) => {
		var index = array.indexOf(element);
		if (index > -1) {
			array.splice(index, 1);
		}
	}
	removeAllLoading(){
		this.loadings=[];
	}

	setIsInfoVideoLogin = () => {
		this.isInfoVideoLogin = !this.isInfoVideoLogin;
		if (this.isInfoVideoLogin) {
			// this.navigate('/video-login')
		}
	}

	infovideoLogin = () => {
		this.isInfoVideo = true;
		this.isInfoVideoLogin = false;
		console.log('navigate to: infovideo details')
	}

	navigateToPasswordChange = () => {
		this.navigate('/change-password');
	}	

	setFromModuleAssignment(value: boolean) {
		this.fromUserModuleAssignment = value;
	}

	*login() {
		this.addloading('login');
		var result: string = yield this.UserService.Login(this.UserName, this.Password)
		if (result == "success") {
			this.isUserAuthed = true;

			const response: User = yield this.UserService.GetCurrentUser(true);
			this.currentUser = response;
			
			this.selectedStore = this.currentUser.StoreCode;
			this.navigate('/home')
			console.log('User authorized:', this.isUserAuthed)
			console.log(result)
			console.log(this.selectedStore)
			this.removeLoading('login');

		}
		else if (result == "failure") {
			this.loginFailure = true;
			this.loginFailMessage = "Hibás felhasználónév vagy jelszó!";
			console.log('failed to login')
			this.removeLoading('login');
		}
		else if (result == "lockedOut") {
			this.loginFailure = true;
			this.loginFailMessage = "Felhasználó letiltva!";
			console.log('failed to login')
			this.removeLoading('login');
		}
	}
	*logOut() {
		var result: string = yield this.UserService.logOut()
		if (result) {
			this.currentUser = null;
		}

		window.location.href = "/";
	}

	get isAuthed() {
		return this.isUserAuthed;
	}

}
