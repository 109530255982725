import { makeAutoObservable } from "mobx";

export class VodafoneCommission {
    Id: number;
    TariffPackageName: string;
    TariffPackageId: number;
    Discount0_5: number;
    Discount5_25: number;
    Discount25_50: number;
    Discount50_Inf: number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.TariffPackageName = null;
        this.TariffPackageId = 0;
        this.Discount0_5 = null;
        this.Discount5_25 = null;
        this.Discount25_50 = null;
        this.Discount50_Inf = null;
    }
}