import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Checkbox, Dropdown, Grid, GridColumn, Radio, Table } from "semantic-ui-react";
import withNavigation from "../../hocs/withNavigation";
import { RiportStore } from "./RiportStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../core/agGridText';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { RootStore } from "../../stores/RootStore";
import { CustomHeader } from "../../components/CustomHeader";


interface IStores {
    RiportStore: RiportStore
}

interface IRiportPageProps {
    navigate?: NavigateFunction
    RootStore?: RootStore
}

class RiportPage extends React.Component<IRiportPageProps> {
    
    stores: IStores

    constructor(props: any) {
        super(props);

        this.stores = { RiportStore: new RiportStore(this.props.RootStore)}
    }

    componentDidMount(): void {
        this.stores.RiportStore.GetRiportFrequencies();
        this.stores.RiportStore.GetRiportTypes();
        //this.stores.RiportStore.GetUserRiportFrequences();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div>
                    <Grid style={{paddingTop: 50}}>                            
                        <Grid.Row centered>
                            <Grid.Column width={14}>																		
                                <div style={{background: 'rgb(248, 248, 248)', width: 'max-width', height: '50px', textAlign: 'center', paddingTop: 7, 
                                            border: '1px solid rgb(186, 191, 199)', display: 'flex'}}>
                                    {/* <div style={{fontWeight: 'bold', fontSize: '16px', marginLeft: '606px', marginRight: 'auto'}}></div> */}
                                    <div style={{display: 'flex', marginRight: 20, marginLeft: 20}}>
                                        <label style={{paddingTop: 9, marginRight: 5}}>Típus:</label>
                                        <div style={{width: 335}}>
                                            <Dropdown
                                                selection
                                                fluid
                                                placeholder='Kérem, válasszon...'
                                                options={this.stores.RiportStore.riportTypesOptions}                                
                                                value={this.stores.RiportStore.selectedRiportTypeId}
                                                onChange={(event, data) => { this.stores.RiportStore.changeSelectedRiportTypeId(data.value) }}
                                            />
                                        </div>                                        
                                    </div>
                                    <div style={{display: 'flex', marginRight: 20, marginLeft: 20}}>
                                        <label style={{paddingTop: 9, marginRight: 5}}>Létrehozás dátumától:</label>                                        
                                        <SemanticDatepicker firstDayOfWeek={1} value={this.stores.RiportStore.startDate ? new Date(this.stores.RiportStore.startDate) : null}
                                            onChange={(event, data) => { this.stores.RiportStore.updateStartDate(data.value) }} maxDate={this.stores.RiportStore.getMaxDate}></SemanticDatepicker>                                        
                                    </div>
                                    <div style={{display: 'flex', marginRight: 20}}>
                                        <label style={{paddingTop: 9, marginRight: 5}}>Létrehozás dátumáig:</label>
                                        <SemanticDatepicker firstDayOfWeek={1} value={this.stores.RiportStore.endDate ? new Date(this.stores.RiportStore.endDate) : null}
                                            onChange={(event, data) => { this.stores.RiportStore.updateEndDate(data.value) }} maxDate={this.stores.RiportStore.getMaxDateForEndDate} minDate={this.stores.RiportStore.getMinDateForEndDate}></SemanticDatepicker>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <label style={{paddingTop: 9, marginRight: 5}}>Csak 12 hónapos hűségidejű csomagok:</label>
                                        <Checkbox style={{paddingTop: 11}} checked={this.stores.RiportStore.isOnlyTwelveMonth === 1} value={this.stores.RiportStore.isOnlyTwelveMonth}
                                            onChange={(event, data) => {this.stores.RiportStore.updateIsOnlyTwelveMOnth()}}></Checkbox>
                                    </div>
                                    <div style={{display: 'flex', marginLeft: 'auto', paddingBottom: 5}}>
                                        <Button compact color="green" disabled={this.stores.RiportStore.getDisabled}
                                        onClick={() => {this.stores.RiportStore.handleGenerate()}}>Lekérés</Button>
                                    </div>
                                </div>									
                            </Grid.Column>
                        </Grid.Row>	
                        <Grid.Row centered>
                            <Grid.Column width={14}>
                                <Grid>
                                    <div style={{width: '100%'}}>
                                        <CustomHeader>Automatizált e-mail kiküldés</CustomHeader>
                                    </div>                                                                        
                                    <Grid.Row>                                        
                                        <Grid.Column width={4}>
                                            <label>Automatizált e-mailek kiküldésének gyakoriság</label>
                                            <Dropdown
                                                selection
                                                fluid
                                                placeholder='Kérem, válasszon...'
                                                options={this.stores.RiportStore.riportFrequencesOptions}                                
                                                value={this.stores.RiportStore.selectedRiportFrequenceId}
                                                onChange={(event, data) => { this.stores.RiportStore.changeSelectedRiportFrequenceId(data.value) }}
                                            />
                                        </Grid.Column>         
                                    </Grid.Row>
                                    <Grid.Column width={8}>
                                        <Grid.Row width={'60%'}>
                                            <Table>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Riport megnevezése</Table.HeaderCell>
                                                        <Table.HeaderCell>Igénylés</Table.HeaderCell>                                                
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {this.stores.RiportStore.userRiportFrequencesToSave.map((item, index) => {
                                                    return <Table.Row key={index}>                                                    
                                                        <Table.Cell>{item.RiportTypeName}</Table.Cell>
                                                        <Table.Cell onClick={() => this.stores.RiportStore.changeFrequence(index)}>
                                                            <Radio checked={item.IsRequested == 1}/>    
                                                        </Table.Cell>                                                        
                                                    </Table.Row>
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </Grid.Row>
                                    </Grid.Column>                                                                                 
                                </Grid>
                                {this.stores.RiportStore.selectedRiportFrequenceId > 0 && (
                                    <div style={{textAlign: 'end'}}>
                                        <Button color={this.stores.RiportStore.isModified == true ? 'green' : 'grey'} disabled={this.stores.RiportStore.isModified == true ? false : true} onClick={() => { this.stores.RiportStore.InsertOrUpdateUserRiportFrequences()}}>Mentés</Button>
                                    </div>                                    
                                )}    
                            </Grid.Column>                                               
                        </Grid.Row>						
                    </Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(RiportPage)));