import { makeAutoObservable } from "mobx";

export class TariffPackage {
    Id: number;
    Name: string;
    PackageCode: string;
    ArticleNumberL1: number;
    ArticleNumberL2: number;
    ArticleNumberL3: number;
    ArticleNumberL4: number;
    ArticleNumberL5: number;
    ArticleNumberL6: number;
    ArticleNumberL7: number;
    ArticleNumberL8: number;
    ArticleNumberL9: number;
    ArticleNumberL10: number;
    AdministrativeNameL1: string;
    AdministrativeNameL2: string;
    AdministrativeNameL3: string;
    AdministrativeNameL4: string;
    AdministrativeNameL5: string;
    AdministrativeNameL6: string;
    AdministrativeNameL7: string;
    AdministrativeNameL8: string;
    AdministrativeNameL9: string;
    AdministrativeNameL10: string;
    Description: string;
    AdminsitrationFee: number;
    DeviceDiscount: number;
    MonthlyDiscount: number;
    MonthlyDiscountDuration: number;
    MonthlyDiscountTwelve: number;
    MonthlyDiscountDurationTwelve: number;
    PackagePrice: number;
    IsActive: number;
    IsActivePackageItem: boolean;
    IsHighestPrice: boolean;
    Logo: string;
    CalculatedMonthlyPriceTwentyFour: number;
    CalculatedMonthlyPriceTwelve: number;
    CalculatedMonthlyPriceTwentyFourSum: number;
    CalculatedMonthlyPriceTwelveSum: number;
    CustomNamePackageCodeForAutoComplete: string;
    NeedTwentyTwo:number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.Name = null;
        this.PackageCode = null;
        this.ArticleNumberL1 = null;
        this.ArticleNumberL2 = null;
        this.ArticleNumberL3 = null;
        this.ArticleNumberL4 = null;
        this.ArticleNumberL5 = null;
        this.ArticleNumberL6 = null;
        this.ArticleNumberL7 = null;
        this.ArticleNumberL8 = null;
        this.ArticleNumberL9 = null;
        this.ArticleNumberL10 = null;
        this.AdministrativeNameL1 = null;
        this.AdministrativeNameL2 = null;
        this.AdministrativeNameL3 = null;
        this.AdministrativeNameL4 = null;
        this.AdministrativeNameL5 = null;
        this.AdministrativeNameL6 = null;
        this.AdministrativeNameL7 = null;
        this.AdministrativeNameL8 = null;
        this.AdministrativeNameL9 = null;
        this.AdministrativeNameL10 = null;
        this.Description = null;
        this.AdminsitrationFee = null;
        this.DeviceDiscount = null;
        this.MonthlyDiscount = null;
        this.MonthlyDiscountDuration = null;
        this.MonthlyDiscountTwelve = null;
        this.MonthlyDiscountDurationTwelve = null;
        this.PackagePrice = null;
        this.IsActive = 1;
        this.IsActivePackageItem = null;
        this.IsHighestPrice = false;
        this.Logo = null;
        this.CalculatedMonthlyPriceTwentyFour = 0;
        this.CalculatedMonthlyPriceTwelve = 0;
        this.CalculatedMonthlyPriceTwentyFourSum = 0;
        this.CalculatedMonthlyPriceTwelveSum = 0;   
        this.CustomNamePackageCodeForAutoComplete = null;     
        this.NeedTwentyTwo=0;
    }
}