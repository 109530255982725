import { makeAutoObservable } from "mobx";

export class RateCommission {
    Id: number;
    Lnumber: number;
    ModifiedById: number;
    ModifiedDate: Date;
    ModifiedByName: string;
    ModifiedDateString: string;
    PrevLnumber: number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.Lnumber = 0;
        this.ModifiedById = 0;
        this.ModifiedDate = null;
        this.ModifiedByName = null;
        this.ModifiedDateString = null;
        this.PrevLnumber = 0;
    }
}