import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { WarrantyPlusPackage } from "../../../models/warrantyPlusPackage";
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";

export class WarrantyPlusStore {

    private RootStrore:RootStore
    warrantyPlusItems: Array<WarrantyPlusPackage>;
    columnDef: Array<ColDef>;
    warrantyPlusPackageId: number = 0;
    warrantyPlusItem: WarrantyPlusPackage = new WarrantyPlusPackage();
    AdminService:AdminService
    warrantyPlusPackageTypeOptions: Array<any> = [];
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    constructor(rootStrore:RootStore,warrantyPlusPackageId?: number) {
        this.RootStrore= rootStrore;
        makeAutoObservable(this, {
            warrantyPlusItems: observable,
            columnDef: observable,
            warrantyPlusPackageId: observable,
            warrantyPlusItem: observable,
            warrantyPlusPackageTypeOptions: observable,
            isSaved: observable,
            showValidationErrorPopup: observable,
            updateWarrantyItem: action,
            SaveWarrantyPlusPackage:flow,
            GetWarrantyPlusPackegeById:flow,
            getWarrantyPlusPackageTypes:flow,
        })
        this.warrantyPlusPackageId = warrantyPlusPackageId;
        
        this.AdminService=new AdminService();
       
        this.columnDef = [
            {
                field: 'Name', headerName: 'Csomag neve', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350,
                cellRenderer: (params) => {
                    var link = document.createElement('a');
                    link.href = '#/administration/warranty-plus-details/' + params.data.Id;
                    link.innerText = params.value;
                    link.addEventListener('click', (e) => {
                        // e.preventDefault();
                        console.log(params.data.id);
                    });
                    return link;
                }
            },
            { field: 'PackageNumber', headerName: 'Csomag száma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
            { field: 'TypeString', headerName: 'Típus', filter: 'agSetColumnFilter', sortable: true, maxWidth: 350, minWidth: 350, },
            { field: 'Price', headerName: 'Ár', filter: 'agSetColumnFilter', sortable: true, valueFormatter: params => this.numberFormatter(params), maxWidth: 350, minWidth: 350, },
        ]
    }

    numberFormatter = (params: any) => {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
    }

    updateWarrantyItem(key: keyof WarrantyPlusPackage, value: any) {                
        if (key === 'IsActive') {
			if (this.warrantyPlusItem[key] === 1) {
				this.warrantyPlusItem[key] = 0
			}	
			else {
				this.warrantyPlusItem[key] = 1;
			}
		}
		else {
			(this.warrantyPlusItem[key] as any) = value;
		}	
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }
   
    *SaveWarrantyPlusPackage() {
        this.isSaved = true;
        this.showValidationErrorPopup = true;
        if (!this.hasValidationErrors) {
            this.RootStrore.addloading('SaveWarrantyPlusPackage');       
		    yield this.AdminService.SaveWarrantyPlusPackage(this.warrantyPlusItem);
            this.RootStrore.removeLoading('SaveWarrantyPlusPackage');            
        }
        
		
	}
    *GetWarrantyPlusPackegeById(){
        this.RootStrore.addloading('GetWarrantyPlusPackegeById');
        this.warrantyPlusItem=yield this.AdminService.GetWarrantyPlusPackegeById(this.warrantyPlusPackageId);
        this.RootStrore.removeLoading('GetWarrantyPlusPackegeById');

    }

    *getWarrantyPlusPackageTypes() {
        this.warrantyPlusPackageTypeOptions = yield this.AdminService.GetWarrantyPlusPackageTypes();
    }

    get getValidationsWarrantyPlusPackage() {
        if (!this.isSaved) {
            return {}
        }

        let validationErrors: Partial<Record<keyof WarrantyPlusPackage, string>> = {}

        if (!this.warrantyPlusItem.PackageNumber) {
            validationErrors["PackageNumber"] = "Csomag cikkszáma kitöltése kötelező!"
        }

        if (!this.warrantyPlusItem.Name) {
            validationErrors["Name"] = "Csomag neve kitöltése kötelező!"
        }

        if (!this.warrantyPlusItem.ValueFrom) {
            validationErrors["ValueFrom"] = "Támogatott készülékérték-tól kitöltése kötelező!"
        }

        if (!this.warrantyPlusItem.ValueTo) {
            validationErrors["ValueTo"] = "Támogatott készülékérték-ig kitöltése kötelező!"
        }

        if (!this.warrantyPlusItem.Price) {
            validationErrors["Price"] = "Csomag ára kitöltése kötelező!"
        }

        if (!this.warrantyPlusItem.ExtendedWarrantyLengthMonth) {
            validationErrors["ExtendedWarrantyLengthMonth"] = "Csomag kockázatviselési ideje (hónap) kitöltése kötelező!"
        }

        if (!this.warrantyPlusItem.ValidFrom) {
            validationErrors["ValidFrom"] = "Csomag hatályba lépése érvényességének kezdete kitöltése kötelező!"
        }

        // console.log(validationErrors)
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsWarrantyPlusPackage).length === 0) {
            return false;
        }

        return true;
    }
}