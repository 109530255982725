import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import withNavigation from '../../../hocs/withNavigation';
import { Button, Checkbox, Grid } from 'semantic-ui-react';
import { CustomHeader } from '../../../components/CustomHeader';
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { flow, makeObservable, observable } from 'mobx';
import { AdminService } from '../../../services/admin.service';
import { RootStore } from '../../../stores/RootStore';
import { TariffPackageScoreStore } from './TariffPackageScoreStore';

interface IStores {
	TariffPackageScoreStore: TariffPackageScoreStore;
}

interface ITariffPackageScorePageProps {
	navigate?: NavigateFunction;
	RootStore?:RootStore
}

class TariffPackageScorePage extends React.Component<ITariffPackageScorePageProps> {
	private stores: IStores;
    AdminService: AdminService;
	 showInactive:boolean=false;
	 

	constructor(props: any) {
		super(props);
        this.AdminService = new AdminService();

		this.stores = { TariffPackageScoreStore: new TariffPackageScoreStore(this.props.RootStore, this.props.navigate) };
		makeObservable(this, {            
            getTariffPackageScoreItems: flow,
			showInactive:observable
        })
	}
	componentDidMount(): void {        
        this.getTariffPackageScoreItems();
    }
	*getTariffPackageScoreItems() {
        this.props.RootStore.addloading('getTariffPackageScoreItems');
        this.stores.TariffPackageScoreStore.tariffPackageScoreItems = yield this.AdminService.GetTariffPackageScores();
		this.props.RootStore.removeLoading('getTariffPackageScoreItems');
    } 
	public render() {
		return (
			<div>
				<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={13}>
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                                <div style={{width: '1382px'}}>
                                                    <div style={{marginBottom: 20}}>
                                                        <CustomHeader>Tarifacsomagok pontértéke</CustomHeader>
                                                    </div>			
                                                    <div style={{textAlign: 'right', marginBottom: '10px'}}>
                                                        <label>A riport tartalmazza az inaktív elemeket is:</label>
                                                        <Checkbox
                                                                style={{ paddingLeft: 4, paddingTop: 4, marginRight: 12}}											
                                                                checked={this.stores.TariffPackageScoreStore.containsInActive === 1}
                                                                value={this.stores.TariffPackageScoreStore.containsInActive}
                                                                onChange={(event, data) => {this.stores.TariffPackageScoreStore.setContainsInActive() }}
                                                            />
                                                        <Button primary onClick={() => {this.stores.TariffPackageScoreStore.generateTariffPackageScoreExport()}}>
                                                            Adatok exportálása
                                                        </Button>                                    
                                                    </div>
                                                </div>                                                
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>                                    
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                            {/* <div style={{background: 'rgb(248, 248, 248)', width: '1400px', height: '40px', textAlign: 'center', paddingTop: 10, fontWeight: 'bold', fontSize: '16px',
												border: '1px solid rgb(186, 191, 199)', borderBottom: 'none'}}>Ajánlatböngésző (Vodafone BO)</div> */}
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1382px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.TariffPackageScoreStore.tariffPackageScoreItems}											
                                                    columnDefs={this.stores.TariffPackageScoreStore.columnDef}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
													gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(TariffPackageScorePage)));
