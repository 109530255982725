import { Offer } from "../models/offer"
import { Device } from "../models/device"
import { Comment } from "../models/comment";
import { Settlement } from "../models/settlement";
import { TariffPackage } from "../models/tariffPackage";
import { InfovideoAnswers } from "../models/infovideoAnswers";

export class OfferService {




    public async SendNextWFStep(postData: any): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };
        const response = await fetch(`/api/offer/SendNextWFStep`, options)
        return response.json()
    }




    public async SaveOffer(Offer: Offer): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(Offer)
        };
        const response = await fetch(`/api/offer/SaveOffer`, options)
        return response.json()
    }

    public async GetOfferById(id: number): Promise<Offer> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetOfferById?id=' + id, options)
        return response.json();
    }
    public async CheckOfferChange(offer: any): Promise<any> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(offer)
        };
        const response = await fetch(`/api/offer/CheckOfferChange`, options)
        return response.json()
    }

    public async SendSimChangeEmailToVodaBO(Id: number, SimNumber: string): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/SendSimChangeEmailToVodaBO?id=' + Id + '&simNumber=' + SimNumber, options)
        return response;
    }

    public async SendOfferToStart(Id: number): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/SendOfferToStart?id=' + Id, options)
        return response;
    }

    public async CreateSalesDoc(Id: number): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/CreateSalesDoc?id=' + Id, options)
        if (response)
            return response.json();
        else
            return null;
    }
    public async OfferBonding(Id: number): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/OfferBonding?id=' + Id, options)
        return response;
    }
    public async GetWorkFlowHistoryByOfferId(id: number): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetWorkFlowHistoryByOfferId?offerId=' + id, options)
        return response.json();
    }

    public async GetDocumentByObjectId(id: number, type: number, IsOfferDocument: boolean, IsPolicyDocument: boolean, packageId: number, packageNumber: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        window.location.assign(`/api/document/GetDocumentByObjectId?id=` + id + "&type=" + type + "&isOfferDocument=" + IsOfferDocument + "&isPolicyDocument=" + IsPolicyDocument +
            "&packageId=" + packageId + "&packageNumber=" + packageNumber)
        // window.location.assign(response.body as any);
    }
    public async SendDocumentToSigning(offerId: number, documentId: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };
        const response = await fetch(`/api/offer/SendDocumentToSigning?offerId=` + offerId + "&documentId=" + documentId, options)
        return response;
        // window.location.assign(response.body as any);
    }
    public async CheckSignedDocument(offerId: number, documentId: number): Promise<string> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/document/CheckSignedDocument?offerId=' + offerId + "&documentId=" + documentId, options)
        return response.json();
    }

    // public async CheckSignedDocument(offerId: number, documentId: number) {
    //     const options: RequestInit = {
    //         method: "GET",
    //         headers: { Accept: "application/json", "Content-Type": "application/json" },
    //         credentials: "include",

    //     };
    //     const response = await fetch(`/api/offer/CheckSignedDocument?offerId=` + offerId + "&documentId=" + documentId, options)
    //     return response;
    //     // window.location.assign(response.body as any);
    // }

    public async GetSignedDocument(offerId: number, documentId: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        window.location.assign(`/api/document/GetSignedDocument?offerId=` + offerId + '&documentId=' + documentId)
        // window.location.assign(response.body as any);
    }

    public async GetSystemDocumentByType(type: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        window.location.assign(`/api/document/GetSystemDocumentByType?type=` + type)
        // window.location.assign(response.body as any);
    }

    public async GenerateReport(storeCode: string, startDate: Date, endDate: Date, only12month: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        window.location.assign(`/api/report/GenerateReport?storeCode=` + storeCode + "&startDate=" + startDate + "&endDate=" + endDate + "&only12month=" + only12month)
        // window.location.assign(response.body as any);
    }

    public async GenerateReportForStoreAdmin(storeCode: string, startDate: Date, endDate: Date, only12month: number) {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        window.location.assign(`/api/report/GenerateReportForStoreAdmin?storeCode=` + storeCode + "&startDate=" + startDate + "&endDate=" + endDate + "&only12month=" + only12month)
        // window.location.assign(response.body as any);
    }

    public async GetDocumentsOfObject(id: number): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/document/GetDocumentsOfObject?id=` + id, options)
        return response.json();
    }


    public async GetWfSteps(statusCode: number, type: number): Promise<Array<Device>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetWfSteps?statusCode=` + statusCode + "&type=" + type, options)
        return response.json();
    }

    public async GetDeviceByQueryTextRate(queryText: string, storeCode: string): Promise<Array<Device>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetDeviceByQueryTextRate?queryText=` + queryText + "&sapCode=" + storeCode, options)
        return response.json();
    }
    public async GetDeviceByQueryText(queryText: string, storeCode: string): Promise<Array<Device>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };
        let query = queryText.replaceAll('#', '');
        let url = `/api/offer/GetDeviceByQueryText?queryText=` + query + "&sapCode=" + storeCode;

        const response = await fetch(url, options)
        return response.json();
    }

    public async GetScreenProtectorsByQueryText(queryText: string, storeCode: string): Promise<Array<Device>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetScreenProtectorsByQueryText?queryText=` + queryText + "&sapCode=" + storeCode, options)
        return response.json();
    }

    public async GetAccessoriesByQueryText(queryText: string, storeCode: string): Promise<Array<Device>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetAccessoriesByQueryText?queryText=` + queryText + "&sapCode=" + storeCode, options)
        return response.json();
    }

    public async GetSettlementsByZipCodeQuery(queryText: string): Promise<Array<Settlement>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetSettlementsByZipCodeQuery?queryText=` + queryText, options)
        return response.json();
    }

    public async GetTasks(storeCode: string): Promise<Array<Offer>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetTasks?storeCode=' + storeCode, options)
        return response.json();
    }
    public async GetOffers(storeCode: string): Promise<Array<Offer>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetOffers?sapCode=' + storeCode, options)
        return response.json();
    }

    public async GetCommentsByOfferId(offerId: number): Promise<Comment> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetCommentsByOfferId?offerId=' + offerId, options)
        return response.json();
    }

    public async GetCustomerTitlesForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetCustomerTitlesForDDL`, options)
        return response.json();
    }

    public async GetBBGendersForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetBBGendersForDDL`, options)
        return response.json();
    }

    public async GetVodafoneGendersForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetVodafoneGendersForDDL`, options)
        return response.json();
    }

    public async GetIdentityTypesForDDL(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetIdentityTypesForDDL`, options)
        return response.json();
    }

    public async GetOfferDurations(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetOfferDurations`, options)
        return response.json();
    }

    public async GetManufacturerWarrantyExtensionDurations(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetManufacturerWarrantyExtensionDurations`, options)
        return response.json();
    }

    public async GetTariffPackagesByQueryText(queryText: string): Promise<Array<TariffPackage>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetTariffPackagesByQueryText?queryText=` + queryText, options)
        return response.json();
    }

    public async GetOffersWithinDays(days: number, storeCode: string, offerStatusNumber: number): Promise<Array<Offer>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetOffersWithinDays?days=` + days + '&sapCode=' + storeCode + '&offerStatusNumber=' + offerStatusNumber, options)
        return response.json();
    }

    public async GetMobilePhonePrefixes(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetMobilePhonePrefixes`, options)
        return response.json();
    }

    public async GetStreetNamesAfterAddressZipCodeSelect(zipCode: number): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetStreetNamesAfterAddressZipCodeSelect?zipCode=` + zipCode, options)
        return response.json();
    }

    public async GetAddressTypeNamesByAddressName(addressNameString: string, zipCode: number): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetAddressTypeNamesByAddressName?addressNameString=` + addressNameString + "&zipCode=" + zipCode, options)
        return response.json();
    }

    public async GetDefaultAddressTypes(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetDefaultAddressTypes`, options)
        return response.json();
    }

    public async GetAddressNameSuffix(streetName: string, zipCode: number): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",

        };

        const response = await fetch(`/api/offer/GetAddressNameSuffix?streetName=` + streetName + "&zipCode=" + zipCode, options)
        return response.json();
    }

    public async GetAddressStoreys(): Promise<Array<any>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch(`/api/offer/GetAddressStoreys`, options)
        return response.json();
    }

    public async CloneOffer(id: number): Promise<Offer> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/CloneOffer?id=' + id, options)
        return response.json();
    }

    public async GetOfferStoreId(id: number): Promise<number> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetOfferStoreId?id=' + id, options)
        return response.json();
    }

    public async SetDocumentCheckDate(id: number, isSuccessful: boolean): Promise<any> {
        const postData = {
            id: id,
            isSuccessful: isSuccessful
        }
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };
        const response = await fetch('/api/offer/SetDocumentCheckDate', options)
        return response
    }

    public async UploadOfferDocument(formData: any, type: number, offerId: number): Promise<void> {
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: formData
        }
        const requestHeaders: Headers = new Headers();
        //	requestHeaders.append('Content-type', 'multipart/form-data');

        const httpClientOptions: any = {
            headers: requestHeaders,
            credentials: 'include',
            method: 'POST',
            body: formData
        };

        const response = await fetch(`/api/document/UploadOfferDocument?type=` + type + "&offerId=" + offerId, httpClientOptions)
        return response.json();
    }

    public async GetInfoVideoAnswers(id: number): Promise<Array<InfovideoAnswers>> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/GetInfoVideoAnswers?id=' + id, options)
        return response.json();
    }

    public async RemoveOfferDocumentByTypeAndOfferId(type: number, offerId: number): Promise<any> {
        const postData = {
            type: type,
            offerId: offerId
        }
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };
        const response = await fetch('/api/offer/RemoveOfferDocumentByTypeAndOfferId', options)
        return response
    }

    public async SetOfferIsMCCPressed(Id: number): Promise<any> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/SetOfferIsMCCPressed?id=' + Id, options)
        return response;
    }

    public async SetOfferIsInformationObligationRead(Id: number): Promise<boolean> {
        const options: RequestInit = {
            method: "GET",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include"
        };

        const response = await fetch('/api/offer/SetOfferIsInformationObligationRead?id=' + Id, options)
        return response.json();
    }
}