import { RootStore } from '../../stores/RootStore';
import { NavigateFunction } from 'react-router-dom';
import React, { Suspense } from 'react';
import { flow, makeAutoObservable, makeObservable } from 'mobx';
import withNavigation from '../../hocs/withNavigation';
import withParams from '../../hocs/withParams';
import { inject, observer, Provider } from 'mobx-react';
import { OfferService } from '../../services/offer.service';
import { Button, Grid, Icon, Input, Message } from 'semantic-ui-react';
import { ChangePasswordStore } from './ChangePasswordStore';
import { ChangePassword } from "../../models/changePassword";
import { CustomHeader } from '../../components/CustomHeader';

interface IStores {
	ChangePasswordStore: ChangePasswordStore;
}

interface IProps {
	RootStore?: RootStore;
	ChangePasswordStore?: ChangePasswordStore;
	navigate?: NavigateFunction;
}

class ChangePasswordPage extends React.Component<IProps> {
    private stores: IStores;
	OfferService: OfferService
	
    constructor(props: any) {
        super(props);

        this.stores = { ChangePasswordStore: new ChangePasswordStore(this.props.navigate, this.props.RootStore) };               
    }

    componentDidMount(): void {
        this.stores.ChangePasswordStore.changePasswordItem = new ChangePassword();
        this.stores.ChangePasswordStore.changePasswordItem.UserGuid = this.stores.ChangePasswordStore.RootStore.currentUser.UserGuid;
        this.stores.ChangePasswordStore.changePasswordItem.OldPassword = "";
        this.stores.ChangePasswordStore.changePasswordItem.NewPassword = "";
        this.stores.ChangePasswordStore.newPasswordConfirm = "";
    }


    public render() {        

        return <div style={{marginLeft: 15}}>
            <Provider {...this.stores}>                               
                <div style={{marginTop: '65px'}}>
                    <Grid>
                        <Grid.Row centered>
                            <Grid.Column width={3}>
                                <div style={{width: '65%'}}>
                                    <CustomHeader>Jelszó megváltoztatása</CustomHeader>
                                </div>                        
                            </Grid.Column>
                        </Grid.Row>                        
                        <Grid.Row centered>
                                <Grid.Column width={3}>
                                    <div>
                                        <label>Régi jelszó</label>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <Input value={this.stores.ChangePasswordStore.changePasswordItem?.OldPassword} onChange={(event, data) => {this.stores.ChangePasswordStore.updateOldPassword(data.value)}}
                                               type={this.stores.ChangePasswordStore.showOldPassword ? "text" : "password"}></Input>
                                        {!this.stores.ChangePasswordStore.showOldPassword && (
                                            <Icon name="eye" style={{paddingTop: '9px', marginLeft: '8px', cursor: 'pointer'}} onClick={() => {this.stores.ChangePasswordStore.setShowOldPassword()}}></Icon>
                                        )}
                                        {this.stores.ChangePasswordStore.showOldPassword && (
                                            <Icon name="eye slash" style={{paddingTop: '9px', marginLeft: '8px', cursor: 'pointer'}} onClick={() => {this.stores.ChangePasswordStore.setShowOldPassword()}}></Icon>
                                        )}                                        
                                    </div>                                    
                                </Grid.Column>            
                        </Grid.Row>
                        <Grid.Row centered>
                                <Grid.Column width={3}>
                                    <div>
                                        <label>Új jelszó</label>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <Input id="newPassword" value={this.stores.ChangePasswordStore.changePasswordItem?.NewPassword} onChange={(event, data) => {this.stores.ChangePasswordStore.updateNewPassword(data.value)}}
                                            type={this.stores.ChangePasswordStore.showNewPassword ? "text" : "password"} error={!this.stores.ChangePasswordStore.checkCorrectFormNewPassword}></Input>
                                        {!this.stores.ChangePasswordStore.showNewPassword && (
                                            <Icon name="eye" style={{paddingTop: '9px', marginLeft: '8px', cursor: 'pointer'}} onClick={() => {this.stores.ChangePasswordStore.setShowNewPassword()}}></Icon>
                                        )}
                                        {this.stores.ChangePasswordStore.showNewPassword && (
                                            <Icon name="eye slash" style={{paddingTop: '9px', marginLeft: '8px', cursor: 'pointer'}} onClick={() => {this.stores.ChangePasswordStore.setShowNewPassword()}}></Icon>
                                        )}
                                    </div>    
                                    {!this.stores.ChangePasswordStore.checkCorrectFormNewPassword && (
                                        <Message negative>
                                            <Message.Header>A jelszó nem megfelelő!</Message.Header>
                                            <div style={{paddingLeft: '10px', paddingTop: '10px'}}>
                                                <ul>
                                                    <li>Pontosan 10 karakter hosszúságú</li>
                                                    <li>Tartalmaz kis- és nagybetűt is</li>
                                                    <li>Tartalmaz számot</li>
                                                    <li>Tartalmaz legalább egy különleges karaktert</li>
                                                </ul>
                                            </div>
                                        </Message>
                                    )}                                     
                                </Grid.Column>            
                        </Grid.Row>
                        <Grid.Row centered>
                                <Grid.Column width={3}>
                                    <div>
                                        <label>Új jelszó megerősítése</label>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <Input id="passwordConfirm" value={this.stores.ChangePasswordStore.newPasswordConfirm} onChange={(event, data) => {this.stores.ChangePasswordStore.updateNewPasswordConfirm(data.value)}}
                                            type={this.stores.ChangePasswordStore.showNewPasswordConfirm ? "text" : "password"}></Input>
                                        {!this.stores.ChangePasswordStore.showNewPasswordConfirm && (
                                                <Icon name="eye" style={{paddingTop: '9px', marginLeft: '8px', cursor: 'pointer'}} onClick={() => {this.stores.ChangePasswordStore.setShowNewPasswordConfirm()}}></Icon>
                                        )}
                                        {this.stores.ChangePasswordStore.showNewPasswordConfirm && (
                                            <Icon name="eye slash" style={{paddingTop: '9px', marginLeft: '8px', cursor: 'pointer'}} onClick={() => {this.stores.ChangePasswordStore.setShowNewPasswordConfirm()}}></Icon>
                                        )}
                                    </div>                                    
                                    {this.stores.ChangePasswordStore.getNotTheSamePassword && (
                                        <div style={{color: 'red', marginTop: 10}}>A két jelszó nem egyezik!</div>
                                    )}                                             
                                </Grid.Column>            
                        </Grid.Row>
                        <Grid.Row centered>
                                <Grid.Column width={3}>                                    
                                    <Button primary disabled={this.stores.ChangePasswordStore.getDisable} onClick={() => {this.stores.ChangePasswordStore.changePassword()}}>Jelszó megváltoztatása</Button>
                                    {this.stores.ChangePasswordStore.isSucceeded == true && (
                                        <div style={{marginTop: '24px', color: 'green', fontSize: '15px', fontWeight: 'bold'}}>Jelszó sikeresen megváltoztatva!</div>
                                    )}
                                    {this.stores.ChangePasswordStore.isSucceeded == false && (
                                        <div style={{marginTop: '24px', color: 'red', fontSize: '15px', fontWeight: 'bold'}}>Sikertelen jelszóváltoztatás!</div>
                                    )}
                                </Grid.Column>            
                        </Grid.Row>
                    </Grid>
                </div>              
            </Provider>            
        </div>
    }
}

export default withParams(withNavigation(inject('RootStore')(observer(ChangePasswordPage))));