import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Confirm, Dropdown, Grid, Message, Table } from 'semantic-ui-react';
import withNavigation from '../../hocs/withNavigation';
import { ModuleAssignmentStore } from './ModuleAssignmentStore';
import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../core/agGridText';
import OpenItem from "../../components/OpenItem";
import { GridReadyEvent } from '@ag-grid-enterprise/all-modules';
import { RootStore } from '../../stores/RootStore';
import { CustomHeader } from "../../components/CustomHeader";

interface IStores {
	ModuleAssignmentStore: ModuleAssignmentStore;
}

interface IModuleAssignmentPageProps {
	navigate?: NavigateFunction;
	RootStore?: RootStore;
}

class ModuleAssignmentPage extends React.Component<IModuleAssignmentPageProps> {
	private stores: IStores;

	constructor(props: any) {
		super(props);

		this.stores = { ModuleAssignmentStore: new ModuleAssignmentStore(this.props.navigate, this.props.RootStore) };
	}

	componentDidMount(): void {
		this.stores.ModuleAssignmentStore.GetUserAllNotCompletedModuleAssignment()
	}


	public render() {
		return (
			<div>
				<Provider {...this.stores}>
					<div>
						<Grid style={{ paddingTop: 50 }}>
							<Grid.Row centered>
								<Grid.Column width={12}>									
									<CustomHeader>Teljesítendő továbbképzés(ek)</CustomHeader>
                                    <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Grid>
                                            <Grid.Column width={16}>
                                                <div style={{ fontWeight: 'bold', color: 'red'}}>
                                                A továbbképzés teljesítéséhez szükséges tananyag a továbbképzési modul nevére kattintva tölthető le!
                                                </div>
                                                <div style={{ width: "95%", marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <Table>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell>Továbbképzési modul neve</Table.HeaderCell>
                                                                <Table.HeaderCell>Határidő</Table.HeaderCell>
                                                                <Table.HeaderCell>Művelet</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {this.stores.ModuleAssignmentStore.moduleAssignments.map((item, index) => {
                                                                return <Table.Row key={index}>
                                                                    <Table.Cell>
                                                                    <a style={{ cursor: 'pointer' }} onClick={() => this.stores.ModuleAssignmentStore.GetTrainingModuleDocumentByObjectId(item.ModuleDocumentId, index)} download>{item.ModuleName}</a>
                                                                    </Table.Cell>
                                                                    <Table.Cell>{item.DueDateString}</Table.Cell>
                                                                    <Table.Cell>                                                                                                                              
                                                                        <Button style={{margin: 3}} color="green" onClick={() => { this.stores.ModuleAssignmentStore.openModuleStatementPopup(item.Id) }} disabled={!item.IsRead}>Teljesítés igazolása</Button>                                                                
                                                                    </Table.Cell>                                                                                     
                                                                </Table.Row>
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </div>                                                
                                            </Grid.Column>                                        
                                        </Grid>
                                        <Confirm open={this.stores.ModuleAssignmentStore.showTrainingModuleStatement}	
                                            closeOnEscape={false} //disables closing the window by pressing ESC	
                                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
                                            onConfirm={() => this.stores.ModuleAssignmentStore.SetModuleAssignmentCompleted()}
                                            cancelButton={false}
                                            confirmButton={"OK"}								
                                            content={
                                                <div style={{ padding: 40 }}>							
                                                    <div>
                                                        <label>Modulhoz kapcsolódó nyilatkozat:</label>                                                        
                                                    </div>											
                                                    <div dangerouslySetInnerHTML={{ __html: this.stores.ModuleAssignmentStore.selectedModuleAssignment.Statement}} style={{marginTop: 5, marginBottom: 10}}></div>					
                                                </div>
                                            }
                                        ></Confirm>	
                                    </div>
								</Grid.Column>
							</Grid.Row>							
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(ModuleAssignmentPage)));