import React from 'react';
import { RootStore } from '../stores/RootStore'
import { Icon } from "semantic-ui-react";
import withNavigation from '../hocs/withNavigation';
import { inject, observer } from 'mobx-react';
import { NavigateFunction } from 'react-router-dom';

interface IProps {
    RootStore?: RootStore,
    navigate?: NavigateFunction,
    clicked:any;
    data:any;
}

class EditItem extends React.Component<IProps> {
    
    constructor(props:any) {
        super(props);
    
      }
      btnClickedHandler(){
        this.props.clicked();
       }
             public render() {
        return <Icon name="edit" style={{cursor: 'pointer',color:'#df0000'}} onClick={() => {
            this.props.clicked(this.props.data)
        }}></Icon>
    }
}

export default withNavigation(inject('RootStore')(observer(EditItem)))