import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Button, Confirm, Grid, Icon, Input, Label } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { AddressLoaderStore } from "./AddressLoaderStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { AdminService } from '../../../services/admin.service'
import { flow, makeObservable } from "mobx";

interface IStores {
    AddressLoaderStore: AddressLoaderStore
}

interface IAddressLoaderPageProps {
    navigate?: NavigateFunction;
}

class AddressLoaderPage extends React.Component<IAddressLoaderPageProps> {
    private stores: IStores
    AdminService: AdminService;

    constructor(props: any){
        super(props);

        this.stores = { AddressLoaderStore: new AddressLoaderStore()}
        this.AdminService = new AdminService();

        makeObservable(this, {            
            getSettlementItems: flow,
            getSettlementAddressItems: flow,        
        })
    }

    componentDidMount(): void {
        this.getSettlementItems();
        this.getSettlementAddressItems();
    }

    *getSettlementItems() {
        this.stores.AddressLoaderStore.settlementItems = yield this.AdminService.GetSettlements();
    } 

    *getSettlementAddressItems() {
        this.stores.AddressLoaderStore.settlementAddressItems = yield this.AdminService.GetSettlementAddresses();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
            <Confirm open={this.stores.AddressLoaderStore.isUploadConfirmOpen}
                closeOnEscape={false}
                closeOnDimmerClick={false}
                onConfirm={(event) => {this.stores.AddressLoaderStore.confirmUpload(event);}}
                onCancel={(event) => {this.stores.AddressLoaderStore.cancelUpload(event);}}
                cancelButton="Mégse"
                confirmButton="Feltöltés"
                content={
                    <div>
                        <Label as="label" basic htmlFor="upload" style={{padding: '50px 20px'}}>                            
                            <Input hidden id={this.stores.AddressLoaderStore.uploadIdString} onChange={(event) => {this.stores.AddressLoaderStore.setFile(event)}} type="file" style={{width: '718px'}}/>
                        </Label>
                    </div>
                }>                    
            </Confirm>
            <div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Címadatbázis</CustomHeader>
                                    </div>		
                                    <div style={{marginBottom: 20}}>
                                        <Button color="green" animated="fade" onClick={() => {this.stores.AddressLoaderStore.setIsUploadConfirmOpen()}}>
                                            <Button.Content hidden><Icon name="file"></Icon></Button.Content>
                                            <Button.Content visible>Excel feltöltése</Button.Content>
                                        </Button>
                                    </div>
                                    <Grid>
                                        <Grid.Row centered >
                                            <Grid.Column width={16}>
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1400px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.AddressLoaderStore.settlementItems}											
                                                    columnDefs={this.stores.AddressLoaderStore.settlementColumnDef}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>              
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1400px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.AddressLoaderStore.settlementAddressItems}											
                                                    columnDefs={this.stores.AddressLoaderStore.settlementAddressColumnDef}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>  
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                              
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(AddressLoaderPage)));