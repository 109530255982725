import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button, Checkbox, Grid, Radio, Table } from 'semantic-ui-react';
import { OfferStore } from '../pages/offer/OfferStore';
import { CustomHeader } from './CustomHeader';
import { CustomTableHeader } from './CustomTableHeader';
import PhoneTable from './PhoneTable';
import PhoneTableSummarizer from './PhoneTableSummarizer';
interface IProps {
	OfferStore?: OfferStore;
}

class CartPanel extends React.Component<IProps> {
	public render() {
		const { OfferStore } = this.props;

		return (
			<React.Fragment>
				<div style={{display: 'flex'}}>
					<div style={{ position: 'relative', width: '92%' }}>
						<CustomHeader>Kosár tartalma</CustomHeader>
						<div style={{ position: 'absolute', right: 0, top: 0, display: 'flex' }}>
							<div>
								<table>
									<tbody>
										<tr>
											<td>
												<Radio checked={OfferStore.showFullCart === false} style={{ paddingTop: 2, width: '20px' }}
													onClick={() => OfferStore.changeCartView()} />
											</td>
											<td>
												<div onClick={() => OfferStore.changeCartView()}>

													Kiválasztott csomag
												</div>
											</td>
											<td>
												<Radio checked={OfferStore.showFullCart === true} style={{ paddingTop: 2, width: '20px' }}
													onClick={() => OfferStore.changeCartView()} />
											</td>
											<td>
												<div onClick={() => OfferStore.changeCartView()}>

													Összes csomag
												</div>
											</td>									
										</tr>
									</tbody>									
								</table>							
							</div>												
						</div>					
					</div>
					{OfferStore.showFullCart && (
						<div style={{width: '8%', paddingLeft: 10}}>
							<Button primary onClick={() => {OfferStore.saveOfferAndNavigateToPrintableOfferPage()}}>Nyomtatás</Button>
						</div>
					)}					
				</div>				

				<div>
					<div className="right-container">
						{!OfferStore.showFullCart && (
							<PhoneTable packageItem={OfferStore.selectedItem} />
						)}

						{OfferStore.showFullCart && (
							<React.Fragment>
								{OfferStore.offer.Packages.map((currentPackageItem) => {
									return <div style={{ marginLeft: 24 }}>
										<PhoneTable packageItem={currentPackageItem} />
									</div>
								})}

								{this.props.OfferStore.offer.IsLoanDemand &&(OfferStore.RootStore.currentUser.HoldingAdmin || OfferStore.RootStore.currentUser.HoldingGlobalViewer || OfferStore.RootStore.currentUser.StoreAdmin || OfferStore.RootStore.currentUser.CreditManager || OfferStore.RootStore.currentUser.OnlinePickup || OfferStore.RootStore.currentUser.IsConsultant || OfferStore.RootStore.currentUser.IsSeniorConsultant) && (
									<PhoneTableSummarizer OfferStore={OfferStore}></PhoneTableSummarizer>									
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default inject('OfferStore')(observer(CartPanel));
