import { makeAutoObservable } from "mobx";

export class TrainingModuleDocument {
    Id: number;
    TrainingModuleId: number;
    Content: any;
    FileName: string;
    ModifiedById: number;
    ModifiedByName: string;
    ModifiedDate: Date;
    ModifiedDateString: string;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.TrainingModuleId = 0;
        this.Content = null;
        this.FileName = null;        
        this.ModifiedById = 0;
        this.ModifiedByName = null;
        this.ModifiedDate = null;
        this.ModifiedDateString = null;
    }
}