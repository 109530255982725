import { ServiceProviderPermissionManagementStore } from './ServiceProviderPermissionManagementStore';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import withNavigation from '../../../hocs/withNavigation';
import { Button, Confirm, Dropdown, Grid, Search } from 'semantic-ui-react';
import { CustomHeader } from '../../../components/CustomHeader';
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { flow, makeObservable, observable } from 'mobx';
import { AdminService } from '../../../services/admin.service';
import { RootStore } from '../../../stores/RootStore';
import { toJS } from "mobx";
import DeleteButton from '../../../components/DeleteButton';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import OpenItem from "../../../components/OpenItem";
import EditItem from "../../../components/EditItem";

interface IStores {
	ServiceProviderPermissionManagementStore: ServiceProviderPermissionManagementStore;
}

interface IWarrantyPlusPageProps {
	navigate?: NavigateFunction;
	RootStore?:RootStore
}

class ServiceProviderPermissionManagementPage extends React.Component<IWarrantyPlusPageProps> {
	private stores: IStores;
    AdminService: AdminService;
	 showInactive:boolean=false;
	 

	constructor(props: any) {
		super(props);
        this.AdminService = new AdminService();

		this.stores = { ServiceProviderPermissionManagementStore: new ServiceProviderPermissionManagementStore(this.props.RootStore,this.props.navigate) };
		makeObservable(this, {            
            GetServiceProviderPermissionManagements: flow,
			showInactive:observable
        })
	}
	componentDidMount(): void {        
        this.GetServiceProviderPermissionManagements();
        this.stores.ServiceProviderPermissionManagementStore.GetRoles();
        this.stores.ServiceProviderPermissionManagementStore.GetTrainingModules();
        this.stores.ServiceProviderPermissionManagementStore.SetStores();
    }
	*GetServiceProviderPermissionManagements() {
        this.props.RootStore.addloading('GetServiceProviderPermissionManagements');
        this.stores.ServiceProviderPermissionManagementStore.serviceProviderPermissionManagementItems = yield this.AdminService.GetServiceProviderPermissionManagements();
		this.props.RootStore.removeLoading('GetServiceProviderPermissionManagements');
    } 
	public render() {
		return (
			<div>
				<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column style={{width: 1435}}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Továbbképzés és jóváhagyás adminisztráció</CustomHeader>
                                    </div>		
                                    <div style={{display: 'flex'}}>
                                        {(this.props.RootStore.currentUser.HoldingAdmin || this.props.RootStore.currentUser.VodafoneSupervisor) && (
                                            <div style={{marginBottom: 20}}>
                                                <Button color="red" onClick={() => {this.stores.ServiceProviderPermissionManagementStore.openAssignNewModulePopup()}}>Modul hozzárendelése</Button>								
                                            </div>				
                                        )}          
                                        <Button style={{marginLeft: 'auto', maxHeight: '36px'}} primary onClick={() => {this.stores.ServiceProviderPermissionManagementStore.GenerateUserRoleModuleAssigmentExportRiport()}}>
                                            Adatok exportálása
                                        </Button>   
                                    </div>	                                                                
                                    <Confirm open={this.stores.ServiceProviderPermissionManagementStore.showAssignNewModulePopup}
                                        closeOnEscape={false} //disables closing the window by pressing ESC	
                                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it	
                                        onConfirm={(event) => { this.stores.ServiceProviderPermissionManagementStore.SaveModuleAssignment(); }}
                                        onCancel={(event) => { this.stores.ServiceProviderPermissionManagementStore.cancelAssignNewModulePopup(); }}
                                        cancelButton="Mégse"
                                        confirmButton={this.stores.ServiceProviderPermissionManagementStore.getSaveEnabled == true ? "Mentés" : null}                                        
                                        style={{width: '1000px'}}
                                        content={
                                            <div style={{ padding: 30 }}>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={6}>
                                                            <div>
                                                                <label>Szerepkör</label>
                                                            </div>
                                                            <Dropdown
                                                                fluid
                                                                selection
                                                                value={this.stores.ServiceProviderPermissionManagementStore.selectedRoleId}
                                                                onChange={(e, data) => { this.stores.ServiceProviderPermissionManagementStore.updateSelectedRoleId(data?.value) }}
                                                                options={this.stores.ServiceProviderPermissionManagementStore.rolesForDDL}>
                                                            </Dropdown>
                                                        </Grid.Column>
                                                        {this.stores.ServiceProviderPermissionManagementStore.selectedRoleId > 0 && (
                                                            <React.Fragment>
                                                                <Grid.Column width={6}>
                                                                    <div>
                                                                        <label>Áruház</label>
                                                                    </div>                                                            
                                                                    <div style={{display: 'flex'}} id="tesztStore">                                                                    
                                                                    <Dropdown
                                                                        fluid
                                                                        selection
                                                                        value={this.stores.ServiceProviderPermissionManagementStore.selectedStoreId}
                                                                        options={this.stores.ServiceProviderPermissionManagementStore.storesForDDL}                                                                       
                                                                        onChange={(e, data) => { this.stores.ServiceProviderPermissionManagementStore.updateSelectedStoreId(data?.value) }}
                                                                        />                                                                                                                                
                                                                    </div>                                                                                                                           
                                                                </Grid.Column>                                                                                                                        
                                                            </React.Fragment>
                                                        )}                                                        
                                                    </Grid.Row>
                                                    {this.stores.ServiceProviderPermissionManagementStore.selectedRoleId > 0 && (
                                                        <Grid.Row>
                                                            <Grid.Column width={6}>
                                                                <div>
                                                                    <label>Felhasználó</label>
                                                                </div>                                                            
                                                                <div style={{display: 'flex'}} id="teszt">                                                                    
                                                                    <Dropdown
                                                                        fluid
                                                                        selection
                                                                        values={this.stores.ServiceProviderPermissionManagementStore.getSelectedUsers}
                                                                        onChange={(e, data) => { this.stores.ServiceProviderPermissionManagementStore.selectUser(data?.value) }}
                                                                        options={this.stores.ServiceProviderPermissionManagementStore.multipleUserOptions}
                                                                        multiple={true}>                                                                            
                                                                    </Dropdown>                                                                                                                                    
                                                                </div>                                                                                                                           
                                                            </Grid.Column>                                                               
                                                        </Grid.Row>
                                                    )}    
                                                    {this.stores.ServiceProviderPermissionManagementStore.selectedRoleId > 0 && (
                                                        <Grid.Row>
                                                            <Grid.Column width={6}>
                                                                <div>
                                                                    <label>Továbbképzési modul</label>
                                                                </div>
                                                                <Dropdown
                                                                    fluid
                                                                    selection
                                                                    value={this.stores.ServiceProviderPermissionManagementStore.selectedModuleId}
                                                                    onChange={(e, data) => { this.stores.ServiceProviderPermissionManagementStore.updateSelectedModuleId(data?.value) }}
                                                                    options={this.stores.ServiceProviderPermissionManagementStore.modulesForDDL}>
                                                                </Dropdown>
                                                            </Grid.Column>
                                                            <Grid.Column width={6}>
                                                                <div>
                                                                    <label>Határidő</label>
                                                                </div>
                                                                <SemanticDatepicker firstDayOfWeek={1} value={this.stores.ServiceProviderPermissionManagementStore.dueDate ? new Date(this.stores.ServiceProviderPermissionManagementStore.dueDate) : null}
                                                                    onChange={(event, data) => { this.stores.ServiceProviderPermissionManagementStore.updateDueDate(data.value) }} 
                                                                    error={!!this.stores.ServiceProviderPermissionManagementStore.getValidationsModuleAssignment.DueDate}/>
                                                                {this.stores.ServiceProviderPermissionManagementStore.hasValidationErrors && (
                                                                    <div style={{fontWeight: 'bold', color: 'red'}}>
                                                                        {this.stores.ServiceProviderPermissionManagementStore.getValidationsModuleAssignment.DueDate}
                                                                    </div>
                                                                )}
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    )}                                                    
                                                </Grid>       
                                                {this.stores.ServiceProviderPermissionManagementStore.isModuleRolePairingExistsAtUser && (
                                                    <div style={{color: 'red', fontWeight: 'bold', marginTop: 20, paddingLeft: 20}}>                                                        
                                                        {this.stores.ServiceProviderPermissionManagementStore.errorUserNameStringArray.map((item) => {
																return <span>{item}<br/></span>
															})}
                                                    </div>
                                                )}
                                                <div style={{marginTop: 30, fontSize: 20, color: 'red', marginLeft: 20}}>
                                                    Modulhoz rendelt felhasználók
                                                </div>			
                                                <div>
                                                    <div id="grid" className="ag-theme-alpine" style={{width: '850px', height: '519px', marginTop: 10, marginLeft: 20}}>
                                                        <AgGridReact											
                                                            rowData={toJS(this.stores.ServiceProviderPermissionManagementStore.selectedUsersToSave)}											
                                                            columnDefs={this.stores.ServiceProviderPermissionManagementStore.userColumnDef}
                                                            localeText={AgGridText}
                                                            frameworkComponents={{ btnCellRenderer: DeleteButton }}
                                                            pagination={true}
                                                            paginationPageSize={10}
                                                            gridOptions={{
                                                                paginationNumberFormatter: (params:any) => {
                                                                return  params.value.toLocaleString() ;
                                                            },
                                                            }}>																				
                                                        </AgGridReact>
                                                    </div>  
                                                </div>                                         
                                            </div>
                                        }
                                    ></Confirm>			
                                    <Confirm open={this.stores.ServiceProviderPermissionManagementStore.showUserRoleAssigmentPopup}
                                        closeOnEscape={false} //disables closing the window by pressing ESC	
                                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                                        
                                        onCancel={(event) => { this.stores.ServiceProviderPermissionManagementStore.cancelUserRoleAssigmentPopup(); }}
                                        cancelButton="OK"
                                        confirmButton={false}                                        
                                        style={{width: '1270px'}}
                                        content={
                                            <div style={{ padding: 30 }}>                                                     
                                                <div style={{marginTop: 30, fontSize: 20, color: 'red', marginLeft: 20}}>
                                                    Felhasználóhoz rendelt modulok - {this.stores.ServiceProviderPermissionManagementStore.selectedUserRoleAssigmentNameString}
                                                </div>			
                                                <div>
                                                    <div id="grid" className="ag-theme-alpine" style={{width: '1170px', height: '519px', marginTop: 10, marginLeft: 20}}>
                                                        <AgGridReact											
                                                            rowData={toJS(this.stores.ServiceProviderPermissionManagementStore.selectedUserRoleAssigments)}											
                                                            columnDefs={this.stores.ServiceProviderPermissionManagementStore.userRoleModuleAssigmentColumnDef}
                                                            localeText={AgGridText}                                                            
                                                            pagination={true}
                                                            paginationPageSize={10}
                                                            frameworkComponents={{ btnCellRenderer: DeleteButton, btnCellRendererOpen: EditItem }}
                                                            gridOptions={{
                                                                paginationNumberFormatter: (params:any) => {
                                                                return  params.value.toLocaleString() ;
                                                                },
                                                            }}>
                                                        </AgGridReact>
                                                    </div>  
                                                </div>                                         
                                            </div>
                                        }
                                    ></Confirm>
                                    <Confirm open={this.stores.ServiceProviderPermissionManagementStore.showUpdateUserRoleModulePopup}
                                        closeOnEscape={false} //disables closing the window by pressing ESC	
                                        closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                         
                                        onConfirm={(event) => { this.stores.ServiceProviderPermissionManagementStore.UpdateUserRuleModuleAssignmentDueDate(); }}               
                                        onCancel={(event) => { this.stores.ServiceProviderPermissionManagementStore.cancelUpdateUserRoleModulePopup(); }}
                                        cancelButton="Mégse"
                                        confirmButton={this.stores.ServiceProviderPermissionManagementStore.getUpdateEnabled == true ? "Mentés" : null}                     
                                        style={{width: '1000px'}}
                                        content={
                                            <div style={{ padding: 30 }}>                                                     
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={5}>
                                                            <div style={{marginBottom: 10}}>
                                                                <label>Modul</label>												
                                                            </div>
                                                            {this.stores.ServiceProviderPermissionManagementStore.selectedUpdateUserRoleModuleItem?.ModuleName} 																						                                            
                                                        </Grid.Column>		
                                                        <Grid.Column width={5}>
                                                            <div style={{marginBottom: 10}}>
                                                                <label>Szerepkör</label>												
                                                            </div>
                                                            {this.stores.ServiceProviderPermissionManagementStore.selectedUpdateUserRoleModuleItem?.RoleName} 	
                                                        </Grid.Column>
                                                        <Grid.Column width={5}>
                                                            <div style={{marginBottom: 10}}>
                                                                <label>Hozzárendelés dátuma</label>												
                                                            </div>
                                                            {this.stores.ServiceProviderPermissionManagementStore.selectedUpdateUserRoleModuleItem?.CreatedDateString} 	
                                                        </Grid.Column>								
                                                    </Grid.Row>
                                                    <Grid.Row>																			                                                        
                                                        <Grid.Column width={5}>
                                                            <div>
                                                                <label>Határidő</label>
                                                            </div>
                                                            <SemanticDatepicker firstDayOfWeek={1} value={this.stores.ServiceProviderPermissionManagementStore.selectedUpdateUserRoleModuleItem?.DueDate ? new Date(this.stores.ServiceProviderPermissionManagementStore.selectedUpdateUserRoleModuleItem.DueDate) : null}
                                                                    onChange={(event, data) => { this.stores.ServiceProviderPermissionManagementStore.updateUpdateUserRoleModuleItem(data.value) }} 
                                                                    error={!!this.stores.ServiceProviderPermissionManagementStore.getValidationsModuleAssignment.UpdateUserRoleDueDate}/>
                                                            {this.stores.ServiceProviderPermissionManagementStore.hasValidationErrors && (
                                                            <div style={{fontWeight: 'bold', color: 'red'}}>
                                                                {this.stores.ServiceProviderPermissionManagementStore.getValidationsModuleAssignment.UpdateUserRoleDueDate}
                                                            </div>
                                                                )}
                                                        </Grid.Column>
                                                        <Grid.Column width={5}>
                                                            <div style={{marginBottom: 10}}>
                                                                <label>Elvégzés dátuma</label>
                                                            </div>
                                                            {this.stores.ServiceProviderPermissionManagementStore.selectedUpdateUserRoleModuleItem?.ComplianceDateString} 	
                                                        </Grid.Column>
                                                    </Grid.Row>									
                                                </Grid>                                 
                                            </div>
                                        }
                                    ></Confirm>										
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                            {/* <div style={{background: 'rgb(248, 248, 248)', width: '1400px', height: '40px', textAlign: 'center', paddingTop: 10, fontWeight: 'bold', fontSize: '16px',
												border: '1px solid rgb(186, 191, 199)', borderBottom: 'none'}}>Ajánlatböngésző (Vodafone BO)</div> */}
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1402px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.ServiceProviderPermissionManagementStore.serviceProviderPermissionManagementItems}											
                                                    columnDefs={this.stores.ServiceProviderPermissionManagementStore.columnDef}
                                                    localeText={AgGridText}                                                    
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    frameworkComponents={{ btnCellRenderer: OpenItem}}
													gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}
                                                    onRowClicked={(params: any) =>  {
                                                        this.stores.ServiceProviderPermissionManagementStore.openUserRoleAssigmentPopup(params?.data?.UserId, params?.data?.RoleId, params?.data?.UserName);
                                                        console.log('params:', params);}}
                                                    style={{cursor: 'pointer'}}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(ServiceProviderPermissionManagementPage)));
