import { inject, observer } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Grid, Image, Input } from 'semantic-ui-react';
import withNavigation from '../../hocs/withNavigation';
import { RootStore } from '../../stores/RootStore';
import logo from '../../assets/logo.png';

interface IInfoVideoProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore;
}

class InfoVideoLoginPage extends React.Component<IInfoVideoProps> {
    constructor(props: any) {
        super(props);
    }

    public render() {
		return (
			<div>
				<div>
					<Grid>
						<Grid.Row centered style={{ marginTop: 100 }}>
							<Grid.Column width={6}>
								<div style={{alignItems: 'center'}}>
									<Image src={logo} size="large" style={{marginLeft: 'auto', marginRight: 'auto'}}/>
								</div>
							</Grid.Column>
						</Grid.Row>
                        <Grid.Row centered style={{ marginBottom: 20 }}>
							<Grid.Column width={4}>
								<div style={{textAlign: 'center', fontSize: '25px', color: 'rgb(102, 104, 106)', fontWeight: 'bold'}}>
									<label>Infovideo Bejelentkezés</label>
								</div>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row centered>
							<Grid style={{width: '300px'}}>
								<Grid.Row centered>
									<Grid.Column width={16} style={{ textAlign: 'center' }}>
										{/* <div>
											<label>Felhasználónév</label>
										</div> */}
										<Input placeholder="Email vagy Felhasználónév azonosító" fluid value={this.props.RootStore.UserName} onChange={(event, data) => { this.props.RootStore.onChangeUserName(event, data) }} style={{width: '100px !important'}}></Input>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Grid.Column width={16} style={{ textAlign: 'center' }}>
										{/* <div>
											<label>Jelszó</label>
										</div> */}
										<Input placeholder="Születési dátum" fluid value={this.props.RootStore.Password} onChange={(event, data) => { this.props.RootStore.onChangePassword(event, data) }} type='password'></Input>
										
									</Grid.Column>
								</Grid.Row>
								<Grid.Row centered>
									<Grid.Column width={16} style={{ textAlign: 'center' }}>

										{this.props.RootStore.loginFailure &&
											<div style={{ color: 'rgb(255,40,0)' }}>
												{this.props.RootStore.loginFailMessage}
											</div>
										}
										
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width={16}>
										<Button
											style={{color: 'white', width: '170px', height: '55px'}}   
                                            color='grey'                                     
											onClick={() => {
												this.props.RootStore.infovideoLogin();
											}}
										>
											Bejelentkezés
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Row>
					</Grid>
				</div>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(InfoVideoLoginPage)));
