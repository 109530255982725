import { inject, observer, Provider } from 'mobx-react';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Button, Checkbox, Confirm, Dropdown, Grid, Input, Message } from 'semantic-ui-react';
import withNavigation from '../../../hocs/withNavigation';
import { CustomHeader } from '../../../components/CustomHeader';
import withParams from '../../../hocs/withParams';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { RootStore } from '../../../stores/RootStore';
import { WarrantyPlusPackage } from '../../../models/warrantyPlusPackage';
import { AdminService } from '../../../services/admin.service';
import NumberFormat from 'react-number-format';
import { TariffPackageScoreStore } from './TariffPackageScoreStore';
import { TariffPackageScore } from '../../../models/TariffPackageScore';

interface IStores {
	TariffPackageScoreStore: TariffPackageScoreStore
}

interface ITariffPackageScorePageProps {
	navigate?: NavigateFunction,
	params?: any
	RootStore?: RootStore
}

class TariffPackageScoreDetailsPage extends React.Component<ITariffPackageScorePageProps> {

	stores: IStores
	AdminService: AdminService

	constructor(props: any) {
		super(props);

		this.stores = { TariffPackageScoreStore: new TariffPackageScoreStore(this.props.RootStore, this.props.navigate, this.props.params.id) }
		// if (this.props.params.id) {
		// 	this.stores.WarrantyPlusStore.GetWarrantyPlusPackegeById();
		// }

		this.AdminService = new AdminService()

	}	

	public render() {
		return <div>
			<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={10}>
								<div style={{ marginBottom: 20 }}>
									<CustomHeader>Tarifacsomag pontérték - {this.stores.TariffPackageScoreStore.tariffPackageScoreItem.TariffPackageName}</CustomHeader>
								</div>
								<Grid>
									<Grid.Row>
										<Grid.Column width={5}>
											<div style={{marginBottom: 10}}>
												<label>Tarifacsomag neve</label>												
											</div>
											{this.stores.TariffPackageScoreStore.tariffPackageScoreItem.TariffPackageName} 																						                                            
										</Grid.Column>		
                                        <Grid.Column width={5}>
                                            <div style={{marginBottom: 10}}>
												<label>Utoljára módosította</label>												
											</div>
											{this.stores.TariffPackageScoreStore.tariffPackageScoreItem.ModifiedByName} 	
										</Grid.Column>
                                        <Grid.Column width={5}>
											<div style={{marginBottom: 10}}>
												<label>Utoljára módosítva</label>												
											</div>
											{this.stores.TariffPackageScoreStore.tariffPackageScoreItem.ModifiedDateString} 	
										</Grid.Column>								
									</Grid.Row>
									<Grid.Row>																			
										<Grid.Column width={5}>
											<div style={{marginBottom: 10}}>
												<label>Előző pontérték</label>
											</div>
											{this.stores.TariffPackageScoreStore.tariffPackageScoreItem.PreviousScore?.toString().replace('.', ',')} 	
										</Grid.Column>
                                        <Grid.Column width={5}>
											<div>
												<label>Aktuális pontérték</label>
											</div>
											<NumberFormat
                                                value={this.stores.TariffPackageScoreStore.tariffPackageScoreItem.ActualScore}                                                
                                                fluid                 
												decimalSeparator=','
                                                allowLeadingZeros={false}
                                                allowNegative={false}      												
												decimalScale={2}										
                                                isAllowed={(values) => {
                                                    const { formattedValue, floatValue } = values;
                                                    return formattedValue === "" || (floatValue <= 100 && floatValue >= 0);
                                                }}                                                                       
                                                onValueChange={(data) => {this.stores.TariffPackageScoreStore.updateTariffPackageItem('ActualScore', data.value)}}                                                
                                                customInput={Input} />
										</Grid.Column>
									</Grid.Row>									
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>				
				<Confirm open={this.stores.TariffPackageScoreStore.showValidationErrorPopup && this.stores.TariffPackageScoreStore.hasValidationErrors}	
					closeOnEscape={false} //disables closing the window by pressing ESC	
					closeOnDimmerClick={false} //disables closing the window by clicking outside of it						
					onCancel={() => {this.stores.TariffPackageScoreStore.cancelShowValidationErrorPopup();}}	
					cancelButton="Bezár"
					confirmButton={false}								
					content={
						<div style={{ padding: 40 }}>							
								<Message style={{maxHeight: 300, overflow: 'auto'}} negative>
									<Message.Header>Hiba a mentés közben!</Message.Header>
									<span>Validációs hibák:
										<ul style={{marginLeft: 18}}>{Object.values(this.stores.TariffPackageScoreStore.getValidationsTariffPackageScore).map((error) => {
											return <li key={error}>{error}</li>
											})}
										</ul>										
									</span>
								</Message>							
						</div>
					}
				></Confirm>				
				<div style={{ marginTop: 20 }}>
					<Grid>
						<Grid.Row centered>
							<Grid.Column width={5} style={{ textAlign: 'left' }}>
								<Button onClick={() => { this.props.navigate('/administration/tariffpackagescore') }}>Mégse</Button>
							</Grid.Column>
							<Grid.Column width={5} style={{ textAlign: 'right' }}>
								<Button color="green" onClick={() => { this.save() }}>Mentés</Button>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</div>
			</Provider>
		</div>
	}
	save() {
		this.stores.TariffPackageScoreStore.SaveTariffPackageScore();		
	}
	componentDidMount(): void {
		if (this.props.params.id && this.props.params.id != '0') {
			this.stores.TariffPackageScoreStore.GetTariffPackageScoreById();
		} else {
			this.stores.TariffPackageScoreStore.tariffPackageScoreItem = new TariffPackageScore();
		}		
	}
}

export default withParams(withNavigation(inject('RootStore')(observer(TariffPackageScoreDetailsPage))));


