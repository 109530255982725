import { inject, observer, Provider } from "mobx-react";
import React from "react";
import { NavigateFunction } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import withNavigation from "../../../hocs/withNavigation";
import { StoreDataStore } from "./StoreDataStore";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { RootStore } from "../../../stores/RootStore";

interface IStores {
    StoreDataStore: StoreDataStore
}

interface IStoreDataPageProps {
    navigate?: NavigateFunction;
    RootStore?:RootStore
}

class StoreDataPage extends React.Component<IStoreDataPageProps> {
    private stores: IStores

    constructor(props: any){
        super(props);

        this.stores = { StoreDataStore: new StoreDataStore(this.props.RootStore)}
    }

    componentDidMount(): void {
        this.stores.StoreDataStore.getStoreDatas()
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
            <div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Áruházi adatok</CustomHeader>
                                    </div>								
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1002px', height: '519px', marginLeft: 'auto', marginRight: 'auto'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.StoreDataStore.storeDataItems}											
                                                    columnDefs={this.stores.StoreDataStore.columnDef}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>                                   									
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(StoreDataPage)));