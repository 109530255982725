import { makeAutoObservable } from "mobx";
import { User } from "./user";

export class ModuleAssignment {
    Id: number;
    CreatedById: number;
    CreatedByName: string;
    CreatedDate: Date;
    CreatedDateString: string;
    ModuleId: number;
    ModuleName: string;
    RoleId: number;
    RoleName: string;
    DueDate: Date;
    DueDateString: string;
    UserId: number;
    UserName: string;
    ComplianceDate: Date;
    ComplianceDateString: string;
    Users: Array<User>;
    ModuleDocumentId: number;
    IsRead: boolean;
    Statement: string;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.CreatedById = 0;
        this.CreatedByName = null;
        this.CreatedDate = null;
        this.CreatedDateString = null;
        this.ModuleId = 0;
        this.ModuleName = null;
        this.RoleId = 0;
        this.RoleName = null;
        this.DueDate = null;
        this.DueDateString = null;
        this.UserId = 0;
        this.UserName = null;
        this.ComplianceDate = null;
        this.ComplianceDateString = null;
        this.Users = [];
        this.ModuleDocumentId = 0;
        this.IsRead = false;
        this.Statement = null;
    }
}