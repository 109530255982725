import { RoleStore } from "./RoleStore";
import { NavigateFunction } from "react-router-dom";
import React from "react";
import { inject, observer, Provider } from "mobx-react";
import withNavigation from "../../../hocs/withNavigation";
import { Button, Dropdown, Grid, Input, Search } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import { AgGridText } from "../../../core/agGridText";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { UserService } from "../../../services/user.service";
import { flow, makeObservable } from "mobx";
import { RootStore } from "../../../stores/RootStore";
import DeleteButton from "../../../components/DeleteButton";
import { GridReadyEvent } from "@ag-grid-enterprise/all-modules";
interface IStores {
    RoleStore: RoleStore
}

interface IRolePageProps {
    navigate?: NavigateFunction;
    RootStore?: RootStore
}

class RolePage extends React.Component<IRolePageProps> {
    private stores: IStores
    UserService: UserService;

    constructor(props: any) {
        super(props);

        this.stores = { RoleStore: new RoleStore(this.props.RootStore) }
        this.UserService = new UserService();

        makeObservable(this, {
            getUsers: flow,
            getRoles: flow,
        })
    }

    componentDidMount(): void {
        if(!this.props.RootStore.currentUser.HoldingAdmin){
            this.stores.RoleStore.selectedStore=this.props.RootStore.currentUser.StoreCode;
        }
        this.getUsers();
        this.getRoles();
    }

    *getUsers() {
        this.stores.RoleStore.users = yield this.UserService.GetUserInRoles()
    }

    *getRoles() {
        this.stores.RoleStore.roles = yield this.UserService.GetRolesForDDL()
    }

    handleGridReady(event: GridReadyEvent) {
        event.api.sizeColumnsToFit();
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
                <div style={{ margin: 20, width: '95%' }}>
                    <Grid width={12}>
                        <Grid.Row centered>
                            <Grid.Column width={14}>
                                <div style={{ marginBottom: 20 }}>
                                    <CustomHeader>Felhasználók szerepköreinek kezelése</CustomHeader>
                                </div>
                                <Grid>
                                    <Grid.Row>
                                       
                                        <Grid.Column width={3}>
                                            <div>
                                                <label>Jogkör hozzáadása</label>
                                            </div>
                                            <Dropdown
                                                fluid
                                                selection
                                                value={this.stores.RoleStore.selectedRole}
                                                onChange={(e, data) => { this.stores.RoleStore.update('selectedRole', data?.value) }}
                                                options={this.stores.RoleStore.roles}>
                                            </Dropdown>
                                        </Grid.Column>
                                        { (this.stores.RoleStore?.selectedRole>2 && this.stores.RoleStore?.selectedRole<7 || this.stores.RoleStore?.selectedRole ==9) && <Grid.Column width={3}>
                                            <div>
                                                <label>Áruház</label>
                                            </div>
                                            <Dropdown

                                                selection
                                                value={this.stores.RoleStore.selectedStore}
                                                options={this.props.RootStore.stores}
                                                disabled={!this.props.RootStore.currentUser.HoldingAdmin}
                                                onChange={(e, data) => { this.stores.RoleStore.update('selectedStore', data?.value) }}
                                            />
                                        </Grid.Column>}
                                        <Grid.Column width={4}>
                                            <div>
                                                <label>Felhasználó</label>
                                            </div>
                                            { this.stores.RoleStore.showSearch && (
                                                <div style={{display: 'flex'}} id="teszt">
                                                    {
                                                        this.stores.RoleStore.showSearch &&
                                                        <Search
                                                        placeholder='Keresés...'
                                                        onResultSelect={(e, data) => {
                                                            this.stores.RoleStore.update('SelectedUser', data.result?.item)
                                                        }

                                                        }
                                                        onSearchChange={
                                                            (event, data) => {
                                                                // this.stores.RoleStore.SelectedUser = null;
                                                                this.stores.RoleStore.onChangeQueryText(event, data)
                                                            }}
                                                        results={this.stores.RoleStore.userByQueryTextDDL}
                                                        value={this.stores.RoleStore.SelectedUser?.Name}
                                                    />
                                                    }
                                                    <div>
                                                        <Button color='grey' icon='delete' onClick={() => {this.stores.RoleStore.setUserNull()}}></Button>
                                                    </div>
                                                </div>       
                                            )}
                                            { !this.stores.RoleStore.showSearch && (
                                                <div style={{display: 'flex'}} id="teszt2">
                                                    {
                                                        !this.stores.RoleStore.showSearch &&
                                                        <Search
                                                        placeholder='Keresés...'
                                                        onResultSelect={(e, data) => {
                                                            this.stores.RoleStore.update('SelectedUser', data.result?.item)
                                                        }

                                                        }
                                                        onSearchChange={
                                                            (event, data) => {
                                                                // this.stores.RoleStore.SelectedUser = null;
                                                                this.stores.RoleStore.onChangeQueryText(event, data)
                                                            }}
                                                        results={this.stores.RoleStore.userByQueryTextDDL}
                                                        value={this.stores.RoleStore.SelectedUser?.Name}
                                                    />
                                                    }
                                                    <div>
                                                        <Button color='grey' icon='delete' onClick={() => {this.stores.RoleStore.setUserNull()}}></Button>
                                                    </div>
                                                </div>       
                                            )}
                                                                                 
                                           
                                        </Grid.Column>
                                        <Grid.Column width={2}>
                                            <Button color="green" style={{ height: '38px', marginTop: '19px' }} onClick={() => this.stores.RoleStore.adduserToRole()}>Hozzáadás</Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16} style={{ alignItems: 'center' }}>
                                            <Grid.Row centered>
                                                <div id="grid" className="ag-theme-alpine" style={{ width: '1400px', height: '519px' }}>
                                                    <AgGridReact
                                                        rowData={this.stores.RoleStore.users}
                                                        columnDefs={this.stores.RoleStore.usersColumnDef}
                                                        localeText={AgGridText}
                                                        pagination={true}
                                                        paginationPageSize={10}
                                                        frameworkComponents={{ btnCellRenderer: DeleteButton }}
                                                        onGridReady={this.handleGridReady}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                            </Grid.Row>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(RolePage)))