import { WarrantyPlusStore } from './WarrantyPlusStore';
import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import withNavigation from '../../../hocs/withNavigation';
import { Button, Grid } from 'semantic-ui-react';
import { CustomHeader } from '../../../components/CustomHeader';
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { flow, makeObservable, observable } from 'mobx';
import { AdminService } from '../../../services/admin.service';
import { RootStore } from '../../../stores/RootStore';

interface IStores {
	WarrantyPlusStore: WarrantyPlusStore;
}

interface IWarrantyPlusPageProps {
	navigate?: NavigateFunction;
	RootStore?:RootStore
}

class WarrantyPlusPage extends React.Component<IWarrantyPlusPageProps> {
	private stores: IStores;
    AdminService: AdminService;
	 showInactive:boolean=false;
	 

	constructor(props: any) {
		super(props);
        this.AdminService = new AdminService();

		this.stores = { WarrantyPlusStore: new WarrantyPlusStore(this.props.RootStore) };
		makeObservable(this, {            
            getWarrantyPlusItems: flow,
			showInactive:observable
        })
	}
	componentDidMount(): void {
        
        this.getWarrantyPlusItems();
    }
	*getWarrantyPlusItems() {
        this.props.RootStore.addloading('getWarrantyPlusItems');
        this.stores.WarrantyPlusStore.warrantyPlusItems = yield this.AdminService.GetWarrantyPlusPackeges(this.showInactive);
		this.props.RootStore.removeLoading('getWarrantyPlusItems');
    } 
	public render() {
		return (
			<div>
				<Provider {...this.stores}>
				<div style={{ margin: 20, width: '95%' }}>
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={12}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Garancia+ csomagajánlatok</CustomHeader>
                                    </div>			
									<div style={{marginBottom: 20}}>
										<Button color="red" onClick={() => {this.props.navigate('/administration/warranty-plus-details/0')}}>Új csomag rögzítése</Button>								
									</div>					
                                    <Grid>
                                        <Grid.Row centered>
                                            <Grid.Column width={16}>
                                            {/* <div style={{background: 'rgb(248, 248, 248)', width: '1400px', height: '40px', textAlign: 'center', paddingTop: 10, fontWeight: 'bold', fontSize: '16px',
												border: '1px solid rgb(186, 191, 199)', borderBottom: 'none'}}>Ajánlatböngésző (Vodafone BO)</div> */}
                                            <div id="grid" className="ag-theme-alpine" style={{width: '1402px', height: '519px'}}>
                                                <AgGridReact											
                                                    rowData={this.stores.WarrantyPlusStore.warrantyPlusItems}											
                                                    columnDefs={this.stores.WarrantyPlusStore.columnDef}
                                                    localeText={AgGridText}
                                                    pagination={true}
                                                    paginationPageSize={10}
													gridOptions={{
                                                        paginationNumberFormatter: (params:any) => {
                                                        return  params.value.toLocaleString() ;
                                                      },
                                                    }}>																				
                                                </AgGridReact>
                                            </div>    
                                            </Grid.Column>                                        
                                        </Grid.Row>                                                                                                       
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
				</Provider>
			</div>
		);
	}
}

export default withNavigation(inject('RootStore')(observer(WarrantyPlusPage)));
