'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
var tokensMap = {
  // 'D MMMM': '',
  // 'Do MMMM': '',
  // 'DD MMMM': '',
  M: 'L',
  Mo: 'Mo',
  MM: 'LL',
  MMM: 'LLL',
  MMMM: 'LLLL',
  Q: 'q',
  Qo: 'qo',
  D: 'd',
  Do: 'do',
  DD: 'dd',
  DDD: 'D',
  DDDo: 'Do',
  DDDD: 'DDD',
  d: 'i',
  do: 'io',
  dd: 'iiiiii',
  ddd: 'iii',
  dddd: 'iiii',
  A: 'a',
  a: 'a',
  aa: 'aaaa',
  E: 'i',
  W: 'I',
  Wo: 'Io',
  WW: 'II',
  YY: 'yy',
  YYYY: 'yyyy',
  GG: 'RR',
  GGGG: 'RRRR',
  H: 'H',
  HH: 'HH',
  h: 'h',
  hh: 'hh',
  m: 'm',
  mm: 'mm',
  s: 's',
  ss: 'ss',
  S: 'S',
  SS: 'SS',
  SSS: 'SSS',
  Z: 'xxx',
  ZZ: 'xx',
  X: 't',
  x: 'T'
}
var v1tokens = Object.keys(tokensMap)
  .sort()
  .reverse()
var tokensRegExp = new RegExp(
  '(\\[[^\\[]*\\])|(\\\\)?' + '(' + v1tokens.join('|') + '|.)',
  'g'
)
function convertTokens(format) {
  var tokensCaptures = format.match(tokensRegExp)
  if (tokensCaptures) {
    return tokensCaptures
      .reduce(
        function(acc, tokenString, index) {
          var v2token = tokensMap[tokenString]
          if (!v2token) {
            var escapedCaptures = tokenString.match(/^\[(.+)\]$/)
            if (escapedCaptures) {
              acc.textBuffer.push(escapedCaptures[1])
            } else {
              acc.textBuffer.push(tokenString)
            }
          }
          var endOfString = index === tokensCaptures.length - 1
          if (acc.textBuffer.length && (v2token || endOfString)) {
            acc.formatBuffer.push("'" + acc.textBuffer.join('') + "'")
            acc.textBuffer = []
          }
          if (v2token) acc.formatBuffer.push(v2token)
          return acc
        },
        { formatBuffer: [], textBuffer: [] }
      )
      .formatBuffer.join('')
  } else {
    return format
  }
}
exports.convertTokens = convertTokens
