export class RiportService {
    

    public async GenerateClientReport(storeCode: string, startDate: Date, endDate: Date, only12month: number): Promise<void> {
        let postData = {
            storeCode: storeCode,
            startDate: startDate,
            endDate: endDate,
            only12month: only12month
        }
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };

        const response: any = await fetch('/api/report/GenerateClientReport', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'Riport_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }
    public async GenerateReport(storeCode: string, startDate: Date, endDate: Date, only12month: number): Promise<void> {
        let postData = {
            storeCode: storeCode,
            startDate: startDate,
            endDate: endDate,
            only12month: only12month
        }
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };

        const response: any = await fetch('/api/report/GenerateReport', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'Riport_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    public async GenerateReportForStoreAdmin(storeCode: string, startDate: Date, endDate: Date, only12month: number): Promise<void> {
        let postData = {
            storeCode: storeCode,
            startDate: startDate,
            endDate: endDate,
            only12month: only12month
        }
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };

        const response: any = await fetch('/api/report/GenerateReportForStoreAdmin', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'Ertekesitesi_riport_naplo_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    public async GenerateRateCommission(): Promise<void> {        
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",            
        };

        const response: any = await fetch('/api/report/GenerateRateCommission', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'JutalekOsztaly_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    public async GenerateDeviceDiscountPartial(storeCode: string): Promise<void> {             
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(storeCode)
        };

        const response: any = await fetch(`/api/report/GenerateDeviceDiscountPartial`, options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'DeviceDiscountPartial_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    public async GenerateDeviceDiscountComplete(storeCode: string): Promise<void> {             
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(storeCode)
        };

        const response: any = await fetch(`/api/report/GenerateDeviceDiscountComplete`, options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'DeviceDiscountComplete_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    formatDate(date: any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    public async GenerateTariffPackageScoreExport(containsInActive: boolean): Promise<void> {        
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",          
            body: JSON.stringify(containsInActive)  
        };

        const response: any = await fetch('/api/report/GenerateTariffPackageScoreExport', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'TarifaCsomagPontErtek'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    public async GenerateUserRoleModuleAssigmentExportRiport(): Promise<void> {        
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",                      
        };

        const response: any = await fetch('/api/report/GenerateUserRoleModuleAssigmentExportRiport', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        link.setAttribute('download', 'FelhasznaloModulOsszerendeles_'+date_format_str+'.xlsx');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }

    public async GenerateRiportBySelectedRiportType(storeCode: string, startDate: Date, endDate: Date, only12month: number, selectedRiportTypeId: number): Promise<void> {
        let postData = {
            storeCode: storeCode,
            startDate: startDate,
            endDate: endDate,
            only12month: only12month,
            selectedRiportTypeId: selectedRiportTypeId,
        } 
        const options: RequestInit = {
            method: "POST",
            headers: { Accept: "application/json", "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify(postData)
        };

        const response: any = await fetch('/api/report/GenerateRiportBySelectedRiportType', options)
        // setTimeout(() => this.downloadFile(this.base64ToArrayBuffer(response)), 300);
        const blob = await response.blob();
        const newBlob = new Blob([blob]);

        const blobUrl = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = blobUrl;

        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + " " + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ":" + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + ":00";
        var d = new Date();
        d = new Date(d.getTime() - 3000000);
        var date_format_str = d.getFullYear().toString() + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + '_' + (d.getHours().toString().length == 2 ? d.getHours().toString() : "0" + d.getHours().toString()) + ((Number(d.getMinutes() / 5) * 5).toString().length == 2 ? (Number(d.getMinutes() / 5) * 5).toString() : "0" + (Number(d.getMinutes() / 5) * 5).toString()) + "00";
        switch(selectedRiportTypeId) {
            case 1:
                link.setAttribute('download', 'MasterDataEredmenyTablaRiport_'+date_format_str+'.xlsx');
                break;
            case 2:
                link.setAttribute('download', 'MasterDataStatisztikaiRiport_'+date_format_str+'.xlsx');
                break;
            case 3:
                link.setAttribute('download', 'Aruhazi_riport_ertekesitokent_premium_SAP_'+date_format_str+'.xlsx');
                break;
            case 4:
                link.setAttribute('download', 'Aruhazi_riport_ertekesitonkent_'+date_format_str+'.xlsx');
                break;
            case 5:
                link.setAttribute('download', 'Osszegzo_riport_Regio_Aruhaz_'+date_format_str+'.xlsx');
                break;
            case 6:
                link.setAttribute('download', 'Ertekesitoi_premium_riport_kontrolling_'+date_format_str+'.xlsx');
                break;
            case 7:
                link.setAttribute('download', 'EredmenyRiport_konyveles_'+date_format_str+'.xlsx');
                break;
            case 8:
                link.setAttribute('download', 'VodafoneTarifacsomagRiport_'+date_format_str+'.xlsx');
                break;
            case 9:
                link.setAttribute('download', 'AjanlatIdotartamMonitoring_'+date_format_str+'.xlsx');
                break;
            case 10:
                link.setAttribute('download', 'OsszesitoRiport_'+date_format_str+'.xlsx');
                break;
            default:
                break;
        }        
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        // clean up Url
        window.URL.revokeObjectURL(blobUrl);
    }
}