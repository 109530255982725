import { flow, makeAutoObservable, observable } from "mobx";
import { NavigateFunction } from "react-router-dom";
import { ChangePassword } from "../../models/changePassword";
import { UserService } from "../../services/user.service";
import { RootStore } from "../../stores/RootStore";

export class ChangePasswordStore {
    navigate: NavigateFunction;
    RootStore: RootStore;
    changePasswordItem: ChangePassword;
    UserService: UserService;
    newPasswordConfirm: string;
    showOldPassword: boolean = false;
    showNewPassword: boolean = false;
    showNewPasswordConfirm: boolean = false;
    isSucceeded: boolean;
    constructor(navigate: NavigateFunction, RootStore?: RootStore) {
        this.navigate = navigate;
        this.RootStore = RootStore;

        this.UserService = new UserService();

        makeAutoObservable(this, {
            newPasswordConfirm: observable,
            changePassword: flow,            
        })
    }

    setShowOldPassword = () => {
        this.showOldPassword = !this.showOldPassword;
    }

    setShowNewPassword = () => {
        this.showNewPassword = !this.showNewPassword;
    }

    setShowNewPasswordConfirm = () => {
        this.showNewPasswordConfirm = !this.showNewPasswordConfirm;
    }

    updateOldPassword(value: any) {
        this.changePasswordItem.OldPassword = value;
    }

    updateNewPassword(value: any) {
        var regexp = new RegExp('^[a-zA-Z0-9\/@"!£$%^&*()#€]*$')
        var regexpRes = regexp.test(value);
        if (regexpRes) {
            this.changePasswordItem.NewPassword = value;
            if (this.changePasswordItem.NewPassword.length > 10) {
                this.changePasswordItem.NewPassword = value.toString().slice(0, 10)
            }
        }                
    }

    updateNewPasswordConfirm(value: any) {        
        var regexp = new RegExp('^[a-zA-Z0-9\/@"!£$%^&*()#€]*$')
        var regexpRes = regexp.test(value);
        if (regexpRes) {
            this.newPasswordConfirm = value;
            if (this.newPasswordConfirm.length > 10) {
                this.newPasswordConfirm = value.toString().slice(0, 10)
            }
        }    
    }    

    *changePassword() {
        this.isSucceeded = yield this.UserService.ChangePassword(this.changePasswordItem)
        this.changePasswordItem.OldPassword = "";
        this.changePasswordItem.NewPassword = "";
        this.newPasswordConfirm = "";
        // this.isSucceeded = null;
    }

    get getDisable() {
        if (this.changePasswordItem?.OldPassword == null || this.changePasswordItem?.OldPassword == "") {
            return true;
        }
        if (this.changePasswordItem?.NewPassword == null || this.changePasswordItem?.NewPassword == "") {
            return true;
        }
        if (this.newPasswordConfirm == null || this.newPasswordConfirm == "") {
            return true;
        }
        if (this.changePasswordItem?.NewPassword != this.newPasswordConfirm) {
            return true;
        }
        return false
    }

    get getNotTheSamePassword() {
        if (this.changePasswordItem?.NewPassword && this.newPasswordConfirm && this.changePasswordItem?.NewPassword != this.newPasswordConfirm) {
            return true;
        }
        return false;
    }

    get checkCorrectFormNewPassword() {
        if (!this.changePasswordItem?.NewPassword) {
            return true;
        }

        var containsLowerCase = false;
        var containsUpperCase = false;
        var containsNumber = false;
        var containsSpecialCharacter = false;        

        for (let i = 0; i < this.changePasswordItem.NewPassword.length; i++) {
            var character = this.changePasswordItem.NewPassword[i];

            if (character == character.toLowerCase() && (character != "1" && character != "2" && character != "3" && character != "4" && character != "5" && 
            character != "6" && character != "7" && character != "8" && character != "9" && character != "10") && !(character == "!" ||
            character == "\"" ||
            character == "#" ||
            character == "$" ||
            character == "%" ||
            character == "&" ||
            character == "'" ||
            character == "(" ||
            character == ")" ||
            character == "*" ||
            character == "+" ||
            character == "," ||
            character == "-" ||
            character == "." ||
            character == "/" ||
            character == ":" ||
            character == ";" ||
            character == "<" ||
            character == "=" ||
            character == ">" ||
            character == "?" ||
            character == "@" ||
            character == "[" ||
            character == "\\" ||
            character == "]" ||
            character == "^" ||
            character == "_" ||
            character == "`" ||
            character == "{" ||
            character == "|" ||
            character == "}" ||
            character == "~" 
                        )) {
                containsLowerCase = true;
            }
            if (character == character.toUpperCase() && (character != "1" && character != "2" && character != "3" && character != "4" && character != "5" && 
            character != "6" && character != "7" && character != "8" && character != "9" && character != "10")) {
                containsUpperCase = true;
            }
            if (character == "1" || character == "2" || character == "3" || character == "4" || character == "5" || 
                character == "6" || character == "7" || character == "8" || character == "9" || character == "10") {
                    containsNumber = true;
            }
            // if (character == "@" || character == "\"" || character == "!" || character == "£" || character == "$" ||
            //     character == "%" || character == "^" || character == "&" || character == "*" || character == "(" ||
            //     character == ")" || character == "#" || character == "€")
            if(character == "!" ||
character == "\"" ||
character == "#" ||
character == "$" ||
character == "%" ||
character == "&" ||
character == "'" ||
character == "(" ||
character == ")" ||
character == "*" ||
character == "+" ||
character == "," ||
character == "-" ||
character == "." ||
character == "/" ||
character == ":" ||
character == ";" ||
character == "<" ||
character == "=" ||
character == ">" ||
character == "?" ||
character == "@" ||
character == "[" ||
character == "\\" ||
character == "]" ||
character == "^" ||
character == "_" ||
character == "`" ||
character == "{" ||
character == "|" ||
character == "}" ||
character == "~" 
            )
                 {
                containsSpecialCharacter = true;
            }
        }

        if (containsLowerCase && containsUpperCase && containsNumber && containsSpecialCharacter && this.changePasswordItem.NewPassword.length == 10) {
            return true;
        }
        return false;
    }


    // get newPasswordNotOnFocusAndFilled() {
    //     var sss = document.getElementById("newPassword") !== document.activeElement
    //     var sss = document.getElementById("passwordConfirm") !== document.activeElement
    //     var sss = this.changePasswordItem?.NewPassword != ""
    //     if ((document.getElementById("newPassword") !== document.activeElement && this.changePasswordItem?.NewPassword)) {
    //         var deubg = 1;
    //         return true;
    //     }
    // }

}