import { makeAutoObservable } from "mobx";

export class ServiceProviderPermissionManagement {
    Id: number;
    StoreId: number;
    StoreName: string;
    UserId: number;
    UserName: string;
    UserLoginName: string;
    RoleId: number;
    RoleName: string;
    LastLoginDate: Date;
    LastLoginDateString: string;
    IsApprovedByServiceProvider: number;
    LastApprovalDate: Date;
    LastApprovalDateString: string;
    ApprovalUserId: number;
    ApprovalUserLoginName: string;    
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.StoreId = 0;
        this.StoreName = null;
        this.UserId = 0;
        this.UserName = null;
        this.UserLoginName = null;
        this.RoleId = 0;
        this.RoleName = null;
        this.LastLoginDate = null;
        this.LastLoginDateString = null;
        this.IsApprovedByServiceProvider = null;
        this.LastApprovalDate = null;
        this.LastApprovalDateString = null;
        this.ApprovalUserId = 0;
        this.ApprovalUserLoginName = null;
    }
}