import { makeAutoObservable } from "mobx";

export class StoreCommission {
    Id: number;
    StoreName: string;
    WarrantyCommissionPercentage: number;
    constructor() {
        makeAutoObservable(this);
        this.Id = 0;
        this.StoreName = null;
        this.WarrantyCommissionPercentage = null;
    }
}