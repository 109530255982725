import React from "react";
import { NavigateFunction } from "react-router-dom";
import { ManufacturerWarrantyStore } from "./ManufacturerWarrantyStore";
import withNavigation from "../../../hocs/withNavigation";
import { inject, observer, Provider } from "mobx-react";
import { Button, Confirm, Grid, Input } from "semantic-ui-react";
import { CustomHeader } from "../../../components/CustomHeader";
import { AgGridReact } from "@ag-grid-community/react/lib/agGridReact";
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { AgGridText } from '../../../core/agGridText';
import { RootStore } from "../../../stores/RootStore";
import OpenItem from "../../../components/OpenItem";
import { toJS } from "mobx";

interface IStores {
    ManufacturerWarrantyStore: ManufacturerWarrantyStore
}

interface IStoreCommissionPageProps {
    navigate?: NavigateFunction
    RootStore?:RootStore
}

class StoreCommissionPage extends React.Component<IStoreCommissionPageProps> {
    
    stores: IStores

    constructor(props: any) {
        super(props);

        this.stores = { ManufacturerWarrantyStore: new ManufacturerWarrantyStore(this.props.RootStore)}
    }    

    componentDidMount(): void {
        this.stores.ManufacturerWarrantyStore.getStoreCommissionsItems()
    }

    public render() {
        return <div>
            <Provider {...this.stores}>
            <div style={{ margin: 20, width: '95%' }}>
                        <Confirm open={this.stores.ManufacturerWarrantyStore.isStoreCommissionPopupOpen}	
                            closeOnEscape={false} //disables closing the window by pressing ESC	
                            closeOnDimmerClick={false} //disables closing the window by clicking outside of it	                            
                            onCancel={() => {this.stores.ManufacturerWarrantyStore.setIsStoreCommissionPopupOpenFalse();}}	
                            cancelButton="Mégse"
                            confirmButton={false}								
                            content={
                                <div style={{ padding: 30 }}>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                                        <div style={{marginRight: '70px', height: '50px'}}>
                                            <div style={{marginBottom: 13}}>
                                                <label>Áruház</label>
                                            </div>
                                            <div>
                                                {this.stores.ManufacturerWarrantyStore.storeCommissionItem.StoreName}
                                            </div>                                            
                                        </div>
                                        <div>
                                            <div>
                                                <label>G+ jutalék százalék</label>
                                            </div>
                                            <Input value={this.stores.ManufacturerWarrantyStore.temporaryStoreCommissionNumber}
                                            type="number" onChange={(event, data) => {this.stores.ManufacturerWarrantyStore.setStoreCommissionItemWarrantyCommissionPercentage(event, data)}}/>
                                        </div>                                                                        
                                    </div>                                    
                                    <div style={{ textAlign: 'left' }}>
                                        <Button color="red" disabled={!this.stores.ManufacturerWarrantyStore.getStoreCommissionItemAddable} onClick={() => {this.stores.ManufacturerWarrantyStore.saveStoreCommissionItem()}}>Mentés</Button>
                                    </div>
                                </div>
                            }
                        />
						<Grid>
							<Grid.Row centered>
                                <Grid.Column width={10}>
                                    <div style={{marginBottom: 20}}>
                                        <CustomHeader>Áruházankénti jutalék beállítása</CustomHeader>
                                    </div>								
                                    <Grid>                                       
                                        <div id="grid" className="ag-theme-alpine" style={{width: '610px', height: '600px', marginLeft: 'auto', marginRight: 'auto'}}>
                                            <AgGridReact											
                                                rowData={toJS(this.stores.ManufacturerWarrantyStore.storeCommissionItems)}											
                                                columnDefs={this.stores.ManufacturerWarrantyStore.storeCommissionColumnDef}
                                                localeText={AgGridText}
                                                frameworkComponents={{  btnCellRendererOpenItem: OpenItem }}
                                                // pagination={true}

                                               alwaysShowVerticalScroll={true}
                                                paginationPageSize={this.stores.ManufacturerWarrantyStore.storeCommissionItems?.length}
                                                gridOptions={{
                                                    paginationNumberFormatter: (params:any) => {
                                                    return  params.value.toLocaleString() ;
                                                  },
                                                }}>																				
                                            </AgGridReact>
                                        </div>                                                                                                                                        
                                    </Grid>            
                                </Grid.Column>                                
							</Grid.Row>
						</Grid>
					</div>
            </Provider>
        </div>
    }
}

export default withNavigation(inject('RootStore')(observer(StoreCommissionPage)));