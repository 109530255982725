import { action, computed, flow, makeAutoObservable, makeObservable, observable, toJS } from "mobx";
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { ServiceProviderPermissionManagement } from "../../../models/serviceProviderPermissionManagement";
import { AdminService } from "../../../services/admin.service";
import { RootStore } from "../../../stores/RootStore";
import { UserService } from "../../../services/user.service";
import { Role } from "../../../models/role";
import { User } from "../../../models/user";
import { TrainingModule } from "../../../models/trainingModel";
import { ModuleAssignment } from "../../../models/moduleAssignment";
import { RiportService } from "../../../services/riport.service";
import { NavigateFunction } from "react-router-dom";

export class ServiceProviderPermissionManagementStore {

    private RootStrore:RootStore
    serviceProviderPermissionManagementItems: Array<ServiceProviderPermissionManagement>;
    columnDef: Array<ColDef>;
    userColumnDef: Array<ColDef>;
    userRoleModuleAssigmentColumnDef: Array<ColDef>;
    serviceProviderPermissionManagementId: number = 0;
    serviceProviderPermissionManagemenItem: ServiceProviderPermissionManagement = new ServiceProviderPermissionManagement();
    AdminService:AdminService;
    UserService: UserService;
    RiportService: RiportService;
    isSaved: boolean = false;
    showValidationErrorPopup: boolean = false;
    showAssignNewModulePopup: boolean = false;
    roles: Array<Role> = [];
    selectedRoleId: number;
    rolesForDDL: any[];
    selectedUser: User;
    queryText: string = "";
    usersByQueryText: Array<User> = [];
    selectedUsersToSave: Array<User> = [];
    modules: Array<TrainingModule> = [];
    selectedModuleId: number;
    modulesForDDL: any[];
    moduleAssigmentItem: ModuleAssignment = new ModuleAssignment();
    dueDate: Date;
    showUserRoleAssigmentPopup: boolean = false;
    selectedUserRoleAssigments: Array<ModuleAssignment> = [];
    selectedUserRoleAssigmentNameString: string = "";
    multipleUserOptions: any[] = [];
    filteredUsersByRoleId: Array<User> = [];
    isModuleRolePairingExistsAtUser: boolean = false;
    errorUserNameStringArray: Array<string> = [];
    showUpdateUserRoleModulePopup: boolean = false;
    selectedUpdateUserRoleModuleItem: ModuleAssignment = new ModuleAssignment();
    selectedStoreId: number = 0;
    navigate?: NavigateFunction;
    storesForDDL: any[];
    constructor(rootStrore:RootStore, navigate?: NavigateFunction,serviceProviderPermissionManagementId?: number) {
        this.RootStrore= rootStrore;
        this.navigate = navigate;
        makeAutoObservable(this, {
            serviceProviderPermissionManagementItems: observable,
            columnDef: observable,
            userColumnDef: observable,
            userRoleModuleAssigmentColumnDef: observable,
            serviceProviderPermissionManagementId: observable,
            serviceProviderPermissionManagemenItem: observable,            
            isSaved: observable,
            showValidationErrorPopup: observable,
            showAssignNewModulePopup: observable,
            roles: observable,
            selectedRoleId: observable,
            selectedUser: observable,
            queryText: observable,
            usersByQueryText: observable,        
            selectedUsersToSave: observable,    
            modules: observable,    
            selectedModuleId: observable,    
            modulesForDDL: observable,    
            dueDate: observable,
            showUserRoleAssigmentPopup: observable,
            selectedUserRoleAssigments: observable,
            selectedUserRoleAssigmentNameString: observable,
            multipleUserOptions: observable,
            filteredUsersByRoleId: observable,
            isModuleRolePairingExistsAtUser: observable,
            errorUserNameStringArray: observable,
            showUpdateUserRoleModulePopup: observable,
            selectedUpdateUserRoleModuleItem: observable,
            storesForDDL: observable,
            selectedStoreId: observable,
            updateDueDate: action,
            updateSelectedModuleId: action,
            updateSelectedRoleId: action,
            updateSelectedUser: action,
            navigateToUserDetailsPage: action,
            selectUser: action,
            SetStores: action,
            updateSelectedStoreId: action,
            SaveModuleAssignment:flow,
            GetRoles:flow,       
            getUserByQueryText: flow,   
            GetTrainingModules: flow,
            GetUserRuleModuleAssignment: flow,
            GenerateUserRoleModuleAssigmentExportRiport: flow,
            GetUsersByRoleIdForModuleAssignment: flow,
            GetUserRuleModuleAssignmentById: flow,
            UpdateUserRuleModuleAssignmentDueDate: flow,
            deleteModuleById: flow,
            userByQueryTextDDL: computed,
            getSaveEnabled: computed,
            getSelectedUsers: computed,
            getUpdateEnabled: computed,
        })
        this.serviceProviderPermissionManagementId = serviceProviderPermissionManagementId;
        
        this.AdminService=new AdminService();
        this.UserService=new UserService();
        this.RiportService=new RiportService();
       
        this.columnDef = [
            {
                field: 'edit', headerName: 'Műveletek', width: 120,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => { this.navigateToUserDetailsPage(data.Id); },
                }
            },
            { field: 'StoreName', headerName: 'Áruház', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
            { field: 'UserName', headerName: 'Név', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
            { field: 'UserLoginName', headerName: 'Bejelentkezési név', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
            { field: 'RoleName', headerName: 'Szerepkör', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
            { field: 'LastLoginDateString', headerName: 'Utolsó bejelentkezés dátuma', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
            { field: 'IsApprovedByServiceProvider', headerName: 'Szolgáltató által jóváhagyva?', filter: 'agSetColumnFilter', valueFormatter: params => this.booleanFormatter(params), filterValueGetter: params => this.booleanFilterFormatter(params), sortable: true, resizable: true, width: 175 },
            { field: 'LastApprovalDateString', headerName: 'Jóváhagyás utolsó módosítása', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
            { field: 'ApprovalUserLoginName', headerName: 'Módosító kódja', filter: 'agSetColumnFilter', sortable: true, resizable: true, width: 175 },
        ]

        this.userColumnDef = [
            { field: 'Name', headerName: 'Név', filter: 'agSetColumnFilter', sortable: true, maxWidth: 425, minWidth: 425, },
            { field: 'UserCode', headerName: 'Login név', filter: 'agSetColumnFilter', sortable: true, maxWidth: 425, minWidth: 425, },            
        ] 

        this.userRoleModuleAssigmentColumnDef = [
            {
                field: 'edit', headerName: 'Szerkesztés', maxWidth: 120, minWidth: 120,
                cellRenderer: 'btnCellRendererOpen',
                cellRendererParams: {
                    clicked: (data: any) => { this.openUpdateUserRoleModulePopup(data.Id, data.DueDate); },
                },
                cellStyle: params =>
                {
                    //van határidő és nincs elvégezve
                    if (params?.data?.DueDate != null && params?.data?.ComplianceDate == null) {                        
                        return null;
                    }
                    return {display: 'none'};
                }
            },  
            { field: 'ModuleName', headerName: 'Modul', filter: 'agSetColumnFilter', sortable: true, maxWidth: 180, minWidth: 180, },
            { field: 'RoleName', headerName: 'Szerepkör', filter: 'agSetColumnFilter', sortable: true, maxWidth: 180, minWidth: 180, },           
            { field: 'CreatedDateString', headerName: 'Hozzárendelés dátuma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 180, minWidth: 180, },           
            { field: 'DueDateString', headerName: 'Határidő', filter: 'agSetColumnFilter', sortable: true, maxWidth: 180, minWidth: 180, },           
            { field: 'ComplianceDateString', headerName: 'Elvégzés dátuma', filter: 'agSetColumnFilter', sortable: true, maxWidth: 180, minWidth: 180, },  
            {
                field: 'delete', headerName: 'Eltávolítás', maxWidth: 150, minWidth: 150,
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => { this.deleteModuleById(data.Id); },
                },
                cellStyle: params =>
                {
                    if (params?.data?.ComplianceDate != null) {                        
                        return {display: 'none'};
                    }
                    return null;
                }
            },         
        ] 
    }

    booleanFormatter = (params: any) => {
        return params != null && params.value != null ? (params.value == 1 ? "Igen" : (params.value == 3 ? "Jóváhagyásra vár" : "Nem")) : "";
    }

    booleanFilterFormatter = (params: any) => {        
        return params?.data?.IsApprovedByServiceProvider != null? (params.data.IsApprovedByServiceProvider == 1 ? "Igen" : (params.data.IsApprovedByServiceProvider == 3 ? "Jóváhagyásra vár" : "Nem")) : "";
    }

    numberFormatter = (params: any) => {
        return params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' Ft';
    }

    deleteUserFromListById(extendedInfoId: number) {
        this.selectedUsersToSave = this.selectedUsersToSave.filter(x => x.ExtendedInfoId !== extendedInfoId);
        //console.log('this.selectedUsersToSave', toJS(this.selectedUsersToSave))
        //console.log(extendedInfoId)
    }
    
    *deleteModuleById(moduleId: number) {        		
        this.selectedUserRoleAssigments = this.selectedUserRoleAssigments.filter(x => x.Id != moduleId);
        this.RootStrore.addloading('DeleteModuleAssigmentById');
        yield this.AdminService.DeleteModuleAssigmentById(moduleId);        
        this.RootStrore.removeLoading('DeleteModuleAssigmentById');
        this.showUpdateUserRoleModulePopup = false;
        
    }

    navigateToUserDetailsPage = (userId: number) => {
        this.RootStrore.setFromModuleAssignment(true);
        this.navigate('/administration/usermanagement-details/' + userId);           
    }

    cancelShowValidationErrorPopup = () => {
        this.showValidationErrorPopup = false;
    }

    updateSelectedRoleId(value: any) {
        this.selectedRoleId = value;        
        this.selectedUsersToSave = [];
        this.GetUsersByRoleIdForModuleAssignment();
        this.showAssignNewModulePopup = false;
        setTimeout(()=>{this.showAssignNewModulePopup=true}, 20);
    }

    updateSelectedStoreId(value: any) {
        this.selectedStoreId = value;        
        this.selectedUsersToSave = [];
        this.GetUsersByRoleIdForModuleAssignment();
        this.showAssignNewModulePopup = false;
        setTimeout(()=>{this.showAssignNewModulePopup=true}, 20);
    }

    updateSelectedModuleId(value: any) {
        this.selectedModuleId = value;        
    }

    updateSelectedUser(value: any) {
        this.selectedUser = value;
        //console.log("this.SelectedUser", this.selectedUser)        
    }

    updateDueDate(value: any) {
        this.dueDate = value;
    }

    updateUpdateUserRoleModuleItem(value: any) {
        if (this.selectedUpdateUserRoleModuleItem != null) {
            this.selectedUpdateUserRoleModuleItem.DueDate = value;
        }
    }

    onChangeQueryText = (event: any, data: any) => {
        this.queryText = data.value;
        //console.log(this.onChangeQueryText);
        this.getUserByQueryText();
    }

    selectUser(idList: any) {
        let typedIdList: number[] = idList;        
        this.selectedUsersToSave = this.filteredUsersByRoleId.filter(x => typedIdList.some(id => id == x.ExtendedInfoId));
        //console.log(this.selectedUsersToSave);        
    }

    addUserToList() {
        if (this.selectedUser && !this.selectedUsersToSave.some(listUser => listUser.ExtendedInfoId == this.selectedUser.ExtendedInfoId)) {
            this.selectedUsersToSave.push(this.selectedUser);
            //console.log(this.selectedUsersToSave);
        }              
    }

    openAssignNewModulePopup() {
        this.selectedUsersToSave = [];
        this.showAssignNewModulePopup = true;        
    }

    cancelAssignNewModulePopup() {
        this.showAssignNewModulePopup = false;
        this.errorUserNameStringArray = [];
        this.selectedStoreId = 0;
        this.selectedModuleId = 0;
        this.dueDate = null;
    }

    openUserRoleAssigmentPopup(userId: number, roleId: number, userName: string) {
        this.showUserRoleAssigmentPopup = true;                      
        this.selectedUserRoleAssigmentNameString = `${userName}`;        
        this.GetUserRuleModuleAssignment(userId, roleId)
    }

    openUpdateUserRoleModulePopup(id: number, date: Date) {
        if (date == null) {
            return;
        }        
        this.showUpdateUserRoleModulePopup = true;
        this.GetUserRuleModuleAssignmentById(id);
    }

    cancelUserRoleAssigmentPopup() {
        this.showUserRoleAssigmentPopup = false;
        this.selectedUserRoleAssigmentNameString = "";
    }

    cancelUpdateUserRoleModulePopup() {
        this.showUpdateUserRoleModulePopup = false;
        this.selectedUserRoleAssigmentNameString = "";
    }

    *getUserByQueryText() {
        this.usersByQueryText = yield this.UserService.GetUserByQueryTextForModuleAssignment(this.queryText,this.selectedRoleId)
    }    

    *GetUsersByRoleIdForModuleAssignment() {
        this.filteredUsersByRoleId = yield this.UserService.GetUsersByRoleIdForModuleAssignment(this.selectedRoleId, this.selectedStoreId > 0 ? this.selectedStoreId : null)
        this.multipleUserOptions = this.filteredUsersByRoleId.map((user) => {
            return {value: user.ExtendedInfoId, text: user.Name}
        })
    } 

    *UpdateUserRuleModuleAssignmentDueDate() {
        yield this.AdminService.UpdateUserRuleModuleAssignmentDueDate(this.selectedUpdateUserRoleModuleItem)  
        this.GetUserRuleModuleAssignment(this.selectedUpdateUserRoleModuleItem.UserId, this.selectedUpdateUserRoleModuleItem.RoleId);     
        this.showUpdateUserRoleModulePopup = false;
    } 
   
    *SaveModuleAssignment() {
        this.RootStrore.addloading('SaveModuleAssignment');
        this.moduleAssigmentItem = new ModuleAssignment();
        this.moduleAssigmentItem.ModuleId = this.selectedModuleId;
        this.moduleAssigmentItem.RoleId = this.selectedRoleId;
        this.moduleAssigmentItem.Users = this.selectedUsersToSave;              
        this.moduleAssigmentItem.DueDate = this.dueDate;
        const result: number[] = yield this.AdminService.SaveModuleAssignment(this.moduleAssigmentItem);
        if (result.length == 0) {
            this.isModuleRolePairingExistsAtUser = false;
            this.cancelAssignNewModulePopup();		
            this.selectedUsersToSave = [];
            this.selectedRoleId = 0;
            this.selectedModuleId = 0;
            this.dueDate = null;
            this.selectedStoreId = 0;
        }
        else {
            this.errorUserNameStringArray = [];
            this.isModuleRolePairingExistsAtUser = true;     
            result.map((id) => {
                this.errorUserNameStringArray.push(this.selectedUsersToSave.find(user => user.ExtendedInfoId == id).Name + " már rendelkezik ilyen el nem végzett modul-szerepkör összerendeléssel!\n");
            })                   
        }
        this.RootStrore.removeLoading('SaveModuleAssignment');
	}    
    
    *GetRoles(){
        this.RootStrore.addloading('GetRoles');
        this.roles = yield this.UserService.GetRoles();
        this.rolesForDDL = this.roles.map((role) => {
            return {value: role.Id, text: role.Name}
        })
        this.RootStrore.removeLoading('GetRoles');
    }  

    *GetTrainingModules(){
        this.RootStrore.addloading('GetTrainingModules');
        this.modules = yield this.AdminService.GetTrainingModules(true);
        this.modulesForDDL = this.modules.map((module) => {
            return {value: module.Id, text: module.Name}
        })
        this.RootStrore.removeLoading('GetTrainingModules');
    }  

    *GetUserRuleModuleAssignment(userId: number, roleId: number){
        this.RootStrore.addloading('GetUserRuleModuleAssignment');
        this.selectedUserRoleAssigments = yield this.AdminService.GetUserRuleModuleAssignment(userId, roleId);
        this.modulesForDDL = this.modules.map((module) => {
            return {value: module.Id, text: module.Name}
        })
        this.RootStrore.removeLoading('GetUserRuleModuleAssignment');
    }
    
    *GetUserRuleModuleAssignmentById(id: number){
        this.RootStrore.addloading('GetUserRuleModuleAssignmentById');
        this.selectedUpdateUserRoleModuleItem = yield this.AdminService.GetUserRuleModuleAssignmentById(id);        
        this.RootStrore.removeLoading('GetUserRuleModuleAssignmentById');
    }

    *GenerateUserRoleModuleAssigmentExportRiport() {                
        yield this.RiportService.GenerateUserRoleModuleAssigmentExportRiport();
    }    

    SetStores() {        
        this.storesForDDL = this.RootStrore.stores.map((store) => {
            return {value: store.Id, text: store.Name + ' (' + store.SAPCode + ')'}
        })
        this.storesForDDL.sort((a,b) => a.text.localeCompare(b.text, 'en'));
        this.storesForDDL.unshift({value: 0, text: 'Mind'});
        //console.log('this.storesForDDLthis.storesForDDLthis.storesForDDLthis.storesForDDLthis.storesForDDLthis.storesForDDL', this.storesForDDL);
    }

    get getValidationsModuleAssignment() {        

        let validationErrors: Partial<Record<keyof any, string>> = {}

        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() -1);
        if (this.dueDate && this.dueDate < yesterday) {
            validationErrors["DueDate"] = "Határidő nem lehet múltbeli!"
        }        
        yesterday.setHours(23);
        yesterday.setMilliseconds(59);
        yesterday.setSeconds(59);
        if (this.selectedUpdateUserRoleModuleItem && this.selectedUpdateUserRoleModuleItem.Id != 0 && this.selectedUpdateUserRoleModuleItem.DueDate && this.selectedUpdateUserRoleModuleItem.DueDate < yesterday) {
            validationErrors["UpdateUserRoleDueDate"] = "Határidő nem lehet múltbeli!"
        }
        //console.log(validationErrors);
        return validationErrors
    }

    get hasValidationErrors() {
        if (Object.keys(this.getValidationsModuleAssignment).length === 0) {
            return false;
        }

        return true;
    }

    get userByQueryTextDDL() {
        return this.usersByQueryText.map(user => {
            return { title: user.Name + " (" + user.UserCode + ")", item: user };
        })

    }

    get getDisableAddUserToSaveList() {
        if (this.selectedUser == null) {
            return true;
        }
        if (this.selectedUser && this.selectedUsersToSave.some(listUser => listUser.ExtendedInfoId == this.selectedUser.ExtendedInfoId)) {
            return true;
        }
        return false;
    }

    get getSaveEnabled() {
        if (this.selectedRoleId > 0 && this.selectedModuleId > 0 && this.selectedUsersToSave.length > 0 && this.hasValidationErrors == false) {
            return true;
        }
        return false;
    }

    get getUpdateEnabled() {
        if (this.selectedUpdateUserRoleModuleItem && this.selectedUpdateUserRoleModuleItem.DueDate && this.hasValidationErrors == false) {
            return true;
        }
        return false;
    }

    get getSelectedUsers() {
        return this.multipleUserOptions?.filter((user) => user.IsSelected == true);
    }
}