import { action, computed, flow, makeObservable, observable } from "mobx";
import { User } from "../../../models/user";
import { UserInRole } from "../../../models/userInRole";
import { UserService } from "../../../services/user.service";
import { RootStore } from "../../../stores/RootStore";

export class RoleStore {
    private RootStrore: RootStore
    users: Array<UserInRole>;
    usersColumnDef: Array<any>;
    roles: Array<any>;
    queryText: string = "";
    usersByQueryText: Array<User> = [];
    UserService: UserService;
    SelectedUser: User;
    selectedRole: any;
    selectedStore: any;
    showSearch:boolean=true;


    constructor(rootStore?: RootStore) {
        this.RootStrore = rootStore;
        makeObservable(this, {
            users: observable,
            usersColumnDef: observable,
            roles: observable,
            queryText: observable,
            usersByQueryText: observable,
            onChangeQueryText: action,
            getUserByQueryText: flow,
            SelectedUser: observable,
            userByQueryTextDDL: computed,
            update: action,
            adduserToRole: flow,
            selectedRole: observable,
            removeUserFromRole: flow,
            showSearch:observable,
            setUserNull: action,
            setShowSearch: action
        })

        this.usersColumnDef = [
            { field: 'UserName', headerName: 'Név', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' } },
            { field: 'RoleName', headerName: 'Szerepkör', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' } },
            { field: 'StoreName', headerName: 'Áruház', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' } },
            
            {
                field: 'edit', headerName: 'Műveletek', filter: 'agSetColumnFilter', sortable: true, cellStyle: { textAlign: 'center' },
                cellRenderer: 'btnCellRenderer',
                cellRendererParams: {
                    clicked: (data: any) => {
                        if (window.confirm('Biztosan törli a jogosultságot?')) {
                            this.removeUserFromRole(data.UserId, data.RoleId);
                        }
                    },
                },
            },

        ]

        this.UserService = new UserService();

        this.selectedRole = 1


    }

    onChangeQueryText = (event: any, data: any) => {
        this.queryText = data.value
        console.log(this.onChangeQueryText)
        this.getUserByQueryText();
    }

    *getUserByQueryText() {
        this.usersByQueryText = yield this.UserService.GetUserByQueryTextUserManagement(this.queryText,this.selectedRole,this.selectedStore)
    }
    update(key: keyof this, value: any) {
        (this[key] as any) = value;
        if (key == 'selectedRole') {
            if (value < 3 ||value>6 && value!=9) {
                this.selectedStore = "";
            }
            if(value>2 && value <7 || value ==9){
                this.showSearch=false;
                this.SelectedUser=null;
                setTimeout(()=>{this.showSearch=true}, 100);
                //this.showSearch=true;
                // this.update('SelectedUser', null)
            }
        }
        
    }
    setUserNull() {
        this.SelectedUser = null;
        this.queryText = "";
        this.showSearch = !this.showSearch;
        // this.showSearch = false;
        console.log("this.SelectedUser", this.SelectedUser)        
    }    

    setShowSearch(value: boolean) {
        setTimeout(()=>{}, 100);
        this.showSearch = value;
    }
    *removeUserFromRole(userId: any, roleId: any) {
        this.RootStrore.addloading('removeUserFromRole');
        let userInRole = new UserInRole();
        userInRole.UserId = userId;
        userInRole.RoleId = roleId;
        yield this.UserService.RemoveuserFormRole(userInRole);
        this.users = yield this.UserService.GetUserInRoles();
        this.RootStrore.removeLoading('removeUserFromRole');
    }
    *adduserToRole() {
        this.RootStrore.addloading('adduserToRole');
        let userInRole = new UserInRole();
        userInRole.UserId = this.SelectedUser.UserGuid;
        userInRole.RoleId = this.selectedRole;
        userInRole.StoreCode=this.selectedStore;
        yield this.UserService.AddUserToRole(userInRole);
        this.users = yield this.UserService.GetUserInRoles();
        this.RootStrore.removeLoading('adduserToRole');
    }
    get userByQueryTextDDL() {
        return this.usersByQueryText.map(user => {
            return { title: user.Name + " (" + user.UserName + ")", item: user };
        })

    }
}