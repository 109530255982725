import { action, flow, makeAutoObservable, makeObservable, observable } from "mobx";
import { AdminService } from "../../../services/admin.service";

export class BannerStore {
    
    isUploadConfirmOpen1: boolean = false;
    isUploadConfirmOpen2: boolean = false;
    isUploadConfirmOpen3: boolean = false;
    isUploadConfirmOpen4: boolean = false;
    // isUploadConfirmOpen5: boolean = false;
	bannerByteContent1: { content: string};
	bannerByteContent2: { content: string};
	bannerByteContent3: { content: string};
	bannerByteContent4: { content: string};
	// bannerByteContent5: { content: string};
    uploadIdString: string = "uploadId";    
    file: any;
    AdminService: AdminService
    isSuccedeed: boolean = false;

    constructor(){
        makeAutoObservable(this, {            
            isUploadConfirmOpen1: observable,
            isUploadConfirmOpen2: observable,
            isUploadConfirmOpen3: observable,
            isUploadConfirmOpen4: observable,
            // isUploadConfirmOpen5: observable,
            uploadIdString: observable,            
            isSuccedeed: observable,            
            cancelUpload: action,
            setIsUploadConfirmOpen: action,
            getBanners: flow,
            confirmUpload: flow,            
        })
        
        this.AdminService = new AdminService()
    }        

    cancelUpload = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, type: number) => {
        if (type == 1) {
            this.isUploadConfirmOpen1 = false;        
        }  
        if (type == 2) {
            this.isUploadConfirmOpen2 = false;        
        }  
        if (type == 3) {
            this.isUploadConfirmOpen3 = false;        
        }  
        if (type == 4) {
            this.isUploadConfirmOpen4 = false;        
        }  
        // if (type == 5) {
        //     this.isUploadConfirmOpen5 = false;        
        // }        
    }

    setIsUploadConfirmOpen = (type: number) => {
        if (type == 1) {
            this.isUploadConfirmOpen1 = true;        
        }  
        if (type == 2) {
            this.isUploadConfirmOpen2 = true;        
        }  
        if (type == 3) {
            this.isUploadConfirmOpen3 = true;        
        }  
        if (type == 4) {
            this.isUploadConfirmOpen4 = true;        
        }  
        // if (type == 5) {
        //     this.isUploadConfirmOpen5 = true;        
        // }            
    }    

    setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.file = (<HTMLInputElement>document.getElementById(this.uploadIdString)).files[0];
        console.log('Kiválasztott file:', this.file)
    }

    *confirmUpload(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, type: number) {        
        if (type == 1) {
            this.isUploadConfirmOpen1 = false;        
        }  
        if (type == 2) {
            this.isUploadConfirmOpen2 = false;        
        }  
        if (type == 3) {
            this.isUploadConfirmOpen3 = false;        
        }  
        if (type == 4) {
            this.isUploadConfirmOpen4 = false;        
        }  
        // if (type == 5) {
        //     this.isUploadConfirmOpen5 = false;        
        // }
        let formData: FormData = new FormData();
        this.file = (document.getElementById(this.uploadIdString) as HTMLInputElement).files[0];
        formData.append('uploadFile', this.file, this.file.name);
        var realType: number = type +1
        yield this.AdminService.UploadBanner(formData, realType); 
        console.log(this.file)
        this.isSuccedeed = true;
        this.getBanners();
    }

    *getBanners() {
		this.bannerByteContent1 = yield this.AdminService.GetBannerByContent(1)
		this.bannerByteContent2 = yield this.AdminService.GetBannerByContent(2)
		this.bannerByteContent3 = yield this.AdminService.GetBannerByContent(3)
		this.bannerByteContent4 = yield this.AdminService.GetBannerByContent(4)
		// this.bannerByteContent5 = yield this.AdminService.GetBannerByContent(5)
        // console.log('this.bannerByteContent1',this.bannerByteContent1)
        // console.log('this.bannerByteContent2',this.bannerByteContent2)
        // console.log('this.bannerByteContent3',this.bannerByteContent4)
        // console.log('this.bannerByteContent4',this.bannerByteContent4)
        // console.log('this.bannerByteContent5',this.bannerByteContent5)      
    }
}